import React from 'react';
import { Form, Button, Col, Select, DatePicker, Card, Divider, message } from 'antd';
import BackPage from '../../../../../common/backPage';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './node-monitor.scss'


const { RangePicker } = DatePicker;

let searchStartTime = '';
let searchEndTime = '';

function Search(props) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log(searchStartTime)
        console.log(searchEndTime)
        props.onClick();
    };
    const onChange = (values, datastring) => {
        searchStartTime = datastring[0];
        searchEndTime = datastring[1];
        props.onClick();
    };
    return (
        <>
            <div className="monitor-search">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish} >
                    <Col>
                        <Form.Item name='uuid' label='时间段' style={{ float: 'left', marginRight: '7.14px' }}>
                            <RangePicker
                                style={{ width: 328, height: 28 }}
                                locale={locale} onChange={onChange} allowClear
                                showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Form>
            </div>
        </>
    );
}

class  EdgeEffectMonitor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nodeMonitorName: '查看监控',
            nodeId: null,
        };
    }
    goback() {
        if (this.props.onSubmit) {
            var { showManage } = this.state;
            this.props.onSubmit({ showManage })
        }
        this.props.history.go(-1);
    }
    drawCharts(cpuTime, cpuValue, gpuValue, diskValue, memValue) {
        const chartOptionCpu = {
            title: {
                text: '',
                subtext: ''
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: cpuTime
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                }
            },
            series: [{
                data: cpuValue,
                type: 'line',
                areaStyle: {
                    normal: {
                        color: "#2F80ED"
                    }
                },

                smooth: true
            }]
        }
        const chartOptionGpu = {
            title: {
                text: '',
                subtext: ''
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: cpuTime
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                }
            },
            series: [{
                data: gpuValue,
                type: 'line',
                areaStyle: {
                    normal: {
                        color: "#2F80ED"
                    }
                },

                smooth: true
            }]
        }
        const chartOptionMem = {
            title: {
                text: '',
                subtext: ''
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: cpuTime
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                }
            },
            series: [{
                data: memValue,
                type: 'line',
                areaStyle: {
                    normal: {
                        color: "#2F80ED"
                    }
                },

                smooth: true
            }]
        }
        const chartOptionDisk = {
            title: {
                text: '',
                subtext: ''
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: cpuTime
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                }
            },
            series: [{
                data: diskValue,
                type: 'line',
                areaStyle: {
                    normal: {
                        color: "#2F80ED"
                    }
                },

                smooth: true
            }]
        }
        // myChart1.setOption(chartOptionCpu);
        // myChart2.setOption(chartOptionGpu);
        // myChart3.setOption(chartOptionMem);
        // myChart4.setOption(chartOptionDisk);
        this.state.cpuChart.setOption(chartOptionCpu);
        this.state.gpuChart.setOption(chartOptionGpu);
        this.state.memChart.setOption(chartOptionMem);
        this.state.diskChart.setOption(chartOptionDisk);
    }
    componentDidMount() {
     
        const echarts = require('echarts');
        const myChart1 = echarts.init(document.getElementById('chart1'));
        const myChart2 = echarts.init(document.getElementById('chart2'));
        const myChart3 = echarts.init(document.getElementById('chart3'));
        const myChart4 = echarts.init(document.getElementById('chart4'));
        this.setState({
            cpuChart: myChart1,
            gpuChart: myChart2,
            memChart: myChart3,
            diskChart: myChart4
        });
       // this.getMonitor(nodeId);
    }
    getMonitor(nodeId) {
 
                this.drawCharts([], [], [], [], []);
           
    }
    render() {
        return (
            <>
                <div className="node-log-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.nodeMonitorName} itemTitle="边缘节点" itemSubTitle="查看监控" />
                    <Search onClick={() => this.getMonitor()} />
                    <div className="monitoring-echarts">
                        <Card>
                            <h3>
                                <span>CPU使用率</span>
                            </h3>
                            <Divider />
                            <div id="chart1" style={{ height: 320, width: 520 }}></div>
                        </Card>
                        <Card>
                            <h3>
                                <span>GPU使用率</span>
                            </h3>
                            <Divider />
                            <div id="chart2" style={{ height: 320, width: 520 }}></div>
                        </Card>
                        <Card className="mt12">
                            <h3>
                                <span>内存使用率</span>
                            </h3>
                            <div id="chart3" style={{ height: 320, width: 520 }}></div>
                        </Card>
                        <Card className="mt12">
                            <h3>
                                <span>硬盘使用率</span>
                            </h3>
                            <div id="chart4" style={{ height: 320, width: 520 }}></div>
                        </Card>
                    </div>

                </div>
            </>
        )
    }
}
export default EdgeEffectMonitor;