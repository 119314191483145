import React from 'react';
import { Form, Button, Table, Pagination, ConfigProvider, DatePicker, Select } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { AUTCONFIG } from '../../../../../utils/auth.config';
import { userService } from '../../../../services/user.service';
import store from '../../../../../store';

import './../../../../../index.scss';


const { RangePicker } = DatePicker;
const { Option } = Select;

let searchStarTime = '';
let searchEndTime = '';
let searchUser = '';
let page = 1;
let pageSize = 10;

function TopSearch(props) {
  const form = React.createRef();
  const onFinish = (values) => {
    page = 1;
    searchUser = values.username || '';
    props.onClick();
  };
  const resetForm = () => {
    form.current.resetFields();
    searchStarTime = '';
    searchEndTime = '';
    searchUser = '';
    page = 1;
    props.onClick();
  }
  const onChange = (values, datastring) => {
    searchStarTime = datastring[0];
    searchEndTime = datastring[1];
    props.onClick();
  };

  return (
    <>
      <div className="log-search-form ">
        <Form
          ref={form}
          name="advanced_search_user"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          layout="inline"
          // style={{ justifyContent: 'center' }}
          >
          <Form.Item name='time' label='选择时间' style={{ float: 'left', marginRight: '7.14px', marginTop: '8px' }}>
            <RangePicker
              style={{ width: 300, height: 28 }}
              locale={locale} onChange={onChange}
              showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          {
            store.getState().currentUser.type !== AUTCONFIG.SUB.value ?
              <Form.Item name='username' label='所属用户' style={{ marginTop: '8px' }}>
                <Select 
                style={{ width: '200px' }} 
                placeholder="请选择所属用户"
                showSearch
                filterOption={(input, option) =>
                  option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                >
                  {props.tenantList}
                </Select>
              </Form.Item>
              : ''
          }
          <Form.Item style={{ marginTop: '8px' }}>
            <Button type="primary" htmlType="submit"> 查询 </Button>
            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

class ActionLog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logList: [],
      logAmount: 0,
      loading: false,
      page: 1,
      pageSize: 10,

      tenantList: [],
    };
  }

  searchList() {
    console.log('selected user:' + searchUser);
    console.log('selected start time:' + searchStarTime);
    console.log('selected end time:' + searchEndTime);

    console.log('selected page:' + page);
    console.log('selected pageSize:' + pageSize);

    this.setState({
      logList: [
        { id: 1, obj: '边缘节点：node1', upTime: '2021-05-25 10:24:00', result: '禁用成功', content: '禁用', username: 'user' },
        { id: 2, obj: '边缘节点：application1', upTime: '2021-05-25 10:24:00', result: '停止成功', content: '停止', username: 'user1' },
        { id: 3, obj: '边缘节点：application2', upTime: '2021-05-25 10:24:00', result: '删除成功', content: '删除', username: 'user2' },
      ],
      logAmount: 3
    })
  }
  componentWillMount() {
    this.searchList();
    this.getTenantList();
  }
  getTenantList() {
    userService.getAllTenantList().then(res => {
      if (res.code === 200) {
        const tenants = res.users;
        this.setState({
          tenantList: this.getTenantItem(tenants),
        });
      }
    });
  }
  getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id} >{item.realName}/{item.username}</Option>
      );
    });
  }
  componentDidMount() {
    pageSize = 10;
    page = 1;
    this.searchList();
  }

  // gotoDetail = (text, key) => {
  //   this.props.history.push({ pathname: '/home/sys/user-manage/detail', state: { id: text } });
  // }

  render() {
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.searchList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    }

    const columns = [
      {
        title: '操作对象',
        dataIndex: 'obj',
      },
      {
        title: '操作内容',
        dataIndex: 'content',
      },
      {
        title: '操作结果',
        dataIndex: 'result',
      },
      {
        title: '操作人',
        dataIndex: 'username',
      },
      {
        title: '操作时间',
        dataIndex: 'upTime',
      }
    ];

    return (
      <>
        <div className="common-pd20 task-log-container">
          <div >
            <h1 className="common-top-title0">操作日志</h1>
            <div className="common-center-container">
              <TopSearch onClick={() => this.searchList()} tenantList={this.state.tenantList} />
              <div className="mt32">
                <Table
                  rowKey={record => record.id}
                  bordered={false}
                  tableLayout="fixed"
                  pagination={false}
                  columns={columns}
                  dataSource={this.state.logList}
                />
              </div>
              <div>
                {
                  this.state.logList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        defaultCurrent={1}
                        current={page}
                        pageSize={pageSize}
                        total={this.state.logAmount}
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}


export default ActionLog;
