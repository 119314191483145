import { handleResponse } from './handle-response';
import store from '../../store/index';

export const otaService = {
    getOtaList,
    getOtaDetail,
    removeOta,

    addOta,
    editOta,

    getAllOtaList,
    getShellList,
    getRecordList,
    getStateRecordList,
    addShell,
    updateShell,
    getShellDetail,
    removeShell
};
/**
 * 查询指定的产品测试脚本列表
 * @param {*} productId 
 * @param {*} scriptName 
 * @param {*} limit 
 * @param {*} offset 
 * @returns 
 */
function getShellList(productId, scriptName, limit, offset) {
    return fetch(`/v1/console/products/${productId}/scripts?scriptName=${scriptName || ''}&productId=${productId || ''}&offset=${offset || 1}&limit=${limit || 20}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
/**
 * 获取脚本详情
 * @param {*} id 
 * @returns 
 */
function getShellDetail(id) {
    return fetch(`/v1/console/products/scripts/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

/**
 * 获取测试记录
 * @param {*} productId 
 * @param {*} seriesNumber 
 * @param {*} state 
 * @param {*} startTime 
 * @param {*} endTime 
 * @param {*} limit 
 * @param {*} offset 
 * @returns 
 */
function getRecordList(productId, seriesNumber, state, startTime, endTime,  limit, offset) {

 //   startTime && (startTime = (new Date(startTime)).getTime());
  //  endTime && (endTime = (new Date(endTime)).getTime())
    return fetch(`/v1/console/products/${productId}/scripts/results?seriesNumber=${seriesNumber || ''}&state=${state || ''}&startTime=${startTime || ''}&endTime=${endTime || ''}&offset=${offset || 1}&limit=${limit || 20}`, {
        method: 'GET',
        headers: {
          
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
/**
 * 添加脚本
 * @param {*} productId 
 * @param {*} parsms 
 * @returns 
 */
function addShell(productId, parsms) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify(parsms)
    };
    return fetch(`/v1/console/products/${productId}/scripts`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
/**
 * 更新脚本
 * @param {*} id 
 * @param {*} parsms 
 * @returns 
 */
function updateShell(id, parsms) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify(parsms)
    };
    return fetch(`/v1/console/products/scripts/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
/**
 * 删除脚本
 * @param {*} id 
 * @returns 
 */
function removeShell(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/products/scripts/${id}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
/**
 * 查询指定的产品测试脚本运行结果列表
 * @param {*} params 
 * @returns 
 */
function getStateRecordList(params) {
    return fetch(`/v1/console/products/scripts/results/${params.id}?scriptName=${params.name || ''}&state=${params.state || ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

function getAllOtaList() {
    return fetch(`/v1/console/products/list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
function getOtaList(name, model, offset, limit, searchAdminId) {
    return fetch(`/v1/console/products?name=${name || ''}&model=${model || ''}&userId=${searchAdminId || ''}&offset=${offset || 1}&limit=${limit || 20}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
function getOtaDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/products/${id}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}

function removeOta(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/products/${id}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
function addOta(name, model, description, adminId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name || '',
            model: model || '',
            description: description || '',
            userId: adminId || ''
        })
    };
    return fetch(`/v1/console/products`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function editOta(id, name, model, description, adminId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name || '',
            model: model || '',
            description: description || '',
            userId: adminId || ''
        })
    };
    return fetch(`/v1/console/products/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
