import { Form, Input, Col, Row, message, Select, DatePicker, Button, Table, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';

import React from 'react';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

const { TextArea } = Input;
const { Option } = Select;

const { confirm } = Modal;
const formItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4},
  wrapperCol: { xs: 24, sm: 12, md: 15},
};

class UpgradeM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chooseNodeList: [
                {
                    id: 1,
                    name: '节点1',
                    description: '描述'
                }, {
                    id: 2,
                    name: '节点2',
                    description: '描述'
                }
            ],
            nodeList: [
                {
                    id: 1,
                    name: '节点1',
                    description: '描述'
                }, {
                    id: 2,
                    name: '节点2',
                    description: '描述'
                }
            ],
            isNodeVisible: false
        };
    }
    goback() {
        this.props.history.push('/home/effect-edge-m/list');
    }
    removeNodeFromList(id) {
        confirm({
            title: '确定删除吗?',
            icon: <ExclamationCircleOutlined />,
            content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
            closable: true,
            onOk: () => {
              message.success('删除成功');
              
            },
            onCancel() {
      
            },
          });
    }
    showNodeModal() { // node start
        this.setState({ isNodeVisible: true, isFirmwareVisible: false });
      }
    handleCancel = () => {
        // nodeActions = [];
        // zheli
        this.setState({ isNodeVisible: false, isFirmwareVisible: false });
      };
    render() {
        const nodeListColumns = [
            {
              title: '节点名称',
              dataIndex: 'name',
              key: 'name',
              render: text => <>{text}</>,
            },
          
            {
              title: '描述',
              dataIndex: 'description',
              key: 'description',
            },
            {
              title: '操作',
              key: 'action',
              render: (text, record) => (
                <>
                  <a href onClick={() => this.removeNodeFromList(record.id)}>删除</a>
                </>
              )
            },
          ];
        return (
            <>
             <div className="common-detail-container">
               <BackPage onClick={() => this.goback()} headeTitle='应用升级' itemTitle="应用管理" itemSubTitle="应用管理" />
               <div className="common-edit-card">
               <Form name="basic"
                labelAlign="left"
                {...formItemLayout} >
                     <Row>
                        <Col span={12}>
                            <Form.Item name="name" label="应用名称" rules={[{ required: true, message: '应用名称不能为空' }]} >
                                <Input placeholder="请输入应用名称" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="name" label="应用镜像" rules={[{ required: true, message: '应用名称不能为空' }]} >
                            <Select
                                    placeholder="选择应用镜像">
                                <Option value="male">unbantu-app</Option>
                                <Option value="female">linux</Option>
                                <Option value="other">other</Option>
                            </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="name" label="镜像版本" rules={[{ required: true, message: '应用名称不能为空' }]} >
                            <Select
                                placeholder="选择镜像版本">
                                <Option value="male">18.12</Option>
                                <Option value="female">20.12</Option>
                                <Option value="other">13.15</Option>
                            </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
               </div>
               <div className="job-bottom-card">
            <Row className="job-bottom-card-title" 
            style={{ height: 48, display:'flex',
             justifyContent: 'space-between', paddingRight: 24
            }}> 
            <div>
            <span className="common-star-red">*</span>部署节点
            <Button type="primary" style={{ marginLeft: 36  }} onClick={() => {
                    this.showNodeModal();
                }}>选择节点</Button>
                 </div>
                <div>
                <Form.Item label='标签' name="label" >
                    <Select mode="multiple" allowClear style={{ width: '260px' }} placeholder="请选择标签" showArrow >
                   
                    </Select>
                  </Form.Item>
                </div>
                 
            </Row>
            <div className="job-bottom-content">
             
              <Table columns={nodeListColumns}
                rowKey={record => record.id}
                pagination={false}
                dataSource={this.state.chooseNodeList}
                scroll={{ y: 240 }}
              />
            </div>
          </div>
          <Modal width={800} title="选择升级节点" visible={this.state.isNodeVisible} onOk={() => this.handleCancel()} onCancel={() => this.handleCancel()} okText="确定" cancelText="取消">
          <NodeModal nodeList={this.state.nodeList}/>
        </Modal>
            </div>
            </>
        );
    }
}

function NodeModal(props) {
    const [form] = Form.useForm();
    const nodeColumns = [
      {
        title: '节点名称',
        dataIndex: 'name',
        key: 'name',
      },
    
      {
        title: '描述',
        dataIndex: 'description',
        key: 'description',
      },
    ];
    const onFinish = (values) => {
   
      
    };
    const resetForm = () => {
      form.resetFields();
  
     
    }
    const rowSelection = {
     
    };
    return (
      <>
        <div className="common-search-form">
          <Form
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            layout="inline"
            style={{justifyContent:'center', marginBottom: 12}}
            onFinish={onFinish} >
         
              <Form.Item name='searchNodeName' label='节点名称' style={{  marginTop: 8  }}>
                <Input placeholder="请输入关键字" />
              </Form.Item>
             
            
              <Form.Item style={{ marginTop: 8 }}>
                <Button type="primary" htmlType="submit"> 查询 </Button>
                <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
              </Form.Item>
          
          </Form>
        </div>
        <Table
          rowSelection={{
            ...rowSelection,
          }}
          pagination={false}
          rowKey={record => record.id}
          scroll={{ y: 240 }}
          columns={nodeColumns} dataSource={props.nodeList} />
      </>
    );
  }


export default UpgradeM;