import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './basis-setting.scss';
import { Button, Card, Col, Input, Menu, Row, Form, message, Select } from 'antd';
import { loginService } from '../../../../services/login';
import {userService} from '../../../../services/user.service';
import store from '../../../../../store/index';

import { getCurrentAction } from '../../../../../store/actionCreators';
import { connect } from "react-redux";
import { AUTCONFIG , getDisplayName} from '../../../../../utils/auth.config';
import { Router } from 'react-router';
const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};
const { Option } = Select;
let isEdit = false;
let currentUser = null;
class BasisSetting extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            companyList:[],
            companyname: ''
        };
    }
    componentDidMount() {
        this.getCurrentUser();
        this.getCompanyList();
    }
    getCompanyList() {
        userService.getCompanyList().then(res => {
            const getCompanyItem =  (companys) => {
                return companys.map((item, index) => {
                    return (
                      <Option key={item.id} value={item.id}>{item.name}</Option>
                    );
                  });
            }
            if (res.code === 200) {
              const companys = res.companys;
              this.setState({
                companyList: getCompanyItem(companys),
              });
            }
          });
      }
    goBack() {
        this.props.history.push( sessionStorage.getItem('lastPath') || '/home/first-page')
    }
    getCurrentUser() {
        loginService.getCurrentUser().then(res => {
            if (res.code === 200) {
                currentUser = res.users;
                this.setState({
                    updateTime: currentUser.updateTime,
                    createTime: currentUser.createTime,
                    username: currentUser.username,
                    companyname: currentUser.company?.name,
                });

                const action2 = getCurrentAction(currentUser);
                store.dispatch(action2);
                this.formRef.current?.setFieldsValue({
                    realName: currentUser.realName,
                    mobile: currentUser.mobile,
                    email: currentUser.email,
                    companyId: currentUser.company?.id,
                    address: currentUser.address,
                });
              
            }
            //  else {
            //     message.error('获取当前用户失败，' + res.message);
            // }
        }, error => {
            error && message.error(error);
        });
    }
    render() {
        const onFinish = (values) => {
           
            loginService.changeCurrentUserMsg(values.realName, values.mobile, values.email, values.companyId, values.address).then(res => {

                if (res.code === 200) {
                    message.success('修改用户信息成功');
                    this.getCurrentUser();
                    this.goBack();
                } 
                // else {
                //     message.error('修改用户信息失败，' + res.message);
                // }
            }, error => {
               error && message.error(error);
            });
        };
        // const resetForm = () => {
        //     this.formRef.current.resetFields();
        // };
        return (
            <>
                <Form
                    ref={this.formRef}
                    name="validate_other"
                    labelAlign="left"
                    {...formItemLayout}
                    onFinish={onFinish}
                    style = {{padding: '20px 40px'}}
                    initialValues={{
                        'input-number': 3,
                        'checkbox-group': ['A', 'B'],
                        rate: 3.5,
                    }}>
                    <Form.Item label="账号名">
                        {this.state.username}
                    </Form.Item>
                    <Form.Item label="创建时间" >
                        {this.state.createTime || '--'}
                    </Form.Item>
                    <Form.Item label="更新时间" >
                        {this.state.updateTime || '--'}
                    </Form.Item>
                    {this.props.currentUser?.type === AUTCONFIG.MAIN.value 
                    || this.props.currentUser?.type === AUTCONFIG.SUB.value ? 
                    <Form.Item label="企业" >
                        {this.state.companyname || '--'}
                    </Form.Item> : ''}
                    <Form.Item name="realName" label="用户名称" rules={[{ required: true, message: '用户名称不能为空' },{ pattern: /^[\u0391-\uFFE5\w_-]{0,64}$/, message: '请输入64位以内中英文/数字/中划线/下划线' }]}>
                        <Input placeholder="请输入用户名称" />
                    </Form.Item>
                    <Form.Item name="mobile" label="手机号码" rules={[{ required: true, message: '手机号码不能为空' },{ pattern: /^[1][3,4,5,7,8][0-9]{9}$/, message: '请输入正确的手机号' }]}>
                        <Input placeholder="请输入手机号码" />
                    </Form.Item>
                    <Form.Item name="email" label="电子邮箱" rules={[{ type: 'email', message: '请输入正确的电子邮箱' }]}>
                        <Input placeholder="请输入邮箱" />
                    </Form.Item>
                    {/* {this.props.currentUser?.type !== 'SUB' ? 
                    <Form.Item name="companyId" label="企业"  rules={[{ required: true, message: '请选择企业' }]}>
                    <Select placeholder="请选择企业" showSearch  allowClear
                                        filterOption={(input, option) =>
                                        option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.companyList}
                                    </Select>
                    </Form.Item> : ''} */}
                    <Form.Item name="address" label="联系地址"  rules={[{ pattern: /^(a-z|A-Z|0-9)*[^$%^&*;:,<>?()\""\']{0,50}$/, message: '请输入50位以内字符' }]}>
                        <Input placeholder="请输入联系地址" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 3 }}>
                        <Button type="primary" htmlType="submit"> 确定 </Button>
                      
                       <Button style={{ marginLeft: 24 }} onClick={() => this.goBack()}>取消</Button>
                     
                      
                        
                    </Form.Item>
                </Form>
            </>
        );
    }
}



// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };
// const tailLayout = {
//     wrapperCol: { offset: 8, span: 16 },
// };

// function View(props) {
//     return (
//         <>
//             <Form
//                 {...layout}
//                 name="basic"
//                 initialValues={{ remember: true }}
//             >
//                 <Form.Item
//                     label="Username"
//                     name="username"

//                 >
//                     <Input />
//                 </Form.Item>

//                 <Form.Item
//                     label="Password"
//                     name="password"
//                     rules={[{ required: true, message: 'Please input your password!' }]}
//                 >
//                     <Input.Password />
//                 </Form.Item>

//                 <Form.Item {...tailLayout} name="remember" valuePropName="checked">
//                 </Form.Item>

//                 <Form.Item {...tailLayout}>
//                     <Button type="primary" htmlType="submit">
//                         Submit
//         </Button>
//                 </Form.Item>
//             </Form>
//         </>
//     );
// }
// function Edit(props) {
//     const [form] = Form.useForm();
//     const onFinish = (values) => {
//         console.log(values)
//         props.onClick();
//     };
//     const resetForm = () => {
//         form.resetFields();
//         props.onClick();
//     };
//     return (
//         <>
//             <Form
//                 ref={this.formRef}
//                 name="validate_other"
//                 labelAlign="left"
//                 {...formItemLayout}
//                 onFinish={onFinish}
//                 initialValues={{
//                     'input-number': 3,
//                     'checkbox-group': ['A', 'B'],
//                     rate: 3.5,
//                 }}>
//                 <Form.Item name="username" label="用户名" rules={[{ required: true, message: '用户名不能为空' }]}>
//                     <Input />
//                 </Form.Item>
//                 <Form.Item name="name" label="姓名" rules={[{ required: true, message: '姓名不能为空' }]}>
//                     <Input />
//                 </Form.Item>
//                 <Form.Item name="phone" label="手机号码" rules={[{ required: true, message: '手机号码不能为空' }]}>
//                     <Input />
//                 </Form.Item>
//                 <Form.Item name="email" label="邮箱" rules={[{ required: true, message: '邮箱不能为空' }]}>
//                     <Input />
//                 </Form.Item>
//                 <Form.Item name="companyName" label="企业名称" rules={[{ required: true, message: '企业名称不能为空' }]}>
//                     <Input />
//                 </Form.Item>
//                 <Form.Item name="address" label="联系地址" rules={[{ required: true, message: '联系地址不能为空' }]}>
//                     <Input />
//                 </Form.Item>
//                 <Form.Item name="registeredTime" label="注册时间">
//                     <Input />
//                 </Form.Item>
//                 <Form.Item name="updateTime" label="更新时间" >
//                     <Input />
//                 </Form.Item>
//                 <Form.Item wrapperCol={{ span: 12, offset: 3 }}>
//                     <Button type="primary" htmlType="submit"> 提交 </Button>
//                     <Button style={{ marginLeft: 24 }} onClick={resetForm}>取消</Button>
//                 </Form.Item>
//             </Form>
//         </>
//     );
// }
// export default BasisSetting;


export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(BasisSetting);