export const AUTCONFIG = {
    'SUPERADMIN': {
        displayName: '超级管理员',
        value: 'SUPERADMIN'
    },
    'ADMIN': {
        displayName: '管理员',
        value: 'ADMIN'
    },
    'MAIN': {
        displayName: '主用户',
        value: 'MAIN'
    },
    'SUB': {
        displayName: '子用户',
        value: 'SUB'
    }
};


export const getDisplayName = (type) => {
    let stringName  = '';
    Object.keys(AUTCONFIG).forEach(elx => {
      if(AUTCONFIG[elx].value === type) {
         stringName = AUTCONFIG[elx].displayName
      }
    });
    return stringName;
}

// eslint-disable-next-line import/no-anonymous-default-export
//export const AUTCONFIG;