/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Select, message, Row, Col, InputNumber,     Card , Divider,  Upload  } from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import './../../../../../../index.scss';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class DeployEdgeEffectTemplate extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      showManage: true,
      hasSubmit: false
    };
  }
  goback() {
   
    this.props.history.push('/home/effect-edge-t/list');
  }
  onSelectTypeChange(value) {
    console.log(value)
  }
  componentDidMount() {
    
  }
  
  render() {
    const onFinish = (values) => {
        message.success('创建模板成功');
        this.goback();
    };
    return (
      <>
        <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="部署应用" itemTitle="应用管理" itemSubTitle="创建用户" />
        <div className="common-create-center-container">
           
          <div className="common-create-page">
          
       
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish} >
              <Form.Item name="userName" label="模板名称" rules={[{ required: true, message: '请输入模板名称' }]} >
                <Input placeholder="请输入模板名称" />
              </Form.Item>
              <Form.Item name="description" label="描述" >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                </Form.Item>
                <Form.Item name="version" label="部署节点" >
                <Select
                placeholder="请选择部署节点"
                      initialvalue="male"  >
                    <Option value="male">node-app</Option>
                    <Option value="female">node-linux</Option>
                    <Option value="other">other</Option>
                </Select>
              </Form.Item>
             
          
            
              <BottomBtnGroup onClick={() => this.goback()} />
           
            </Form>
        
       
          </div>
        </div>
        </div>
      </>
    );
  }
}

//export default CreateUser;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(DeployEdgeEffectTemplate);