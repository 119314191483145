import React, {Component} from 'react';

import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import BottomBtnGroup from '../../../../../common/bottomBtnGroup';
import { edgeService } from '../../../../../../services/edge.service';

import '../../../../../../../index.scss';
const { Option } = Select;
const { confirm } = Modal;

export default class EditApplicationDemo extends Component {

  state = {
    appPorts: [],
    loading: false,
    portVisible: false,//新增端口的弹框
    type: 'TCP',

    name: '',
    version: '',
    networkType: '',
  }

  goback() {
    //this.props.history.push('/home/effect-edge-demo/list');
    sessionStorage.clear()
    this.props.onClick()
  }

  //改变选择的状态
  onSelectTypeChange(value) {
    //console.log(value);
    this.setState({
      type: value
    })
  
  }

  //匹配端口状态
  getPortType = (record) => {
    if (record.type === 0) {
      return <span>TCP</span>
    } else if (record.type === 1) {
      return <span>UDP</span>
    }
  }

  //将类型转换为数字
  changType(obj) {
    if (obj.type === 'TCP') {
      obj.type=0
    } else if (obj.type === 'UDP') {
      obj.type=1
    }
  }

  //查询端口信息
  getAppPorts(id) {
    edgeService.getEdgePorts(id).then(res => {
      if (res.code === 200) {
          console.log("端口数组信息",res.ports);
        
          this.setState({
            appPorts: res.ports,
            loading: true
          })
      }  
      // else {
      //   this.setState({
      //     loading: false
      //   })
      // }
  }).catch(error => {
    this.setState({
      loading: false
    })
    error && message.error(error);
  })
  }

  //添加端口
  addAppPort(id, appPort) {
    edgeService.addEdgePort(id, appPort).then(res => {
      if (res.code === 200) {
        console.log(res);

        //重新刷新端口列表
        this.getAppPorts(this.props.id)
        message.success('添加成功');

        this.setState({
          loading: true,
          portVisible: false,
        })
    }  
    // else {
    //   this.setState({
    //     loading: false
    //   })
    // }
    }).catch(error => {
      this.setState({
        loading: false
      })
      error && message.error(error);
    })
  }

  //删除端口接口调用
  deleteAppPort(id, portId) {
    edgeService.deleteEdgePort(id, portId).then(res => {
      if (res.code === 200) {
        console.log(res);

        //重新刷新端口列表
        this.getAppPorts(this.props.id)
        message.success('删除成功');

        this.setState({
          loading: true
        })
    }  
    // else {
    //   this.setState({
    //     loading: false
    //   })
    // }
    }).catch(error => {
      this.setState({
        loading: false
      })
      error && message.error(error);
    })
  }


  //修改应用
  editApp(id, name, description, version, cmd, networkType) {
    edgeService.editEdge(id, name, description, version, cmd, networkType).then(res => {
      if (res.code === 200) {
        console.log(res);
        message.success("修改成功")
      } 
    }).catch(error => {
      error && message.error(error);
  })
    
  }

  componentDidMount() {
    //页面加载端口
    this.getAppPorts(this.props.id)
  }


  //添加端口
  addPort = (e) => {
    e.type=this.state.type
    this.changType(e)
    console.log("添加的端口的信息", e);
    this.addAppPort(this.props.id, e)
  }

  //删除端口
  deletePort = (text, record) => {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        console.log("要删除的信息", record);
        console.log(record.id);
        this.deleteAppPort(this.props.id, record.id)
      },
      onCancel() {

      },
    });
    
  }

  //弹窗添加端口
  showPort() {
    this.setState({
      portVisible: true
    })
  }

  //弹窗的取消设置
  handleOk(e) {
    this.setState({
        portVisible: false,
    })
  }

  
  render() {
    console.log(this.props);
    const portColumn = [
      {
        title: '容器端口',
        dataIndex: 'containerPort',
      },
      {
        title: '主机端口',
        dataIndex: 'hostPort',
      },
      {
        title: '类型',
        dataIndex: 'type',
        render: (text, record) => (
          this.getPortType(record)
        )
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) =>  <a href onClick={() => this.deletePort(text,record)} >删除</a>
      }
    ];

    const layout = {
      labelCol: { xs: 24, sm: 12, md: 8, lg: 2, xl: 2},
      wrapperCol: { xs: 24, sm: 12, md: 15},
    };

    const layout2 = {
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
    };

    const onFinish = () => {
      console.log('session里的全局信息description', sessionStorage.getItem('description'));
      console.log('session里的全局信息command', sessionStorage.getItem('command'));
      this.editApp(this.props.id, this.props.name, sessionStorage.getItem('description'), this.props.version, sessionStorage.getItem('command'), this.props.networkType)
      this.props.onClick()
    }


    return (
      <div>

        <Form
          {...layout}
          labelAlign="left"
          onFinish={onFinish}>
          <Form.Item label="端口映射" style={{marginBottom: 12}}>
                    <Button onClick={() => this.showPort()} type="primary" style={{color: '#fff'}} > 新增 </Button>
              </Form.Item>
              <div style={{ marginLeft: 40 }}>
                        <BottomBtnGroup onClick={() => this.goback()} />
                </div>
        </Form>
        

        <div>
            <Table
                rowKey={record => record.id}
                pagination={false}
                columns={portColumn}
                dataSource={this.state.appPorts}
                bordered={true}
            />
        </div>

        <Modal title="添加端口映射" visible={this.state.portVisible} cancelText okText closable={false}>
            <Form {...layout2}
            name="control-ref"
            initialValues={{type: this.state.type}}
              onFinish={this.addPort}>
              <Form.Item name="containerPort" label="容器端口" rules={[{ required: true, message: '容器端口不能为空,请输入0-65535范围的数字' }]} >
                  <Input placeholder="请输入容器端口,0-65535范围的数字" type="number" max={65535} min={0} />
              </Form.Item>

              <Form.Item name="hostPort" label="主机端口" rules={[{ required: true, message: '主机端口不能为空,请输入0-65535范围的数字' }]} >
                  <Input placeholder="请输入主机端口,0-65535范围的数字" type="number" max={65535} min={0} />
              </Form.Item>
            
              <Form.Item name="type" label="类型"   >
              <Select
                placeholder="请选类型"
                onChange={(value) => {
                  this.onSelectTypeChange(value);
                }}
                 >
                    <Option value="TCP">TCP</Option>
                    <Option value="UDP">UDP</Option>
                </Select>
              </Form.Item>
              <br/>
              <Form.Item  style={{marginLeft:260,marginBottom:-70}}>
                <Button type="primary" htmlType="submit" > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
          </Form>
          </Modal>
            
        </div>
      )
    }
}