import React from 'react';
import { Form, Input, Col, Row, Tabs, Button, Table, Divider, message, Select, Tag, Spin } from 'antd';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

import './view-node.scss';
import Application from './application/application';
import Equipment from './equipment/equipment';

import { connect } from 'react-redux';

const { TabPane } = Tabs;

const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
};
const formItemLayout2 = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
};
let dfLabel = []

class ViewEdgeEffectManagement extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            showManage: true,
            nodeId: null,
            nodeName: '查看应用--application1',
            labelList: [],
            allLabels: [],
          
            loading: false,
            nodeObj: {
                nodeUuid: '',
                name: '',
                description: '',
                hostname: '',
                macAddress: '',
                cpu: '',
                gpu: '',
                mem: '',
                disk: '',
                instanceNum: '',
                deviceNum: '',
                state: '',
                labels: '',
                upTime: '',
                updateTime: '',
                createTime: '',
                username: '',
                tenantName: '',
                userId: ''
            },
            labels: [
                {
                    "id": 1,
                    "name": "测试",
                    "code": ""
                }
            ],
            nlist: [{
                id: 1,
                name: 'application-ins1',
                state: 'RUNNING',
                resource: [90, 80, 70],
                duration: '1小时'
            }, {
                id: 1,
                name: 'application-ins2',
                state: 'FAILED',
                resource: [90, 90, 70],
                duration: '1小时37分钟'
            }],
          
        };
    }
    goback() {
        // if (this.props.onSubmit) {
        //     var { showManage } = this.state;
        //     this.props.onSubmit({ showManage })
        // }
        this.props.history.push('/home/effect-edge-m/list');
    }
    cancel = () => {
        /* this.formRef.current.setFieldsValue({
            name: this.state.roleObj.name || '',
            description: this.state.roleObj.description || '',
        }); */
    }
    componentDidMount() {
    }

    handleChange(value) {
        console.log(`selected ${value}`);
    }
    getState(record) {
        if (record.state === 'UNCONNECTED') {
          return <div><div className="common-green"></div> <span>未纳管</span></div>
        } else if (record.state === 'RUNNING') {
          return <div><div className="common-green"></div> <span>运行中</span></div>
        } else if (record.state === 'UPGRADING') {
          return <div><div className="common-blue"></div> <span>升级中</span></div>
        } else if (record.state === 'OVERTIME') {
          return <div><div className="common-purple"></div> <span>超时</span></div>
        } else if (record.state === 'FAILED') {
          return <div><div className="common-yellow"></div> <span>异常</span></div>
        } else if (record.state === 'DISABLED') {
          return <div><div className="common-gray"></div> <span>禁用</span></div>
        } else if (record.state === 'WAITING') {
          return <div><div className="common-yellow"></div> <span>操作中</span></div>
        } else {
          return '';
        }
      }
      gotoLog() {
        this.props.history.push('/home/effect-edge-m/log');
      }
      gotoMonitor() {
        this.props.history.push('/home/effect-edge-m/monitor');
      }
    render() {
        const onFinish = (values) => {
            message.success('修改成功');
            this.goback();
        };
        const columns = [
            {
              title: '实例名称',
              dataIndex: 'name',
             // render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
            },
            {
              title: '状态',
              dataIndex: 'state',
              render: (text, record) => (
                <>
                  {
                    this.getState(record)
                  }
                </>
              ),
            },
            {
              title: '资源使用',
              dataIndex: 'resource',
              render: (text, record) => (
                <>
                  <div>
                      <p style={{marginBottom: 4}}>GPU: {text[0]}%</p>
                      <p style={{marginBottom: 4}}>CPU: {text[2]}%</p>
                      <p style={{marginBottom: 4}}>MEM: {text[2]}%</p>
                  </div>
                </>
              ),
           
            },
            {
              title: '运行时长',
              dataIndex: 'duration',
            },
            {
              title: '操作',
              dataIndex: 'action',
            
              render: (text, record) => {
                  return (
                      <span>
                          <a href onClick={() => this.gotoLog(record.key)} style={{marginRight: '5px'}}>日志</a>
                          <a href onClick={() => this.gotoMonitor(record.key)} >监控</a>
                      </span>
                 
                  )
              }
            }
          ];
        return (
            <><Spin spinning={this.state.loading} delay={500}>
          
       
                <div className="common-detail-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.nodeName} itemTitle="边缘节点" itemSubTitle="查看节点" />
                    <div style={{ background: '#fff', padding: "0 20px 20px" }}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="详情" key="1">
                            <div style={{ marginLeft: 40 }}>
                                    <Form name="basic"
                                        onFinish={onFinish}
                                        labelAlign="left"
                                        ref={this.formRef}
                                        {...formItemLayout} >
                                          <Row><Col span={24}>
                                                <Form.Item label="状态" >
                                                   <div><div className="common-green"></div> <span>运行中</span></div>
                                                </Form.Item>
                                            </Col></Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="name" label="实例配额" >
                                                <span>CPU &nbsp;&nbsp; 1c</span>   <span style={{marginLeft: 12, marginRight: 12}}>GPU &nbsp;&nbsp; 1</span>  <span>内存 &nbsp;&nbsp; 1G</span>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="description" label="部署节点" >
                                                 node1
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="mac" label="描述" >
                                                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                      
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="创建时间" >
                                                     2021-02-05 12:06:02
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="应用镜像"  >
                                                   ubantu-app
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="镜像版本"  >
                                                    18.04
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <BottomBtnGroup onClick={() => this.goback()} />
                                    </Form>
                                </div>
                              
                            </TabPane>
                            <TabPane tab="访问设置" key="2">
                                <Application history={this.props.history} nodeId={this.state.nodeId} />
                            </TabPane>
                            <TabPane tab="高级设置" key="3">
                                <Equipment history={this.props.history} nodeId={this.state.nodeId} />
                            </TabPane>
                        </Tabs>
                    </div>
                    <div className="job-bottom-card">
                        <Row className="job-bottom-card-title">实例对象  </Row>
                            <div className="job-bottom-content">
                        
                                <Table columns={columns}
                                    rowKey={record => record.id}
                                    pagination={false}
                                    dataSource={this.state.nlist}
                                    scroll={{ y: 240 }}
                                />
                            </div>
                       </div>
                   </div>
           
                </Spin>
            </>
        );
    }
}

// export default ViewNode;


export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(ViewEdgeEffectManagement);