import React from 'react';
import { Form, Input, Select, message, Row, Col, InputNumber, Card , Divider, Button, Table, Modal, Badge } from 'antd';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { algorithmService } from '../../../../../services/algorithm-manage';
import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};

class CreatAlgorithmVersionMangement extends React.Component{
    formRef = React.createRef();
    constructor(props) {
    super(props);
      this.state = {
        id: -1,
        algorithmName: '',
        title: '新增算法版本'
    };

    }
    componentDidMount() {
      if (this.props.location.state) {
        const algorithmName = this.props.location.state.algorithmName;
        const id = this.props.location.state.id;
        console.log("添加算法版本的algorithmName为：", algorithmName);
        console.log("添加算法版本的的id为：", id);
        sessionStorage.setItem('algorithmVersionId', id)
        sessionStorage.setItem('algorithmVersionName', algorithmName)
        this.setState({
          id,
          algorithmName,
        });
      } else {
        if(sessionStorage.getItem('algorithmVersionId')) {
          const algorithmName = sessionStorage.getItem('algorithmVersionName');
          const id = sessionStorage.getItem('algorithmVersionId');
          console.log("添加算法版本的algorithmName为：", algorithmName);
          console.log("添加算法版本的的id为：", id);
          this.setState({
            id,
            algorithmName,
          });
        } else {
          this.goback();
        }
      }
    }
    
    //调用新增算法版本接口
    addAlgorithmVersion(id, algorithmName, algorithmVersion, description){
      algorithmService.addAlgorithm(id, algorithmName, algorithmVersion, description).then(res => {
        if (res.code === 200) {
          message.success('创建版本成功');
          this.goback();
        } 
        // else {
        //   message.success('创建版本失败，' + res.message);
        // }
      }, error => {
        message.destroy();
        error && message.error(error);
      });
    }
    
    goback() {
        this.props.history.push('/home/algorithm-manage/view');
    }

  render() {
        const onFinish = (values) => {
            this.addAlgorithmVersion(this.state.id, this.state.algorithmName, values.algorithmVersion, values.description)
        };

        return (
        <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle={this.state.title} itemTitle="版本" itemSubTitle="创建版本" />
        <div className="common-create-center-container">
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish}>
              <Form.Item name="algorithmVersion" label="算法版本"
                rules={[
                  { required: true, message: '请输入算法版本' },
                  { max: 64, message: '算法版本不允许超过64个字符'}, 
                ]} >
                <Input placeholder="请输入算法版本" />
              </Form.Item>
              <Form.Item name="description" label="描述" >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255}/>
              </Form.Item>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
        </div>
      </div>
        )
    }
}

export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(CreatAlgorithmVersionMangement);