import React from "react";
import {
  Form,
  Input,
  Col,
  Row,
  message,
} from "antd";

import BackPage from "../../../../common/backPage";
import BottomBtnGroup from "../../../../common/bottomBtnGroup";
import { AUTCONFIG } from "../../../../../../utils/auth.config";

import { userService } from "../../../../../services/user.service";
import { userDebService } from "../../../../../services/user-deb.service";
import { connect } from "react-redux";

import "./edit-firmware";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
// const formAddFirItemLayout = {
//   labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
//   wrapperCol: { xs: 24, sm: 12, md: 15 },
// };

class EditFirmware extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      userDebId: "",
      userDebName: "编辑软件包",

      history: require("history").createHashHistory,
    };
  }

  goback() {
    // if (this.props.onSubmit) {
    //     var { showManage } = this.state;
    //     this.props.onSubmit({ showManage })
    // }
    // this.props.history.go(-1);
    this.props.history.push("/home/userDeb/view");
  }

  componentDidMount() {
    const data = this.props?.location?.state?.record;
    if (data) {
      this.setState({
        userDebId: data.id,
      });
      this.getUserDebDetail(data.id);
      sessionStorage.setItem("userDebId", data.id || "");
    } else {
      if (sessionStorage.getItem("userDebId")) {
        const userDebId = sessionStorage.getItem("userDebId");
        this.setState({
          userDebId,
        });
        this.getUserDebDetail(userDebId);
      } else {
        this.goback();
      }
    }
  }

  getUserDebDetail(userDebId) {
    userDebService.getUserDebDetails(userDebId).then(
      (res) => {
        console.log("软件包详情：", res);
        if (res.code === 200) {
          const debInfo = res.deb;
          this.setState({
            userDebName: "编辑软件包 -- " + debInfo.name,
          });
          this.formRef.current.setFieldsValue({
            name: debInfo.name || "",
            version: debInfo.version || "",
            description: debInfo.description || "",
            url: debInfo.url || "",
          });
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }

  render() {
    const onFinish = (values) => {
      console.log(values);
      userDebService
        .editUserDeb(
          this.state.userDebId,
          values.name,
          values.version,
          values.description,
          values.url
        )
        .then(
          (res) => {
            if (res.code === 200) {
              message.success("修改成功");
              this.goback();
            }
          },
          (error) => {
            message.error("修改失败，" + error);
          }
        );
    };

    return (
      <>
        <div className="ota-detail-container">
          <BackPage
            onClick={() => this.goback()}
            headeTitle={this.state.userDebName}
          />
          <div className="common-edit-card">
            <Form
              name="basic"
              onFinish={onFinish}
              //   labelAlign="left"
              ref={this.formRef}
              {...formItemLayout}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="名称"
                    rules={[
                      { required: true, message: "软件包名称不能为空" },
                      {
                        pattern: /^[\u0391-\uFFE5\w_-]{0,64}$/,
                        message: "请输入64位以内中英文/数字/中划线/下划线",
                      },
                    ]}
                  >
                    <Input
                      //   style={{ width: 300 }}
                      placeholder="请输入软件包名称"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="version"
                    label="版本"
                    rules={[
                      { required: true, message: "请输入软件包版本" },
                      {
                        pattern: /^((\d+)\.){1,}(\d+)$/,
                        message: "软件包版本格式不对,例如123.123.123",
                      },
                    ]}
                  >
                    <Input name="version" placeholder="请输入软件包版本" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="url" label="链接">
                    <Input name="url" placeholder="请输入软件包链接" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="description" label="描述">
                    <TextArea
                      //   style={{ width: 800 }}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder="请输入描述"
                      showCount={true}
                      maxLength={255}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
        </div>
      </>
    );
  }
}

// export default EditOta;

// let versionList = [];

// const { Option } = Select;

export default connect((state) => ({
  currentUser: state.currentUser,
}))(EditFirmware);
