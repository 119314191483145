import React from 'react';

import { Select, Form, Col, Input, Button, Table, Pagination, Badge, ConfigProvider, Modal, message, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import { nodeService } from '../../../../../services/node.service';
import { equipmentService } from '../../../../../services/equipment.service';
import { terminalService } from '../../../../../services/terminal-equ';
import { labelService } from '../../../../../services/label.service';
import { searchRule } from '../../../../../../utils/search-util'
import './../../../../../../index.scss';

const { confirm } = Modal;

// let searchName = '';
let searchUuid = '';

let page = 1;
let pageSize = 10;
let searchName = '';
let searchNodeName = '';
let searchState = '';
let searchLabel = [];
let searchType = '';
let userId = '';
//select
const { Option } = Select;

function Search(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log(values);
    let name = values.deviceName || '';
    let str = searchRule(name)
    searchName = str || '';


    searchState = values.state || '';
    searchType = values.type || '';
    page = 1;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchState = '';
    searchType = '';
    searchName = '';
    page = 1;
    props.onClick();
  };
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          layout="inline"
          // style={{ justifyContent: "center" }}
          onFinish={onFinish} >

          <Form.Item name='deviceName' label='设备名称' style={{ marginTop: '8px' }}>
            <Input placeholder="请输入设备名称" />
          </Form.Item>
          <Form.Item name='state' label='设备状态' style={{ marginTop: '8px' }}>
            <Select allowClear
              placeholder="请选择设备状态" >
              <Option value="RUNNING">运行中</Option>
              <Option value="STOPPED">关闭</Option>
              <Option value="FAILED">异常</Option>
              <Option value="DISABLED">禁用</Option>
              <Option value="WATING">操作中</Option>{/**FIXME:单词错误，接口返回改正需要修改 */}
            </Select>
          </Form.Item>
          <Form.Item name='type' label='设备类型' style={{ marginTop: '8px' }}>
            <Select allowClear
              placeholder="请选择设备类型" >
              <Option value="0">视频摄像头</Option>
              <Option value="1">音频</Option>
              <Option value="2">屏幕</Option>
              <Option value="3">麦克风</Option>
              <Option value="4">其他</Option>
            </Select>
          </Form.Item>
          <Form.Item style={{ marginTop: '8px' }}>
            <Button type="primary" htmlType="submit"> 查询 </Button>
            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
          </Form.Item>

        </Form>
      </div>
    </>
  );
}
// let page = 1;
// let pageSize = 10;
function onChange(value) {
  console.log('changed', value);
}
class Equipment extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      devicesList: [],
      devicesAmount: 0,
      deleteIds: [],
      showUpgrade: false,
      showQuota: false,
      labelList: [],
      history: require("history").createHashHistory,
    };
  }
  getLabelList() {
    const getLableItem = (labels) => {
      return labels.map((item, index) => {
        return (
          <Option key={item.id} >{item.name}</Option>
        );
      });
    }
    labelService.getAllLabels().then(res => {
      if (res.code === 200) {
        const labels = res.labels;
        this.setState({
          labelList: getLableItem(labels)
        });
      }
    });
  }
  /* 升级 */
  showUpgrade = (id) => {
    this.setState({
      showUpgrade: true,
    });
  };

  hideModalUpgrade = () => {
    this.setState({
      showUpgrade: false,
    });
  };
  /* 配额 */
  showQuota = (id) => {
    this.setState({
      showQuota: true,
    });
  };

  hideModalQuota = () => {
    this.setState({
      showQuota: false,
    });
  };

  showDeleteConfirm(event) {
    console.log(event);
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        terminalService.deleteDevice(event).then(res => {
          if (res.code === 200) {
            message.success('删除成功');
            this.searchList();
          }
          // else {
          //   message.error('删除失败，' + res.message);
          // }
        }, error => {
          message.error('删除失败');
        })
      },
      onCancel() {

      },
    });
  }
  showMultiDeleteConfirm() {
    if (this.state.deleteIds.length === 0) {
      message.warning('请先选择要删除的节点');
      return
    }
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        // nodeService.removeNodes(this.state.deleteIds).then(res => {
        //   if (res.code === 200) {
        message.success('删除成功');
        //     this.searchList();
        //   } else {
        //     message.error('删除失败，' + res.message);
        //   }
        // }, error => {
        //   message.error('删除失败');
        // })
      },
      onCancel() {

      },
    });
  }

  searchList() {
    equipmentService.getDevicesList(searchName, this.props?.nodeName, searchState, userId, searchLabel,
      searchType, pageSize, page).then(res => {
        if (res.code === 200) {
          console.log(res);
          const devices = res.devices;
          devices.forEach(element => {
            element.key = element.id;
          });
          this.setState({
            devicesList: devices,
            devicesAmount: res.dataCount
          });
        }
        // else {
        //   message.error('获取设备列表失败，' + res.message);
        // }
      }, error => {
        message.error('获取设备列表失败，' + error);
      });
  }
  componentDidMount() {
    this.getLabelList();
    this.searchList();
    page = 1;
    pageSize = 10;
  }
  getLableItem(labels) {
    return labels.map((item, index) => {
      return (
        <Option key={item.id} >{item.name}</Option>
      );
    });
  }

  showCheckConfirm(event) {
    confirm({
      title: '确定进行检查吗?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        nodeService.stopNodes(event).then(res => {
          if (res.code === 200) {
            this.searchList();
            message.success('停止应用成功');
          }
          // else {
          //   message.error('停止应用失败，' + res.message);
          // }
        }, error => {
          message.error('停止应用失败，' + error);
        });
      },
      onCancel() {
      },
    });
  }

  getDeviceType = (record) => {
    if (record.deviceType === 0) {
      return <span>视频摄像头</span>
    } else if (record.deviceType === 1) {
      return <span>音频</span>
    } else if (record.deviceType === 2) {
      return <span>屏幕</span>
    } else if (record.deviceType === 3) {
      return <span>麦克风</span>
    } else if (record.deviceType === 4) {
      return <span>其他</span>
    }
  }

  getDeviceInterface = (record) => {
    if (record.deviceInterface === 'HDMI') {
      return <span>HDMI</span>
    } else if (record.deviceInterface === 'USB') {
      return <span>USB</span>
    } else if (record.deviceInterface === 'NET') {
      return <span>网络</span>
    }
  }
  getAction = (record) => {
    if (record.deviceInterface === 'HDMI') {
      return <>
        <a href onClick={()=>this.gotoEdit( record)} style={{ marginRight: '5px' }}>编辑</a>
      </>
    } else if (record.deviceInterface === 'USB') {
      return <>
        <a href onClick={()=>this.gotoEdit( record)} style={{ marginRight: '5px' }}>编辑</a>
      </>
    } else if (record.deviceInterface === 'NET') {
      return <>
        <a href onClick={()=>this.gotoEdit( record)} style={{ marginRight: '5px' }}>编辑</a>
        <a href onClick={() => this.showDeleteConfirm(record.id)} style={{ marginRight: '5px' }}>删除</a>
      </>
    }
  }
  getState(record) {
    if (record.deviceStatus === 'RUNNING') {
      return <span> <Badge status="success" />运行中</span>
    } else if (record.deviceStatus === 'FAILED') {
      return <span> <Badge status="error" />异常</span>
    } else if (record.deviceStatus === 'DISABLED') {
      return <span><Badge status="error" />禁用</span>
    } else if (record.deviceStatus === 'WATING') {//FIXME:WATING=>WAITING
      return <span><Badge status="processing" />操作中</span>

    } else if (record.deviceStatus === 'STOPPED') {
      return <span><Badge status="default" />关闭</span>
    }
  }


  gotoDetail = (text, key) => {
    this.props.history.push({ pathname: '/home/terminal-equ/view', state: { id: text.id } });
  }
  gotoEdit = (text) => {
    console.log(text);
    this.props.history.push({ pathname: '/home/terminal-equ/edit', state: { id: text.id } });
  }
  render() {
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.searchList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    }
    const registered = () => {
      this.props.history.push({ pathname: '/home/node-manage/equipment/create-equipment', state: this.props?.nodeUuid });
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          deleteIds: selectedRowKeys
        })
      }
    };
    const columns = [
      {
        title: '设备别名',
        dataIndex: 'aliasName',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record)} >{text}</a>,
      },
      {
        title: '设备名称',
        dataIndex: 'deviceName',
        //render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record)} >{text}</a>,
      },
      {
        title: '设备类型',
        dataIndex: 'deviceType',
        render: (text, record) => (
          <>
            {
              this.getDeviceType(record)
            }
          </>
        ),
      }, {
        title: '设备接口',
        dataIndex: 'deviceInterface',
        render: (text, record) => (
          <>
            {
              this.getDeviceInterface(record)
            }
          </>
        ),
      },
      {
        title: '设备状态',
        dataIndex: 'deviceStatus',
        render: (text, record) => (
          <>
            {
              this.getState(record)
            }
          </>
        ),
      },


      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => (
          <>
            {
              record.deviceStatus != 'WATING' ?
                <a href onClick={() => this.gotoEdit(record)} style={{ marginRight: '5px' }}>编辑</a>
                : ''
            }
           
            {
              (record.deviceInterface === 'NET' && record.deviceStatus === 'RUNNING') || record.deviceStatus === 'FAILED' ?
              <a href onClick={() => this.showDeleteConfirm(record.id)} style={{ marginRight: '5px' }}>删除</a>
                : ''
            }
          </>
        ),
      },
    ];

    return (
      <>
        <div>
          <div>
            <div className="common-center-container" style={{ padding: 0 }}>
              <Search onClick={() => this.searchList()}
                labelList={this.state.labelList} />
              <div className="oper-button">

                <Button type="primary" onClick={registered}>  注册  </Button>
                {/* 
                <Button style={{ marginLeft: 10 }} onClick={() => this.showMultiDeleteConfirm()}> 删除 </Button> */}
              </div>
              <div className="user-table-card">
                <Table
                  // rowSelection={{
                  //   ...rowSelection,
                  // }}
                  rowKey={record => record.id}
                  bordered={false}
                  pagination={false}
                  columns={columns}
                  dataSource={this.state.devicesList}
                />
              </div>
              <div>
                {
                  this.state.devicesList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        defaultCurrent={1}
                        current={page} pageSize={pageSize}
                        total={this.state.devicesAmount}
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>


        </div>

        {/* 升级 */}
        <Modal
          title="应用升级"
          visible={this.state.showUpgrade}
          onOk={this.hideModalUpgrade}
          onCancel={this.hideModalUpgrade}
          okText="确定"
          cancelText="取消"
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            name="form_in_modal"
          >
            <Form.Item
              name="title"
              label="应用镜像"
            >
              <Select placeholder="">
                <Option value="china">China</Option>
                <Option value="usa">U.S.A</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="title"
              label="镜像版本"
            >
              <Select placeholder="">
                <Option value="china">China</Option>
                <Option value="usa">U.S.A</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        {/* 配额 */}
        <Modal
          title="调整应用配额"
          visible={this.state.showQuota}
          onOk={this.hideModalQuota}
          onCancel={this.hideModalQuota}
          okText="确定"
          cancelText="取消"
        >
          <Form
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            name="form_in_modal"
          >
            <Form.Item
              name="title"
              label="CPU"
            >
              <InputNumber min={0} max={10} onChange={onChange} />
            </Form.Item>
            <Form.Item
              name="title"
              label="GPU"
            >
              <InputNumber min={0} max={10} onChange={onChange} />
            </Form.Item>
            <Form.Item
              name="title"
              label="内存"
            >
              <InputNumber min={0} max={10} onChange={onChange} />
            </Form.Item>
          </Form>
        </Modal>

      </>
    );
  }
}
export default Equipment;