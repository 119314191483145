/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Button, Table,  Select, message, Row, Col, InputNumber,  Modal,  Card , Divider,  Upload  } from 'antd';

import { LoadingOutlined, PlusOutlined , ExclamationCircleOutlined} from '@ant-design/icons';

import './../../../../../../index.scss';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class ViewEdgeEffectConfig extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      showManage: true,
      hasSubmit: false,
      showQuota: false,
      nlist: [
        {
          id: 1,
          name: 'ser',
          zhi: '12'
        }
      ]
    };
  }
  goback() {
   
    this.props.history.push('/home/effect-edge-c/list');
  }
  onSelectTypeChange(value) {
    console.log(value)
  }
  componentDidMount() {
    this.formRef.current.setFieldsValue({ userName: 'config1', 
                                       description: '描述' });
  }
  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        message.success('删除成功');
      
      },
      onCancel() {

      },
    });
  }
  hideModalQuota = () => {
    this.setState({
      showQuota: false,
    });
  };
  render() {
    const onFinish = (values) => {
        message.success('创建模板成功');
        this.goback();
    };
    const registered=() => {
        
        this.setState({
            showQuota: true
          })
    }
    const  onFinishFR = (values) => {
      
        this.setState({
            showQuota: false
          })
      };
    const columns = [ {
        title: '属性名',
        dataIndex: 'name',
      
      }, {
        title: '属性值',
        dataIndex: 'zhi',
      },
      {
        title: '操作',
        dataIndex: 'action',
      
        render: (text, record) => {
            return (
                <a href onClick={() => this.showDeleteConfirm(record.key)} >删除</a>
           
            )
        }
      }];
    return (
      <>
        <div className="common-pd20">
    

        <BackPage onClick={() => this.goback()} headeTitle="查看应用配置--config1" itemTitle="应用管理" itemSubTitle="创建用户" />
        <div className="common-create-page">
              <div className="job-box">
       
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish} >
              <Form.Item name="userName" label="模板名称" rules={[{ required: true, message: '请输入模板名称' }]} >
                <Input placeholder="请输入模板名称" />
              </Form.Item>
              <Form.Item name="description" label="描述" >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                </Form.Item>
                <Form.Item name="version" label="版本" >
                <Input placeholder="请输入版本" />
              </Form.Item>
              <Form.Item label="创建时间" >
                    2021-02-05 12:06:02
            </Form.Item>
            <Form.Item label="更新时间" >
                    2021-02-05 12:06:02
            </Form.Item>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
         
            </div>
          </div>
        <div className="job-bottom-card">
        <Row className="job-bottom-card-title" style={{ height: 48, display:'flex',
             justifyContent: 'space-between', paddingRight: 24
            }}> 
            <div>
            <span className="common-star-red">*</span>配置项
            <Button type="danger" style={{ marginLeft: 36, color: '#fff' , width: 'auto' }} onClick={() => {
                    registered();
                }}>新增</Button>
                 </div>
              
                 
            </Row>
            <div className="job-bottom-content">
        
                <Table columns={columns}
                    rowKey={record => record.id}
                    pagination={false}
                    dataSource={this.state.nlist}
                    scroll={{ y: 240 }}
                />
            </div>
        </div>
   
                  
       
   
        <Modal
          title="新增环境变量"
          visible={this.state.showQuota}
          onOk={this.hideModalQuota}
          onCancel={this.hideModalQuota}
          okText="确定"
          cancelText="取消"
          onFinish={onFinishFR}
          footer={null}
        >
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            name="form_in_modal"
          >
            <Form.Item
              name="title"
              label="变量名"
              rules={[{ required: true, message: '请输入变量名' }]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="title"
              label="变量值"
              rules={[{ required: true, message: '请输入变量值' }]}
            >
               <Input/>
            </Form.Item>
            <div style={{ marginTop: '8px' }}>
                  <div style={{textAlign: 'center'}}>
                    <Button type="primary" htmlType="submit"> 确定 </Button>
                    <Button style={{ margin: '0 9.46px' }} onClick={this.hideModalQuota}>取消 </Button>
                    </div>
                </div>
          </Form>
        </Modal>
        </div>
      </>
    );
  }
}

//export default CreateUser;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(ViewEdgeEffectConfig);