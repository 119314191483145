/* eslint-disable import/no-anonymous-default-export */
import Login from './pages/modules/login/login/login';
import Home from './pages/modules/home/home';
import NoMatch from './pages/modules/no-match/no-match';
import Registered from './pages/modules/login/registered/registered';

export default [
    { path: "/home", name: "Home", component: Home, auth: true, children: [] },
    { path: "/login", name: "Login", component: Login },
   // { path: "/registered", name: "registered", component: Registered },
    { path: "/404", name: "404", component: NoMatch },

];