import { handleResponse } from './handle-response';
import store from '../../store/index';

export const companyService = {
    getCompanyList,
    removeCompany,
    removeBatchCompany,
    addCompany,
    updateCompany,
    getCompanyDetail
};



/**
 * 获取公司列表
 * @param {*} name 
 * @param {*} limit 
 * @param {*} offset 
 * @returns 
 */
function getCompanyList(name, limit, offset) {
    return fetch(`/v1/console/company?name=${name || ''}&offset=${offset || 1}&limit=${limit || 20}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

/**
 * 删除公司
 * @param {sd} id 
 * @returns 
 */
function removeCompany(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/company/${id}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
/**
 * 批量删除
 * @param {*} id 
 * @returns 
 */
function removeBatchCompany(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`,
            body: JSON.stringify({
                ids: id
            })
        },
    };
    return fetch(`/v1/console/company/batch?ids=${id}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}



/**
 * 添加公司
 * @param {*} parsms 
 * @returns 
 */
function addCompany(parsms) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify(parsms)
    };
    return fetch(`/v1/console/company`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

/**
 * 更新公司
 * @param {*} id 
 * @param {*} parsms 
 * @returns 
 */
function updateCompany(id, parsms) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify(parsms)
    };
    return fetch(`/v1/console/company/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}



/**
 * 获取公司详情
 * @param {*} id 
 * @returns 
 */
 function getCompanyDetail(id) {
    return fetch(`/v1/console/company/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}