/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Select, message, Badge, Row, Col, InputNumber, Table, Card, Divider  } from 'antd';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

import { configService } from '../../../../../services/config-manage';
import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class ViewConfigManage extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      id: -1,
      type: -1,
      configKey: '',
      configValue: "",
      description: '',
      createTime: '',
      createBy: '',
      updateTime: '',
      updateBy: '',
    }
  }
  goback() {
   
    this.props.history.go(-1);
  }
  onSelectTypeChange(value) {
    console.log(value)
  }
  componentDidMount() {
    if (this.props.location.state) {
      const id = this.props.location.state.id;
      console.log("查看配置详情的id为：", id);
      this.getConfigDetail(id)
      sessionStorage.setItem('configId', id)
    } else {
      if(sessionStorage.getItem('configId')) {
        const id = sessionStorage.getItem('configId');
        this.setState({
            id,
        });
        this.getConfigDetail(id)
      } else {
        this.goback();
      }
    }
    
  }

  //查询设备详情接口
  getConfigDetail(id) {
    configService.getConfigDetail(id).then(res => {
      if (res.code === 200) {
        console.log(res);
        const config = res.config
        this.setState({
            type: config.type,
            configKey: config.configKey,
            configValue: config.configValue,
            description: config.description,
            createTime: config.createTime,
            createBy: config.createBy,
            updateTime: config.updateTime,
            updateBy: config.updateBy,
        })
        this.formRef.current.setFieldsValue({
            configValue: config.configValue,
        })
      }
      }).catch(error => {
        error && message.error(error);
      })
  }

  getConfigType = (type) => {
    if (type === 0) {
      return <span>云端配置</span>
    } else if (type === 1) {
      return <span>盒子端配置</span>
    } 
  }
  
  render() {
     
    return (
      <>
        <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="查看设备" itemTitle="应用管理" itemSubTitle="创建用户" />
        <div className="common-create-center-container">
           
          <div className="common-create-page">
          
       
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              labelWrap={true}
              {...formItemLayout}
             >
                <Form.Item name="type" label="设备类型" >
                    {this.getConfigType(this.state.type)}
                </Form.Item>

                <Form.Item name="configKey" label="系统配置项" >  
                    {this.state.configKey} 
                </Form.Item>

                <Form.Item name="configValue" label="系统配置值">  
                    <Input.TextArea readonly="readonly" rows={6}>
                        {/* {this.state.configValue} */}
                    </Input.TextArea>
                    
                </Form.Item>

                <Form.Item name="description" label="系统配置描述" >
                     {this.state.description}
                </Form.Item>

                <Form.Item name="createTime" label="创建时间">
                     {this.state.createTime || '--'}
                </Form.Item>
                
                <Form.Item name="createBy" label="创建人">
                     {this.state.createBy || '--'}
                </Form.Item>

                <Form.Item name="updateTime" label="更新时间" >
                     {this.state.updateTime || '--'}
                </Form.Item>
                
                <Form.Item name="updateBy" label="更新人" >
                     {this.state.updateBy || '--'}
                </Form.Item>
                
            </Form>
       
          </div>
        </div>
        </div>
      </>
    );
  }
}

//export default CreateUser;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(ViewConfigManage);