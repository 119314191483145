import { takeEvery,put } from 'redux-saga/effects';
import { GET_INIT_LIST } from './actionTypes';
import axios from 'axios';
import store from './index';
import { initListAction } from './actionCreators';

function* getInitList() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    try {
        const res =  yield axios.get('/v1/console/users/all', requestOptions);
        // console.log(res);
        const action = initListAction(res.data);
        yield put(action);
    }catch(e) {
        console.log('网络请求失败');
    }
}

function* listSagas() {
    yield takeEvery(GET_INIT_LIST, getInitList);
}

export default listSagas;