/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Select, message, Badge, Row, Col, InputNumber,     Card , Divider  } from 'antd';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { configService } from '../../../../../services/config-manage';

import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class EditConfigManagement extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      id: -1,
      deviceName: '',
      rtspUrl: '',
      aliasName: '',
      nodeUuid: '',
      nodeName: '',
      deviceStatus: '',
      deviceType: -1,
      deviceInterface: '',
      createTime: '',
      updateTime: '',
      upTime: '',
    };
  }
  goback() {
   
    this.props.history.go(-1);
  }
  
  componentDidMount() {
    if (this.props.location.state) {
      const id = this.props.location.state.id;
      console.log("修改配置详情的id为：", id);
      this.getConfigDetail(id)
      sessionStorage.setItem('editConfigId', id)
    } else {
      if(sessionStorage.getItem('editConfigId')) {
        const id = sessionStorage.getItem('editConfigId');
        this.setState({
            id,
        });
        this.getConfigDetail(id)
      } else {
        this.goback();
      }
    }  
  }

  //查询设备详情接口
  getConfigDetail(id) {
    configService.getConfigDetail(id).then(res => {
      if (res.code === 200) {
        console.log(res);
        const config = res.config
        
        this.setState({
            type: config.type,
            configKey: config.configKey,
            configValue: config.configValue,
            createTime: config.createTime,
            createBy: config.createBy,
            updateTime: config.updateTime,
            updateBy: config.updateBy,
        })
        this.formRef.current.setFieldsValue({
            description: config.description,
            configValue: config.configValue,
        })
      }
      }).catch(error => {
        error && message.error(error);
      })
  }

  //修改设备接口
  editConfig(id, description, configKey, configValue) {
    configService.editConfig(id, description, configKey, configValue).then(res => {
      if (res.code === 200) {
        message.success("操作成功！")
        this.goback();
      }
      }).catch(error => {
        error && message.error(error);
      })
  }

  getConfigType = (type) => {
    if (type === 0) {
      return <span>云端配置</span>
    } else if (type === 1) {
      return <span>盒子端配置</span>
    } 
  }
  
  render() {
    const onFinish = (values) => {
      console.log(values);
      if (this.props.location.state) {
        console.log(this.props.location.state.id)
        // if (values.deviceName === this.state.deviceName && values.rtspUrl === this.state.rtspUrl) {
        //   message.success("操作成功！")
        //   this.goback();
        // } else {
          this.editConfig(this.props.location.state.id, values.description, this.state.configKey, values.configValue)
        // }
        
      } else {
        console.log(sessionStorage.getItem('editConfigId'))
        
        // if (values.deviceName === this.state.deviceName && values.rtspUrl === this.state.rtspUrl) {
        //   message.success("操作成功！")
        //   this.goback();
        // } else {
          this.editConfig(sessionStorage.getItem('editConfigId'), values.description, this.state.configKey, values.configValue)
        // }
        
      }
    };

    return (
      <>
        <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="编辑配置" itemTitle="配置管理" itemSubTitle="配置管理" />
        <div className="common-create-center-container">
           
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish} >
                <Form.Item name="type" label="设备类型" >
                    {this.getConfigType(this.state.type)}
                </Form.Item>

                <Form.Item name="configKey" label="系统配置项" >  
                    {this.state.configKey} 
                </Form.Item>

                <Form.Item name="configValue" label="系统配置值" rules={[{ required: true, message: '请输入系统配置值' }]}>  
                    <Input.TextArea rows={6}>
                    </Input.TextArea>
                    
                </Form.Item>

                <Form.Item name="description" label="系统配置描述" rules={[{ required: true, message: '请输入系统配置描述' }]}>
                     <Input placeholder="系统配置描述" />
                </Form.Item>

                <Form.Item name="createTime" label="创建时间">
                     {this.state.createTime || '--'}
                </Form.Item>
                
                <Form.Item name="createBy" label="创建人">
                     {this.state.createBy || '--'}
                </Form.Item>

                <Form.Item name="updateTime" label="更新时间" >
                     {this.state.updateTime || '--'}
                </Form.Item>
                
                <Form.Item name="updateBy" label="更新人" >
                     {this.state.updateBy || '--'}
                </Form.Item>
          
            
              <BottomBtnGroup onClick={() => this.goback()} />
           
            </Form>
        
       
          </div>
        </div>
        </div>
      </>
    );
  }
}

//export default CreateUser;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(EditConfigManagement);