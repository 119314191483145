import React from 'react';

import './../../../../index.scss';
import { Card, Form, Row, Col, Input, Button, Table, Space, Pagination, ConfigProvider, Modal, Select, message } from 'antd';
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import { mirrorWarehouseService } from '../../../services/mirror-warehouse.service';
import UploadMirror from './upload-mirror/upload-mirror';
import ViewMirror from './view-mirror/view-mirror';
import { searchRule } from '../../../../utils/search-util'

const { confirm } = Modal;


//列表查询
let imageName = '';
let pageSize = 10;
let page = 1;
function Search(props) {
  const form = React.createRef();
  const onFinish = (values) => {
    let name = ''
    if (values.imageName != null) {
      name = searchRule(values.imageName)
    };
    imageName = name || '';
    props.onClick();
  }
  const resetForm = () => {
    form.current.resetFields();
    imageName = '';
    pageSize = 10;
    page = 1;
    props.onClick();
  }

  return (
    <div>
      <div className="common-search-form">
        <Form
          ref={form}
          name="advanced_search_user"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          layout="inline"

          // style={{ justifyContent: 'center' }}
        >

          <Form.Item name='imageName' label='镜像名称' style={{ marginTop: '8px' }} >
            <Input placeholder="请输入镜像名称" />
          </Form.Item>


          <Form.Item style={{ marginTop: '8px' }}>
            <Button type="primary" htmlType="submit"> 查询 </Button>
            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
          </Form.Item>

        </Form>
      </div>
    </div>
  );
}

class MirrorWarehouse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showManage: true,
      showCreate: false,
      showView: false,
      imagesList: [],
      actionIds: [],
      loading: false,
      page: 1,
      pageSize: 10,
      imagesAmount: 0
    };
  }
  componentDidMount() {
    page = 1;
    pageSize = 10;
    this.searchList();
  }
  searchList() {
    this.setState({
      loading: true,
      page,
      pageSize
    })
    mirrorWarehouseService.getImagesList(imageName, pageSize, page).then(res => {
      this.setState({
        loading: false
      })

      if (res.code === 200) {
        console.log(res);
        this.setState({
          imagesList: res.imageOverviews,
          imagesAmount: res.dataCount,
        });
      } 
      // else {
      //   message.error(res.message);
      // }
    }).catch(error => {
      this.setState({
        loading: false
      });
      message.error(error);
    })
  }

 showConfirm(record) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk:()=> {
        console.log("要删除的镜像为：",record.name);
        this.deletImageOverview(record.name);
      },
      onCancel() {
       
      },
    });
  }
  deletImageOverview(name){
    mirrorWarehouseService.deleteImageOverview(name).then(res=>{
      if (res.code === 200) {
        message.success('操作成功！');
        page = 1;
        this.searchList();
      } 
      // else {
      //   message.error(res.message);
      // }
    }).catch(error => {
      message.error(error);
    })
  }

  printContent(comment) {
    this.setState({
      showManage: comment.showManage
    });
    console.log(comment)
  }
  render() {
    const onChange = (pageNumber) => {
      console.log('pageNumber',pageNumber);
      page = pageNumber;
      this.searchList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      console.log('pageSizeNumber',pageSizeNumber);
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    }
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          actionIds: selectedRowKeys
        })
      }
    };

    const registered = () => {
      this.setState({
        showManage: false,
        showCreate: true,
        showView: false
      })
    }
    const handlCancle = () => {
      this.setState({
        showManage: false,
        showCreate: false,
        showView: false
      })
    }
    const toView = (record) => {
      console.log('history传递的数据', record);
      this.props.history.push({ pathname: '/home/mirror-warehouse/view', state: { record } });

    }
    const toEdit = (record) => {
      console.log('history传递的数据', record);
      this.props.history.push({ pathname: '/home/mirror-warehouse/edit', state: { record } });
    }
    const columns = [
      {
        title: '镜像名称',
        dataIndex: 'name',
        render: (text, record) => <a onClick={() => toView(record)}>{text}</a>,
      },
      {
        title: '版本数目',
        dataIndex: 'versionNum',
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => (
          <Space size="middle">
            <a onClick={() =>this.showConfirm(record)}>删除</a>
            <a onClick={() => toEdit(record)}>编辑</a>
          </Space>
        )

      },
    ];

    return (
      <div className="common-pd20">

        <div >
          <h1 className="common-top-title0">镜像仓库</h1>
          <div className="common-center-container">
            <Search onClick={() => this.searchList()} />
            <div className="oper-button">
              <Router>
                <Button type="primary" onClick={registered} icon={<PlusOutlined />} style={{ color: '#fff', width: 'auto' }}>
                上传镜像
                </Button>
              </Router>
              <Button style={{ marginLeft: 16, color: '#fff', width: 'auto' }} type="danger" icon={<DeleteOutlined />}
              >
                删除
              </Button>
            </div>
            <div className="user-table-card">
              <Table
                rowSelection={{
                  ...rowSelection,
                }}
                rowKey={record => record.name}
                bordered={false}
                pagination={false}
                columns={columns}
                dataSource={this.state.imagesList}

                loading={this.state.loading}
              />
            </div>
            <div className="common-bottom-pagination">
              <ConfigProvider locale={zhCN}>
                <Pagination
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={this.state.page}
                  pageSize={this.state.pageSize}
                  total={this.state.imagesAmount}
                  onChange={onChange} />
              </ConfigProvider>
            </div>
          </div>

        </div>
        {/* 上传镜像 */}
        <Modal title="上传镜像" visible={this.state.showCreate}
          onOk={handlCancle} onCancel={handlCancle}
          footer={null}>
          <UploadMirror onClick={handlCancle.bind(this)} />
        </Modal>



      </div>

    );
  }
}
export default MirrorWarehouse;