import { handleResponse } from './handle-response';
import store from '../../store/index';

export const userService = {
    getUserList,
    getUserDetail,
    addUser,
    removeUsers,
    removeUser,
    editUser,
    changeUserState,

    getAllUsers,

    addOrganForUser,
    getOrganByUser,
    removeOranFromUser,

    addRoleForUser,
    getRoleByUser,
    removeRoleFromUser,

    getTenantUserList,
    getAllTenantList,
    getLabelList,
    addLabelToUsers,
    removeLabelFromuser,
    getBindLabelUserList,
    getCompanyList,
    getUserListByCompany,
    getUsersToken
};
// type: 0-管理员，1-主用户，2-子用户，空值-全部
function getUserList(realName, name, type, offset, limit) { // 接口加tenantId
    return fetch(`/v1/console/users?realName=${realName || ''}&username=${name || ''}&type=${type }&offset=${offset || 1}&limit=${limit || 20}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
function getAllTenantList() {
    // /v1/console/users/allTenant
    return fetch(`/v1/console/users/all`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

function getCompanyList() {
    return fetch(`/v1/console/company/all`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

function getUserListByCompany(cid) {
    cid  = cid || '';
    return fetch(`/v1/console/users?companyId=${cid || ''}&offset=1&limit=1000000000`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

function getBindLabelUserList(labelsId) {
    // /v1/console/users/allTenant
    let url = `/v1/console/users/labels/${labelsId}`;
    if(store.getState().currentUser?.type === 'MAIN') {
        url = '/v1/console/users/all';
    }
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
function addLabelToUsers(userId, labelsInfos) {
    const labels =  labelsInfos.map(ids => {
        return {id:ids};
    });
     const requestOptions = {
         method: 'POST',
         headers: {
             'Content-Type': 'application/json;charset=UTF-8',
             Authorization: `Bearer ${store.getState().authToken}`
         },
         body: JSON.stringify(labels)
     };
     return fetch(`v1/console/users/${userId}/labels`, requestOptions)
         .then(handleResponse)
         .then(res => {
             return res;
         });
 }

 function removeLabelFromuser(id, labelId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: {labelId}
    };
    return fetch(`/v1/console/users/${id}/labels?labelId=${labelId}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}

function getTenantUserList(realName, username,  tenantId, offset, limit) { // 接口加tenantId
    return fetch(`/v1/console/users/tenant?realName=${realName || ''}&username=${username || ''}&userId=${tenantId || ''}&offset=${offset || 1}&limit=${limit || 20}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
function getUserDetail(id) {
    return fetch(`/v1/console/users/${id}`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    }).then(handleResponse).then(res => {
        return res;
    })
}

function addUser(username, name, password, mobile, email, companyId, description, address, type) {
   
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            username: username,
            realName: name,
            password: password,
            mobile: mobile || '',
            email: email || '',
            companyId: companyId || '',
            address: address || '',
            description: description || '',
            type
        })
    };
    return fetch(`/v1/console/users`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function editUser(id, username, name, password, mobile, email, desc, companyId, address, state) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            username: username || '',
            realName: name || '',
            password: password || '',
            mobile: mobile || '',
            email: email || '',
            description: desc || '',
            companyId: companyId || '',
            address: address || '',
            state: state || ''
        })
    };
    return fetch(`/v1/console/users/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function removeUsers(ids) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/users/batch?ids=${ids}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
function removeUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/users/${id}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}

function changeUserState(id, state) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/users/${id}/status/${state}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}

function getAllUsers() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/users/all`, requestOptions).then(handleResponse).then(res => { return res });
}


function addOrganForUser(id, organIds) {
    console.log(organIds)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify(organIds)
    };;
    return fetch(`/v1/console/users/${id}/org`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
function removeOranFromUser(id, organId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/users/${id}/org?orgId=${organId}`, requestOptions).then(handleResponse).then(res => {
        return res;
    });
}
function getOrganByUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/users/${id}/org`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}


function addRoleForUser(id, roleId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify(roleId)
    };;

    return fetch(`/v1/console/users/${id}/roles`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
function removeRoleFromUser(id, roleId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/users/${id}/roles?roleId=${roleId}`, requestOptions).then(handleResponse).then(res => {
        return res;
    });
}
function getRoleByUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/users/${id}/roles`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}


function getLabelList(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/users/${id}/labels`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
// 2.1.1.	申请用户令牌
function getUsersToken(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/users/${id}/token`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}