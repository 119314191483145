/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  ConfigProvider,
  Divider,
  Pagination,
  Button,
  Table,
  Modal,
  Badge,
} from "antd";
import "../../../../../index.scss";
import zhCN from "antd/lib/locale/zh_CN";
import BackPage from "../../../common/backPage";
import BottomBtnGroup from "../../../common/bottomBtnGroup";
import { terminalService } from "../../../../services/terminal-equ";
import { nodeService } from "../../../../services/node.service";
import { otaService } from "../../../../services/ota.service";
import { firmwareService } from "../../../../services/firmware.service";
import { userService } from "../../../../services/user.service";
import {epackExclusiveManageService} from "../../../../services/epack-exclusive-manage"
import { connect } from "react-redux";
import UserManage from "../user/user-manage";

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

let nodeActions = [];
class AddEpackExclusiveManageUser extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      otaListInfo: {},
      firmwareListInfo: {},
      userList: [],
      productModelList: [],
      epackVersionList: [],
      modalVisible: false,
      actionIds: [],
      chooseuserList: [],

      nodeList: [],
      selectedNodeIds: [], //选择的节点
      uuidList: [], //节点 1

      hasSubmit: false,
    };
  }

  handleTransferData(value) {
    this.setState({
      actionIds: value,
    });
  }

  goback() {
    this.props.history.push("/home/epack-exclusive-manage");
  }
  componentDidMount() {
    this.getTenantList();
    this.getOtaList();
  }
  // 新增epack用户关系
  handleAddFirmwaresUsers(val_1,val_2){
    epackExclusiveManageService.addFirmwaresUsers(val_1,val_2).then(
      (res)=>{
        if(res.code===200){
          message.success('操作成功');
          this.props.history.push("/home/epack-exclusive-manage");
        }
      },(error) => {
      message.error(error);
    }
    ) 
  }

  //查询用户列表
  getTenantList() {
    userService.getAllTenantList().then(
      (res) => {
        console.log("所属用户", res);
        if (res.code === 200) {
          const tenants = res.users;
          this.setState({
            userList: tenants,
          });
        }
      },
      (error) => {
        message.error(error);
      }
    );
  }
  //查询epack列表
  getFirmwareList(otaId) {
    firmwareService.getFirmwareByOta(otaId, "", "",1,1000,1).then(
      (res) => {
        if (res.code === 200) {
          const lists = res.firmwares;
          console.log("epack列表", lists);
          lists.forEach((element) => {
            element.key = element.id;
          });
          const firmwareListHandled = this.simplyEpackList(lists);
          this.setState({
            firmwareListInfo: firmwareListHandled,
            epackVersionList:
              firmwareListHandled[Object.keys(firmwareListHandled)[0]] || [],
          });
          this.formRef.current?.setFieldsValue({
            epackName: Object.keys(firmwareListHandled)[0],
            epackVersion: firmwareListHandled[
              Object.keys(firmwareListHandled)[0]
            ]
              ? firmwareListHandled[Object.keys(firmwareListHandled)[0]][0].id
              : undefined,
          });
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  simplyEpackList(value) {
    const firmwaresSimpledData = {};
    value.forEach((ele) => {
      if (firmwaresSimpledData[ele.firmwareName]) {
        const obj={version:ele.firmwareVersion,id:ele.id}
        firmwaresSimpledData[ele.firmwareName].push(obj);
      } else {
        firmwaresSimpledData[ele.firmwareName] = [{version:ele.firmwareVersion,id:ele.id}];
      }
    });
    return firmwaresSimpledData;
  }
  //查询产品列表
  getOtaList() {
    otaService.getAllOtaList().then(
      (res) => {
        console.log("产品列表", res);
        if (res.code === 200) {
          const products = res.products;
          products.forEach((element) => {
            element.key = element.id;
          });
          this.setState({
            otaListInfo: this.handleProductsList(products),
          });
        }
      },
      (error) => {}
    );
  }

  handleProductsList(val) {
    const otaListInfo_ = {};
    val.forEach((ele) => {
      if (otaListInfo_[ele.productName]) {
        otaListInfo_[ele.productName].push(ele);
      } else {
        otaListInfo_[ele.productName] = [ele];
      }
    });
    return otaListInfo_;
  }



  addUser() {
    this.setState({
      modalVisible: true,
    });
  }

  render() {
    const {
      otaListInfo,
      productModelList,
      firmwareListInfo,
      epackVersionList,
      userList,
      actionIds,
      chooseuserList
    } = this.state;

    const handleOk = () => {
      const filterData = userList.filter((ele) =>
        actionIds.find((val) => ele.id === val)
      );
      this.setState({
        modalVisible: false,
        chooseuserList: filterData,
      });
    };
    const handleCancel = () => {
      this.setState({
        modalVisible: false,
      });
    };
    const onFinish = (values) => {
      const arr=chooseuserList.map(ele=>ele.id)
      if(arr.length){
        this.handleAddFirmwaresUsers(values?.epackVersion,arr)
      }else{
        message.warning('请选择用户')
      }
    };
    // 产品名称变化
    const onSelectProductName = (value) => {
      this.setState({
        productModelList: otaListInfo[value],
      });
      this.formRef.current.setFieldsValue({
        productModel: otaListInfo[value][0]?.id,
      });
      this.getFirmwareList(otaListInfo[value][0]?.id);
    };

    // 产品型号变化
    const onSelectProductModel = (value) => {
      this.getFirmwareList(value); 
    };
    // epack名称变化
    const onSelectEpackName = (value) => {
      this.setState({
        epackVersionList: firmwareListInfo[value],
      });
      this.formRef.current.setFieldsValue({
        epackVersion: firmwareListInfo[value][0].id,
      });
    };

    //
    const deleteUserList = (text, record) => {
      this.setState({
        chooseuserList: chooseuserList.filter((ele) => record.id !== ele.id),
      });
    };
    //部署节点的列
    const columsOne = [
      {
        title: "账号名",
        dataIndex: "username",
      },
      {
        title: "用户名称",
        dataIndex: "realName",
      },
      {
        title: "手机号码",
        dataIndex: "mobile",
      },
      {
        title: "企业",
        dataIndex: "company",
        render: (text, record) => <span>{text?.name || "-"}</span>,
      },
      {
        title: "操作",
        dataIndex: "action",
        render: (text, record) => {
          return (
            <span>
              <a onClick={() => deleteUserList(text, record)}>删除</a>
            </span>
          );
        },
      },
    ];
    return (
      <>
        <div className="common-pd20">
          <BackPage onClick={() => this.goback()} headeTitle="添加用户" />
          <div className="common-create-center-container">
            <div className="common-create-page">
              <Form
                ref={this.formRef}
                name="validate_other"
                labelAlign="left"
                {...formItemLayout}
                onFinish={onFinish}
              >
                <Form.Item
                  name="productName"
                  label="产品名称"
                  rules={[{ required: true, message: "请选择产品名称" }]}
                >
                  <Select
                    placeholder="请选择产品名称"
                    onChange={onSelectProductName}
                  >
                    { Object.keys(otaListInfo).map((ele) => (
                      <Option key={ele} value={ele}>
                        {ele}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="productModel"
                  label="产品型号"
                  rules={[{ required: true, message: "请选择产品型号" }]}
                  style={{ marginTop: "8px" }}
                >
                  <Select
                    placeholder="请选择产品型号"
                    onChange={onSelectProductModel}
                  >
                    {productModelList.map((ele) => (
                      <Option key={ele.id} value={ele.id}>
                        {ele.productModel}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="epackName"
                  label="epack名称"
                  rules={[{ required: true, message: "请选择epack名称" }]}
                  style={{ marginTop: "8px" }}
                >
                  <Select
                    placeholder="请选择epack名称"
                    onChange={onSelectEpackName}
                  >
                    {Object.keys(firmwareListInfo).map((ele) => (
                      <Option key={ele} value={ele}>
                        {ele}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="epackVersion"
                  label="epack版本"
                  rules={[{ required: true, message: "请选择epack版本" }]}
                  style={{ marginTop: "8px" }}
                >
                  <Select  placeholder="请选择epack版本">
                    {epackVersionList.map((ele) => (
                      <Option key={ele.id} value={ele.id}>
                        {ele.version}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <BottomBtnGroup onClick={() => this.goback()} />
              </Form>

              <div className="common-create-page">
                <span>* 专属用户 :</span>
                <Button
                  onClick={() => this.addUser()}
                  type="primary"
                  style={{ fontSize: 13, marginBottom: 20, marginLeft: 20 }}
                >
                  选择用户
                </Button>
                <Table
                  columns={columsOne} //列
                  rowKey={(record) => record.id}
                  dataSource={this.state.chooseuserList} //行数据
                  pagination={false} //不展示分页
                  scroll={{ y: 200 }} //滚动
                  //bordered={true}
                />
              </div>
            </div>
          </div>

          {/* 弹窗 */}
          <Modal
            width={800}
            title="选择用户"
            visible={this.state.modalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="确定"
            cancelText="取消"
          >
            <UserManage
              selectedRowKeys={(val) => {
                this.handleTransferData(val);
              }}
            />
          </Modal>
        </div>
      </>
    );
  }
}

//export default CreateUser;

export default connect((state) => ({
  currentUser: state.currentUser,
}))(AddEpackExclusiveManageUser);
