import { handleResponse } from './handle-response';
import store from '../../store/index';

export const menuService = {
    getMenus,
    getAllMenus,
    addMenu,
    editMenu,
    deleteMenu,
};

// 获取菜单
function getMenus() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/resource/menu`, requestOptions).then(handleResponse).then(res => { return res });
}

// 菜单操作功能列表
function getAllMenus() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/resource/active`, requestOptions).then(handleResponse).then(res => { return res });
}

//添加菜单
function addMenu(name, url, type, parentId, odr) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name,
            url: url,
            type: type,
            parentId: parentId,
            odr: odr || null,
        })
    };
    return fetch(`/v1/console/resource`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
    });
}

//修改菜单
function editMenu(id, name, url, type, parentId, odr) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name,
            url: url,
            type: type,
            parentId: parentId,
            odr: odr || null,
        })
    };
    return fetch(`/v1/console/resource/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

//删除菜单
function deleteMenu(id) {
    return fetch(`/v1/console/resource/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}