import { handleResponse } from './handle-response';
import store from '../../store/index';

export const roleService = {
    getRoleList,
    getRoleDetail,
    addRole,
    removeRoles,
    removeRole,
    editRole,
    getAllRoles,
    getRoleMenu,
    addMenuForRole,
    removeMenuFormRole,
    openMenuItem,

    getRoleLabel,

    addLabelForRole,
    removeLableFromRole
};



function getRoleList(name, tenantId, offset, limit, type) { // 接口加tenantId
    let url  = `/v1/console/roles?name=${name || ''}&offset=${offset || 1}&limit=${limit || 20}`
    if(type === 'subuser') {
        url  = `/v1/console/roles/tenant?name=${name || ''}&userId=${tenantId || ''}&offset=${offset || 1}&limit=${limit || 20}`
    }
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
function getRoleDetail(id) {
    return fetch(`/v1/console/roles/${id}`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    }).then(handleResponse).then(res => {
        return res;
    })
}

function addRole(name, description) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name,
            description: description
        })
    };
    return fetch(`/v1/console/roles`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function removeRoles(ids) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/roles/batch?ids=${ids}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
function removeRole(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/roles/${id}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
function editRole(id, name, description) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name,
            description: description
        })
    };
    return fetch(`/v1/console/roles/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function getAllRoles() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/roles`, requestOptions).then(handleResponse).then(res => { return res });
}




function getRoleMenu(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/roles/${id}/resources`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}

function addMenuForRole(id, menuId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify(menuId)
    };;

    return fetch(`/v1/console/roles/${id}/resources`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}

function removeMenuFormRole(id, resourceId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/roles/${id}/resource/${resourceId}`, requestOptions).then(handleResponse).then(res => {
        return res;
    });
}

function openMenuItem(id, menuId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/roles/${id}/resource/${menuId}`, requestOptions).then(handleResponse).then(res => {
        return res;
    });

}

function getRoleLabel(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };;
    return fetch(`/v1/console/roles/${id}/labels`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}


function addLabelForRole(id, labelId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify(labelId)
    };;

    return fetch(`/v1/console/roles/${id}/label`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
function removeLableFromRole(id, labelId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    };
    return fetch(`/v1/console/roles/${id}/labels?labelId=${labelId}`, requestOptions).then(handleResponse).then(res => {
        return res;
    });
}

