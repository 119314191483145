/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Radio, Tabs, Modal, Space, message, Cascader, Menu, Dropdown } from "antd";
import { ExclamationCircleOutlined, ArrowUpOutlined, MinusCircleOutlined, PlusOutlined, DownOutlined } from "@ant-design/icons";
import zhCN from "antd/lib/locale/zh_CN";
import { BrowserRouter as Router } from "react-router-dom";

import city from "../../../services/cities";
import { algorithmService } from "../../../services/algorithm-manage";
import { nodeService } from "../../../services/node.service";
import { labelService } from "../../../services/label.service";
import { userService } from "../../../services/user.service";
import { firmwareService } from "../../../services/firmware.service";
import { otaService } from "../../../services/ota.service";
import { logConfig } from "../../../services/log-config";
import { connect } from "react-redux";

import { AUTCONFIG } from "../../../../utils/auth.config";
import "./node-manage.scss";
import "./../../../../index.scss";
import store from "../../../../store";
import { searchRule } from "../../../../utils/search-util";
import { re } from "semver";

const { confirm } = Modal;
const { TabPane } = Tabs;
let searchName = "";
let searchModel = "";
let searchLable = [];
let searchCompany = "";
let searchUser = "";
let searchState = "";
let searchStationId = "";
let searchProvince = "";
let searchCity = "";
let searchDistrict = "";
let algorithem_a = false;
let algorithem_b = false;
let conut = 0;
//select
const { Option } = Select;

function Search(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    let name = values.name || "";
    let str = searchRule(name);
    searchName = str || "";

    let model = values.model || "";
    let str1 = searchRule(model);
    searchModel = str1 || "";

    searchState = values.state || "";
    searchUser = values.username || "";
    searchCompany = values.company || "";
    searchLable = values.labels || [];

    let stationId = values.stationId || "";
    let str2 = searchRule(stationId);
    searchStationId = str2 || "";
    page = 1;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchName = "";
    searchModel = "";
    searchLable = [];
    searchUser = "";
    searchState = "";
    searchStationId = "";
    searchCompany = "";
    searchProvince = "";
    searchCity = "";
    searchDistrict = "";
    props.changeUserList("");
    page = 1;
    props.onClick();
  };

  const handleChangeLable = (value) => {
    searchLable = value;
  };
  const onGenderChange = (value) => {
    const keys = Object.keys(value);
    if (keys.find((elx) => elx === "company")) {
      props.changeUserList(value.company);
      form.setFieldsValue({ username: null });
    }
  };

  const onChange = (value, selectedOptions) => {
    if (value.length === 3) {
      searchProvince = value[0];
      searchCity = value[1];
      searchDistrict = value[2];
    } else if (value.length === 2) {
      searchProvince = "";
      searchCity = value[0];
      searchDistrict = value[1];
    } else if (value.length === 1) {
      searchProvince = "";
      searchCity = value[0];
      searchDistrict = "";
    } else if (value.length === 0) {
      searchProvince = "";
      searchCity = "";
      searchDistrict = "";
    }
  };

  const filter = (inputValue, path) => {
    return path.some((option) => option.label.indexOf(inputValue) > -1);
  };

  return (
    <>
      <div className="common-search-form node-mm">
        <Form
          form={form}
          name="advanced_search"
          layout="inline"
          // style={{ justifyContent: 'center' }}
          className="ant-advanced-search-form"
          onValuesChange={onGenderChange}
          onFinish={onFinish}>
          <Form.Item name="name" label="节点名称" style={{ marginTop: "8px" }}>
            <Input placeholder="请输入节点名称" />
          </Form.Item>
          <Form.Item name="model" label="产品型号" style={{ marginTop: "8px" }}>
            <Input placeholder="请输入产品型号" />
          </Form.Item>
          <Form.Item name="stationId" label="序列号" style={{ marginTop: "8px" }}>
            <Input placeholder="请输入序列号" />
          </Form.Item>
          <Form.Item name="state" label="节点状态" style={{ marginTop: "8px" }}>
            <Select style={{ width: "200px" }} placeholder="请选择节点状态" allowClear>
              {props.stateList}
            </Select>
          </Form.Item>
          <Form.Item name="labels" label="节点标签" style={{ marginTop: "8px" }}>
            <Select mode="multiple" allowClear style={{ width: "200px" }} placeholder="请选择节点标签" onChange={(value) => handleChangeLable(value)} showArrow>
              {props.labelList}
            </Select>
          </Form.Item>
          {store.getState().currentUser.type === AUTCONFIG.ADMIN.value || store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value ? (
            <Form.Item name="company" label="企业" style={{ marginTop: "8px" }}>
              <Select style={{ width: "200px" }} placeholder="请选择企业" showSearch allowClear filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {props.companyList}
              </Select>
            </Form.Item>
          ) : (
            ""
          )}
          {store.getState().currentUser.type !== AUTCONFIG.SUB.value ? (
            <Form.Item name="username" label="所属用户" style={{ marginTop: "8px" }}>
              <Select style={{ width: "200px" }} placeholder="请选择所属用户" showSearch allowClear filterOption={(input, option) => option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {props.tenantList}
              </Select>
            </Form.Item>
          ) : (
            ""
          )}

          <Form.Item name="city" label="地理位置" style={{ marginTop: "8px" }}>
            <Cascader options={city} onChange={onChange} placeholder="请选择省市区" showSearch={filter} />
          </Form.Item>

          <Form.Item style={{ marginTop: "8px" }} label="">
            <div style={{ width: "262px" }}>
              <Button type="primary" htmlType="submit">
                {" "}
                查询{" "}
              </Button>
              <Button style={{ margin: "0 9.46px" }} onClick={resetForm}>
                重置{" "}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
// 选择epack弹窗
let searchFirmwareVersion = "";
let searchFirmwareOta = null;
let firmwaresList = [];
let firmLoading = true;
let firmwareAction = null;
function FirmwareModal(props) {
  const [form] = Form.useForm();
  const firmwareColumns = [
    {
      title: "epack名称",
      dataIndex: "firmwareName",
      key: "firmwareName",
    },
    {
      title: "epack版本",
      dataIndex: "firmwareVersion",
      key: "firmwareVersion",
    },
    {
      title: "类型",
      dataIndex: "isPrivate",
      key: "isPrivate",
      render: (text, record) => {
        return text === 0 ? "公共版" : "专属版";
      },
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
    },
  ];
  const onFinish = (values) => {
    searchFirmwareVersion = values.searchName || "";
    searchFirmwareOta = values.searchOta;
    props.onClick();
  };
  const resetForm = () => {
    console.log(props.otaList);
    form.resetFields();
    searchFirmwareVersion = "";
    searchFirmwareOta = props.defaultSelectValue;
    form.setFieldsValue({ searchOta: props.defaultSelectValue });
    props.onClick();
  };
  const onValuesChange = (value) => {
    const keys = Object.keys(value);
    if (keys.find((elx) => elx === "searchOta")) {
      searchFirmwareVersion = form.getFieldValue("searchName") || "";
      searchFirmwareOta = value.searchOta || "";
      console.log(searchFirmwareOta);
      props.onClick();
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRows);
      // id: selectedRowKeys
      // obj : selectedRows
      firmwareAction = selectedRows;
    },
  };
  return (
    <>
      {/* <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          initialValues={{ 'searchOta': props.defaultSelectValue }}
          onValuesChange={onValuesChange} >
          <Col span={24} offset={2}>
            <Form.Item name='searchOta' label='所属产品'
              style={{ float: 'left', marginRight: '7.14px' }}
            >
              <Select style={{ width: '150px' }} placeholder="请选择所属产品"
              >
                {props.otaList}
              </Select>
            </Form.Item>
            <Form.Item name='searchName' label='epack版本' style={{ float: 'left', marginRight: '7.14px' }}>
              <Input style={{ width: 150 }} placeholder="请输入关键字" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </Form.Item>
          </Col>
        </Form>
      </div> */}
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        rowKey={(record) => record.id}
        pagination={false}
        loading={props.firmLoading}
        scroll={{ y: 360 }}
        columns={firmwareColumns}
        dataSource={firmwaresList}
      />
    </>
  );
}

let page = 1;
let pageSize = 10;
let provinceData = [];
const cityData = {};
let algorithmGroupData = [];
let IsUpgrading = false;

let searchFirwareName = "";
let searchFirwareVersion = "";
let searchFirwareType = "";
let searchFirwarePage = 1;
let searchFirwarePageSize = 10;
class NodeManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      osStateHistory: [], // 用于存储状态记录的数组
      osStateHistoryMes: "", // 用于存储状态记录的数组
      isUpgradeModalVisible: false,
      upgradePollingInterval: null,
      isSelectOsPackModalVisible: false,
      selectedNodeId: null,
      selectedFirmwareId: null,
      osPackList: [],
      algorithmGroupId: {}, //算法组id信息
      isAddVisible: false, //批量新增算法授权
      initCities: [],
      initSecondCity: "",
      citiesInfo: {},
      secondCityInfo: {},
      selectLicences: {}, //算法授权选择

      isLogConfigVisible: false, //批量新增日志配置
      addLogConfigType: 1, //默认追加

      isinstallVisible: false, //推送升级
      OtadefaultSelectValue: null,
      firmLoading: false,
      otaList: [],
      firmwareList: [],

      nodeList: [],
      nodeAmount: 0,
      // deleteIds: [],
      showManage: true,
      showCreate: false,
      showRegistered: false,
      loading: true,
      isModalVisible: false,
      page: 1,
      pageSize: 10,
      labelList: [],
      actionIds: [],
      actionRows: [],
      currentkeys: [],
      // selectedLable: [],
      history: require("history").createHashHistory,

      tenantList: [],
      companyUserList: [],
      companyList: [],
      stateList: [],

      //日志配置
      logData: [],
    };
  }
  form = React.createRef();
  formLog = React.createRef();
  getTenantList() {
    userService.getAllTenantList().then(
      (res) => {
        if (res.code === 200) {
          const tenants = res.users;

          this.setState({
            tenantList: this.getTenantItem(tenants),
            companyUserList: this.getTenantItem(tenants),
          });
        }
      },
      (error) => {
        message.error(error);
      }
    );
  }
  getCompanyList() {
    userService.getCompanyList().then(
      (res) => {
        const getCompanyItem = (companys) => {
          return companys.map((item, index) => {
            return <Option key={item.id}>{item.name}</Option>;
          });
        };
        if (res.code === 200) {
          const companys = res.companys;

          this.setState({
            companyList: getCompanyItem(companys),
          });
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  /**
   * 检索
   */
  getUserListByCompanyForSearch(cid) {
    if (cid) {
      userService.getAllTenantList().then(
        (res) => {
          if (res.code === 200) {
            const tenants = res.users.filter((elx) => elx.company && elx.company.id == cid);
            this.setState({
              tenantList: this.getTenantItem(tenants),
            });
          }
        },
        (error) => {
          error && message.error(error);
        }
      );
    } else {
      userService.getAllTenantList().then(
        (res) => {
          if (res.code === 200) {
            const tenants = res.users;
            this.setState({
              tenantList: this.getTenantItem(tenants),
            });
          }
        },
        (error) => {
          message.error(error);
        }
      );
    }
  }
  /**
   * 分配用户
   */
  getUserListByCompany(cid) {
    if (cid) {
      userService.getAllTenantList().then(
        (res) => {
          if (res.code === 200) {
            const tenants = res.users.filter((elx) => elx.company && elx.company.id == cid);
            this.setState({
              companyUserList: this.getTenantItem(tenants),
            });
          }
        },
        (error) => {
          error && message.error(error);
        }
      );
    } else {
      userService.getAllTenantList().then(
        (res) => {
          if (res.code === 200) {
            const tenants = res.users;
            this.setState({
              companyUserList: this.getTenantItem(tenants),
            });
          }
        },
        (error) => {
          message.error(error);
        }
      );
    }
  }

  assignUser() {
    if (this.state.actionIds.length === 0) {
      message.warn("请先选择节点!");
      return;
    }
    this.setState({
      isModalVisible: true,
    });
    this.form.current?.resetFields();
  }
  onFinish(values) {
    const type = values.type;

    message.loading("节点分配中~~", 2000);
    nodeService
      .assignNode(this.state.actionIds, type)
      .then((res) => {
        message.destroy();
        message.success("节点分配成功");
        this.setState({
          isModalVisible: false,
          actionIds: [],
        });
        this.searchList();
      })
      .catch((error) => {
        this.setState({
          isModalVisible: false,
          actionIds: [],
        });
        this.searchList();
        message.destroy();
        message.error(error);
      });
  }
  getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id}>
          {item.realName}/{item.username}
        </Option>
      );
    });
  }

  getStateList() {
    const arr = [
      { id: 1, state: "UNCONNECTED", name: "未纳管" },
      { id: 2, state: "RUNNING", name: "运行中" },
      { id: 3, state: "UPGRADEWAITING", name: "待升级" },
      { id: 4, state: "UPGRADING", name: "升级中" },
      { id: 6, state: "DISABLED", name: "禁用" },
      { id: 5, state: "OFFLINE", name: "不在线" },
      // { id: 7, state: 'DELETED', name: '已删除' },
      // { id: 8, state: "RECOVER", name: "恢复出厂" },
      { id: 9, state: "OSPACK", name: "系统升级" },
    ];
    this.setState({
      stateList: this.getStateItem(arr),
    });
  }
  getStateItem(states) {
    return states.map((item) => {
      return <Option key={item.state}>{item.name}</Option>;
    });
  }
  showDeleteConfirm(event) {
    confirm({
      title: "确定删除吗?",
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: "#ffb051" }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        message.loading("节点删除中~~", 2500);
        nodeService.removeNodes(event).then(
          (res) => {
            message.destroy();
            if (res.code === 200) {
              message.success("删除成功");
              if (this.state.nodeList.length === 1) {
                page = page - 1;
                page = page ? page : 1;
              }
              this.searchList();
            }
            // else {
            //   message.error('删除失败，' + res.message);
            // }
          },
          (error) => {
            message.destroy();
            message.error(error);
          }
        );
      },
      onCancel() {},
    });
  }

  searchList() {
    this.setState({
      loading: true,
      page,
      pageSize,
    });

    nodeService.getNodeList(searchName, searchModel, searchLable.toLocaleString(), searchUser, searchState, searchCompany, searchStationId, searchProvince, searchCity, searchDistrict, pageSize, page).then(
      (res) => {
        this.setState({
          loading: false,
        });
        if (res.code === 200) {
          console.log(res);
          const nodes = res.nodes;
          nodes.forEach((element) => {
            element.key = element.id;
          });
          this.setState({
            nodeList: nodes,
            nodeAmount: res.dataCount,
          });
        }
        // else {
        //   message.error(res.message);
        // }
      },
      (error) => {
        this.setState({
          loading: false,
        });
        error && message.error(error);
      }
    );
  }
  componentDidMount() {
    searchName = "";
    searchModel = "";
    searchStationId = "";
    searchLable = [];
    searchUser = "";
    searchState = "";
    searchCompany = "";
    searchProvince = "";
    searchCity = "";
    searchDistrict = "";
    page = 1;
    pageSize = 10;
    this.searchList();
    this.getLabelList();
    this.getTenantList();
    this.getCompanyList();
    this.getStateList();
    // if (this.props.currentUser && (this.props.currentUser?.type === AUTCONFIG.ADMIN.value ||
    //   this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value)) {
    //   this.getOtaList();
    // }
  }
  getLableItem(labels) {
    return labels.map((item, index) => {
      return <Option key={item.id}>{item.name}</Option>;
    });
  }
  getLabelList() {
    labelService.getAllLabels().then(
      (res) => {
        if (res.code === 200) {
          const labels = res.labels;
          this.setState({
            labelList: this.getLableItem(labels),
          });
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }

  gotoDetail = (record, key) => {
    this.props.history.push({ pathname: "/home/node-manage/detail", state: { id: record.key } });
  };

  printContent(comment) {
    this.setState({
      showManage: true,
      showCreate: false,
      showRegistered: false,
    });
    if (comment.showManage) {
      this.searchList();
    }
  }

  gotoEdit = (text, key) => {
    this.props.history.push({ pathname: "/home/node-manage/edit", state: { id: text.key, nodeInfo: text } });
  };

  showEnaConfirm(event) {
    confirm({
      title: `确定启用${event.name}吗?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        nodeService.startNodes(event.id).then(
          (res) => {
            if (res.code === 200) {
              message.success("启用节点成功");
              this.searchList();
            }
            // else {
            //   message.error('启用节点失败，' + res.message);
            // }
          },
          (error) => {
            error && message.error(error);
          }
        );
      },
      onCancel() {},
    });
  }
  showDisConfirm(event) {
    confirm({
      title: `确定禁用${event.name}?`,
      icon: <ExclamationCircleOutlined />,
      closable: true,
      onOk: () => {
        nodeService.stopNodes(event.id).then(
          (res) => {
            if (res.code === 200) {
              this.searchList();
              message.success("禁用节点成功");
            }
            // else {
            //   message.error(res.message);
            // }
          },
          (error) => {
            error && message.error(error);
          }
        );
      },
      onCancel() {},
    });
  }
  gotoAlgorithmInstall(record) {
    this.props.history.push({ pathname: "/home/node-manage/algorithm-install", state: { name: record.name, id: record.id, status: record.state, firmwareVersion: record.firmwareVersion } });
  }
  gotoAlgorithmAuthorization(record) {
    this.props.history.push({ pathname: "/home/node-manage/algorithm-authorization", state: { name: record.name, id: record.id } });
  }
  gotoLogConfig(record) {
    this.props.history.push({ pathname: "/home/node-manage/log-config", state: { name: record.name, id: record.id, nodeUuid: record.nodeUuid } });
  }
  gotoRemoteOperation(record) {
    this.props.history.push({ pathname: "/home/node-manage/remote-operation", state: { name: record.name, id: record.id } });
  }
  gotoRemoteSSh(record) {
    this.props.history.push({ pathname: "/home/node-manage/remote-ssh", state: { name: record.name, id: record.id } });
  }
  gotoRemoteLogCollection(record) {
    this.props.history.push({ pathname: "/home/node-manage/log-collection", state: { name: record.name, id: record.id } });
  }
  gotoLog(id) {
    this.props.history.push({ pathname: "/home/node-manage/log", state: { id: id } });
  }
  gotoMonitor(id) {
    this.props.history.push({ pathname: "/home/node-manage/monitor", state: { id: id } });
  }
  gotoUpdate(id) {
    this.props.history.push({ pathname: "/home/node-manage/update", state: { id: id } });
  }
  async getListStatus(nodeName) {
    try {
      const res = await nodeService.getNodeList(nodeName, "", "", "", "", "", "", "", "", "", 10, 1);
      if (res.code === 200) {
        console.log(res.nodes[0]);
        return res.nodes[0].state;
      }
    } catch (error) {
      error && message.error(error);
    }
  }
  startUpgradePolling(selectedNodeId, nodeName) {
    const { Firmware } = this.state;
    const pollingInterval = setInterval(async () => {
      const updateSate = await this.getListStatus(nodeName || Firmware);
      nodeService
        .getOSState(selectedNodeId)
        .then((res) => {
          this.setState((prevState) => ({
            // osStateHistory: [...prevState.osStateHistory, res.osState], // 将新状态添加到数组中
            osStateHistoryMes: res.osState
          }));
          if (updateSate === "RUNNING") {
            clearInterval(pollingInterval);
            this.setState({ isUpgradeModalVisible: false });
            message.success("升级完成");
            this.searchList();
          }
        })
        .catch((error) => {
          console.error("获取OS状态失败", error);
          clearInterval(pollingInterval);
          this.setState({ isUpgradeModalVisible: false });
          message.error("获取OS状态失败");
        });
    }, 5000); // 每5秒轮询一次

    this.setState({ isUpgradeModalVisible: true, upgradePollingInterval: pollingInterval });
  }

  async handleUpgradeProcedure(event,recover) {
    const selectedNodeId = event.id;
    const updateSate = await this.getListStatus(event.name);
    console.log("updateSate@@@", updateSate);
    nodeService
      .getOSState(selectedNodeId)
      .then((res) => {
        if (updateSate !== "RUNNING") {
          this.startUpgradePolling(selectedNodeId, event.name);
        } else {
          if (recover) {
            confirm({
              title: `确定恢复${event.name}?`,
              icon: <ExclamationCircleOutlined />,
              closable: true,
              onOk: () => {
                nodeService.startResetOS(event.id).then(
                  (res) => {
                    if (res.code === 200) {
                      message.success("开始恢复出厂设置");
                      this.searchList();
                      this.startUpgradePolling(event.id);
                    }
                    // else {
                    //   message.error(res.message);
                    // }
                  },
                  (error) => {
                    error && message.error(error);
                  }
                );
              },
              onCancel() {},
            });
          } else {
            this.setState({ isSelectOsPackModalVisible: true, selectedNodeId: event.id });
            this.getOsPackList(event);
          }
          
        }
      })
      .catch((error) => {
        console.error("获取OS状态失败", error);
        message.error("获取OS状态失败");
      });
  }
  handleUpgradeConfirm = () => {
    console.log("@@@@@@state", this.state);
    const { selectedNodeId, selectedFirmwareId } = this.state;
    if (!selectedNodeId) {
      message.warning("请选择一个节点!");
      return;
    }
    if (!selectedFirmwareId) {
      message.warning("请选择一个系统版本!");
      return;
    }
    confirm({
      title: `确定升级吗?`,
      icon: <ExclamationCircleOutlined />,
      closable: true,
      onOk: () => {
        firmwareService.upgradeOspack(selectedNodeId, selectedFirmwareId).then(
          (res) => {
            if (res.code === 200) {
              message.success("升级开始");
              this.setState({ isSelectOsPackModalVisible: false });
              this.searchList();
              this.startUpgradePolling(selectedNodeId);
            } else {
              message.error("升级失败，" + res.message);
            }
          },
          (error) => {
            error && message.error(error);
          }
        );
      },
      onCancel() {},
    });
  };
  handleOsPackRowSelect = (record) => {
    console.log("handleOsPackRowSelect", record);
    if (record) {
      this.setState({ selectedFirmwareId: record.id });
    } else {
      this.setState({ selectedFirmwareId: null });
    }
  };
  getOsPackList(event) {
    console.log("@@@@@@@@@@event", event);
    this.setState({ Firmware: event.name })
    console.log("@@@@@@@@@@state", this.state);
    if (event && event.productId) {
      firmwareService.getFirmwareByOsPack(event.productId, searchFirwareName, searchFirwareVersion, searchFirwarePage, searchFirwarePageSize, searchFirwareType).then(
        (res) => {
          if (res.code === 200) {
            const lists = res.osPacks;
            lists.forEach((element) => {
              element.key = element.id;
            });
            this.setState({ osPackList: lists });
          }
        },
        (error) => {
          error && message.error(error);
        }
      );
    } else {
      message.error("产品ID无效");
    }
  }
  gotoOspack(event) {
    this.handleUpgradeProcedure(event);
  }
  gotoRecover(event) {
    console.log("点击恢复出厂", event);
    this.handleUpgradeProcedure(event,"recover");
    
  }
  gotoUserSoftwareUpdate(id) {
    this.props.history.push({ pathname: "/home/node-manage/user-software-update", state: { id: id } });
  }
  // gotoCertificate(id) {
  //   this.props.history.push({ pathname: '/home/node-manage/certificate', state: { id: id } });
  // }

  getState(record) {
    // f (record.state === 'WAITING') {
    //     return <div><div className="common-yellow"></div> <span>待升级</span></div>
    //   }
    if (record.state === "UNCONNECTED") {
      return (
        <div>
          <div className="common-deep-gray"></div> <span>未纳管</span>
        </div>
      );
    } else if (record.state === "RUNNING") {
      return (
        <div>
          <div className="common-green"></div> <span>运行中</span>
        </div>
      );
    }
    if (record.state === "UPGRADEWAITING") {
      return (
        <div>
          <div className="common-yellow"></div> <span>待升级</span>
        </div>
      );
    } else if (record.state === "UPGRADING") {
      return (
        <div>
          <div className="common-yellow"></div> <span>升级中</span>
        </div>
      );
    } else if (record.state === "DISABLED") {
      return (
        <div>
          <div className="common-gray"></div> <span>禁用</span>
        </div>
      );
    } else if (record.state === "OFFLINE") {
      return (
        <div>
          <div className="common-red"></div> <span>不在线</span>
        </div>
      );
    } else if (record.state === "DELETED") {
      return (
        <div>
          <div className="common-red"></div> <span>已删除</span>
        </div>
      );
    } else if (record.state === "OSPACK") {
      return (
        <div>
          <div className="common-red"></div> <span>系统升级</span>
        </div>
      );
    }
    // else if (record.state === "RECOVER") {
    //   return (
    //     <div>
    //       <div className="common-red"></div> <span>恢复</span>
    //     </div>
    //   );
    // }
    else {
      return "";
    }
  }

  //拼接位置
  getPosition(record) {
    let position = null;
    //没有省市区，返回空
    if (!record?.province && !record.city && !record.district) {
      return position;
    }
    //没有省，返回市区
    else if (!record?.province) {
      position = `${record?.city}/${record?.district}`;
    }
    //没有区，返回省市
    else if (!record?.district) {
      position = `${record?.province}/${record?.city}`;
    } else {
      position = `${record?.province}/${record?.city}/${record?.district}`;
    }

    return position;
  }

  getOperByRecordState(text, record) {
    const actionClick = ({ key }) => {
      message.info(`Click on item ${key}`);
    };
    const actionMenu = (
      <Menu>
        {record.state === "DISABLED" ? (
          <Menu.Item key="1">
            <a onClick={() => this.showEnaConfirm(record)} style={{ marginRight: "5px", wordBreak: "keep-all", color: "red" }}>
              启用
            </a>
          </Menu.Item>
        ) : (
          ""
        )}

        {record.state === "RUNNING" ? (
          <Menu.Item key="2">
            <a onClick={() => this.showDisConfirm(record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              禁用
            </a>
          </Menu.Item>
        ) : (
          ""
        )}

        {record.state === "UNCONNECTED" || record.state === "OFFLINE" || record.state === "DISABLED" ? (
          <Menu.Item key="3">
            <a onClick={() => this.showDeleteConfirm(record.id)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              删除
            </a>
          </Menu.Item>
        ) : (
          ""
        )}

        {record.state === "RUNNING" ? (
          <Menu.Item key="4">
            <a onClick={() => this.gotoRemoteOperation(record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              远程运维
            </a>
          </Menu.Item>
        ) : (
          ""
        )}
        {/* 测试添加|| record.state === "UPGRADEWAITING" || record.state === "UPGRADING" */}
        {record.state === "RUNNING" ? (
          <Menu.Item key="9">
            <a onClick={() => this.gotoRemoteSSh(record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              远程SSH
            </a>
          </Menu.Item>
        ) : (
          ""
        )}

        {(store.getState().currentUser.type === AUTCONFIG.ADMIN.value || store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value) && (record.state == "RUNNING" || record.state == "UPGRADEWAITING" || record.state == "UPGRADING" || record.state == "DISABLED" || record.state == "OFFLINE" || record.state == "OSPACK" || record.state == "RECOVER") ? (
          <Menu.Item key="5">
            <a onClick={() => this.gotoAlgorithmAuthorization(record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              算法授权
            </a>
          </Menu.Item>
        ) : (
          ""
        )}

        {record.state !== "UNCONNECTED" ? (
          <Menu.Item key="6">
            <a onClick={() => this.gotoUserSoftwareUpdate(record.id)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              用户软件升级
            </a>
          </Menu.Item>
        ) : (
          ""
        )}

        {(store.getState().currentUser.type === AUTCONFIG.ADMIN.value || store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value) && (record.state == "RUNNING" || record.state == "UPGRADEWAITING" || record.state == "UPGRADING" || record.state == "DISABLED" || record.state == "OFFLINE" || record.state == "OSPACK" || record.state == "RECOVER") ? (
          <Menu.Item key="7">
            <a onClick={() => this.gotoLogConfig(record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              日志配置
            </a>
          </Menu.Item>
        ) : (
          ""
        )}

        {record.state === "RUNNING" ? (
          <Menu.Item key="10">
            <a onClick={() => this.gotoRemoteLogCollection(record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              日志采集
            </a>
          </Menu.Item>
        ) : (
          ""
        )}

        {record.state !== "UNCONNECTED" ? (
          <Menu.Item key="8">
            <a onClick={() => this.gotoUpdate(record.id)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              升级
              {record.upgradeable === "true" ? <ArrowUpOutlined style={{ fontSize: 12, color: "#389e0d", marginLeft: "2px" }} /> : ""}
            </a>
          </Menu.Item>
        ) : (
          ""
        )}
        {record.state !== "OSPACK" ? (
          <Menu.Item key="12">
            <a onClick={() => this.gotoOspack(record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              系统升级
            </a>
          </Menu.Item>
        ) : (
          ""
        )}
        {/* {record.state !== "RECOVER" ? (
          <Menu.Item key="11">
            <a onClick={() => this.gotoRecover(record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
              恢复出厂
            </a>
          </Menu.Item>
        ) : (
          ""
        )} */}
      </Menu>
    );

    return (
      <>
        <a onClick={this.gotoEdit.bind(text, record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
          编辑
        </a>

        {record.state == "RUNNING" || record.state == "UPGRADEWAITING" || record.state == "UPGRADING" || record.state == "DISABLED" || record.state == "OFFLINE" || record.state == "OSPACK" || record.state == "RECOVER" ? (
          <a onClick={() => this.gotoLog(record.id)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
            日志
          </a>
        ) : (
          ""
        )}

        {record.state == "RUNNING" || record.state == "UPGRADEWAITING" || record.state == "UPGRADING" || record.state == "DISABLED" || record.state == "OFFLINE" || record.state == "OSPACK" || record.state == "RECOVER" ? (
          <a onClick={() => this.gotoMonitor(record.id)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
            监控
          </a>
        ) : (
          ""
        )}

        {record.state == "RUNNING" || record.state == "UPGRADEWAITING" || record.state == "UPGRADING" || record.state == "DISABLED" || record.state == "OFFLINE" || record.state == "OSPACK" || record.state == "RECOVER" ? (
          <a onClick={() => this.gotoAlgorithmInstall(record)} style={{ marginRight: "5px", wordBreak: "keep-all" }}>
            推送升级
          </a>
        ) : (
          ""
        )}

        <Dropdown overlay={actionMenu} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              更多
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
        {/* {
          record.state === 'DISABLED' ?
            <a onClick={() => this.showEnaConfirm(record)} style={{ marginRight: '5px', wordBreak: 'keep-all' }}>启用</a>
            : ''
        } */}

        {/* {
          record.state === 'RUNNING' ?
            <a onClick={() => this.showDisConfirm(record)} style={{ marginRight: '5px', wordBreak: 'keep-all' }}>禁用</a>
            : ''
        } */}

        {/* {record.state === 'UNCONNECTED'
          || record.state === 'OFFLINE'
          || record.state === 'DISABLED' ?
          <a onClick={() => this.showDeleteConfirm(record.id)} style={{ marginRight: '5px', wordBreak: 'keep-all' }}>删除</a> : ''}


        {
          record.state === 'RUNNING' ?
            <a onClick={() => this.gotoRemoteOperation(record)} style={{ marginRight: '5px', wordBreak: 'keep-all' }}>远程运维</a>
            : ''
        }
        {
          (store.getState().currentUser.type === AUTCONFIG.ADMIN.value ||
            store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value)
            && (record.state == 'RUNNING'
              || record.state == 'UPGRADEWAITING'
              || record.state == 'UPGRADING'
              || record.state == 'DISABLED'
              || record.state == 'OFFLINE') ?
            <a onClick={() => this.gotoAlgorithmAuthorization(record)} style={{ marginRight: '5px', wordBreak: 'keep-all' }}>算法授权</a> : ''} */}
        {/* {record.state !== 'UNCONNECTED' ?
          <a onClick={() => this.gotoUserSoftwareUpdate(record.id)} style={{ marginRight: '5px', wordBreak: 'keep-all' }}>用户软件升级</a>
         : ''} */}
        {/* {record.state !== 'UNCONNECTED' ?
          <a onClick={() => this.gotoUpdate(record.id)} style={{ marginRight: '5px', wordBreak: 'keep-all' }}>升级
            {
              record.upgradeable === 'true' ?
                <ArrowUpOutlined style={{ fontSize: 12, color: '#389e0d', marginLeft: '2px' }} /> : ''}
          </a>
          : ''} */}
      </>
    );
  }

  // 推送升级开始
  installAlgorithmShow() {
    if (this.state.actionIds.length === 0) {
      message.warn("请先选择节点!");
      return;
    }
    const nodeInfo = this.state.actionRows[0];
    if (this.state.actionRows.findIndex((ele) => ele.firmwareVersion !== nodeInfo.firmwareVersion) !== -1) {
      message.warn("请选择相同的epack版本!");
      return;
    }
    IsUpgrading = false;
    this.state.actionRows.forEach((ele, index) => {
      this.getUpgradeHistory(ele, index);
    });
  }

  getUpgradeHistory(ele, index) {
    //节点推送升级记录查询
    const length = this.state.actionIds.length;
    nodeService.getNodeAlgorithmsUpgradeHistory(ele.id).then(
      (res) => {
        if (res.code === 200) {
          if (res.nodeUpgrades[0]?.state == "UPGRADING") {
            IsUpgrading = true;
            message.warn(`节点 ${ele.name} 正在升级epack版本，无法操作！`);
          } else if (index === length - 1 && !IsUpgrading) {
            this.getNodeUpdate(ele.id);
          }
        }
        // else {
        //   message.error('节点推送升级记录查询失败，' + res.message);
        // }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  handleInstallOk() {
    this.handleAlgBathUp();
    // firmwareAction
  }
  handleAlgBathUp() {
    console.log("@@@@@this.state", this.state);
    const actionIds = this.state.actionIds;
    nodeService.addNodeAlgorithmsBathUpgrade(actionIds, firmwareAction[0].id).then(
      (res) => {
        if (res.code === 200) {
          message.success("操作成功");
          this.handleInstallCancel();
        }
        // else {
        //   message.error('操作失败，' + res.message);
        // }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }

  handleInstallCancel() {
    searchFirmwareOta = null;
    this.setState({
      isinstallVisible: false,
    });
  }

  getOtaList() {
    otaService.getAllOtaList().then(
      (res) => {
        if (res.code === 200) {
          const products = res.products;
          this.setState({
            otaList: this.getOtaItem(products),
            OtadefaultSelectValue: products[0]?.id,
          });

          // this.getFirmwareList(products[0]?.id);
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  getOtaItem(products) {
    return products.map((item, index) => {
      return (
        <Option key={item.id} value={item.id}>
          {item.productName}
        </Option>
      );
    });
  }
  // getFirmwareList() {
  //   if (this.state.OtadefaultSelectValue) {
  //     this.setState({
  //       firmLoading: true,
  //     })
  //     firmwareService.getAllFirmwareByOta(searchFirmwareOta || this.state.OtadefaultSelectValue, '', searchFirmwareVersion).then(res => {
  //       this.setState({
  //         firmLoading: false
  //       })
  //       if (res.code === 200) {
  //         firmwaresList = res.firmwares;
  //         this.setState({
  //           isinstallVisible: true,
  //         });
  //       } else {
  //         message.error('获取epack列表失败，' + res.message);
  //       }
  //     }, error => {
  //       this.setState({
  //         firmLoading: false
  //       })
  //       error && message.error(error);
  //     });
  //   } else {
  //     message.info('没有可选择epack')
  //   }
  // }
  getNodeUpdate(nodeId) {
    this.setState({
      isinstallVisible: true,
      firmLoading: true,
    });
    nodeService.getUpgradeFirmwares(nodeId).then(
      (res) => {
        if (res.code === 200) {
          firmwaresList = res.firmwares;
          this.setState({
            firmLoading: false,
          });
        }
        //  else {
        //   message.error('获取epack列表失败，' + res.message);
        //   this.setState({
        //     firmLoading: false,
        //   });
        // }
      },
      (error) => {
        this.setState({
          firmLoading: false,
        });
        error && message.error(error);
      }
    );
  }

  // 推送升级结束

  //算法授权开始
  showAddConfirm() {
    if (this.state.actionIds.length === 0) {
      message.warn("请先选择节点!");
      return;
    }
    this.getTotalAlgorithms(); //查询算法处理
    this.getAlgorithmGroups(); //查询算法组处理
  }

  handleOk() {
    let flag = true;
    const selectIds = this.state.actionIds; //批量授权节点
    const selectInfo = this.state.selectLicences;
    const algorithmsArr = []; //最终传body的数据
    const judgeRepeatArr = []; //判断是否重复添加的数组
    // if (!Object.keys(selectInfo).length) {//对象为空
    //   // message.warning("请添加算法!");
    // } else {
    for (let key in selectInfo) {
      if (judgeRepeatArr.find((ele) => ele === selectInfo[key][0])) {
        if (flag) {
          flag = !flag;
          message.warning("请勿添加相同的算法!");
        }
      } else {
        const info = {};
        info.algorithmName = selectInfo[key][0];
        info.algorithmVersion = selectInfo[key][1];
        algorithmsArr.push(info);
        judgeRepeatArr.push(selectInfo[key][0]);
      }
    }
    if (flag) {
      confirm({
        style: { marginTop: 35 },
        width: 400,
        title: "此操作会覆盖当前算法，确定添加吗?",
        icon: <ExclamationCircleOutlined />,
        closable: true,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.nodeLicencesBath(selectIds, algorithmsArr);
        },
        onCancel() {},
      });
    }
    // }
  }

  //批量授权
  nodeLicencesBath(nodeIds, algorithms) {
    nodeService.nodeLicencesBath(nodeIds, algorithms).then(
      (res) => {
        if (res.code === 200) {
          message.success("操作成功");
          this.handleAddCancel();
        }
        // else {
        //   message.error('操作失败' + res.message);
        // }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  handleAddCancel = () => {
    algorithem_b = false;
    algorithem_a = false;
    conut = 0;
    this.setState({
      isAddVisible: false,
      initCities: [],
      initSecondCity: "",
      citiesInfo: {},
      secondCityInfo: {},
      selectLicences: {},
    });
  };
  //授权算法查询
  // getLicensesInfo(nodeId) {
  //   nodeService.getLicences(nodeId).then(res => {
  //     if (res.code === 200) {
  //       console.log('授权算法查询', res);
  //     } else {
  //       message.error('启动运维失败' + res.message);
  //     }
  //   }, error => {
  //     error && message.error(error);
  //   })
  // }
  //查询算法概览列表
  getTotalAlgorithms() {
    algorithmService.getAlgorithmOverview("", 1000, 1).then(
      (res) => {
        if (res.code === 200) {
          const data = res.namespaces;
          this.handleOverviewData(data, data.length);
          console.log("算法概览查询", res);
        }
        // else {
        //   message.error('算法概览查询失败' + res.message);
        // }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  handleOverviewData(data, length) {
    provinceData = [];
    data.map((ele, index) => {
      const info = {};
      info.name = ele.name;
      provinceData.push(info);
      this.getalgorithmsList(ele.id, ele.name, index, length);
    });
  }
  //算法组概览列表查询
  getAlgorithmGroups() {
    algorithmService.getAlgorithmGroups("", 1000, "").then((res) => {
      console.log("算法组", res.groups);
      if (res.code === 200) {
        const data = res.groups;
        this.handleAlgorithmGroups(data);
        algorithem_b = true;
        if (algorithem_a) {
          this.setState({
            isAddVisible: true,
          });
        }
      }
    });
  }
  handleAlgorithmGroups(data) {
    algorithmGroupData = [];
    data.map((ele) => {
      algorithmGroupData.push(ele.algorithmGroupName);
      this.state.algorithmGroupId[ele.algorithmGroupName] = ele.id;
    });
  }

  //查询算法列表
  getalgorithmsList(id, name, index, length) {
    algorithmService.getAlgorithm(id, "", "", 100, 1).then(
      (res) => {
        if (res.code === 200) {
          const data = res.algorithms;
          conut++;
          if (data.length) {
            this.handleListData(data, name);
            provinceData[index].state = true;
          } else {
            provinceData[index].state = false;
          }
          if (length === conut) {
            //确保在最后一个算法列表数据处理完在显现信息框
            algorithem_a = true;
            if (algorithem_b) {
              this.setState({
                isAddVisible: true,
              });
            }
          }
        }
        // else {
        //   message.error('算法列表查询失败' + res.message);
        // }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  handleListData(data, name) {
    const arr = [];
    data.map((ele) => {
      arr.push(ele?.algorithmVersion);
    });
    cityData[name] = arr;
  }
  // 删除算法处理
  handleRemove(key) {
    const selectLicences = this.state.selectLicences;
    // const citiesInfo = this.state.citiesInfo;
    // const secondCityInfo = this.state.secondCityInfo;
    delete selectLicences[key];
    // delete citiesInfo[key];
    // delete secondCityInfo[key];
    this.setState({
      selectLicences,
      // citiesInfo,
      // secondCityInfo
    });
  }

  // 添加算法处理
  handleAdd() {
    const citiesInfo = this.state.citiesInfo;
    const secondCityInfo = this.state.secondCityInfo;
    const len_1 = Object.keys(citiesInfo).length;
    citiesInfo[len_1] = [];
    secondCityInfo[len_1] = "";
    this.setState({
      citiesInfo,
      secondCityInfo,
    });
  }
  componentWillUnmount() {
    sessionStorage.removeItem("nodeId");
    sessionStorage.removeItem("nodeName");
  }

  // 固定第一条 选择框联动
  handleChange_1 = (value) => {
    const dataArr = [value, cityData[value][0]];
    const selectLicences = this.state.selectLicences; //selectLicences={}
    selectLicences.init = dataArr;
    this.setState({
      initCities: cityData[value],
      initSecondCity: cityData[value][0],
      selectLicences,
    });
  };
  handleChange_2 = (value) => {
    const selectLicences = this.state.selectLicences; //selectLicences={}
    selectLicences.init[1] = value;
    this.setState({
      initSecondCity: value,
      selectLicences,
    });
  };

  //动态 选择框联动
  handleProvinceChange = (value, num) => {
    const dataArr = [value, cityData[value][0]]; //形如selectLicences={[province,city],{dataArr},...}
    const citiesInfo = this.state.citiesInfo;
    const secondCityInfo = this.state.secondCityInfo;
    const selectLicences = this.state.selectLicences;

    selectLicences[num] = dataArr;

    citiesInfo[num] = cityData[value];
    secondCityInfo[num] = cityData[value][0];
    this.setState({
      citiesInfo,
      secondCityInfo,
      selectLicences,
    });
  };

  onSecondCityChange = (value, num) => {
    const selectLicences = this.state.selectLicences;
    const secondCityInfo = this.state.secondCityInfo;
    selectLicences[num][1] = value;
    secondCityInfo[num] = value;
    this.setState({
      secondCityInfo,
      selectLicences,
    });
  };
  //算法授权结束

  //日志配置开始
  changeLogConfigType(e) {
    let type = e.target.value;
    this.setState({
      addLogConfigType: type,
    });
  }

  logConfigConfirm() {
    if (this.state.actionIds.length === 0) {
      message.warn("请先选择节点!");
      return;
    }

    this.setState({
      isLogConfigVisible: true,
      // logData: [
      //   { name: 'firset1', value: 'log1' },
      //   { name: 'firset2', value: 'log2' },
      // ]
    });

    // this.formLog.current?.setFieldsValue({
    //   logRouter: '/a',
    //   logLever: 'a',
    //   description: 'aaa',
    //   users: [
    //     { first: 'a', last: 'a', des: 'aaa' },
    //     { first: 'b', last: 'b', des: 'bbb' },
    //     { first: 'c', last: 'c', des: 'ccc' },
    //   ],
    // })
  }

  //数组对象去重
  getOnlyOneEleInArr(arr) {
    let result = [];
    for (let i of arr) {
      if (result.find((ele) => ele.logAddr === i.logAddr && ele.logLevel === i.logLevel)) {
        continue;
      }
      result.push(i);
    }
    return result;
  }

  handleLogConfigOk(event) {
    console.log(event);
    console.log(this.state.actionIds);
    console.log(this.state.actionRows);
    console.log(this.state.addLogConfigType);

    let edgeLogTasks = [];
    let edgeLogTask = {};

    if (this.state.addLogConfigType === 1) {
      edgeLogTask.logAddr = event?.logAddr;
      edgeLogTask.logLevel = event?.logLevel;
      edgeLogTask.description = event?.description;
      edgeLogTasks.push(edgeLogTask);

      if (event?.users) {
        edgeLogTasks.push(...event?.users);
      }
    } else if (this.state.addLogConfigType === 0) {
      edgeLogTask.logAddr = event?.logAddr2;
      edgeLogTask.logLevel = event?.logLevel2;
      edgeLogTask.description = event?.description2;
      edgeLogTasks.push(edgeLogTask);

      if (event?.users2) {
        edgeLogTasks.push(...event?.users2);
      }
    }

    console.log(edgeLogTasks);
    let newArr = this.getOnlyOneEleInArr(edgeLogTasks);
    console.log(newArr);

    let nodeUuids = [];
    if (this.state.actionRows?.length !== 0) {
      this.state.actionRows.forEach((ele) => {
        nodeUuids.push(ele.nodeUuid);
      });
    }

    let overFlag = this.state.addLogConfigType; //0覆盖,1追增
    logConfig.bulkAddLogConfigs(newArr, nodeUuids, overFlag).then((res) => {
      console.log(res);
      if (res.code === 200) {
        message.success("添加成功！");
        this.setState({
          isLogConfigVisible: false,
          actionIds: [],
        });
      } else {
        message.error("添加失败");
        this.setState({
          isLogConfigVisible: false,
        });
      }
    });
  }

  handleLogConfigCancel = () => {
    this.setState({
      isLogConfigVisible: false,
      // initCities: [],
      // initSecondCity: '',
      // citiesInfo: {},
      // secondCityInfo: {},
      // selectLicences: {}
    });
  };
  //日志配置结束

  render() {
    const onPageChange = (pageNumber, ps) => {
      page = pageNumber;
      if (ps != pageSize) {
        page = 1;
      }
      pageSize = ps;
      this.searchList();
    };

    const handleCancel = () => {
      this.setState({
        isModalVisible: false,
      });
    };

    const registered = () => {
      //   this.setState({
      //     showManage: false,
      //     showCreate: true,
      //     showRegistered: true,
      //   })
      this.props.history.push("/home/node-manage/add");
    };
    // const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     this.setState({
    //       deleteIds: selectedRowKeys
    //     })
    //   }
    // };

    // 批量算法授权
    const menu = (
      <Menu>
        {/* <Menu.Item key="1" onClick={() => this.assignUser()}>
          分配用户
        </Menu.Item>
        <Menu.Item key="2" onClick={() => this.showAddConfirm()}>
          算法授权
        </Menu.Item>
        <Menu.Item key="3" onClick={() => this.installAlgorithmShow()}>
          推送升级
        </Menu.Item> */}
      </Menu>
    );

    const { cities } = this.state;
    const Columns = [
      {
        title: "算法名称",
        dataIndex: "variableName",
      },
      {
        title: "算法版本",
        dataIndex: "variableValue",
      },
      {
        title: "算法描述",
        dataIndex: "variableValue",
      },
      {
        title: "授权时间",
        dataIndex: "variableValue",
      },
      {
        title: "操作",
        dataIndex: "action",
        render: (text, record) => (
          <a
            href
            // onClick={() => this.showDeleteEnv(record)}
          >
            删除
          </a>
        ),
      },
    ];

    const sysColumns = [
      {
        title: "节点名称",
        dataIndex: "name",
        width: "8%",
        render: (text, record) => (
          <a href onClick={this.gotoDetail.bind(text, record)}>
            {text}
          </a>
        ),
      },
      {
        title: "产品序列号",
        dataIndex: "seriesNumber",
      },
      {
        title: "产品型号",
        dataIndex: "productModel",
      },
      {
        title: "epack版本",
        dataIndex: "firmwareVersion",
      },
      {
        title: "系统名称",
        dataIndex: "OSName",
        render: (text, record) => {
          const fullImageVersion = record.fullImageVersion || "";
          // 使用正则表达式提取英文字母
          const lettersOnly = fullImageVersion.replace(/[^a-zA-Z]/g, '');
          return lettersOnly;
        },
      },
      {
        title: "系统版本",
        dataIndex: "fullImageVersion",
      },
      {
        title: "地理位置",
        width: "12%",
        render: (text, record) => this.getPosition(record),
      },
      {
        title: "应用实例",
        dataIndex: "instanceNum",
      },
      {
        title: "设备",
        dataIndex: "deviceNum",
      },
      {
        title: "状态",
        dataIndex: "state",
        render: (text, record) => <>{this.getState(record)}</>,
      },
      {
        title: "所属用户",
        dataIndex: "realName",
      },
      {
        title: "上报时间",
        dataIndex: "upTime",
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
      },
      {
        title: "操作",
        dataIndex: "action",
        width: "16%",
        render: (text, record) => <>{this.getOperByRecordState(text, record)}</>,
      },
    ];
    const columns = [
      {
        title: "节点名称",
        dataIndex: "name",
        width: "8%",
        render: (text, record) => (
          <a href onClick={this.gotoDetail.bind(text, record)}>
            {text}
          </a>
        ),
      },
      {
        title: "产品序列号",
        dataIndex: "seriesNumber",
      },
      {
        title: "产品型号",
        dataIndex: "productModel",
      },
      {
        title: "地理位置",
        width: "12%",
        render: (text, record) => this.getPosition(record),
      },
      {
        title: "应用实例",
        dataIndex: "instanceNum",
      },
      {
        title: "设备",
        dataIndex: "deviceNum",
      },
      {
        title: "状态",
        dataIndex: "state",
        render: (text, record) => <>{this.getState(record)}</>,
      },
      {
        title: "上报时间",
        dataIndex: "upTime",
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
      },
      {
        title: "操作",
        dataIndex: "action",
        width: "15%",
        render: (text, record) => <>{this.getOperByRecordState(text, record)}</>,
      },
    ];
    //批量算法组授权
    const onFinish = (values) => {
      const selectIds = this.state.actionIds; //批量授权节点

      let flag = true;
      const algorithmGroupId = this.state.algorithmGroupId;
      const algorithmsGroup = []; //最终传body的数据
      const judgeRepeatArr = []; //判断是否重复添加的数组
      if (values.first) {
        const info = {};
        info.id = algorithmGroupId[values.first];
        info.algorithmGroupName = values.first;
        judgeRepeatArr.push(values.first);
        algorithmsGroup.push(info);
      }
      let res = values.last?.find((ele) => ele.info !== undefined);
      if (!values.first && !res) {
        message.warning("请添加算法!");
      } else {
        values.last?.forEach((val) => {
          if (judgeRepeatArr.find((ele) => ele === val.info)) {
            if (flag) {
              message.warning("请勿添加相同的算法!");
              flag = !flag;
            }
          } else if (val.info) {
            const info = {};
            info.id = algorithmGroupId[val.info];
            info.algorithmGroupName = val.info;
            judgeRepeatArr.push(val.info);
            algorithmsGroup.push(info);
          }
        });
        if (flag) {
          algorithmGroupLicencesBath(selectIds, algorithmsGroup);
        }
      }
    };
    //算法组授权后台
    const algorithmGroupLicencesBath = (nodeIds, algorithmGroups) => {
      nodeService.algorithmGroupLicencesBath(nodeIds, algorithmGroups).then(
        (res) => {
          if (res.code === 200) {
            message.success("操作成功!");
            this.handleAddCancel();
          }
          // else {
          //     message.error('操作失败' + res.message);
          // }
        },
        (error) => {
          error && message.error(error);
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const actionIds = this.state.actionIds;
        const actionRows = this.state.actionRows;
        this.state.nodeList.forEach((elx) => {
          actionIds.find((item) => item === elx.id) !== undefined && actionIds.splice(actionIds.indexOf(elx.id), 1);
          actionRows.find((item) => item.id === elx.id) !== undefined &&
            actionRows.splice(
              actionRows.findIndex((val) => val.id === elx.id),
              1
            );
        });

        this.setState({
          actionIds: actionIds.concat(selectedRowKeys),
          actionRows: actionRows.concat(selectedRows),
        });
      },
      selectedRowKeys: this.state.actionIds,
    };
    const onGenderChange = (value) => {
      const keys = Object.keys(value);
      if (keys.find((elx) => elx === "company")) {
        this.getUserListByCompany(value.company);
        this.form.current.setFieldsValue({ type: null });
      }
    };

    const logConfigFinish = (values) => {
      console.log(values);
    };

    return (
      <>
        <div className="common-pd20">
          <div style={{ display: this.state.showManage === true ? "block" : "none" }}>
            <h1 className="common-top-title0">边缘节点</h1>
            <div className="common-center-container no-search">
              <Search stateList={this.state.stateList} tenantList={this.state.tenantList} companyList={this.state.companyList} labelList={this.state.labelList} onClick={() => this.searchList()} changeUserList={($event) => this.getUserListByCompanyForSearch($event)} />
              <div className="oper-button">
                {store.getState().currentUser.type !== AUTCONFIG.SUB.value ? (
                  <Router>
                    <Button type="primary" onClick={registered}>
                      注册
                    </Button>
                  </Router>
                ) : (
                  ""
                )}
                {store.getState().currentUser.type !== AUTCONFIG.SUB.value ? (
                  <Button type="primary" style={{ marginLeft: "10px", width: "78px", textAlign: "center" }} onClick={() => this.assignUser()}>
                    {" "}
                    分配用户{" "}
                  </Button>
                ) : (
                  ""
                )}
                {store.getState().currentUser.type === AUTCONFIG.ADMIN.value || store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value ? (
                  // <Dropdown trigger={['click']}  overlay={menu}>
                  //   <Button   style={{marginLeft:10,lineHeight:-1}} type="primary" >
                  //     批量 <DownOutlined  />
                  //   </Button>

                  // </Dropdown>
                  // : ''

                  <Button type="primary" style={{ marginLeft: "10px", width: "78px", textAlign: "center" }} onClick={() => this.showAddConfirm()}>
                    {" "}
                    算法授权{" "}
                  </Button>
                ) : (
                  ""
                )}
                {store.getState().currentUser.type === AUTCONFIG.ADMIN.value || store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value ? (
                  <Button type="primary" style={{ marginLeft: "10px", width: "78px", textAlign: "center" }} onClick={() => this.logConfigConfirm()}>
                    {" "}
                    日志配置{" "}
                  </Button>
                ) : (
                  ""
                )}
                {
                  <Button type="primary" style={{ marginLeft: "10px", width: "78px", textAlign: "center" }} onClick={() => this.installAlgorithmShow()}>
                    {" "}
                    推送升级{" "}
                  </Button>
                }
              </div>
              <div className="user-table-card">
                {/* 
                    rowSelection={{
                    ...rowSelection,
                  }} */}
                {store.getState().currentUser.type !== AUTCONFIG.SUB.value ? (
                  <Table
                    rowSelection={{
                      ...rowSelection,
                    }}
                    rowKey={(record) => record.id}
                    bordered={false}
                    pagination={false}
                    tableLayout="fixed"
                    columns={sysColumns}
                    loading={this.state.loading}
                    dataSource={this.state.nodeList}
                  />
                ) : (
                  <Table rowKey={(record) => record.id} bordered={false} tableLayout="fixed" loading={this.state.loading} pagination={false} columns={columns} dataSource={this.state.nodeList} />
                )}
              </div>
              <div>
                {this.state.nodeList.length > 0 && (
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination showSizeChanger defaultCurrent={1} current={this.state.page} pageSize={this.state.pageSize} total={this.state.nodeAmount} onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal title="选择用户" visible={this.state.isModalVisible} footer={null} onCancel={handleCancel}>
            <Form ref={this.form} name="advanced_search_user" className="ant-advanced-search-assign" onFinish={this.onFinish.bind(this)} layout="horizontal" onValuesChange={onGenderChange} style={{ justifyContent: "center", marginLeft: 64 }}>
              {store.getState().currentUser.type !== AUTCONFIG.MAIN.value ? (
                <Form.Item name="company" label="企业" style={{ marginTop: "12px", justifyContent: "center", paddingLeft: 40 }}>
                  <Select style={{ width: "200px" }} placeholder="企业名称" showSearch allowClear>
                    {this.state.companyList}
                  </Select>
                </Form.Item>
              ) : (
                ""
              )}

              <Form.Item name="type" label="用户名称" style={{ marginTop: "12px", justifyContent: "center" }} rules={[{ required: true, message: "请选择用户名" }]}>
                <Select style={{ width: "200px" }} placeholder="用户名称" showSearch allowClear filterOption={(input, option) => option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.state.companyUserList}
                </Select>
              </Form.Item>

              <Col span={24}>
                <Form.Item style={{ marginTop: "12px", justifyContent: "center" }}>
                  <div style={{ textAlign: "center", marginLeft: -64 }}>
                    <Button type="primary" htmlType="submit">
                      {" "}
                      确认{" "}
                    </Button>
                    <Button style={{ margin: "0 9.46px" }} onClick={handleCancel}>
                      {" "}
                      取消
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Form>
          </Modal>
          {/* 创建 */}
          {/* <div style={{ display: (this.state.showRegistered === true) ? "block" : "none" }}>
            <CreateNode onSubmit={this.printContent.bind(this)} labelList={this.state.labelList} />
          </div> */}

          <Modal
            destroyOnClose //打开新内容
            width={450}
            visible={this.state.isAddVisible}
            onOk={() => this.handleOk()}
            onCancel={() => this.handleAddCancel()}
            footer={null}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="新增算法" key="1">
                <Form
                  preserve={false}
                  name="dynamic_form_nest_item"
                  // onFinish={onFinish}
                  autoComplete="off">
                  <Space key={-1} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                    <Form.Item label="算法名称" rules={[{ required: true, message: "请选择算法名称" }]}>
                      <Select style={{ width: 120 }} onChange={this.handleChange_1}>
                        {provinceData.map((province) => (
                          <Option key={province.name} value={province.name} disabled={!province.state}>
                            {province.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="算法版本" rules={[{ required: true, message: "请选择算法版本" }]}>
                      <Select style={{ width: 120 }} value={this.state.initSecondCity} onChange={this.handleChange_2}>
                        {this.state.initCities.map((city) => (
                          <Option key={city}>{city}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space>
                  <Form.List name="users">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...restField}
                              label="算法名称"
                              // name={[name, 'first']}
                              // initialValue={provinceData[0]}
                              fieldKey={[fieldKey, "first"]}
                              rules={[{ required: true, message: "请选择算法名称" }]}>
                              <Select
                                // defaultValue={provinceData[0]}
                                style={{ width: 120 }}
                                onChange={(e) => this.handleProvinceChange(e, key)}>
                                {provinceData.map((province) => (
                                  <Option key={province.name} value={province.name} disabled={!province.state}>
                                    {province.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              label="算法版本"
                              // initialValue={this.state.secondCity}
                              // name={[name, 'last']}
                              fieldKey={[fieldKey, "last"]}
                              rules={[{ required: true, message: "请选择算法版本" }]}>
                              <Select style={{ width: 120 }} value={this.state.secondCityInfo[key]} onChange={(e) => this.onSecondCityChange(e, key)}>
                                {this.state.citiesInfo[key]?.map((city) => (
                                  <Option key={city}>{city}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(name);
                                this.handleRemove(key);
                              }}
                            />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                              this.handleAdd();
                            }}
                            block
                            icon={<PlusOutlined />}>
                            添加
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item name="type" label="已授权算法">
                    {/* <Radio.Group defaultValue={"b"} style={{ marginLeft: 15 }}>
                  <Radio disabled value="a" style={{ marginRight: 60 }}>追加新增</Radio>
                  <Radio value="b">覆盖替换</Radio>
                </Radio.Group> */}
                    <Radio defaultChecked style={{ marginLeft: 20 }}>
                      覆盖替换
                    </Radio>
                  </Form.Item>
                  <Form.Item style={{ marginTop: "12px", justifyContent: "center" }}>
                    <div style={{ textAlign: "center" }}>
                      <Button type="primary" htmlType="submit" style={{ marginRight: 20 }} onClick={() => this.handleOk()}>
                        {" "}
                        确认{" "}
                      </Button>
                      <Button style={{ margin: "0 9.46px" }} onClick={() => this.handleAddCancel()}>
                        {" "}
                        取消
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane tab="新增算法组" key="2">
                <Form preserve={false} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                  <Space key={-1} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                    <Form.Item
                      label="算法组名称"
                      name="first"
                      // rules={[{ required: true, message: '请选择算法组名称' }]}
                    >
                      <Select
                        style={{ width: 300 }}
                        // onChange={this.handleChange_1}
                      >
                        {algorithmGroupData.map((ele) => (
                          <Option key={ele} value={ele}>
                            {ele}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space>
                  <Form.List name="last">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                            <Form.Item {...restField} label="算法组名称" name={[name, "info"]}>
                              <Select style={{ width: 300 }}>
                                {algorithmGroupData.map((ele) => (
                                  <Option key={ele} value={ele}>
                                    {ele}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(name);
                              }}
                            />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}>
                            添加
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item style={{ marginTop: "32px", justifyContent: "center" }}>
                    <div style={{ textAlign: "center" }}>
                      <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>
                        {" "}
                        确认{" "}
                      </Button>
                      <Button style={{ margin: "0 9.46px" }} onClick={() => this.handleAddCancel()}>
                        {" "}
                        取消
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </Modal>

          {/* 日志配置 */}
          <Modal
            destroyOnClose //打开新内容
            forceRender={true} //强制渲染dom,防止formRef不生效
            width={750}
            visible={this.state.isLogConfigVisible}
            // visible={true}
            onOk={() => this.handleLogConfigOk()}
            onCancel={() => this.handleLogConfigCancel()}
            footer={null}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="日志配置" key="1">
                <Form
                  ref={this.formLog}
                  //preserve={false}
                  name="dynamic_form_nest_item"
                  onFinish={(e) => this.handleLogConfigOk(e)}
                  //autoComplete="off"
                >
                  {this.state.addLogConfigType === 1 ? (
                    <>
                      <Space key={-1} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                        <Form.Item name="logAddr" label="日志采集路径" rules={[{ required: true, message: "请选择日志采集路径" }]}>
                          <Input style={{ width: 150 }} />
                        </Form.Item>
                        <Form.Item name="logLevel" label="日志级别" rules={[{ required: true, message: "请选择日志级别" }]}>
                          <Select style={{ width: 100 }}>
                            <Option value={0}>ALL</Option>
                            <Option value={1}>DEBUG</Option>
                            <Option value={2}>INFO</Option>
                            <Option value={3}>WARN</Option>
                            <Option value={4}>ERROR</Option>
                            <Option value={5}>FATAL</Option>
                            <Option value={99}>NONE</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="description" label="描述">
                          <Input style={{ width: 180 }}></Input>
                        </Form.Item>
                      </Space>
                      <Form.List name="users">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                              <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                                <Form.Item
                                  {...restField}
                                  label="日志采集路径"
                                  name={[name, "logAddr"]}
                                  // initialValue={provinceData[0]}
                                  fieldKey={[fieldKey, "logAddr"]}
                                  rules={[{ required: true, message: "请填写日志采集路径" }]}>
                                  <Input style={{ width: 150 }}></Input>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  label="日志级别"
                                  // initialValue={this.state.secondCity}
                                  name={[name, "logLevel"]}
                                  fieldKey={[fieldKey, "logLevel"]}
                                  rules={[{ required: true, message: "请选择日志级别" }]}>
                                  <Select style={{ width: 100 }}>
                                    <Option value={0}>ALL</Option>
                                    <Option value={1}>DEBUG</Option>
                                    <Option value={2}>INFO</Option>
                                    <Option value={3}>WARN</Option>
                                    <Option value={4}>ERROR</Option>
                                    <Option value={5}>FATAL</Option>
                                    <Option value={99}>NONE</Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  label="描述"
                                  name={[name, "description"]}
                                  // initialValue={provinceData[0]}
                                  fieldKey={[fieldKey, "description"]}>
                                  <Input style={{ width: 180 }}></Input>
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(name);
                                  }}
                                />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                                block
                                icon={<PlusOutlined />}>
                                添加
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </>
                  ) : (
                    <>
                      <Space key={-1} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                        <Form.Item name="logAddr2" label="日志采集路径" rules={[{ required: true, message: "请选择日志采集路径" }]}>
                          <Input style={{ width: 150 }}></Input>
                        </Form.Item>
                        <Form.Item name="logLevel2" label="日志级别" rules={[{ required: true, message: "请选择日志级别" }]}>
                          <Select style={{ width: 100 }}>
                            <Option value={0}>ALL</Option>
                            <Option value={1}>DEBUG</Option>
                            <Option value={2}>INFO</Option>
                            <Option value={3}>WARN</Option>
                            <Option value={4}>ERROR</Option>
                            <Option value={5}>FATAL</Option>
                            <Option value={99}>NONE</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="description2" label="描述">
                          <Input style={{ width: 180 }}></Input>
                        </Form.Item>
                      </Space>
                      <Form.List name="users2">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                              <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                                <Form.Item
                                  {...restField}
                                  label="日志采集路径"
                                  name={[name, "logAddr"]}
                                  // initialValue={provinceData[0]}
                                  fieldKey={[fieldKey, "logAddr"]}
                                  rules={[{ required: true, message: "请选择日志采集路径" }]}>
                                  <Input style={{ width: 150 }}></Input>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  label="日志级别"
                                  // initialValue={this.state.secondCity}
                                  name={[name, "logLevel"]}
                                  fieldKey={[fieldKey, "logLevel"]}
                                  rules={[{ required: true, message: "请选择日志级别" }]}>
                                  <Select style={{ width: 100 }}>
                                    <Option value={0}>ALL</Option>
                                    <Option value={1}>DEBUG</Option>
                                    <Option value={2}>INFO</Option>
                                    <Option value={3}>WARN</Option>
                                    <Option value={4}>ERROR</Option>
                                    <Option value={5}>FATAL</Option>
                                    <Option value={99}>NONE</Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  label="描述"
                                  name={[name, "description"]}
                                  // initialValue={provinceData[0]}
                                  fieldKey={[fieldKey, "description"]}>
                                  <Input style={{ width: 180 }}></Input>
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(name);
                                  }}
                                />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                                block
                                icon={<PlusOutlined />}>
                                添加
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </>
                  )}

                  <Form.Item name="type" label="配置内容">
                    <Radio.Group defaultValue={1} style={{ marginLeft: 15 }} onChange={(e) => this.changeLogConfigType(e)}>
                      <Radio value={1} style={{ marginRight: 60 }}>
                        追加新增
                      </Radio>
                      <Radio value={0}>覆盖替换</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item style={{ marginTop: "12px", justifyContent: "center" }}>
                    <div style={{ textAlign: "center" }}>
                      <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>
                        {" "}
                        确认{" "}
                      </Button>
                      <Button style={{ margin: "0 9.46px" }} onClick={() => this.handleLogConfigCancel()}>
                        {" "}
                        取消
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </Modal>

          <Modal
            destroyOnClose //打开新内容
            width={800}
            title="推送升级"
            visible={this.state.isinstallVisible}
            onOk={() => this.handleInstallOk()}
            onCancel={() => this.handleInstallCancel()}
            // footer={null}
          >
            <FirmwareModal otaList={this.state.otaList} defaultSelectValue={this.state.OtadefaultSelectValue} firmLoading={this.state.firmLoading} onClick={() => this.getFirmwareList()} />
          </Modal>
          <Modal
            title="选择系统版本"
            visible={this.state.isSelectOsPackModalVisible}
            footer={[
              <Button key="back" onClick={() => this.setState({ isSelectOsPackModalVisible: false })}>
                取消
              </Button>,
              <Button key="submit" type="primary" onClick={this.handleUpgradeConfirm}>
                确认升级
              </Button>,
            ]}>
            <Table
              rowSelection={{
                type: "radio",
                onChange: (selectedRowKeys, selectedRows) => {
                  this.handleOsPackRowSelect(selectedRows[0]);
                },
              }}
              rowKey="id"
              pagination={false}
              columns={[
                { title: "系统名称", dataIndex: "name", key: "name" },
                { title: "系统版本", dataIndex: "version", key: "version" },
                {
                  title: "ftpUrl",
                  dataIndex: "ftpUrl",
                  render: (text, record) => {
                    if (record.updateInfo) {
                      const ftp = JSON.parse(record.updateInfo);
                      return ftp.ftp.url;
                    } else {
                      return "";
                    }
                  },
                },
                // { title: "类型", dataIndex: "isPrivate", key: "isPrivate", render: (text) => (text === 0 ? "公共版" : "专属版") },
                // { title: "描述", dataIndex: "description", key: "description" },
              ]}
              dataSource={this.state.osPackList}
            />
          </Modal>
          <Modal
            title="升级状态"
            visible={this.state.isUpgradeModalVisible}
            footer={null}
            onCancel={() => {
              clearInterval(this.state.upgradePollingInterval);
              this.setState({ isUpgradeModalVisible: false });
            }}
            style={{ maxHeight: '400px', overflow: 'auto' }}
          >
            <div>
              {/* {this.state.osStateHistory.map((state, index) => (
                <p key={index}>当前状态: {state}</p> // 渲染每个状态记录
              ))} */}
              <p style={{ color: 'red' }}>当前状态:{this.state.osStateHistoryMes}</p>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
// export default NodeManage;

export default connect((state) => ({
  currentUser: state.currentUser,
}))(NodeManage);
