import { handleResponse } from './handle-response';
import store from '../../store/index';

export const firstPage = {
    getStatisticsStates,
    getStatisticsNodes,
    getStatisticsDevices,
    getStatisticsApps,
    getStatisticsImages,
    getNodeMonitor,
    getAppMonitor,
    getNodeStats,
    getAppStats
};

//查询运行状态信息
function getStatisticsStates() {
    return fetch(`/v1/console/statistics/states`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询节点数量（用户）TOP
function getStatisticsNodes() {
    return fetch(`/v1/console/statistics/nodes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询设备数量（用户）TOP
function getStatisticsDevices() {
    return fetch(`/v1/console/statistics/devices`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询应用数量（用户）TOP
function getStatisticsApps() {
    return fetch(`/v1/console/statistics/apps`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询镜像数量（用户）TOP
function getStatisticsImages() {
    return fetch(`/v1/console/statistics/images`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}


//查询节点监控TOP
function getNodeMonitor(type,topNum) {
    return fetch(`/v1/console/statistics/monitor/node?type=${type || 0}&topNum=${topNum || 5}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
//查询应用监控TOP
function getAppMonitor(type,topNum) {
    return fetch(`/v1/console/statistics/monitor/app?type=${type || 0}&topNum=${topNum || 5}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
//查询节点监控列表
function getNodeStats(id,startTime,endTime,limit,offset) {
    return fetch(`/v1/console/nodes/${id}/stats?startTime=${startTime || ''}&endTime=${endTime || ''}&limit=${limit||10}&offset=${offset||1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
//查询应用监控列表
function getAppStats(id,startTime,endTime) {
    return fetch(`/v1/console/apps/instances/${id}/stats?startTime=${startTime || ''}&endTime=${endTime || ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}