import React, {Component} from 'react';

import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, InputNumber, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import BottomBtnGroup from '../../../../../common/bottomBtnGroup';

import { edgeService } from '../../../../../../services/edge.service';

import '../../../../../../../index.scss';

const { confirm } = Modal;

let newcommand=''

export default class EditEquipmentDemo extends Component {
  formRef = React.createRef();
  state = {
    appEnvs: [],
    newcommand: '',

    variableVisible: false,//新增环境变量的弹框
    loading: false,
  }


  goback() {
    //this.props.history.push('/home/effect-edge-demo/list');
    sessionStorage.clear()
    this.props.onClick()
  }

  //调用查看环境变量接口
  getAppEnvs(id) {
    edgeService.getEdgeEnvs(id).then(res => {
      if (res.code === 200) {
          console.log("环境变量数组信息",res.envs);

          console.log(this.state.newcommand);
          this.formRef.current?.setFieldsValue({
            command:this.state.newcommand
          })
        
          this.setState({
            appEnvs: res.envs,
            loading: true
          })
      }  
      // else {
      //   this.setState({
      //     loading: false
      //   })
      // }
  }).catch(error => {
    this.setState({
      loading: false
    })
    error && message.error(error);
  })
  }


  //调用增加环境变量接口
  addAppEnv(id, appEnv) {
    edgeService.addEdgeEnv(id, appEnv).then(res => {
      if (res.code === 200) {
        console.log(res);
        
        //重新刷新环境变量列表
        this.getAppEnvs(this.props.id)
        message.success('添加成功');

        this.setState({
          loading: true,
          variableVisible: false,
        })
    }  
    // else {
    //   this.setState({
    //     loading: false
    //   })
    // }
    }).catch(error => {
      this.setState({
        loading: false
      })
      error && message.error(error);
    })
  }

  //调用删除环境变量接口
  deleteAppEnv(id, envId) {
    edgeService.deleteEdgeEnv(id, envId).then(res => {
      if (res.code === 200) {
        console.log(res);
        
        //重新刷新环境变量列表
        this.getAppEnvs(this.props.id)
        

        this.setState({
          loading: true
        })
    }  
    // else {
    //   this.setState({
    //     loading: false
    //   })
    // }
    }).catch(error => {
      this.setState({
        loading: false
      })
      error && message.error(error);
    })
  }

  //修改应用
  editApp(id, name, description, version, cmd, networkType) {
    edgeService.editEdge(id, name, description, version, cmd, networkType).then(res => {
      if (res.code === 200) {
        console.log(res);
        message.success("修改成功")
      } 
    }).catch(error => {
      error && message.error(error);
  })
    
  }

  componentDidMount() {
    
    //页面加载环境变量
    this.getAppEnvs(this.props.id)

    newcommand = this.props.command
    

    this.setState({
     newcommand: this.props.command
    })
    
    
  }

  //添加环境变量
  handleAddOk=(e)=> {
    console.log("添加的环境变量的信息", e);
    console.log(this.props.id);
    this.addAppEnv(this.props.id, e)
  }

  //删除环境变量
  deleteEnv = (text, record) => {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        console.log("要删除的信息", record);
        this.deleteAppEnv(record.appId, record.id)
        message.success('删除成功');
      },
      onCancel() {

      },
    });
    
  }

  //弹窗添加变量
  addVariable() {
    this.setState({
      variableVisible: true
    })
  }

  //弹窗的取消设置
  handleOk(e) {
    this.setState({
        variableVisible: false
    })
  }

  render() {
    
    console.log("props", this.props);
    //console.log('session里的全局信息', sessionStorage.getItem('description'));
    const portColumn = [
      {
         title: '变量名',
         dataIndex: 'name',
       },
       {
         title: '变量值',
         dataIndex: 'value',
       },
       {
         title: '操作',
         dataIndex: 'action',
       
         render: (text, record) =>  <a href onClick={() => this.deleteEnv(text,record)} >删除</a>
       }
    ];
    
    const layout = {
      labelCol: { xs: 24, sm: 12, md: 8, lg: 2, xl:2},
      wrapperCol: { xs: 24, sm: 12, md: 15},
    };

    const layout2 = {
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
    };

    //获取cookie
    const onFinish = (values) => {
      console.log("运行命令", values);
      console.log('session里的全局信息description', sessionStorage.getItem('description'));
      this.editApp(this.props.id, this.props.name, sessionStorage.getItem('description'), this.props.version, values.command, this.props.networkType)
      this.props.onClick()

      Object.keys(values).forEach(elx => {
        values[elx] =  values[elx] || '';
      });
     
    }
    
    //设置cookie
    const ee = (e) => {
      sessionStorage.setItem('command', e.target.value)
    }

    return (
      <div>
        <div>

          <Form
            ref={this.formRef} 
            {...layout}
            onFinish={onFinish}
            name="basic" labelAlign="left">
            <Form.Item  name="command" label="运行命令" style={{ marginBottom: 12 }} >
              <Input onChange={ee}/>
            </Form.Item>
            
              <Form.Item label="环境变量" style={{marginBottom: 12}}>
                    <Button onClick={() => this.addVariable()} type="primary" style={{color: '#fff'}} > 新增 </Button>
              </Form.Item>
              <div style={{ marginLeft: 40 }}>
                        <BottomBtnGroup onClick={() => this.goback()} />
                </div>
          </Form>
        </div>

        <div>
          <Table
            rowKey={record => record.id}
            pagination={false}
            columns={portColumn}
            dataSource={this.state.appEnvs}
            bordered={true}
        />
        </div>


        <Modal title="添加环境变量" visible={this.state.variableVisible} cancelText okText closable={false}>
            <Form {...layout2}
              name="control-ref"
              onFinish={this.handleAddOk}>
              <Form.Item name="name" label="变量名" rules={[{ required: true, message: '变量名不能为空' }, { pattern: /^[-a-zA-Z0-9_]{0,32}$/, message: '请输入长度32以内的英文字母、数字、中划线、下划线' }]} >
                  <Input placeholder="格式为英文字母/数字/中划线/下划线" />
              </Form.Item>

              <Form.Item name="value" label="变量值" rules={[{ required: true, message: '变量值不能为空' }, { pattern: /^[a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{0,32}$/, message: '请输入长度32以内的英文字母、数字、中划线、下划线、特殊字符' }]} >
                  <Input placeholder="格式为英文字母/数字/中划线/下划线/特殊字符" />
              </Form.Item>
              <br/>
              <Form.Item  style={{marginLeft:260,marginBottom:-70}}>
                <Button type="primary" htmlType="submit" > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
          </Form>
          </Modal>
        
      </div>
    )
  }
} 