import React, {Component} from 'react';
import { Form, Input, Col, Row, Tabs, Button, Table, Divider, message, Select, Tag, Spin, Badge } from 'antd';
import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { nodeService } from '../../../../services/node.service';
import { labelService } from '../../../../services/label.service'; 
import { edgeService } from '../../../../services/edge.service';
import './view-application.scss'
import ApplicationDemo from './application/application';
import EquipmentDemo from './equipment/equipment';
const { TabPane } = Tabs;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
};

class ViewApplication extends React.Component {
    formRef = React.createRef();
  constructor(props) {
    super(props);
    
    //this.getShellDetail(this.props.location.state.id)
    this.state = {
    id: 0,
    state: '',
    createTime: '',
    upTime: '',
    imageName: '',
    imageVersion: '',
    description: '',
    nodeId: '',
    cpuLimit: '',
    gpuLimit: '',
    memLimit: '',
    appEnvs: [],
    appPorts: [],
    appVolumes: [],
    command: '',
    createBy: '',
    appName: '',
    appName: '',
    nodeName: '',

    nodeObj: {

    },
    instances: [],

  }
  }
  

  //状态匹配
  getState(record) {
    if (record.state === 'RUNNING') {
      return <span><Badge status="success" />运行中</span>
    }
    else if (record.state === 'STOPPED') {
      return <span><Badge status="default" />已停止</span>
    }
    else if (record.state === 'FAILED') {
      return <span><Badge status="error" />异常</span>
    }
    else if (record.state === 'WAITING') {
      return <span><Badge status="warning" />等待</span>
    }
    else if (record.state === 'OFFLINE') {
      return <span><Badge status="error" />不在线</span>
    }
    else {
      return '';
    }
  }

  //状态匹配
  getType(state) {
    if (state === 'RUNNING') {
      return <span><Badge status="success" />运行中</span>
    }
    else if (state === 'STOPPED') {
      return <span><Badge status="default" />已停止</span>
    }
    else if (state === 'FAILED') {
      return <span><Badge status="error" />异常</span>
    }
    else if (state === 'WAITING') {
      return <span><Badge status="warning" />等待</span>
    }
    else if (state === 'UPGRADEWAITING') {
      return <span><Badge status="warning" />待升级</span>
    }
    else if (state === 'UPGRADING') {
      return <span><Badge status="warning" />升级中</span>
    }
    else if (state === 'OFFLINE') {
      return <span><Badge status="error" />不在线</span>
    }
    else {
      return '';
    }
  }

  goback() {
    // if (this.props.onSubmit) {
    //     var { showManage } = this.state;
    //     this.props.onSubmit({ showManage })
    // }
    this.props.history.push('/home/node-manage/detail');
  }


  componentDidMount() {
    //console.log(this.state.id);
    if (this.props.location.state) {
      const id = this.props.location.state.id;
      console.log("查看应用详情的id为：",id);
      this.setState({ id });
      //console.log(this.state.id);
      this.getShellDetail(id)
      this.getAppInstances(id)
      sessionStorage.setItem('appId', id)
      
    } else {
      if(sessionStorage.getItem('appId')) {
        const id = sessionStorage.getItem('appId');
        this.setState({
            id
        });
        this.getShellDetail(id)
        this.getAppInstances(id)
      } else {
        this.goback();
      }
    }
    
  }

  //详情获取
  getShellDetail(id) {
    edgeService.getEdgeDetail(id).then(res => {
        if (res.code === 200) {
            const app = res.app;
            console.log("应用详情：",app);
          
            //获取description到输入框
            // this.formRef.current.setFieldsValue({
            //   description: app.description
            // })
          
            this.setState({
                state: app.state,
                createTime: app.createTime,
                upTime: app.upTime,
                imageName: app.imageName,
                imageVersion: app.imageVersion,
                description: app.description,
                nodeId: app.nodeUuid,
                cpuLimit: app.cpuLimit,
                gpuLimit: app.gpuLimit,
                memLimit: app.memLimit,
                appEnvs: app.envs,
                appPorts: app.ports,
                appVolumes: app.volumes,
                command: app.command,
                appName: '查看应用 -- ' + app.name,
                createBy: app.createBy,
                nodeName: app.nodeName,
              })
        }  
    }).catch(error => {
      error && message.error(error);
    })
  }

  //实例对象获取
  getAppInstances(id) {
    edgeService.getEdgeInstances(id).then(res => {
      if (res.code === 200) {
        console.log("实例对象数组",res);
        this.setState({
          instances: res.instances
        })
      }  
  }).catch(error => {
    error && message.error(error);
  })
  }

  gotoLog(text,record) {
    console.log("选择的应用信息：",record);
    this.props.history.push({ pathname: '/home/effect-edge-m/log', state: { id: record.id, name: record.name } });
  }

  gotoMonitor(text,record) {
    this.props.history.push({ pathname: '/home/effect-edge-m/monitor', state: { id: record.id } });
  }
  
  render() {
    console.log(this.state.id);
    console.log(this.state.appEnvs);
    //this.getShellDetail(this.props.location.state.id)
    //实例对象表格的列
    const colums = [
      {
        title: '实例名称',
        dataIndex: 'name',
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (text, record) => (
          this.getState(record)
        )
      },
      // {
      //   title: '资源使用',
      //   dataIndex: 'resource',
      //   render: (text, record) => (
      //     <div>
      //         <p style={{marginBottom: 4}}>GPU: {text[0]}%</p>
      //         <p style={{marginBottom: 4}}>CPU: {text[1]}%</p>
      //         <p style={{marginBottom: 4}}>MEM: {text[2]}%</p>
      //     </div>
      //   )
      // },
      {
        title: '运行时间',
        dataIndex: 'runTime',
      },
      {
        title: '上传时间',
        dataIndex: 'upTime',
      },
      {
        title: '实例信息',
        dataIndex: 'message',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => {
          return (
            <span>
              {/* {
                this.state.state === 'RUNNING' ?
                  <a onClick={() => this.gotoLog(text, record)} style={{ marginRight: '5px' }}>日志</a>
                  : ''
              }
              {
                this.state.state === 'RUNNING' ?
                  <a onClick={() => this.gotoMonitor(text, record)}>监控</a>
                  : ''
              }      */}
              
                  <a onClick={() => this.gotoLog(text, record)} style={{ marginRight: '5px' }}>日志</a>
              
                  <a onClick={() => this.gotoMonitor(text, record)}>监控</a>
                  
              </span>
          )
        }
      }
    ]

    return (
     

      <div className="common-detail-container"  >
        <BackPage onClick={() => this.goback()} headeTitle={this.state.appName} itemTitle="边缘节点" itemSubTitle="查看节点" />
            <div style={{ background: '#fff', padding: "0 20px 20px" }}>
                <Tabs defaultActiveKey='1'>
                    <TabPane tab="详情" key="1">
                        <div style={{ marginLeft: 40 }}>
                            <Form 
                                ref={this.formRef}
                                name="basic"
                                labelAlign="left"
                                {...formItemLayout}
                                //onFinish={onFinish}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Form.Item  label="状态" >
                                            {this.getType(this.state.state)} 
                                        </Form.Item>
                                    </Col>
                                </Row>
                    
                                <Row>
                                    <Col style={{height:80}} span={24}>
                                        <Form.Item  label="实例配额" >
                                            <div style={{height:18}}>
                                              <div>CPU:  { this.state.cpuLimit }核</div>
                                              <div>GPU:  { this.state.gpuLimit }核</div>
                                              <div>内存:  { this.state.memLimit }M</div>
                                            </div>    
                                        </Form.Item>
                                    </Col>
                                </Row>
                    
                                <Row>
                                    <Col span={24}>
                                        <Form.Item  label="节点名称" >
                                            {this.state.nodeName}
                                        </Form.Item>
                                    </Col>
                                </Row>
                    
                                <Row>
                                    <Col span={24}>
                                        <Form.Item  label="描述" >
                                            {this.state.description || '--'}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                      
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="创建时间" >
                                              {this.state.createTime || '--'}
                                        </Form.Item>
                                    </Col>
                               </Row>
                  
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="更新时间" >
                                              {this.state.upTime || '--'}
                                        </Form.Item>
                                    </Col>
                                </Row>
                  
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="应用镜像"  >
                                            {this.state.imageName}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="镜像版本"  >
                                            {this.state.imageVersion}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="所属用户"  >
                                            {this.state.createBy}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                
                                
                            </Form>
                        </div>
                    </TabPane>
              
                    <TabPane tab="访问设置" key="2">
                        <ApplicationDemo  id={ this.state.id }/>
                    </TabPane>
              
                    <TabPane tab="高级设置" key="3">
                        <EquipmentDemo  id={ this.state.id} command={this.state.command} />
                    </TabPane>
                </Tabs>
            </div>
       


        <div className="job-bottom-card">
            <Row className="job-bottom-card-title">实例对象</Row>
            <div className="job-bottom-content">
            <Table columns={colums}  //列
                rowKey={record => record.id}
                dataSource={this.state.instances}  //行数据
                pagination={false}   //不展示分页
                scroll={{ y: 250 }}  //滚动
              />
            </div>
        </div>
      </div>
    )
  }
}

export default ViewApplication;
