/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Select, message, Badge, Row, Col, InputNumber,     Card , Divider  } from 'antd';
import '../../../../../index.scss';

import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { terminalService } from '../../../../services/terminal-equ';

import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class EditTerminalEquManagement extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      id: 0,
      deviceName: '',
      rtspUrl: '',
      aliasName: '',
      nodeUuid: '',
      nodeName: '',
      deviceStatus: '',
      deviceType: -1,
      deviceInterface: '',
      createTime: '',
      updateTime: '',
      upTime: '',
    };
  }
  goback() {
   
    this.props.history.go(-1);
  }
  onSelectTypeChange(value) {
    console.log(value)
  }
  componentDidMount() {
    if (this.props.location.state) {
      const id = this.props.location.state.id;
      console.log("修改设备详情的id为：", id);
      this.getDeviceDetail(id)
      sessionStorage.setItem('editDeviceId', id)
    } else {
      if(sessionStorage.getItem('editDeviceId')) {
        const id = sessionStorage.getItem('editDeviceId');
        this.setState({
            id,
        });
        this.getDeviceDetail(id)
      } else {
        this.goback();
      }
    }  
  }

  //查询设备详情接口
  getDeviceDetail(id) {
    terminalService.getDeviceDetail(id).then(res => {
      if (res.code === 200) {
        console.log(res);
        const device = res.device

        this.formRef.current.setFieldsValue({
          // deviceName: device.deviceName,
          aliasName: device.aliasName,
          rtspUrl: device.rtspUrl,
        });

        this.setState({
          deviceName: device.deviceName,
          rtspUrl: device.rtspUrl,
          aliasName: device.aliasName,
          nodeUuid: device.nodeUuid,
          nodeName: device.nodeName,
          deviceStatus: device.deviceStatus,
          deviceType: device.deviceType,
          deviceInterface: device.deviceInterface,
          createTime: device.createTime,
          updateTime: device.updateTime,
          upTime: device.upTime,
        })
      }
      }).catch(error => {
        error && message.error(error);
      })
  }

  //修改设备接口
  editDevice(id, deviceName, aliasName, nodeUuid, deviceType, rtspUrl, deviceInterface) {
    terminalService.editDevice(id, deviceName, aliasName, nodeUuid, deviceType, rtspUrl, deviceInterface).then(res => {
      if (res.code === 200) {
        message.success("操作成功！")
        this.goback();
      }
      }).catch(error => {
        error && message.error(error);
      })
  }

  getDeviceState = (state) => {
    if (state === 'RUNNING') {
      return <span> <Badge status="success" />运行中</span>
    } else if (state === 'FAILED') {
      return <span> <Badge status="error" />异常</span>
    } else if (state === 'DISABLED') {
      return <span><Badge status="error" />禁用</span>
    } else if (state === 'WATING') {
      return <span><Badge status="processing" />操作中</span>
    } else if (state === 'STOPPED') {
      return <span><Badge status="default" />关闭</span>
    } 
  }

  getDeviceType = (deviceType) => {
    if (deviceType === 0) {
      return <span>视频摄像头</span>
    } else if (deviceType === 1) {
      return <span>音频</span>
    } else if (deviceType === 2) {
      return <span>屏幕</span>
    } else if (deviceType === 3) {
      return <span>麦克风</span>
    } else if (deviceType === 4) {
      return <span>其他</span>
    }
  }
  
  render() {
    const onFinish = (values) => {
      console.log(values);
      console.log(this.state.deviceName);
      console.log(this.state.nodeUuid);
      if (this.props.location.state) {
        console.log(this.props.location.state.id)
        console.log(this.state.deviceName);
        console.log(this.state.nodeUuid);
        if (values.aliasName === this.state.aliasName && values.rtspUrl === this.state.rtspUrl) {
          message.success("操作成功！")
          this.goback();
        } else {
          this.editDevice(this.props.location.state.id, values.aliasName, this.state.nodeUuid, this.state.deviceType, values.rtspUrl, this.state.deviceInterface)
        }
        
      } else {
        console.log(sessionStorage.getItem('editDeviceId'))
        console.log(this.state.deviceName);
        if (values.aliasName === this.state.aliasName && values.rtspUrl === this.state.rtspUrl) {
          message.success("操作成功！")
          this.goback();
        } else {
          this.editDevice(sessionStorage.getItem('editDeviceId'), values.aliasName, this.state.nodeUuid, this.state.deviceType, values.rtspUrl ,this.state.deviceInterface)
        }
        
      }
    };

    return (
      <>
        <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="编辑设备" itemTitle="应用管理" itemSubTitle="创建用户" />
        <div className="common-create-center-container">
           
          <div className="common-create-page">
          
       
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish} >
               
              <Form.Item name="aliasName" label="设备别名" rules={[{ required: true, message: '请输入设备别名' }]} >
                <Input placeholder="请输入设备别名" />
              </Form.Item>
              <Form.Item name="deviceStatus" label="状态" >
                    {this.getDeviceState(this.state.deviceStatus)}
                </Form.Item>
              {
                (this.state.deviceInterface === 'NET' && this.state.deviceType === 0) ?
                  (<Form.Item name="rtspUrl" label="RTSP地址" rules={[{ required: true, message: 'RTSP地址' }]} >
                  <Input placeholder="RTSP地址" />
                    </Form.Item>)
                    :(<Form.Item name="rtspUrl" label="RTSP地址" >
                      <Input placeholder="RTSP地址" />
                      </Form.Item>)
              }
                

                <Form.Item name="nodeName" label="所属节点" >
                     {this.state.nodeName}
                </Form.Item>

                <Form.Item name="deviceType" label="设备类型" >
                     {this.getDeviceType(this.state.deviceType)}
                </Form.Item>

                <Form.Item name="createTime" label="创建时间">
                     {this.state.createTime || '--'}
                </Form.Item>

                <Form.Item name="updateTime" label="更新时间" >
                     {this.state.updateTime || '--'}
                </Form.Item>
                
                <Form.Item name="upTime" label="检查时间" >
                     {this.state.upTime || '--'}
                </Form.Item>
          
            
              <BottomBtnGroup onClick={() => this.goback()} />
           
            </Form>
        
       
          </div>
        </div>
        </div>
      </>
    );
  }
}

//export default CreateUser;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(EditTerminalEquManagement);