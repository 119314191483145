import React from 'react';
import { Form, Button, Col, Select, DatePicker, message } from 'antd';
import BackPage from '../../../../../common/backPage';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

import { edgeService } from '../../../../../../services/edge.service'

import './node-log.scss';

import store from '../../../../../../../store';

import ExportJsonExcel from 'js-export-excel';

const { RangePicker } = DatePicker;
let startTime = '';
let endTime = '';
let searchLogType = '';
const { Option } = Select;

function Search(props) {
    //const form = React.createRef();
    const [form] = Form.useForm();
    const onChange = (values, datastring) => {
        startTime = datastring[0];
        endTime = datastring[1];
        //props.onClick();
        props.change()
    };
    const handleChange = (value) => {
        searchLogType = value;
        //props.onClick();
    }

    // const onFinish = ()=>{
    //     props.dd()
    // }

    return (
        <>
            <div className="node-log-search">
                <Form layout="inline"
                    form={form}
                    //ref={form}
                    //onFinish={onFinish}
                    name="advanced_search"
                    className="ant-advanced-search-form">
                    <Col span={20} offset={2}>
                        {/* <Form.Item name='type' label='日志类型' style={{ float: 'left', marginRight: '7.14px' }}>
                            <Select style={{ width: '150px' }} placeholder="请选择日志类型" onChange={handleChange} allowClear>
                                {props.logTypeList}
                            </Select>
                        </Form.Item> */}
                        <Form.Item name='time' label='时间段' style={{ float: 'left', marginRight: '7.14px' }}>
                            <RangePicker
                                style={{ width: 328, height: 28 }}
                                locale={locale} onChange={onChange}
                                showTime format="YYYY-MM-DD HH:mm:ss" />
                            
                        </Form.Item>
                        {/* <Form.Item>
                            <Button type="primary" htmlType="submit">查询</Button>
                        </Form.Item> */}
                    </Col>
                </Form>
            </div>
        </>
    );
}

class EdgeEffectLogDemo extends React.Component {

    constructor(props) {
        super(props);
        console.log('startTime',startTime, endTime);
        this.state = {
            id: '',
            nodeLogName: '查看日志',
            instanceLogs: [],//日志数组
            nodeLogs: [],//数组备份
            logsItems: [],//拼接格式

            logTypeList: [],
            name: '',//实例名
        };
    }
    componentDidMount() {
        if (this.props.location.state) {
            const id = this.props.location.state.id;
            const name = this.props.location.state.name;
            console.log("查看日志详情的id为：", id);
            console.log("查看日志实例名为：",name);
            //this.setState({ id });
            //console.log(this.state.id);
            // this.getShellDetail(id)
            // this.getAppInstances(id)
            //this.getAppLogs(id, '2021-09-01 14:18:50', '2021-09-10 14:18:50')
            //console.log('startTime',startTime, endTime);
            this.getAppLogs(id, '', '')
            sessionStorage.setItem('logId', id)
            sessionStorage.setItem('instancesName', name)
            
          } else {
            if(sessionStorage.getItem('logId')  ) {
              const id = sessionStorage.getItem('logId');
              const name = sessionStorage.getItem('instancesName');
              this.setState({
                  id,
                  name,
              });
              this.getAppLogs(id, '', '')
            //   this.getShellDetail(id)
            //   this.getAppInstances(id)
            } else {
              this.goback();
            }
          }
    }
   
   //拼接数组
    getNodeList() {
        const nodeLogs = this.state.instanceLogs;
        console.log(nodeLogs);
        const options = [];
        nodeLogs.map(item => {
            options.push(
                <p key={item.id}><span  style={{ marginRight: 12 }}></span>{item.logContent}</p>
            )
        })
        this.setState({
            logsItems: options
        })  // Equipment
        console.log(options);
    }

    goback() {
        //this.props.history.push('/home/effect-edge-demo/detail');
        this.props.history.go(-1);
    }

    getLogs(){
        //console.log(this.props.location.state.id);
        if (this.props.location.state) {
            const id = this.props.location.state.id;
            console.log("查看日志详情的id为：",id);
            this.setState({ id });
            //console.log(this.state.id);
            // this.getShellDetail(id)
            // this.getAppInstances(id)
            //this.getAppLogs(id, '2021-09-01 14:18:50', '2021-09-10 14:18:50')
            console.log('startTime',startTime, endTime);
            this.getAppLogs(id, startTime, endTime)
            sessionStorage.setItem('logId', id)
            
          } else {
            if(sessionStorage.getItem('logId')) {
              const id = sessionStorage.getItem('logId');
              this.setState({
                  id
              });
              this.getAppLogs(id, startTime, endTime)
            //   this.getShellDetail(id)
            //   this.getAppInstances(id)
            } else {
              this.goback();
            }
          }
    }

    //查询日志
    getAppLogs(id, startTime, endTime) {
        edgeService.getEdgeLogs(id, startTime, endTime).then(res => {
            if (res.code === 200) {
                console.log(res);
                this.setState({
                    id: id,
                    instanceLogs: res.instanceLogs
                  })
                  this.getNodeList()
            }  
        }).catch(error => {
          error && message.error(error);
        })
    }

    // exportExcel 这是一个方法 直接调用
    exportExcel(list){ 
        const option = {};
        const dataTable = []
        list.forEach(item=>{ 
            const obj = {
                    日志ID: item.id,  // 数据日期 要跟sheetHeader里的数据一一对应 
                    应用实例ID: item.instanceId, 
                    日志文件: item.logFile || "无",// 可以多加一个判断,date2没有值时就给"无"
                    日志内容: item.logContent || "无",
                    上传时间: item.upTime || "无",
                }
                dataTable.push(obj)
        })
    
        var myDate = new Date();
        let time1 = myDate.toLocaleDateString().split('/').join('-');//将1970/08/08转化成1970-08-08
        let time2 = myDate.toLocaleTimeString('it-IT')
        
        option.fileName = `实例${sessionStorage.getItem('instancesName')}日志-${time1}_${time2}`;
        option.datas = [
        {
            sheetData: dataTable, // 数据 数组里包着多个对象,一个对象为一列[{},{},{}]
            sheetName: 'sheet',  // 名字(可有可无)(默认 sheet1)
            sheetHeader: [  // 表格里的列表的第一行(表头) 对应sheetData数据,最好不要重复名字
            '日志ID',
            '应用实例ID',
            '日志文件',
            '日志内容',
            '上传时间',
            ],  
            columnWidths: [3, 3, 7, 18, 10], // 每一列的宽度 需与列顺序对应
        },
        ];

        const toExcel = new ExportJsonExcel(option); // 创建表格
        toExcel.saveExcel();	// 保存下载表格
    }

    //下载日志
    downLog() {
        console.log(this.state.instanceLogs);
        console.log(this.state.name);
        if (this.state.instanceLogs.length != 0) {
            this.exportExcel(this.state.instanceLogs)
        } else {
            message.warning("没有日志内容！")
        }
        
    }

    render() {
        return (
            <>
                <div className="node-log-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.nodeLogName} itemTitle="边缘节点" itemSubTitle="查看日志" />

                    <div className="node-log-content">
                        <Search 
                            logTypeList={this.state.logTypeList} 
                            change={()=>this.getLogs()} 
                            //onClick={() => this.getNodeList()} 
                        />
                        <Button type='primary'  onClick={()=>this.downLog()}>日志下载</Button>
                        <div className="log-content">
                            {this.state.logsItems}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default EdgeEffectLogDemo;