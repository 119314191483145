import store from '../../store/index';
import { getTokenChangeAction } from '../../store/actionCreators';
import { getCurrentAction } from '../../store/actionCreators';
import { message } from 'antd';
export function handleResponse(response) {
    // if (response.status === 403 || response.status === 401) {
    //     authenticationService.logout();
    //     const error = response.statusText;
    //     return Promise.reject(error);
    // } else {
        
    return response.text().then(text => {
        const data = text && JSON.parse(text);
      
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            // if ([401, 403].indexOf(response.status) !== -1) {
            //     // authenticationService.logout().then(res => {
            //         // const action = getTokenChangeAction('');
            //         // store.dispatch(action);
            //         // const action2 = getCurrentAction('');
            //         // store.dispatch(action2);
            //         // window.location.href = "/login";
            //     // });
            //     // location.reload(true);
            // }
            if ([403, 401].indexOf(response.status) !== -1) {
                // authenticationService.logout().then(res => {
                    if(store.getState().authToken||store.getState().currentUser){
                        const action = getTokenChangeAction('');
                        store.dispatch(action);
                        const action2 = getCurrentAction('');
                        store.dispatch(action2);
                        return Promise.reject('登录过期，请重新登录!');     
                    }
                 //   window.location.href = "/login";
                // });
                // location.reload(true);
               // message.warn('登录过期，请重新登录!');
            }else{
                return Promise.reject(error);
            }
        }
        return data;
    });
}