import React from 'react';
import { Card, Form, Input, Tabs, Button, Select, Cascader, Row, Col, Table, Tag, Space, Breadcrumb, InputNumber, Modal, message } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { mirrorWarehouseService } from '../../../../services/mirror-warehouse.service';
import './../../../../../index.scss';
import BackPage from '../../../common/backPage';
import DescriptionDetail from './description-detail/description-detail';
const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

// const onFinish = (values) => {
//   console.log('Received values of form: ', values);
// };

// function onChange(value) {
//   console.log('changed', value);
// }


class ViewMirror extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      imageName: '',
      versionNum: 0,
      totalSize: 0,
      createTime: '',
      updateTime: '',
      images: [],
      description: {},
      showManage: true,
      canEdit: [false],
      showMirrorDetail: true,
      showDescription: false
    };
  }
  printContent(comment) {
    this.setState({
      showManage: comment.showManage,
      showMirrorDetail: true,
      showDescription: false
    });
    console.log(comment)
  }
  componentDidMount() {
    const data = this.props?.location?.state?.record;
    if (data) {
      this.setState(
        {
          imageName: data.name || '',
          versionNum: data.versionNum || '',
          totalSize: data.totalSize || '',
          createTime: data.createTime || '',
          updateTime: data.updateTime || '',
        }
      )
      this.searchList(data.name);
      this.searchDescription(data.name);
      sessionStorage.setItem('imageName', data.name || '');
      sessionStorage.setItem('versionNum', data.versionNum || '');
      sessionStorage.setItem('totalSize', data.totalSize || '');
      sessionStorage.setItem('createTime', data.createTime || '');
      sessionStorage.setItem('updateTime', data.updateTime || '');
    } else {
      if (sessionStorage.getItem('imageName')) {
        const imageName = sessionStorage.getItem('imageName');
        const versionNum = sessionStorage.getItem('versionNum');
        const totalSize = sessionStorage.getItem('totalSize');
        const createTime = sessionStorage.getItem('createTime');
        const updateTime = sessionStorage.getItem('updateTime');
        this.setState(
          {
            imageName,
            versionNum,
            totalSize,
            createTime,
            updateTime,
          }
        )
        this.searchList(imageName);
        this.searchDescription(imageName);
      } else {
        this.goback();
      }
    }
  }
  searchList(name) {
    mirrorWarehouseService.getImages(name).then(res => {
      this.setState({
        loading: false
      })
      if (res.code === 200) {
        console.log('镜像列表', res.images);//
        this.setState({
          images: res.images,
        });
      }
      //  else {
      //   message.error(res.message);
      // }
    }).catch(error => {
      this.setState({
        loading: false
      });
      message.error(error);
    })
  }
  searchDescription(name) {
    mirrorWarehouseService.getDescription(name).then(res => {
      this.setState({
        loading: false
      })
      if (res.code === 200) {
        console.log('镜像描述', res.description);//
        this.setState({
          description: res.description,
        });
      } 
      // else {
      //   message.error(res.message);
      // }
    }).catch(error => {
      this.setState({
        loading: false
      });
      message.error(error);
    })
  }


  // submit() {
  //   if (this.props.onSubmit) {
  //     var { showManage } = this.state;
  //     this.props.onSubmit({ showManage })
  //   }
  //   // this.setState({showManage});
  // }
  // /* 编辑 */
  // edit(i) {
  //   let ce = this.state.canEdit;
  //   ce[i] = true
  //   this.setState({
  //     canEdit: ce
  //   })
  // }
  // /* 保存 */
  // save(i) {
  //   let ce = this.state.canEdit;
  //   ce[i] = false
  //   this.setState({
  //     canEdit: ce
  //   })
  // }
  goback() {
    console.log(this);
    this.props.history.push('/home/mirror-warehouse/list');
  }

  showConfirm(record) {
    console.log(record);
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        this.deleteImge(record);
      },
      onCancel() {

      },
    });
  }
  deleteImge(record) {
    const id = record.id;
    const name = this.state.imageName
    let newSize = this.state.totalSize - record.size;
    let newTotalNum = this.state.versionNum - 1;
    mirrorWarehouseService.deleteImage(id).then(res => {
      if (res.code === 200) {
        message.success('操作成功！');
        this.setState({
          totalSize: newSize,
          versionNum: newTotalNum
        })
        sessionStorage.setItem('totalSize', newSize);
        sessionStorage.setItem('versionNum', newTotalNum);
        this.searchList(name);
      } 
      // else {
      //   message.error(res.message);
      // }
    }).catch(error => {
      message.error(error);
    })
  }
  componentWillUnmount() {
    sessionStorage.removeItem('imageName');
    sessionStorage.removeItem('versionNum');
    sessionStorage.removeItem('totalSize');
    sessionStorage.removeItem('updateTime');
    sessionStorage.removeItem('createTime');
  }
  render() {
    const confColumns = [
      {
        title: '版本',
        dataIndex: 'version',
        key: 'version',
        render: text => <span>{text}</span>,
      },
      {
        title: '大小',
        dataIndex: 'size',
        key: 'size',
        render: (text, record) => <span>{record.size} G</span>
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime',
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
        key: 'updateTime',
      },
      {
        title: '操作',
        key: 'action',
        render: (record) => (
          <Space size="middle">
            <a onClick={() => this.showConfirm(record)}>删除</a>
          </Space>
        ),
      },
    ];
    return (
      <>
        <div className="common-detail-container">
          <BackPage onClick={() => this.goback()} headeTitle={'查看镜像--' + this.state.imageName} itemTitle="查看镜像" itemSubTitle="查看任务" />
          <div style={{ background: '#fff', padding: "0 20px 20px" }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="详情" key="1">
                <Form name="complex-form"
                  // onFinish={onFinish}
                  labelAlign="left"
                  ref={this.formRef}
                  {...formItemLayout}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="versionNum"
                        label="版本数目"
                      >
                        {this.state.versionNum || 0} 个
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="totalSize"
                        label="占用大小"
                      >
                        {this.state.totalSize || 0} G
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="createTime"
                        label="创建时间"
                      >
                        {this.state.createTime || '---'}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="updateTime"
                        label="更新时间"
                      >
                        {this.state.updateTime || '---'}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
              <TabPane tab="描述" key="2">
                <p>{this.state.description?.description || ''}</p>
              </TabPane>
            </Tabs>
          </div>
          <div className="job-bottom-card">
            <Row className="job-bottom-card-title"
              style={{
                height: 48, display: 'flex',
                paddingRight: 24,
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>版本列表
            </Row>
            <div className="job-bottom-content">
              <Table columns={confColumns}
                rowKey={record => record.id}
                pagination={false}
                dataSource={this.state.images}

              />
            </div>
          </div>
        </div>

      </>


    );
  }
}

export default ViewMirror;