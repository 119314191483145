import React, {Component} from 'react';

import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import { edgeService } from '../../../../../../services/edge.service';

import '../../../../../../../index.scss';

export default class ApplicationDemo extends Component {

  state = {
    appPorts: [],
    loading: false,
  }

  //匹配端口状态
  getPortType = (record) => {
    if (record.type === 0) {
      return <span>TCP</span>
    } else if (record.type === 1) {
      return <span>UDP</span>
    }
  }

  getAppPorts(id) {
    edgeService.getEdgePorts(id).then(res => {
      if (res.code === 200) {
          console.log("端口数组信息",res.ports);
        
          this.setState({
            appPorts: res.ports,
            loading: true
          })
      }  
      // else {
      //   this.setState({
      //     loading: false
      //   })
      // }
  }).catch(error => {
    this.setState({
      loading: false
    })
    error && message.error(error);
  })
  }

  componentDidMount() {
    this.getAppPorts(this.props.id)
  }
  
  render() {
    //console.log(this.props);
    const portColumn = [
      {
        title: '容器端口',
        dataIndex: 'containerPort',
      },
      {
        title: '主机端口',
        dataIndex: 'hostPort',
      },
      {
        title: '类型',
        dataIndex: 'type',
        render: (text, record) => (
          this.getPortType(record)
        )
      },
      // {
      //   title: '操作',
      //   dataIndex: 'action',
      //   render: (text, record) =>  <a href onClick={() => this.showDeleteConfirm(record.key)} >删除</a>
      // }
    ];

    const layout = {
      labelCol: { xs: 24, sm: 12, md: 8, lg: 2, xl: 2},
      wrapperCol: { xs: 24, sm: 12, md: 15},
    };

    return (
      <div>
        <div>
            <Form name="basic" labelAlign="left" {...layout}>
                <Form.Item label="端口映射" style={{marginBottom: 12}}>
                    {/* <Button type="danger" style={{color: '#fff'}}> 新增 </Button> */}
                </Form.Item>
            </Form>
        </div>


        <div>
            <Table
                rowKey={record => record.id}
                pagination={false}
                columns={portColumn}
                dataSource={this.state.appPorts}
                bordered={true}
            />
        </div>
            
        </div>
      )
    }
}