import React from 'react';
import { Form, Input, message, Spin } from 'antd';
import { companyService } from '../../../../../services/company.service';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class ViewCompany extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true,
      productId: '',
      title: '查看企业',
      id: '',
      createTime: '',
      updateTime: '',
      name: '',
      contactPerson:'',
      phone: '',
      email: '',
      description: '',
      address: '',
      loading: true
    };

  }
  componentDidMount() {
    if (this.props.location.state) {
       
        const id = this.props.location.state.id;
        this.setState({
         
            id
        });
        this.getShellDetail(id);
        sessionStorage.setItem('companyId', id)
    } else {
      if(sessionStorage.getItem('companyId')) {
        const id = sessionStorage.getItem('companyId');
        this.setState({
            id
        });
        this.getShellDetail(id);
      } else {
        this.goback();
      }
    }
}
goback() {
  this.props.history.push('/home/sys/company-manage');
}
  getShellDetail(id) {
    companyService.getCompanyDetail(id).then(res => {
        if (res.code === 200) {
            const company = res.company;
           
              this.setState({
                  createTime: company.createTime,
                  updateTime: company.updateTime,
                  name: company.name,
                  contactPerson: company.contactPerson,
                  mobile: company.mobile,
                  email: company.email,
                  address: company.address,
                  description: company.description,
                  title: '查看企业 -- ' + company.name,
                  loading: false
              })
        }  
        // else {
        //   this.setState({
        //     loading: false
        //   })
        // }
    }).catch(error => {
      this.setState({
        loading: false
      })
      error && message.error(error);
    })
}
  render() {
   
    return (
      <>
      <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle={this.state.title} itemTitle="脚本" itemSubTitle="创建产品" />
        <div className="common-create-center-container">
          <Spin spinning={this.state.loading}>
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
             >
              <Form.Item name="scriptName" label="企业名称"   >
              {this.state.name || '--'}
              </Form.Item>
              <Form.Item label="联系人"  >
                 {this.state.contactPerson || '--'}
              </Form.Item>
              <Form.Item name="url" label="电话"  >
                 {this.state.mobile || '--'}
              </Form.Item>
              <Form.Item name="param" label="邮箱"   >
              {this.state.email || '--'}
              </Form.Item>
              <Form.Item name="order" label="地址"  >
                {this.state.address || '--'}
              </Form.Item>
              <Form.Item name="description" label="描述" >
              {this.state.description || '--'}
              </Form.Item>
              <Form.Item name="description" label="创建时间" >
                {this.state.createTime || '--'}
              </Form.Item>
              <Form.Item name="description" label="更新时间" >
              {this.state.updateTime || '--'}
              </Form.Item>
            
            </Form>
          </div>
          </Spin>
        </div>
      </div>
      </>
    );
  }
}

export default ViewCompany;