import { Form, Input, Col, Row, message, Select, DatePicker, Button, Table, Modal, Spin } from 'antd';

import locale from 'antd/es/date-picker/locale/zh_CN';

import React from 'react';
import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { jobService } from '../../../../services/job.service';
import { nodeService } from '../../../../services/node.service';
import { labelService } from '../../../../services/label.service';
import { otaService } from '../../../../services/ota.service';
import { firmwareService } from '../../../../services/firmware.service';
import { connect }  from 'react-redux'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { getJobIdAction } from '../../../../../store/actionCreators';
import store from '../../../../../store';
import { userDebService } from '../../../../services/user-deb.service';

const { TextArea } = Input;
const { Option } = Select;


const formItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4},
  wrapperCol: { xs: 24, sm: 12, md: 15},
};

class EditJob extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      flagA: false, //判断用户是否选择epack
      flagB: false, //判断用户是否选择节点
      flagEdit: false, //判断是否修改，即是否点击确定按钮
      showManage: true,

      jobId: null,
      upgradeType: null,
      jobObj: {},
      loading: true,
      firmLoading: true,
      userDebLoading: true,
      firmwareName: '',
      jobCreateTime: '',
      jobUpdateTime: '',
      jobTitle: '编辑任务',
      jobState: '',
      jobExecuteTime: '',
      jobFirmwareId: '',
      jobFirmwareVersion: '',

      isNodeVisible: false,
      isUserFirmwareVisible: false,
      
      labelList: [],
      otaList: [],
      userList: [], //所属用户（ota软件）
      chooseFirmwareList: [],
      chooseNodeIds: [],
      chooseNodeList: [],
      chooseLabelList: [],
      otaDefaultSelectProductObj: null,
      UserDebdefaultSelectValue: null, //所属用户选择默认值（ota软件）

      selectedNodeIds: [],
      selectedFirId: [],

      eidtNodeIds: [], //可真实移除节点id
      eidtNodeList: [], //可真实移除节点
      eidtCancelNodeList: [], //可真实移除节点取消时的备份

      selectFirmwareVersion: '',//展示选择的固件
    };
  }

  showNodeModal() { // node start
    if(!this.state.jobFirmwareId || this.state.chooseFirmwareList.length == 0) {
        message.warn('请先选择epack');
        return;
    }
    console.log("this.state.chooseFirmwareList", this.state.chooseFirmwareList);
    console.log("真实移除所需taskid", this.state.jobObj.id);
    console.log("可真实移除节点id", this.state.eidtNodeIds);
    this.getNodeList();
  }
  handleChangeLabel(value) {
    nodeService.getAllNodeList('', '', value).then(res => {
      if (res.code === 200) {
        const nodeList = res.nodes || [];

        const selectedNodeIds = [];
        nodeList.forEach(ele => {
          selectedNodeIds.push(ele.id)
        })

        this.setState({
          chooseNodeList: nodeList,
          selectedNodeIds: selectedNodeIds
        }, function () {
        });
      }
    }, error => {
      error && message.error(error);
    });
    this.setState({
      chooseLabelList: [...value]
    }, function () {
    });
  }
  handleCancel = () => {
     nodeActions = [];
    // zheli
    this.setState({ isNodeVisible: false, isFirmwareVisible: false, isUserFirmwareVisible: false });
  };
  changeSelected() {
    const selectedNodeIds = [];
    nodeActions.forEach(ele => {
      selectedNodeIds.push(ele.id)
    });
    this.setState({
      selectedNodeIds: selectedNodeIds
    })
  }
  changeSelectedFir() {
    const selectedFirId = [];
    selectedFirId[0] = firActions[0].id;
    this.setState({
      selectedFirId: selectedFirId
    })
  }
  handleNodeOk = () => {
    console.log(nodeActions)
    console.log(this.state.eidtNodeList)
    if (nodeActions.length !== 0) {
      this.setState({
        flagB: true
      })
    } else {
      message.warn("请选择节点！")
      return;
    }

    nodeActions.forEach(ele => {
      ele.key = ele.id
    });
    //console.log(this.state.chooseNodeList);
    let temp = [...this.state.eidtNodeList]
    temp.push(...nodeActions)
    this.setState({
      chooseNodeList: temp
    })
    this.handleCancel();
  }
  removeNodeFromList(id) {
    console.log(this.state.eidtNodeIds);
    const arr = [];

    if (this.state.eidtNodeIds.includes(id)) {
      jobService.removeNode(this.state.jobObj.id, id).then(res => {
        if (res.code === 200) {
          // message.success("移除成功！")
          let newNodeIds = []
          this.state.eidtNodeIds.forEach(e => {
            if (e != id) {
              newNodeIds.push(e)
            }
          })

          let newNodeList = []
          this.state.eidtNodeList.forEach(e => {
            if (e.id != id) {
              newNodeList.push(e)
            }
          })
          console.log(newNodeIds);
          this.setState({
            eidtNodeIds: newNodeIds,
            eidtNodeList: newNodeList
          })

        }
      }, error => {
        error && message.error(error);
      });

    } 

    const list = [...this.state.chooseNodeList];
    list.forEach(ele => {
      if (ele.id !== id) {
        arr.push(ele);
      }
    });
    
    this.setState({
      chooseNodeList: arr
    });
  }
  getNodeList() {
    // console.log(this.state.chooseFirmwareList[0]?.id);
    // console.log(this.state.jobFirmwareId);
    if (this.state.upgradeType === 0) {
      nodeService.getAllNodeList(searchNodeName, '', searchLabel.toString(), this.state.chooseFirmwareList[0]?.id || this.state.jobFirmwareId ).then(res => {
        if (res.code === 200) {
          nodeList = res.nodes;
          this.setState({
            isNodeVisible: true,
            selectedNodeIds: []
          });
        }
      }, error => {
        error && message.error(error);
      })
    } else if (this.state.upgradeType === 1) {
      nodeService.getUserDebNodes(searchNodeName, '', searchLabel.toString(), this.state.chooseFirmwareList[0]?.id || this.state.jobFirmwareId ).then(res => {
        if (res.code === 200) {
          nodeList = res.nodes;
          this.setState({
            isNodeVisible: true,
            selectedNodeIds: []
          });
        }
      }, error => {
        error && message.error(error);
      })
    }
    

    
  } // node end



  goback() {
    // if (this.props.onSubmit) {
    //   var { showManage } = this.state;
    //   this.props.onSubmit({ showManage })
    // }
    // this.props.history.go(-1);
    const nodeUuids = [];
    this.state.eidtCancelNodeList.forEach(ele => {
      nodeUuids.push(ele.nodeUuid);
    });
    // if (nodeUuids.length === 0) {
    //   message.warning("当前升级节点列表为空，请选择升级节点", 5);
    //   return
    // } else {
    //   console.log("处理");
    // }
    console.log(nodeUuids);
    console.log(this.state.jobObj);
    jobService.editJob(this.state.jobId, this.state.jobObj.name, this.state.jobObj.description, this.state.jobExecuteTime, nodeUuids, this.state.jobObj.nodeFirmwareId).then(res => {
      if (res.code === 200) {
        //message.success('修改成功');
        this.props.history.push('/home/batch-job');
      } 
      // else {
      //   message.error('修改失败，' + res.message);
      // }
    }, error => {
      error && message.error(error);
    });


    this.setState({
      chooseFirmwareList: [],
      chooseLabelList: [],
      chooseNodeList: [],
      selectedNodeIds: [],
    });
    firmwareAction = null;
    userDebAction = null;
    this.props.history.push('/home/batch-job');
  }

  cancel = () => {
    this.formRef.current.setFieldsValue({
      name: this.state.jobObj.name,
      code: this.state.jobObj.code,
      description: this.state.jobObj.description,
    });
  }

  componentDidMount() {
    console.log(store.getState().currentUser);
    this.getLabelList();
    this.getOtaList(); // product
    if (store.getState().currentUser.type === "ADMIN" || store.getState().currentUser.type === "SUPERADMIN") {
      this.getUserDebOverview();
    } else {
      let arr = [{userId:store.getState().currentUser.id, username:store.getState().currentUser.username}]
      this.setState({
        userList: this.getUserItem(arr),
        UserDebdefaultSelectValue: arr[0]?.userId,
      })
    }

    console.log(this.props.location);
    if (this.props.location.state) {
      const jobId = this.props.location.state;
      if (jobId) {
        const action = getJobIdAction(jobId);
        store.dispatch(action);
        this.setState({
          jobId: jobId.id,
          upgradeType: jobId.upgradeType,
        });
        this.getJobDetail(jobId.id);
      }
    } else {
      const jobId = store.getState().jobId;
      console.log(jobId);
      if (jobId) {
        this.setState({
          jobId: jobId.id,
          upgradeType: jobId.upgradeType,
        });
        this.getJobDetail(jobId.id);
      }
    }
  }

  componentWillUnmount() {
    console.log(this.state.flagEdit);
    if (!this.state.flagEdit) {
      const nodeUuids = [];
      this.state.eidtCancelNodeList.forEach(ele => {
        nodeUuids.push(ele.nodeUuid);
      });
    
      console.log(nodeUuids);
      console.log(this.state.jobObj);
      jobService.editJob(this.state.jobId, this.state.jobObj.name, this.state.jobObj.description, this.state.jobExecuteTime, nodeUuids, this.state.jobObj.nodeFirmwareId).then(res => {
        if (res.code === 200) {
          //message.success('修改成功');
          //this.props.history.push('/home/batch-job');
        } 
        // else {
        //   message.error('修改失败，' + res.message);
        // }
      }, error => {
        error && message.error(error);
      });
    }
    
    
    searchFirmwareOta = null;
    searchUserDebOta = null;
  }

  getLabelList() {
    labelService.getAllLabels().then(res => {
      if (res.code === 200) {
        const labels = res.labels;
        this.setState({
          labelList: this.getLableItem(labels)
        });
      }
    }, error => {
      error && message.error(error);
    });
  }
  getLableItem(labels) {
    return labels.map((item, index) => {
      return (
        <Option key={item.id} >{item.name}</Option>
      );
    });
  }
  getJobDetail(jobId) {
    jobService.getJobDetail(jobId).then(res => {
      if (res.code === 200) {
        console.log(res);
        const job = res.upgradeTask;
        const nodeIds = [];
        job.nodes = job.nodes ||[];
        if (job.nodes) {
          job.nodes.forEach(ele => {
            nodeIds.push(ele.id)
          })
        }
        const selectedNodeIds = [];
        job.nodes.forEach(ele => {
          selectedNodeIds.push(ele.id)
        })
        const selectedFirId = [];
        selectedFirId[0] = job.nodeFirmwareId;

        this.setState({
          jobObj: job,
          firmwareName: job.firmwareName,
          jobCreateTime: job.createTime,
          jobUpdateTime: job.updateTime,
          jobTitle: '编辑任务 -- ' + job.name,
          jobState: job.state,
          jobFirmwareId: job.nodeFirmwareId,
          jobFirmwareVersion: job.firmwareVersion,
          chooseNodeList: job.nodes,
          jobExecuteTime: job.executeTime,
          chooseFirmwareList: [{ id: job.nodeFirmwareId }],
          selectedNodeIds: selectedNodeIds,
          selectedFirId: selectedFirId,
          eidtNodeIds: selectedNodeIds,
          eidtNodeList: job.nodes,
          eidtCancelNodeList: job.nodes,
          loading: false,
        });


        this.formRef.current.setFieldsValue({
          name: job.name || '',
          description: job.description || '',
          firmwareName: job.firmwareName || '',
          firVersion: job.firmwareVersion || '',
          jobExecuteTime: moment(job.executeTime , 'YYYY-MM-DD HH:mm:ss')
        });
      } 
      // else {
      //   message.error('获取任务详情失败,' + res.message);
      // }
    }, error => {
      error && message.error(error);
    });
  }

  // fir start
  showFirmwareModal() {
    if (this.state.upgradeType === 0) {
      this.getFirmwareList();
    } else if (this.state.upgradeType === 1) {
      this.getUserDebAll();
    }
  }

  //3.26.3.查询用户软件包列表（不带分页）
  getUserDebAll() {
    this.setState({
      userDebLoading: true
    });
    userDebService.getUserDebAll(searchUserDebOta || this.state.UserDebdefaultSelectValue, searchUserDebName).then(
      res => {
        this.setState({
          userDebLoading: false,
        });
        if (res.code === 200) {
          console.log("当前用户软件包信息", res);
          userDebList = res?.debs;
          this.setState({
            isUserFirmwareVisible: true,
          });
        }
      },
      (error) => {
        this.setState({
          userDebLoading: false,
        });
        error && message.error(error);
      }
    );
    
  }

  getFirmwareList() {
    this.setState({
      firmLoading : true
    })
    firmwareService.getAllFirmwareByOta(searchFirmwareOta || this.state.otaDefaultSelectProductObj.id, '', searchFirmwareVersion).then(res => {
      this.setState({
        firmLoading : false
      })
      if (res.code === 200) {
        firmwaresList = res.firmwares;
        this.setState({
          isFirmwareVisible: true,
        });
      }
      //  else {
      //   message.error('获取epack列表失败，' + res.message);
      // }
    }, error => {
      this.setState({
        firmLoading : false
      })
      error && message.error(error);
    });
  }
  handleFirmwareOk = () => {
    if (firActions) {
      this.setState({
        chooseFirmwareList: firActions,
        jobFirmwareVersion: firActions[0].firmwareVersion,
        isFirmwareVisible: false
      })
    } else {
      this.setState({
        isFirmwareVisible: false
      })
    }
  }

  handleUserDebOk = () => {
    if (userDebAction) {
      console.log("userDebAction", userDebAction);
      // console.log(this.state.chooseFirmwareList[0]?.id);
      // console.log(this.state.jobFirmwareId);
      console.log(this.state.eidtNodeIds);
      //判断用户软件是否有节点
      nodeService.getUserDebNodes('', '', '', userDebAction[0]?.id || this.state.jobFirmwareId ).then(res => {
        if (res.code === 200) {
          let ids = this.state.eidtNodeIds
          ids.forEach(e => {
            this.removeNodeFromList(e)
          })
          message.warn("更改epack后请重新选择节点！")
          //nodeActions = []
          this.handleCancel();
          this.setState({
            chooseNodeList: [],
            flagA: true,
            chooseFirmwareList: userDebAction,
            selectFirmwareVersion: userDebAction[0].version
          });
        }else{
          // this.setState({
          //   flagA: true
          // })
          message.error('该epack无对应节点,请重新选择！')
        }
      }, error => {
        error && message.error('该epack无对应节点,请重新选择！');
      })
      
      
    }
  }


  getOtaList() {
    otaService.getAllOtaList().then(res => {
      if (res.code === 200) {
        const products = res.products;
        this.setState({
          otaList: this.getOtaItem(products),
          otaDefaultSelectProductObj: products[0]
        });
      }
    });
  }
  getOtaItem(products) {
    return products.map((item, index) => {
      return (
        <Option key={item.id} value={item.id}>{item.productName}</Option>
      );
    });
  }

  //3.26.1.获取用户软件包概览（管理员）
  getUserDebOverview() {
    userDebService.getUserDebOverview().then(
      res => {
        if (res.code === 200) {
          console.log("软件用户列表", res);
          const debs = res.debs
          //const debs = [{userId:432,username:'testOta'},{userId:2,username:'b'}]
          this.setState({
            userList: this.getUserItem(debs),
            UserDebdefaultSelectValue: debs[0]?.userId,
          })
        }
      }
    )
  }

  getUserItem(debs) {
    return debs.map((item, index) => {
      return (
        <Option key={item.userId} value={item.userId}>
          {item.username}
        </Option>
      )
    })
  }

  // fir end
  getState(state) {
    if (state === 'WAITING') {
      return <div><div className="common-yellow"></div> <span>待升级</span></div>
    } else if (state === 'UPGRADING') {
      return <div><div className="common-yellow"></div> <span>升级中</span></div>
    } else if (state === 'SUCCESS') {
      return <div><div className="common-green"></div> <span>升级成功</span></div>
    } else if (state === 'PARTIAL') {
      return <div><div className="common-purple"></div> <span>部分成功</span></div>
    } else if (state === 'FAILED') {
      return <div><div className="common-red"></div> <span>升级失败</span></div>
    } else if (state === 'EXCEPTION'){
      return <div><div className="common-yellow"></div> <span>异常</span></div>;
    } else {
        return '';
      }
  }
  getNodeState(record) {

   if (record.taskState === 'SUCCESS') {
      return <div><div className="common-green"></div> <span>成功</span></div>
    } if (record.taskState === 'WAITING') {
        return <div><div className="common-blue"></div> <span>待升级</span></div>
    } else if (record.taskState === 'UPGRADING') {
      return <div><div className="common-yellow"></div> <span>升级中</span></div>
    }  else if (record.taskState === 'FAILED') {
      return <div><div className="common-red"></div> <span>失败</span></div>
    }  else {
      return '';
    }
  }
  render() {
    const nodeListColumns = [
      {
        title: '节点名称',
        dataIndex: 'name',
        key: 'name',
        render: text => <>{text}</>,
      },
      {
        title: '产品型号',
        dataIndex: 'productModel',
        key: 'productModel',
      },
      {
        title: '目标版本',
        dataIndex: 'firmwareVersion',
        key: 'firmwareVersion',
        render: () => this.state.jobFirmwareVersion
      },
      {
        title: '升级前版本',
        dataIndex: 'firmwareVersion',
        key: 'firmwareVersion',
      },
      {
        title: '升级状态',
        dataIndex: 'taskState',
        key: 'taskState',
        render: (text, record) => (
          <>
              {
                this.getNodeState(record)
              }
           
          </>
          ),
      },
      {
        title: '失败原因',
        dataIndex: 'taskReason',
        key: 'taskReason',
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record) => (
          <>
            <a href onClick={() => this.removeNodeFromList(record.id)}>移除</a>
          </>
        )
      },
    ];
    const onFinish = (values) => {
      if (this.state.flagA && !this.state.flagB) {
        message.warn("更改epack后请重新选择节点！")
        return;
      }
      console.log("userDebAction", userDebAction);
      const firmwareId = this.state.chooseFirmwareList[0].id; // epack
      const nodeUuids = [];
      this.state.chooseNodeList.forEach(ele => {
        nodeUuids.push(ele.nodeUuid);
      });
      if (nodeUuids.length === 0) {
        message.warning("请选择升级节点");
        return;
      }
      console.log("this.state.jobId",this.state.jobId);
      console.log("values.name",values.name);
      console.log("values.description",values.description);
      console.log("this.state.jobExecuteTime",this.state.jobExecuteTime);
      console.log("nodeUuids",nodeUuids);
      console.log("firmwareId",firmwareId);
      jobService.editJob(this.state.jobId, values.name, values.description, this.state.jobExecuteTime, nodeUuids, firmwareId).then(res => {
        if (res.code === 200) {
          this.setState({
            flagEdit: true
          })
          message.success('修改成功');
          this.props.history.push('/home/batch-job');
        } 
        // else {
        //   message.error('修改失败，' + res.message);
        // }
      }, error => {
        error && message.error(error);
      });
    };
    const onChangeDatePicker = (value, dateString) => {
      
      this.setState({
        jobExecuteTime: dateString
      })
    }
    const range = (start, end) => {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    }
    // const disabledDate = (current) => {
    //   if(!this.state.jobExecuteTime) {
    //     return current < moment().subtract(1, 'day')
    //   } else {
    //     return current < moment(this.state.jobExecuteTime).subtract(1, 'day')
    //   }
     
    // }

    // const disabledDateTime = (date) => {
    //   if(this.state.jobExecuteTime) {
    //     if(date && (date.format("YYYY-MM-DD") === moment(this.state.jobExecuteTime).format("YYYY-MM-DD")) ) {
    //       return {
    //         disabledHours: () => range(0, 24).splice(0, moment(this.state.jobExecuteTime).hour()),
    //         disabledMinutes: () => range(0, moment(this.state.jobExecuteTime).minute() + 1),
    //         disabledSeconds: () => [0, 60],
    //       }
    //     }
    //   } else {
    //     if(date && (date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) ) {
    //       return {
    //         disabledHours: () => range(0, 24).splice(0, moment().hour()),
    //         disabledMinutes: () => range(0, moment().minute() + 1),
    //         disabledSeconds: () => [0, 60],
    //       }
    //     }
    //   }
     
    // }

    const disabledDate = (current) => {
      return current < moment().subtract(1, 'day')
    }
  
    const disabledDateTime = (date) => {
       if( date && (date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) ) {
          return {
            disabledHours: () => range(0, 24).splice(0, moment().hour()),
            disabledMinutes: () => range(0, moment().minute() + 1),
            disabledSeconds: () => [0, 60],
          }
       }
    
    }
    return (
      <><Spin spinning={this.state.loading}>
        <div className="common-detail-container">
          <BackPage onClick={() => this.goback()} headeTitle={this.state.jobTitle} itemTitle="批量升级" itemSubTitle="查看任务" />
          <div className="common-edit-card">
            <Form name="basic"
              onFinish={onFinish}
              labelAlign="left"
              ref={this.formRef}
              {...formItemLayout} >
              <Row>
                <Col span={12}>
                  <Form.Item label="状态" >
                    {/* {this.state.jobState} */}
                    {
                      this.getState(this.state.jobState)
                    }
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="name" label="任务名称" rules={[
                    { required: true, message: '任务名称不能为空' }, 
                    { max: 64, message: '任务名称不允许超过64个字符'}, 
                    { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线'}]} >
                    <Input placeholder="请输入任务名称" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="description" label="描述" >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="firmwareName" label={this.state.upgradeType===0?"epack名称":"用户软件包名称"} >
                    {this.state.firmwareName}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="firVersion" label={this.state.upgradeType===0?"升级epack":"升级软件包"} rules={[{ required: true, message: this.state.upgradeType===0?'请选择epack':"请选择软件包"}]} >
                    {/* <span style={{ marginRight: 10 }}>{this.state.jobFirmwareVersion}</span> */}
                    <Button onClick={() => this.showFirmwareModal()}>{this.state.upgradeType===0?'选择epack':"选择软件包"}</Button>
                    {this.state.selectFirmwareVersion ? <span style={{marginLeft:'10px'}}>{this.state.selectFirmwareVersion}</span>:''}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="创建时间" >
                    {this.state.jobCreateTime}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="更新时间"  >
                    {this.state.jobUpdateTime || '暂无数据'}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="执行时间" rules={[{ required: true, message: '请选择执行时间' }]} name="jobExecuteTime">
                    {
                      this.state.jobExecuteTime === '' ?
                        <DatePicker showTime onChange={onChangeDatePicker} showNow={false}
                          disabledDate={disabledDate}
                          disabledTime={disabledDateTime}
                          locale={locale} /> :
                        <DatePicker showTime onChange={onChangeDatePicker} showNow={false}
                          disabledDate={disabledDate}
                          disabledTime={disabledDateTime}
                         // initvalue={moment(this.state.jobExecuteTime, 'YYYY-MM-DD HH:mm:ss')}
                          locale={locale} />
                    }

                  </Form.Item>
                </Col>
              </Row>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>

          <div className="job-bottom-card">
            <Row className="job-bottom-card-title"> <span className="common-star-red">*</span>升级对象  </Row>
            <div className="job-bottom-content">
              <div style={{ marginBottom: '17px', overflow: 'hidden' }}>
                <Button style={{ border: '1px solid #0070CC', color: '#0070CC', float: 'left' }} 
                onClick={() => this.showNodeModal()}>选择节点</Button>
              
              </div>
              <Table columns={nodeListColumns}
                rowKey={record => record.id}
                pagination={false}
                dataSource={this.state.chooseNodeList}
                scroll={{ y: 240 }}
              />
            </div>
          </div>
        </div>

        <Modal width={800} title="选择升级节点" visible={this.state.isNodeVisible} onOk={() => this.handleNodeOk()} onCancel={() => this.handleCancel()} okText="确定" cancelText="取消">
          <NodeModal changeSelected={() => this.changeSelected()} 
                   selectedNodeIds={this.state.selectedNodeIds} 
                   labelList={this.state.labelList}
                   chooseNodeIds={this.state.chooseNodeIds} onClick={() => this.getNodeList()} />
        </Modal>
        <Modal title="选择升级epack" visible={this.state.isFirmwareVisible} width={800} onOk={() => this.handleFirmwareOk()} onCancel={() => this.handleCancel()} okText="确定" cancelText="取消">
          <FirmwareModal changeSelectedFir={() => this.changeSelectedFir()} 
          selectedFirId={this.state.selectedFirId} otaList={this.state.otaList}
          defaultSelectValue={this.state.otaDefaultSelectProductObj?.id} 
          firmLoading={this.state.firmLoading}
           onClick={() => this.getFirmwareList()} />
        </Modal>

        <Modal
          title="选择升级用户软件包"
          visible={this.state.isUserFirmwareVisible}
          width={800}
          onOk={() => this.handleUserDebOk()}
          onCancel={() => this.handleCancel()}
          okText="确定"
          cancelText="取消"
        >
          <UserDebModal
            userList={this.state.userList}
            defaultSelectValue={this.state.UserDebdefaultSelectValue}
            userDebLoading={this.state.userDebLoading}
            onClick={() => this.getUserDebAll()}
          />
        </Modal>
        </Spin>
      </>
    );
  }
}


// export default EditJob;


export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(EditJob);


let searchNodeName = '';
let searchLabel  =[];
let nodeList = [];
let nodeActions = [];
let firActions = [];
function NodeModal(props) {
  const [form] = Form.useForm();
  const nodeColumns = [
    {
      title: '节点名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'epack版本',
      dataIndex: 'firmwareVersion',
      key: 'firmwareVersion',
    },
    {
      title: '产品型号',
      dataIndex: 'productModel',
      key: 'productModel',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  const onFinish = (values) => {
    searchNodeName = values.searchNodeName;
    searchLabel = values.label || [];
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchNodeName = '';
    searchLabel = [];
    props.onClick();
  }
  const rowSelection = {
    selectedRowKeys: props.selectedNodeIds,
    onChange: (selectedRowKeys, selectedRows) => {
      nodeActions = selectedRows;
      props.changeSelected();
    },
  };
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          layout="inline"
          style={{justifyContent:'center', marginBottom: 12}}
          onFinish={onFinish} >
       
            <Form.Item name='searchNodeName' label='节点名称' style={{  marginTop: 8  }}>
              <Input placeholder="请输入关键字" />
            </Form.Item>
           
            <Form.Item label='标签' style={{ marginTop: 8 }} name="label">
                  <Select mode="multiple" allowClear style={{ width: '260px' }} placeholder="请选择标签" showArrow >
                    {props.labelList}
                  </Select>
                </Form.Item>
            <Form.Item style={{ marginTop: 8 }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </Form.Item>
        
        </Form>
      </div>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        pagination={false}
        rowKey={record => record.id}
        scroll={{ y: 240 }}
        columns={nodeColumns} dataSource={nodeList} />
    </>
  );
}

let searchFirmwareVersion = '';
let searchFirmwareOta = null;
let firmwaresList = [];
let firmLoading = true;
let firmwareAction = null;
function FirmwareModal(props) {
  const [form] = Form.useForm();
  const firmwareColumns = [
    {
      title: 'epack版本',
      dataIndex: 'firmwareVersion',
      key: 'firmwareVersion',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
      width: '15%'
    },
  ];
  const onFinish = (values) => {
    searchFirmwareVersion = values.searchName;
    searchFirmwareOta = values.searchOta;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchFirmwareVersion = '';
    searchFirmwareOta = props.defaultSelectValue;
    form.setFieldsValue({searchOta: props.defaultSelectValue});
    props.onClick();
  }
  const onValuesChange = (value) => {
    const keys = Object.keys(value);
    if(keys.find(elx => elx === 'searchOta')) {
      searchFirmwareVersion = form.getFieldValue('searchName') || '';
      searchFirmwareOta = value.searchOta || '';
      props.onClick();
    }
  }
  const rowSelection = {
    type: 'radio',
    selectedRowKeys: props.selectedFirId,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRows)
      firActions = selectedRows;
      props.changeSelectedFir();
    },
  };
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          initialValues={{'searchOta': props.defaultSelectValue}}
          onValuesChange={onValuesChange} >
          <Col span={24} offset={2}>
            <Form.Item name='searchOta' label='所属产品' style={{ float: 'left', marginRight: '7.14px' }}>
              <Select  style={{ width: '150px' }}
               placeholder="请选择所属产品" >
                {props.otaList}
              </Select>
            </Form.Item>
            <Form.Item name='searchName' label='epack版本' style={{ float: 'left', marginRight: '7.14px' }}>
              <Input style={{ width: 150 }} placeholder="请输入关键字" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        rowKey={record => record.id}
        pagination={false}
        loading={props.firmLoading}
        scroll={{ y: 360 }}
        columns={firmwareColumns} dataSource={firmwaresList} />
    </>
  );
}

let searchUserDebName = "";
let searchUserDebOta = null;
let userDebList = [];
let userDebAction = null;
function UserDebModal(props) {
  const [form] = Form.useForm();
  const userDebColumns = [
    {
      title: "软件包名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "软件版本",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
    },
  ];
  const onFinish = (values) => {
    searchUserDebName = values.searchUserDebName || "";
    searchUserDebOta = values.searchUserDebOta;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchUserDebName = "";
    searchUserDebOta = props.defaultSelectValue;
    form.setFieldsValue({ searchUserDebOta: props.defaultSelectValue });
    props.onClick();
  };
  const onValuesChange = (value) => {
    console.log(value);
    const keys = Object.keys(value);
    if (keys.find((elx) => elx === "searchUserDebOta")) {
      searchUserDebName = form.getFieldValue("searchUserDebName") || "";
      searchUserDebOta = value.searchUserDebOta || "";
      props.onClick();
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // id: selectedRowKeys
      // obj : selectedRows
      userDebAction = selectedRows;
    },
  };
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          initialValues={{ searchUserDebOta: props.defaultSelectValue }}
          onValuesChange={onValuesChange}
        >
          <Col span={24} offset={2}>
            <Form.Item
              name="searchUserDebOta"
              label="所属用户"
              style={{ float: "left", marginRight: "7.14px" }}
            >
              <Select style={{ width: "150px" }} placeholder="请选择所属产品">
                {props.userList}
              </Select>
            </Form.Item>
            <Form.Item
              name="searchUserDebName"
              label="软件包名称"
              style={{ float: "left", marginRight: "7.14px" }}
            >
              <Input style={{ width: 150 }} placeholder="请输入关键字" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {" "}
                查询{" "}
              </Button>
              <Button style={{ margin: "0 9.46px" }} onClick={resetForm}>
                重置{" "}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        rowKey={(record) => record.id}
        pagination={false}
        loading={props.userDebLoading}
        scroll={{ y: 360 }}
        columns={userDebColumns}
        dataSource={userDebList}
      />
    </>
  );
}