import React from 'react';
import { Form, Input, Button, Table, Pagination, ConfigProvider, DatePicker, Select, message } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { AUTCONFIG } from '../../../../../utils/auth.config';
import { userService } from '../../../../services/user.service';
import store from '../../../../../store';
import moment from 'moment';
import './../../../../../index.scss';
import './service-log.scss';

import { handleResponse } from '../../../../services/handle-response';
import { mirrorService } from '../../../../services/operation.service'
import ExportJsonExcel from 'js-export-excel';

import { getTokenChangeAction } from '../../../../../store/actionCreators';
import { getCurrentAction } from '../../../../../store/actionCreators';
import { searchRule } from '../../../../../utils/search-util'

const { RangePicker } = DatePicker;
const { Option } = Select;

//获取当前时间
let date = new Date();
let year = date.getFullYear();
let month = date.getMonth() + 1;
let day = date.getDate();
if (month < 10) {
    month = "0" + month;
}
if (day < 10) {
    day = "0" + day;
}
let nowDate = year + "-" + month + "-" + day;

let searchNodeName = '';
let searchStartTime = nowDate + " 00:00:00";
let searchEndTime = nowDate + " 23:59:59";
let searchNodeSeriesNumber = '';
let searchLogAddr = '';
let searchLogLevel = '';
let searchLogType = '';

let page = 1;
let pageSize = 10;

let flag = true; //防止重复下载

// function TopSearch(props) {
//   const form = React.createRef();
//   const [dates, setDates] = React.useState([]);
//   const [hackValue, setHackValue] = React.useState();
//   const [value, setValue] = React.useState();

//   const onFinish = (values) => {
//     console.log(values);
//     searchNodeName = values?.nodeName || '';
//     searchNodeSeriesNumber = values?.nodeSeriesNumber || '';
//     searchLogAddr = values?.logAddr || '';
//     searchLogLevel = values?.logLevel || '';
//     searchLogType = values?.logType || 1;
//     page = 1;
//     props.onClick();
//   };
//   const resetForm = () => {
//     form.current.resetFields();
//     searchNodeName = '';
//     searchStartTime = '';
//     searchEndTime = '';
//     searchNodeSeriesNumber = '';
//     searchLogAddr = '';
//     searchLogLevel = '';
//     searchLogType = '';
//     page = 1;
//     pageSize = 10;
//     props.onClick();
//   }

//   const downClick = () => {
//     props.downClick();
//   }

//   const onChange = (values, datastring) => {
//     setValue(values)
//     searchStartTime = datastring[0];
//     searchEndTime = datastring[1];
//     props.onClick();
//   };

//   const disabledDate = current => {
//     if (!dates || dates.length === 0) {
//       return false;
//     }
//     const tooLate = dates[0] && current.diff(dates[0], 'days') > 1;
//     const tooEarly = dates[1] && dates[1].diff(current, 'days') > 1;
//     return tooEarly || tooLate;
//   };

//   const onOpenChange = open => {
//     if (open) {
//       setHackValue([]);
//       setDates([]);
//     } else {
//       setHackValue(undefined);
//     }
//   };

//   // const disabledDate = (current) => {
//   //   let a = current && current < moment().subtract(2, "days")
//   //   let b = current && current > moment().subtract(2, 'days').endOf('day')
//   //   return   b ||  a ; //当天之前的不可选，不包括当天
//   //   //return current && current < moment().endOf('day');//当天之前的不可选，包括当天
//   // }

//   return (
//     <>
//       <div className="log-search-form ">
//         <Form
//           ref={form}
//           name="advanced_search_user"
//           className="ant-advanced-search-form"
//           onFinish={onFinish}
//           layout="inline"
//           style={{ justifyContent: 'center' }}>
//           <Form.Item name='nodeName' label='节点名称' style={{ marginTop: '8px' }}>
//             <Input placeholder="请输入关键字" />
//           </Form.Item>
//           <Form.Item name='nodeSeriesNumber' label='节点序列号' style={{ marginTop: '8px' }}>
//             <Input placeholder="请输入关键字" />
//           </Form.Item>

//           <Form.Item name='time' label='时间段' style={{ float: 'left', marginRight: '7.14px', marginTop: '8px' }}>
//             <RangePicker
//               value={hackValue || value}
//               style={{ width: 328, height: 28 }}
//               locale={locale}
//               onChange={onChange}
//               onCalendarChange={val => setDates(val)}
//               //onChange={val => setValue(val)}
//               disabledDate={disabledDate}
//               onOpenChange={onOpenChange}
//               //showTime
//               format="YYYY-MM-DD HH:mm:ss" />
//           </Form.Item>

//           <Form.Item name='logAddr' label='采集路径' style={{ marginTop: '8px' }}>
//             <Input placeholder="请输入关键字" />
//           </Form.Item>

//           <Form.Item name='logLevel' label='日志等级' style={{ marginTop: '8px' }}>
//               <Select allowClear
//                       placeholder="请选择日志等级" >
//                       {props.logLevelList}
//               </Select>
//           </Form.Item>

//           <Form.Item name='logType' label='日志类型' style={{ marginTop: '8px' }}>
//               <Select allowClear
//                       placeholder="请选择日志类型" >
//                       {props.logTypeList}
//               </Select>
//           </Form.Item>
//           {/* {
//               store.getState().currentUser.type !== AUTCONFIG.SUB.value ?
//                 <Form.Item name='username' label='所属用户' style={{ marginTop: '8px' }}>
//                   <Select 
//                   style={{ width: '200px' }} 
//                   placeholder="请选择所属用户"
//                   showSearch
//                   filterOption={(input, option) =>
//                     option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
//                   }
//                   >
//                     {props.tenantList}
//                   </Select>
//                 </Form.Item>
//                 : ''
//             } */}

//           <Form.Item style={{ marginTop: '8px' }}>
//             <Button type="primary" htmlType="submit"> 查询 </Button>
//             <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
//             <Button type="primary" style={{ width: 80 }} onClick={downClick}>日志下载</Button>
//           </Form.Item>
//         </Form>
//       </div>
//     </>
//   );
// }
class TopSearch extends React.Component {
  form = React.createRef();
  state = {
    startValue: null,
    endValue: null,
    endOpen: false
  };

  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return (
      startValue.valueOf() < endValue.valueOf() - 86400000 ||
      startValue.valueOf() > endValue.valueOf()
    );
  };

  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return (
      endValue.valueOf() > startValue.valueOf() + 86400000 ||
      endValue.valueOf() < startValue.valueOf()
    );
  };

  onEndChange = (value, dateString) => {
    console.log('Formatted Selected Time: ', dateString);
    searchEndTime = dateString
    this.onChange("endValue", value);
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }

      // Should format date value before submit.
      const values = {
        ...fieldsValue,
        startTime: fieldsValue["date-time-picker"].format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        endTime: fieldsValue["date-time-picker2"].format("YYYY-MM-DD HH:mm:ss")
      };
      console.log("Received values of form: ", values);
    });
  };

  onStartChange = (value, dateString) => {
    console.log('Formatted Selected Time: ', dateString);
    searchStartTime = dateString
    this.onChange("startValue", value);
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };


  render() {
    const searchDownLog = (values) => {
      console.log(values);
      searchNodeName = searchNodeName? searchNodeName: values?.nodeName
      //console.log(searchNodeName);
      searchNodeSeriesNumber = searchNodeSeriesNumber? searchNodeSeriesNumber: values?.nodeSeriesNumber
      searchLogAddr = searchLogAddr? searchLogAddr: values?.logAddr
      searchLogLevel = searchLogLevel? searchLogLevel: values?.logLevel
      searchLogType = searchLogType? searchLogType: values?.logType
    }

    const onFinish = (values) => {
      console.log(values);

      let name = values?.nodeName || '';
      let str = searchRule(name)
      searchNodeName = str || '';

      let number = values?.nodeSeriesNumber || '';
      let str1 = searchRule(number)
      searchNodeSeriesNumber = str1 || '';

      let addr = values?.logAddr || '';
      let str2 = searchRule(addr)
      searchLogAddr = str2 || '';

      searchLogLevel = values?.logLevel || '';
      searchLogType = values?.logType || '';
      page = 1;

      // if (values?.time == null) {
      //   console.log("空");
      //   searchStartTime = '';
      //   searchEndTime = '';
      // }
      this.props.onClick();
    };
    const resetForm = () => {
      this.form.current.resetFields();
      this.setState({
        startValue: null,
        endValue: null,
      })
      searchNodeName = '';
      searchStartTime = nowDate + " 00:00:00";
      searchEndTime = nowDate + " 23:59:59";
      searchNodeSeriesNumber = '';
      searchLogAddr = '';
      searchLogLevel = '';
      searchLogType = '';
      page = 1;
      pageSize = 10;
      this.props.onClick();
    }

    const downClick = () => {
      this.props.downClick();
    }

    const onChange = (values, datastring) => {
      //setValue(values)
      console.log(values, datastring);
      searchStartTime = datastring + " 00:00:00";
      searchEndTime = datastring + " 23:59:59";
      //this.props.onClick();
    };

    //const { getFieldDecorator } = this?.props?.form;
    const { startValue, endValue, endOpen } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    const dateFormat = 'YYYY-MM-DD';
    // const disabledDate = (current) => {
    //   let a = current && current < moment().subtract(2, "days")
    //   let b = current && current > moment().subtract(2, 'days').endOf('day')
    //   return   b ||  a ; //当天之前的不可选，不包括当天
    //   //return current && current < moment().endOf('day');//当天之前的不可选，包括当天
    // }

    return (
      <>
        <div className="log-search-form ">
          <Form
            ref={this.form}
            name="advanced_search_user"
            className="ant-advanced-search-form"
            onFinish={onFinish}
            onValuesChange={searchDownLog}
            layout="inline"
            // style={{ justifyContent: 'center' }}
            >
            <Form.Item name='nodeName' label='节点名称' style={{ marginTop: '8px' }}>
              <Input placeholder="请输入关键字" />
            </Form.Item>
            <Form.Item name='nodeSeriesNumber' label='节点序列' style={{ marginTop: '8px' }}>
              <Input placeholder="请输入关键字" />
            </Form.Item>

            {/* <Form.Item name='time' label='时间段' style={{ float: 'left', marginRight: '7.14px', marginTop: '8px' }}>
              <RangePicker
                 
                style={{ width: 328, height: 28 }}
                locale={locale}
                onChange={onChange}
                 
                //onChange={val => setValue(val)}
                
                 
                //showTime
                format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item> */}

            <Form.Item name='time' label='日期选择' style={{ marginTop: '8px' }}>
              <DatePicker style={{width:200}} onChange={onChange} defaultValue={moment(nowDate, dateFormat)} allowClear={ false}/>
            {/* <DatePicker
              disabledDate={this.disabledStartDate}
              showTime={{ hideDisabledOptions: false,defaultValue: moment('', 'HH:mm:ss') }}
              showNow={false}
              format="YYYY-MM-DD HH:mm:ss"
              value={startValue}
              placeholder="开始时间"
              onChange={this.onStartChange}
              onOpenChange={this.handleStartOpenChange}
            />
           

           
            <DatePicker
              disabledDate={this.disabledEndDate}
              value={endValue}
              placeholder="结束时间"
              onChange={this.onEndChange}
              open={endOpen}
              onOpenChange={this.handleEndOpenChange}
              showTime
              showNow={false}
              format="YYYY-MM-DD HH:mm:ss"
            /> */}
          
        </Form.Item>


            <Form.Item name='logAddr' label='采集路径' style={{ marginTop: '8px' }}>
              <Input placeholder="请输入关键字" />
            </Form.Item>

            <Form.Item name='logLevel' label='日志等级' style={{ marginTop: '8px' }}>
              <Select allowClear
                placeholder="请选择日志等级" >
                {this.props.logLevelList}
              </Select>
            </Form.Item>

            <Form.Item name='logType' label='日志类型' style={{ marginTop: '8px' }}>
              <Select allowClear
                placeholder="请选择日志类型" >
                {this.props.logTypeList}
              </Select>
            </Form.Item>
            {/* {
              store.getState().currentUser.type !== AUTCONFIG.SUB.value ?
                <Form.Item name='username' label='所属用户' style={{ marginTop: '8px' }}>
                  <Select 
                  style={{ width: '200px' }} 
                  placeholder="请选择所属用户"
                  showSearch
                  filterOption={(input, option) =>
                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  >
                    {props.tenantList}
                  </Select>
                </Form.Item>
                : ''
            } */}

            <Form.Item style={{ marginTop: '8px' }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
              {/* <Button type="primary" style={{ width: 80 }} onClick={downClick}>日志下载</Button> */}
              <a onClick={downClick}>
              <Button type="primary" style={{ width: 80 }}>日志下载</Button>
              </a>
            </Form.Item>
          </Form>

        </div>
      </>
    );
  }
}

class ServiceLog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logList: [],
      logAmount: 0,
      loading: false,
      page: 1,
      pageSize: 10,
      loading: true,
      tenantList: [],

      logLevelList: [],//日志等级搜索列表
      logTypeList: [],//日志类型搜索列表
    };
  }

  searchList() {
    console.log('selected searchNodeName:' + searchNodeName);
    console.log('selected start time:' + searchStartTime);
    console.log('selected end time:' + searchEndTime);
    console.log('selected searchLogType:' + searchLogType);

    console.log('selected page:' + page);
    console.log('selected pageSize:' + pageSize);

    this.setState({
      loading: true,
      page,
      pageSize
    })

    mirrorService.getLogList(searchStartTime, searchEndTime, searchNodeName, searchNodeSeriesNumber, searchLogAddr, searchLogLevel, searchLogType, pageSize, page ).then(res => {
      if (res.code === 200) {
        console.log("查找的列表信息为:",res);
        const edgeLogs = res.edgeLogs;
        this.setState({
          logList: edgeLogs,
          logAmount: res.dataCount,
          loading: false,
        })
      }
      // else {
      //   message.error('获取日志列表失败');
      //   this.setState({
      //       loading: false
      //   })
      // }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        loading: false
    })
    })

  }
  componentWillMount() {
    //this.searchList();
    //this.getTenantList();
  }
  getTenantList() {
    userService.getAllTenantList().then(res => {
      if (res.code === 200) {
        const tenants = res.users;
        this.setState({
          tenantList: this.getTenantItem(tenants),
        });
      }
    });
  }
  getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id} >{item.realName}/{item.username}</Option>
      );
    });
  }
  componentDidMount() {
    
    searchStartTime = nowDate + " 00:00:00";
    searchEndTime = nowDate + " 23:59:59";
    page = 1;
    pageSize = 10;
    searchNodeName = '';
    searchNodeSeriesNumber = '';
    searchLogAddr = '';
    searchLogLevel = '';
    searchLogType = ''; 

    this.searchList();
    this.getLogLevelList();
    this.getLogTypeList();
  }

  //日志下载接口
  logDownload() {
    
    if (flag) {
      this.setState({
      loading: true,
    })
      
      flag = false;

    console.log('selected searchNodeName:' + searchNodeName);

    fetch(`/v1/console/log/download?startTime=${searchStartTime || ''}&endTime=${searchEndTime || ''}&nodeName=${searchNodeName || ''}&nodeSeriesNumber=${searchNodeSeriesNumber || ''}&logAddr=${searchLogAddr || ''}&logLevel=${searchLogLevel || ''}&logType=${searchLogType || -1}`, {
      method: 'GET',
      mode: "cors",
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${store.getState().authToken}`
      },
      responseType: 'blob', //一定要加请求类型,不然会乱码

    }).then(response => {
      
        const data = response 
        console.log(data);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            
            if ([403, 401].indexOf(response.status) !== -1) {
                    if(store.getState().authToken||store.getState().currentUser){
                        const action = getTokenChangeAction('');
                        store.dispatch(action);
                        const action2 = getCurrentAction('');
                        store.dispatch(action2);
                        return Promise.reject('登录过期，请重新登录!');
                    }
            }
            else {
                return Promise.reject(error);
            }
            
        }
        return data;
    
  }).then(res => {
      return res.blob(); //一定要有。blob()方法使用一个 Response 流，并将其读取完成。它返回一个使用Blob解决的promise。
  }).then(blob => {
      console.log('blob', blob)
      //let bl = new Blob([blob]); 
      //let fileName = Date.parse(new Date()) + ".cvs"; //这里给了个时间戳命名,可以根据需求自定义
      let fileName = "log_" + searchStartTime.slice(0,10) + ".cvs"; //这里给了个时间戳命名,可以根据需求自定义
      //下面就是下载文件的方法了
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
      
      this.setState({
        loading: false,
      })
      flag = true
  })
      .catch(err => {
        console.log('err', err)
        message.error(err);
        
        this.setState({
          loading: false,
        })
        flag = true
      });
    } else {
      message.warn("下载中，请勿重复操作！")
    }
  }

  // gotoDetail = (text, key) => {
  //   this.props.history.push({ pathname: '/home/sys/user-manage/detail', state: { id: text } });
  // }

  // exportExcel 这是一个方法 直接调用
  exportExcel(list){ 
    const option = {};
    const dataTable = []
    list.forEach(item=>{ 
        const obj = {
                时间: item.upTime,// 数据日期 要跟sheetHeader里的数据一一对应
                节点名称: item.nodeName, 
                节点序列号: item.nodeSeriesNumber,   
                日志等级: item.logLevel, 
                日志类型: item.logType,
                采集路径: item.logAddr, 
                日志文件地址: item.logFile,
                日志内容: item.logContent,
            }
            dataTable.push(obj)
    })
   
    var myDate = new Date();
    let time1 = myDate.toLocaleDateString().split('/').join('-');//将1970/08/08转化成1970-08-08
    let time2 = myDate.toLocaleTimeString('it-IT')
    
    option.fileName = `日志-${time1}_${time2}`;
    option.datas = [
    {
        sheetData: dataTable, // 数据 数组里包着多个对象,一个对象为一列[{},{},{}]
        sheetName: 'sheet',  // 名字(可有可无)(默认 sheet1)
        sheetHeader: [  // 表格里的列表的第一行(表头) 对应sheetData数据,最好不要重复名字
        '时间',
        '节点名称',
        '节点序列号',
        '日志等级',
        '日志类型',
        '采集路径',
        '日志文件地址',
        '日志内容',
        ],  
        columnWidths: [10, 10, 10, 5, 5, 10, 10, 100], // 每一列的宽度 需与列顺序对应
    },
    ];

    const toExcel = new ExportJsonExcel(option); // 创建表格
    toExcel.saveExcel();	// 保存下载表格
  }

  //深克隆
  deepClone(o){
    if(typeof o=='string' || typeof o=='number'||typeof o=='boolean'||typeof o=='undefined'){
        return o;
    }else if(Array.isArray(o)){
        var arr=[];
        for (let i = 0; i < o.length; i++) {
            arr.push(this.deepClone(o[i]))
        }
        return arr;

    }else if(typeof o=='object'){
        var _o={};
        for (var k in o) {
            _o[k]=this.deepClone(o[k]);
        }
        return _o;
    }
  }


  //日志下载
  downLog() {
    if (this.state.logList.length != 0) {
      //深克隆
      let newLogList = this.deepClone(this.state.logList)
      for (let item of newLogList) {
        //日志等级转换
        if (item.logLevel === 0) {
          item.logLevel='ALL'
        }
        else if (item.logLevel === 1) {
          item.logLevel='DENUG'
        }
        else if (item.logLevel === 2) {
          item.logLevel='INFO'
        }
        else if (item.logLevel === 3) {
          item.logLevel='WARN'
        }
        else if (item.logLevel === 4) {
          item.logLevel='ERROR'
        }
        else if (item.logLevel === 5) {
          item.logLevel='FATAL'
        }
        else if (item.logLevel === 99) {
          item.logLevel='NONE'
        }

        //日志类型转换
        if (item.logType === 1) {
          item.logType = 'kernal日志'
        }
        else if (item.logType === 2) {
          item.logType = 'k3s日志'
        }
        else if (item.logType === 3) {
          item.logType = 'EDK日志'
        }
        else if (item.logType === 11) {
          item.logType = '用户应用' 
        }
      }

      console.log(newLogList);
      console.log(this.state.logList);
      this.exportExcel(newLogList)
    } else {
      message.warning("没有日志内容！")
    }
    
  }


  //匹配日志等级
  getLogLevel = (record) => {
    if (record.logLevel === 0) {
      return <span>ALL</span>
    }
    else if (record.logLevel === 1) {
      return <span>DEBUG</span>
    }
    else if (record.logLevel === 2) {
      return <span>INFO</span>
    }
    else if (record.logLevel === 3) {
      return <span>WARN</span>
    }
    else if (record.logLevel === 4) {
      return <span>ERROR</span>
    }
    else if (record.logLevel === 5) {
      return <span>FATAL</span>
    }
    else if (record.logLevel === 99) {
      return <span>NONE</span>
    }

  }

  //匹配日志类型
  getLogType = (record) => {
    if (record.logType === 1) {
      return <span>kernal日志</span>
    }
    else if (record.logType === 2) {
      return <span>k3s日志</span> 
    }
    else if (record.logType === 3) {
      return <span>EDK日志</span> 
    }
    else if (record.logType === 11) {
      return <span>用户应用</span> 
    }
  }

  //日志等级列表选项
  getLogLevelList() {
    const arr = [
      { id: 1, logLevel: 0, name: 'ALL' },
      { id: 2, logLevel: 1, name: 'DEBUG' },
      { id: 3, logLevel: 2, name: 'INFO' },
      { id: 4, logLevel: 3, name: 'WARN' },
      { id: 5, logLevel: 4, name: 'ERROR' },
      { id: 6, logLevel: 5, name: 'FATAL' },
      { id: 7, logLevel: 99, name: 'NONE' },
    ];
    this.setState({
      logLevelList: this.getLogLevelItem(arr)
    })
  }

  getLogLevelItem(arr) {
    return arr.map((item) => {
      return (
        <Option key={item.logLevel} >{item.name}</Option>
      );
    });
  }

  //日志类型类别选项
  getLogTypeList() {
    const arr = [
      { id: 1, logType: 1, name: 'kernal日志' },
      { id: 2, logType: 2, name: 'k3s日志' },
      { id: 3, logType: 3, name: 'EDK日志' },
      { id: 4, logType: 11, name: '用户应用' },
    ];
    this.setState({
      logTypeList: this.getLogTypeItem(arr)
    })
  }

  getLogTypeItem(arr) {
    return arr.map((item) => {
      return (
        <Option key={item.logType} >{item.name}</Option>
      );
    });
  }

  render() {
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.searchList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    }

    const columns = [
      {
        title: '时间',
        dataIndex: 'upTime',
        width: '9%',
      },
      {
        title: '节点名称',
        dataIndex: 'nodeName',
        width: '10%',
      },
      {
        title: '节点序列号',
        dataIndex: 'nodeSeriesNumber',
        width: '10%',
      },
      {
        title: '日志等级',
        dataIndex: 'logLevel',
        width: '5%',
        render: (text, record) => (
          this.getLogLevel(record)
        )
      },
      {
        title: '日志类型',
        dataIndex: 'logType',
        width: '5%',
        render: (text, record) => (
          this.getLogType(record)
        )
      },
      {
        title: '日志采集路径',
        dataIndex: 'logAddr',
        width: '10%',
      },
      {
        title: '日志文件地址',
        dataIndex: 'logFile',
        width: '10%',
      },
      {
        title: '内容',
        dataIndex: 'logContent',
        ellipsis: true,
        width: '30%',
      },
    ];

    return (
      <>
        <div className="common-pd20">
          <div >
            <h1 className="common-top-title0">日志服务</h1>
            <div className="common-center-container">
               
              <div>
                <TopSearch onClick={() => this.searchList()} logLevelList={this.state.logLevelList} logTypeList={this.state.logTypeList}
                  tenantList={this.state.tenantList} downClick={() => this.logDownload()} />
              </div>
              
              {/* <Button type='primary' style={{ width: 80, marginBottom: -10, marginTop: 10 }} onClick={()=>this.downLog()}>日志下载</Button> */}
                
              <div className="user-table-card">
                <Table
                  rowKey={record => record.id}
                  bordered={false}
                  tableLayout="fixed"
                  pagination={false}
                  columns={columns}
                  dataSource={this.state.logList}
                  loading={this.state.loading}
                />
              </div>
              <div>
                {
                  this.state.logList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination
                        showQuickJumper
                        pageSizeOptions={[10, 20, 50, 100, 200, 500, 1000]}
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        defaultCurrent={1}
                        current={this.state.page}
                        pageSize={this.state.pageSize}
                        total={this.state.logAmount}
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}


export default ServiceLog;
