import { handleResponse } from "./handle-response";
import store from "../../store/index";

export const algorithmService = {
  getAlgorithmOverview,
  getAlgorithm,
  getAlgorithmDetail,
  addAlgorithmNamespaces,
  editAlgorithmNamespaces,
  deleteAlgorithmNamespaces,
  getAlgorithmVersionDetial,
  addAlgorithm,
  editAlgorithm,
  deleteAlgorithm,
  getAlgorithmGroups,
  getAlgorithmGroupDetail,
  addAlgorithmGroup,
  editAlgorithmGroup,
  deleteAlgorithmGroup,
  getAlgorithmLabels,
};

//查询算法概览列表
function getAlgorithmOverview(name, limit, offset) {
  return fetch(
    `/v1/console/algorithms/namespaces?name=${name || ""}&limit=${
      limit || 10
    }&offset=${offset || 1}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//查询算法命名空间详情
function getAlgorithmDetail(id, name, limit, offset) {
  return fetch(
    `/v1/console/algorithms/namespaces/${id}?name=${name || ""}&limit=${
      limit || 10
    }&offset=${offset || 1}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//添加算法命名空间
function addAlgorithmNamespaces(name, label, description) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name: name,
      label: label || "",
      description: description || "",
    }),
  };
  return fetch(`/v1/console/algorithms/namespaces`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//修改算法命名空间
function editAlgorithmNamespaces(id, name, label, description) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name: name,
      label: label || "",
      description: description || "",
    }),
  };
  return fetch(`/v1/console/algorithms/namespaces/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//删除算法命名空间（同时删除其下所有算法）
function deleteAlgorithmNamespaces(id) {
  return fetch(`/v1/console/algorithms/namespaces/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//查询算法
function getAlgorithm(id, algorithmName, algorithmVersion, limit, offset) {
  return fetch(
    `/v1/console/algorithms/namespaces/${id}/algorithms?algorithmName=${
      algorithmName || ""
    }&algorithmVersion=${algorithmVersion || ""}&limit=${limit || 10}&offset=${
      offset || 1
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//查询算法详情
function getAlgorithmVersionDetial(id) {
  return fetch(`/v1/console/algorithms/namespaces/algorithms/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//添加算法
function addAlgorithm(id, algorithmName, algorithmVersion, description) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      algorithmName: algorithmName,
      algorithmVersion: algorithmVersion || "",
      description: description || "",
    }),
  };
  return fetch(
    `/v1/console/algorithms/namespaces/${id}/algorithms`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//修改算法
function editAlgorithm(id, algorithmName, algorithmVersion, description) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      algorithmName: algorithmName,
      algorithmVersion: algorithmVersion || "",
      description: description || "",
    }),
  };
  return fetch(
    `/v1/console/algorithms/namespaces/algorithms/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//删除算法
function deleteAlgorithm(id) {
  return fetch(`/v1/console/algorithms/namespaces/algorithms/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//查询算法组列表
function getAlgorithmGroups(algorithmGroupName, limit, offset) {
  return fetch(
    `/v1/console/algorithms/groups?algorithmGroupName=${
      algorithmGroupName || ""
    }&limit=${limit || 10}&offset=${offset || 1}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//查询算法组详情
function getAlgorithmGroupDetail(id) {
  return fetch(`/v1/console/algorithms/groups/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//添加算法组
function addAlgorithmGroup(algorithmGroupName, description, algorithmIds) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      algorithmGroupName: algorithmGroupName,
      description: description,
      algorithmIds: algorithmIds, //算法ID数组
      //algorithmGroup, //算法组对象
    }),
  };
  return fetch(`/v1/console/algorithms/groups`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//修改算法组
function editAlgorithmGroup(id, algorithmGroupName, description, algorithmIds) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      algorithmGroupName: algorithmGroupName,
      description: description,
      algorithmIds: algorithmIds, //算法ID数组
    }),
  };
  return fetch(`/v1/console/algorithms/groups/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//删除算法组
function deleteAlgorithmGroup(id) {
  return fetch(`/v1/console/algorithms/groups/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//获取算法标签
function getAlgorithmLabels(labelName, limit, offset) {
  return fetch(
    `/v1/console/algorithms/labels?algorithmGroupName=${
      labelName || ""
    }&limit=${limit || 10}&offset=${offset || 1}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
