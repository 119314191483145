import React from 'react';

import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';



import '../../../../../../../index.scss';


const { confirm } = Modal;

let searchName = '';
let searchUuid = '';


//select
const { Option } = Select;


let page = 1;
let pageSize = 10;
function onChange(value) {
  console.log('changed', value);
}
class Application extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      nodeList: [{
          id: 1,
          containerport: 8090,
          hostport: 9091
      },
      {
        id: 2,
        containerport: 7090,
        hostport: 9080
    }],
      nodeAmount: 0,
      deleteIds: [],
      showUpgrade: false,
      showQuota: false,
     
    };
  }

  /* 升级 */
  showUpgrade = (id) => {
    this.setState({
      showUpgrade: true,
    });
  };

  hideModalUpgrade = () => {
    this.setState({
      showUpgrade: false,
    });
  };
  /* 配额 */
  showQuota = (id) => {
    this.setState({
      showQuota: true,
    });
  };

  hideModalQuota = () => {
    this.setState({
      showQuota: false,
    });
  };

  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        message.success('删除成功');
        this.searchList();
      },
      onCancel() {

      },
    });
  }


  searchList() {
  
  }
  componentDidMount() {
    this.searchList();
  }

  render() {
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.searchList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    }
    const registered = () => {
     
        this.setState({
            showQuota: true
          })
    };
    const  onFinish = (values) => {
      
        this.setState({
            showQuota: false
          })
      };

    const portColumn = [
         {
            title: '容器端口',
            dataIndex: 'containerport',
          },
          {
            title: '主机端口',
            dataIndex: 'hostport',
          },
          {
            title: '操作',
            dataIndex: 'action',
          
            render: (text, record) =>  <a href onClick={() => this.showDeleteConfirm(record.key)} >删除</a>
          }
    ];
    const layout = {
        labelCol: { xs: 24, sm: 12, md: 8, lg: 2, xl: 2},
        wrapperCol: { xs: 24, sm: 12, md: 15},
      };
    return (
      <>
        <div>
          <div>
            <div className="common-center-container" style={{ padding: 0 }}>
                <div className="oper-button">
                
                <Form
                {...layout}
                name="basic"
                labelAlign="left"
            
                >
                  
                     <Form.Item
                    label="端口映射"  style={{marginBottom: 12}} >
                       <Button type="danger" onClick={registered} style={{color: '#fff'}}>  新增  </Button>
                     </Form.Item>
                </Form>
             
                </div>
                <div className="user-table-card">
                    <Table
                   
                    rowKey={record => record.id}
                    bordered={false}
                    pagination={false}
                    columns={portColumn}
                    dataSource={this.state.nodeList}
                    />
                </div>
            </div>
          </div>
    {/* 配额 */}
    <Modal
          title="新增环境变量"
          visible={this.state.showQuota}
          onOk={this.hideModalQuota}
          onCancel={this.hideModalQuota}
          okText="确定"
          cancelText="取消"
          onFinish={onFinish}
          footer={null}
        >
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            name="form_in_modal"
          >
            <Form.Item
              name="title"
              label="变量名"
              rules={[{ required: true, message: '请输入变量名' }]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="title"
              label="变量值"
              rules={[{ required: true, message: '请输入变量值' }]}
            >
               <Input/>
            </Form.Item>
            <div style={{ marginTop: '8px' }}>
                  <div style={{textAlign: 'center'}}>
                    <Button type="primary" htmlType="submit"> 确定 </Button>
                    <Button style={{ margin: '0 9.46px' }} onClick={this.hideModalQuota}>取消 </Button>
                    </div>
                </div>
          </Form>
        </Modal>

        </div>



    

      </>
    );
  }
}
export default Application;