import React from 'react';
import { Select, Form, Col, Input, Button, Table, Pagination,Badge , InputNumber, ConfigProvider, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import { userService } from '../../../../services/user.service';

import './../../../../../index.scss';
import './list.scss';

import { connect } from "react-redux";


const { Option } = Select;


const { confirm } = Modal;


let page = 1;
let pageSize = 10;


class Search extends React.Component {
    constructor(props) {
        super(props);
       
    }
   form = React.createRef();
  
   onFinish = (values) => {
    page = 1;
    this.props.onClick();
  };
   resetForm = () => {
    this.form.current.resetFields();
    page = 1;
    this.props.onClick();
  }
 
  componentDidMount() {
    this.resetForm();
  }
  render() { 
        return (
            <>
            <div className="common-search-form">
                <Form
                 ref={this.form}
                name="advanced_search_user"
                className="ant-advanced-search-form"
                onFinish={this.onFinish}
                layout="inline"
               
                // style={{ justifyContent: 'center' }}
                 >

                <Form.Item name='username' label='模板名称' style={{  marginTop: '8px' }}>
                    <Input placeholder="请输入模板名称" />
                </Form.Item>
               
              
                <Form.Item style={{ marginTop: '8px' }}>
                    <Button type="primary" htmlType="submit"> 查询 </Button>
                    <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
                </Form.Item>

                </Form>
            </div>
            </>
        );
    }
}

class EdgeEffectListTemplate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
     
      isModalVisible: false,
      configIsModalVisible: false,
      userList: [
        {
        id:1,
        name: 'template1',
        version: 'V0.1',
        node: '--',
        createTime: '2021-09-10 10:00:00'
      },
      {
        id:2,
        name: 'template2',
        version: 'V0.1',
        node: '--',
        createTime: '2021-09-10 10:00:00'
      },
      {
        id:3,
        name: 'template3',
        version: 'V0.1',
        node: '--',
        createTime: '2021-09-10 10:00:00'
      },
      {
        id:4,
        name: 'template4',
        version: 'V0.1',
        node: '--',
        createTime: '2021-09-10 10:00:00'
      },
    
    ],
      loading: false,
      page: 1,
      pageSize: 10,
      actionIds: [],
      userAmount: 10
    //  history: require("history").createHashHistory,
    };
  }

  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        message.success('删除成功');
        this.changeType();
      },
      onCancel() {

      },
    });
  }
 
  showAdjust() {
    this.props.history.push('/home/effect-edge-t/deploy');
  }
  showMultiDeleteConfirm() {
    if (this.state.actionIds.length === 0) {
      message.warning('请先选择要操作的应用');
      return;
    }
    confirm({
      title: '确定批量操作吗?',
      icon: <ExclamationCircleOutlined />,
    //  content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      onOk: () => {
        message.success('操作成功');
        this.changeType();
      },
      onCancel() {

      },
    });
  }


  changeType() {
      this.setState({
          page,
          pageSize
      })
  
      this.setState({
        loading: true
      });
      setTimeout(() => {
        this.setState({
            loading: false
          }); 
      }, 200)
  
  }

  componentWillMount() {
    
  }
  componentDidMount() {
  //  this.changeType();
    pageSize = 10;
    page = 1;
   
  }
 

  
  gotoDetail = (text, key) => {
    this.props.history.push({ pathname: '/home/effect-edge-t/view', state: { id: text } });
  }
  getAppType = (record) => {
    if (record.state === 'NORMAL') {
      return <span> <Badge status="success" />运行中</span>
    } else if (record.type === 'STOP') {
      return <span> <Badge status="warning" />已停止</span>
    } else if (record.type === 'EXCEPTION') {
      return <span><Badge status="error" />异常</span>
    }
  }

  handleOk() {
      this.setState({
          isModalVisible: false,
          configIsModalVisible: false
      })
  }

  render() {
    const onPageChange = (pageNumber) => {
      
      page = pageNumber;
    
      this.changeType();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
  
      this.changeType();
    }
    const registered = () => {
  
      this.props.history.push('/home/effect-edge-t/add');
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          actionIds: selectedRowKeys
        })
      },
    
    };

    const columns = [
      {
        title: '模板名称',
        dataIndex: 'name',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
      },
     
      {
        title: '版本',
        dataIndex: 'version',
      },
      {
        title: '描述',
    
        dataIndex: 'node',
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        width: '20%',
        render: (text, record) => (
          <>
        
           
            <a href onClick={() => this.showDeleteConfirm(record.key)} style={{ marginRight: '5px' }}>删除</a>
            <a href onClick={() => this.showAdjust(record.key)} style={{ marginRight: '5px' }}>部署</a>
          </>
        )
      },
    ];
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    return (
      <>
        <div className="common-pd20">
          <div >
            <h1 className="common-top-title0">应用模板</h1>
            <div className="common-center-container">
              <Search onClick={() => this.changeType()} currentUser={this.props.currentUser}  />
              <div className="oper-button">
                <Router>
                  <Button type="primary" onClick={registered}>  创建  </Button>
                </Router>
              
                <Button style={{ marginLeft: 10 , width: 80, textAlign:"center"}} onClick={() => this.showMultiDeleteConfirm()}> 批量操作 </Button>
              </div>
              <div className="user-table-card">
                <Table
                  rowSelection={{
                    ...rowSelection,
                  }}
                  rowKey={record => record.id}
                  bordered={false}
                  tableLayout="fixed"
                  pagination={false}
                  loading={this.state.loading}
                  columns={columns}
                  dataSource={this.state.userList}
                />
              </div>
              <div>
                {
                  this.state.userList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination 
                        showSizeChanger 
                        onShowSizeChange={onShowSizeChange} 
                        defaultCurrent={1} 
                        current={this.state.page} 
                        pageSize={this.state.pageSize} 
                        total={this.state.userAmount} 
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>
       

        </div>
      </>

    );
  }
}


export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(EdgeEffectListTemplate);
