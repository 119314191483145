import React from 'react';
import { Form, Input, Button, Table, Pagination, ConfigProvider, Modal, message, Select, Row, Col, Tabs, Tree, Card, Checkbox, Radio } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import CreateAccess from './create-access/create-access';
import { roleService } from '../../../../services/role.service';
import { userService } from '../../../../services/user.service';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import store from '../../../../../store';
import { connect } from 'react-redux';
import './access-policy.scss';
import './../../../../../index.scss';

const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;
const { TreeNode } = Tree;
const { TabPane } = Tabs;

let searchData = '';
let searchTenantUser = '';
let page = 1;
let pageSize = 10;
let searchType = 'main';
const formItemLayout = {
  labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4 },
  wrapperCol: { xs: 24, sm: 12, md: 15 },
};

// 树形控件

// const treeData = [
//   {
//     title: '0-0',
//     key: '0-0',
//     children: [
//       {
//         title: '0-0-0',
//         key: '0-0-0',
//         children: [
//           { title: '0-0-0-0', key: '0-0-0-0' },
//           { title: '0-0-0-1', key: '0-0-0-1' },
//           { title: '0-0-0-2', key: '0-0-0-2' },
//         ],
//       },
//       {
//         title: '0-0-1',
//         key: '0-0-1',
//         children: [
//           { title: '0-0-1-0', key: '0-0-1-0' },
//           { title: '0-0-1-1', key: '0-0-1-1' },
//           { title: '0-0-1-2', key: '0-0-1-2' },
//         ],
//       },
//       {
//         title: '0-0-2',
//         key: '0-0-2',
//       },
//     ],
//   }

// ]

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { type: 'main' };
  }
  form = React.createRef();
  onFinish = (values) => {
    searchData = values.name;
    searchTenantUser = values.tenantUser;
    searchType = this.state.type;
    page = 1;
    this.props.onClick();
  };
  componentDidMount() {
    this.resetForm();
  }
  resetForm = () => {
    this.form.current.resetFields();
    searchData = '';
    searchTenantUser = '';
    page = 1;
    searchType = 'main';
    this.setState({
      type: 'main'
    })
    this.form.current?.setFieldsValue({ type: 'main' });
    this.props.onClick();
  }
  onGenderChange = (value) => {
    if (value.type) {
      searchType = value.type;
      this.setState({
        type: value.type
      })
    }

  }
  render() {
    return (
      <>
        <div className="common-search-form">
          <Form
            ref={this.form}
            name="advanced_search_ass"
            className="ant-advanced-search-form"
            onFinish={this.onFinish}

            onValuesChange={this.onGenderChange}
            layout="inline"
            // style={{ justifyContent: 'center' }}
          >

            <Form.Item name='name' label='角色名称' style={{ marginTop: '8px' }}>
              <Input placeholder="请输入角色名称" />
            </Form.Item>
            {
              store.getState().currentUser.type === 'SYS' ?
                <Form.Item name='type' label='用户类别' style={{ marginTop: '8px' }}>
                  <Select
                    initialvalue="main"
                    placeholder="请选择用户类别"
                  >
                    <Option value="main">管理员</Option>
                    <Option value="subuser">用户</Option>
                  </Select>
                </Form.Item>
                : ''
            }
            {
              store.getState().currentUser.type === 'SYS' && this.state.type === 'subuser' ?
                <Form.Item name='tenantUser' label='所属用户' style={{ marginTop: '8px' }}>
                  <Select
                    style={{ width: '150px' }}
                    placeholder="请选择所属用户"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.props.tenantList}
                  </Select>
                </Form.Item>
                : ''
            }
            <Form.Item style={{ marginTop: '8px' }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
            </Form.Item>

          </Form>
        </div>
      </>
    );
  }

}

class AccessPolicy extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      roleId: '',
      roleName: '',
      roleDes: '',

      init: [],//树形框初始选中的点
      allNode: [],//所有节点
      parentNode: [],//所有父节点
      childNode: [],//所有子节点
      chosenNode: [],//选择的节点，作为最终提交的节点列表
      expandedKeys: [],
      checkedKeys: [],//添加的节点
      selectedKeys: [],
      dataSourceMenu: [], // 当前角色已包含的权限列表
      halfCheckedKeys: [],//父子联动，父被选中标记位
      isEditVisible: false,
      isCreateVisible:false,
      roleList: [],
      roleAmount: 0,
      deleteIds: [],
      showManage: true,
      loading: true,
      page: 1,
      pageSize: 10,
      showCreate: false,
      showRegistered: false,
      history: require("history").createHashHistory,
      tenantList: []
    };
  }
  roleNameChange = (e) => {
    this.setState({
      roleName: e.target.value
    })
  }
  roleDesChange = (e) => {
    this.setState({
      roleDes: e.target.value
    })
  }
  handleEditOk = () => {
    // console.log(this.state.parentNode);
    // console.log(this.state.checkedKeys);
    // console.log(this.state.init);
    this.handleCancel();
    let res_1 = -1;
    let res_2 = -1;
    // roleService.editRole(this.state.roleId, this.state.roleName, this.state.roleDes)
    //   .then(res => res_1 = res.code);
    // roleService.addMenuForRole(this.state.roleId, this.state.chosenNode)
    //   .then(res => res_2 = res.code);
    // if (res_1 === 200 && res_2 === 200) {
    //   message.success('修改成功');
    //   this.handleCancel();
    // }
  }
  handleCreateOk=()=>{
    this.handleCancel();
  }

  handleCancel = () => {
    this.setState({
      isEditVisible: false,
      isCreateVisible:false
    })
  }
  handleLink = (e) => {
    this.setState({
      beLink: e.target.checked
    })
  }

  handleIsFold = (e) => {
    if (e.target.checked) {
      this.setState({
        expandedKeys: this.state.childNode
      })
    } else {
      this.setState({
        expandedKeys: []
      })
    }

  }

  handleIsChosen = (e) => {
    if (e.target.checked) {
      this.setState({
        checkedKeys: this.state.childNode,
        chosenNode: this.state.allNode
      })
    } else {
      this.setState({
        checkedKeys: [],
        chosenNode: this.state.parentNode//全部选，但所有父节点需保持
      })
    }
  }


  showEditConfirm(record) {
    this.setState({
      roleName: record.name,
      roleDes: record.description,
      isEditVisible: true,
      roleId: record.id,
      allNode: [],
      init: [],
      childNode: [],
      chosenNode: [],
      parentNode: []
      // showManage: false,
      // showCreate: true,
      // showRegistered: true,
    })
    this.getRoleMenu(record.id);
  }
  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        roleService.removeRole(event).then(res => {
          if (res.code === 200) {
            message.success('删除成功');
            if (this.state.roleList.length === 1) {
              page = page - 1;
              page = page ? page : 1;
            }
            this.searchList();
          }
          //  else {
          //   message.error('删除失败，' + res.message);
          // }
        })
      },
      onCancel() {

      },
    });
  }
  showMultiDeleteConfirm() {
    if (this.state.deleteIds.length === 0) {
      message.warning('请先选择要删除的角色');
      return;
    }
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        roleService.removeRoles(this.state.deleteIds).then(res => {
          if (res.code === 200) {
            message.success('删除成功');
            if (this.state.actionIds.length === this.state.roleList.length) {
              page = page - 1;
              page = page ? page : 1;

            }
            this.searchList();
          } 
          // else {
          //   message.error('删除失败，' + res.message);
          // }
        }, error => {
          message.error('删除失败,' + error);
        })
      },
      onCancel() {

      },
    });
  }

  searchList() {
    this.setState({
      loading: true,
      page,
      pageSize
    });
    roleService.getRoleList(searchData, searchTenantUser, page, pageSize, searchType).then(res => {
      if (res.code === 200) {
        this.setState({
          loading: false
        })
        const roles = res.roles;
        roles.forEach(element => {
          element.key = element.id;
        });
        this.setState({
          roleList: roles,
          roleAmount: res.dataCount
        });
      } 
      // else {
      //   message.error('获取角色列表失败，' + res.message);
      // }
    }, error => {
      this.setState({
        loading: false
      })
      message.error('获取角色列表失败，' + error);
    });
  }
  getTenantList() {
    userService.getAllTenantList().then(res => {
      if (res.code === 200) {
        const tenants = res.users;
        this.setState({
          tenantList: this.getTenantItem(tenants),
        });
      }
    });
  }
  getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id} >{item.realName}</Option>
      );
    });
  }
  componentDidMount() {
    pageSize = 10;
    page = 1;
    this.getTenantList();
  }
  printContent(comment) {
    this.setState({
      showManage: true,
      showCreate: false,
      showRegistered: false,
    });
    if (comment.showManage) {
      this.searchList();
    }
  }
  gotoDetail = (text, key) => {
    this.props.history.push({ pathname: '/home/sys/access-policy/detail', state: { id: text } });
  }

  getType = (record) => {
    return (
      <>
        {
          this.props.currentUser?.id < record.key ?
            <a style={{ marginRight: 10 }} href onClick={() => this.showEditConfirm(record)}>编辑</a>
            : <a disabled style={{ marginRight: 10 }} href onClick={() => this.showEditConfirm(record)}>编辑</a>
        }
        {
          record.key == 1 || record.key == 2 || record.key == 3 || record.key == 4 ?
            <a disabled href onClick={() => this.showDeleteConfirm(record.key)}>删除</a>
            : <a href onClick={() => this.showDeleteConfirm(record.key)}>删除</a>
        }

      </>)
  }


  renderTreeNodes = data => data.map(item => {
    if (item.childResource) {
      return (
        <TreeNode title={item.name} key={item.id} dataRef={item}>
          {this.renderTreeNodes(item.childResource)}
        </TreeNode>
      );
    }
    return <TreeNode key={item.id} {...item} />;
  });


  handleLink = (e) => {
    console.log(this.form);
    this.setState({
      checkedKeys: this.state.checkedKeys,
      checkStrictly: e.target.checked
    })
  }

  onSelect = (selectedKeys, info) => {
    console.log(info);
    console.log(selectedKeys);
    if (this.state.parentNode.find((ele) => ele.id == selectedKeys)) {
      const menuId = parseInt(selectedKeys[0]);
      confirm({
        title: '确定移除此父节点吗?',
        icon: <ExclamationCircleOutlined />,
        content: <p style={{ color: 'red' }}>此为解除关联，而非删除权限模块</p>,
        onOk() {
          // roleService.removeMenuFormRole(this.state.id, menuId).then(res => {
          //     if (res.code === 200) {
          //         message.success('移除关联成功');
          //         that.getRoleMenu(this.state.id);
          //     } else {
          //         message.error('移除关联失败，' + res.message);
          //     }
          // }, error => {
          //     message.error('移除关联失败，' + error.message);
          // })
        },
        onCancel() {
        }
      });
    }
    this.setState({ selectedKeys });
  };

  onCheck = (checkedKeys, info) => {
    const chosenNode = [];
    const fatherCheckedKeys = info.halfCheckedKeys;

    for (let item of info.checkedNodes) {
      chosenNode.push(item.dataRef)
    }
    this.state.parentNode.forEach((ele) => {
      if (fatherCheckedKeys?.find((val) => val === (ele.id).toString())) {
        chosenNode.push(ele);
      }
    })
    console.log(chosenNode);
    console.log(info);
    this.setState({
      checkedKeys,
      chosenNode
    });
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
    });
  };

  getRoleMenu = roleId => {
    roleService.getRoleMenu(roleId || this.state.roleId).then(res => {
      if (res.code === 200) {
        console.log('权限列表', res.resources);
        this.readNodes(res.resources);
        this.setState({
          dataSourceMenu: res.resources,
          checkedKeys: this.state.init,
        });
      } 
      // else {
      //   message.error('获取角色包含的权限失败' + res.message);
      // }
    }, error => {
      message.error('获取角色包含的权限失败,' + error.message);
    })
  }

  //遍历权限数组对象
  readNodes(nodes = []) {
    for (let item of nodes) {
      this.state.allNode.push(item);//所有节点，用于全选特殊使用时
      if (item.states === '1') {
        let str = (item.id).toString();  //初始选中的节点
        this.state.chosenNode.push(item);
        // this.state.init.push(str)
      }
      if (!item.childResource.length) {//所有子节点
        let str = (item.id).toString();
        this.state.childNode.push(str)
      }
      if (!item.childResource.length && item.states === '1') {//初始选中的子节点
        let str = (item.id).toString();
        this.state.init.push(str);
      }
      if (item.childResource.length) { //所有父节点
        this.state.parentNode.push(item);
      }
      if (item.childResource && item.childResource.length) {
        this.readNodes(item.childResource)
      }
    }
  }

  render() {
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.searchList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    }
    const registered = () => {
      this.setState({
        isCreateVisible: true,

        // showManage: false,
        // showCreate: true,
        // showRegistered: true,
      })
      // this.props.history.push("/home/sys/access-policy/add");
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          deleteIds: selectedRowKeys
        })
      }
    };
    const onFinish = (values) => {
      console.log(values);
    }
    const sysColumns = [
      {
        title: '角色名称',
        dataIndex: 'name',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,

      },

      {
        title: '角色类型',
        dataIndex: 'type',
      },
      {
        title: '所属用户',
        dataIndex: 'createBy',
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '描述',
        dataIndex: 'description',
        width: '10%'
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) =>
          <a href onClick={() => this.showDeleteConfirm(record.key)}>删除</a>
      },
    ];
    const columns = [
      {
        title: '角色名称',
        dataIndex: 'name',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,

      },
      {
        title: '描述',
        dataIndex: 'description',
        width: '10%'
      },
      {
        title: '角色类型',
        dataIndex: 'type',
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => this.getType(record)
        // <>
        //   <a style={{ marginRight: 10 }} href onClick={() => this.showEditConfirm(record.key)}>编辑</a>
        //   {/* <a href onClick={() => this.showDeleteConfirm(record.key)}>删除</a> */}
        // </>
      },
    ];

    return (
      <>
        <div className="common-pd20">
          <div style={{ display: (this.state.showManage === true) ? "block" : "none" }}>
            <h1 className="common-top-title0">角色管理</h1>
            <div className="common-center-container">
              <Search onClick={() => this.searchList()} tenantList={this.state.tenantList} />
              <div className="oper-button">
                <Router>
                  <Button disabled onClick={registered}>  创建  </Button>
                </Router>
                <Button disabled style={{ marginLeft: 10 }} onClick={() => this.showMultiDeleteConfirm()}> 删除 </Button>
              </div>
              <div className="user-table-card">
                {/* <Table
                  rowSelection={{
                    ...rowSelection,
                  }}
                  rowKey={record => record.id}
                  bordered={false}
                  pagination={false}
                  loading={this.state.loading}
                  columns={columns}
                  dataSource={this.state.roleList}
                /> */}
                {
                  store.getState().currentUser.type === 'SYS' ?
                    <Table
                      rowSelection={{
                        ...rowSelection,
                      }}
                      rowKey={record => record.id}
                      bordered={false}
                      pagination={false}
                      loading={this.state.loading}
                      columns={sysColumns}
                      dataSource={this.state.roleList}
                    /> :
                    <Table
                      rowSelection={{
                        ...rowSelection,
                      }}
                      rowKey={record => record.id}
                      bordered={false}
                      pagination={false}
                      loading={this.state.loading}
                      columns={columns}
                      dataSource={this.state.roleList}
                    />
                }
              </div>
              <div>
                {
                  this.state.roleList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination showSizeChanger onShowSizeChange={onShowSizeChange}
                        defaultCurrent={1} current={this.state.page} pageSize={this.state.pageSize} total={this.state.roleAmount} onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>

              {/* 角色编辑 */}
              <Modal width={600} title="编辑角色"
                visible={this.state.isEditVisible}
                onOk={() => this.handleEditOk()}
                onCancel={() => this.handleCancel()}
                okText="确定"
                cancelText="取消">
                <Form name="basic"
                  onFinish={onFinish}
                  // labelAlign="left"
                  // ref={this.formRef}
                  initialValues={{ name: this.state.roleName, description: this.state.roleDes }}
                  {...formItemLayout}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item name="name" label="名称" >
                        <Input placeholder="请输入名称"
                          onChange={(e) => { this.roleNameChange(e) }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="description" label="描述" >
                        <TextArea
                          autoSize={{ minRows: 3, maxRows: 5 }}
                          placeholder="请输入描述"
                          onChange={(e) => { this.roleDesChange(e) }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Form.Item name="description" label="菜单权限" >
                        <Row>
                          <Col span={8}>
                            <Checkbox value="A" onChange={(e) => { this.handleIsFold(e) }}>展开/折叠</Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="B" onChange={(e) => { this.handleIsChosen(e) }}>全选/全不选</Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="C" checked onChange={(e) => { this.handleLink(e) }}>父子联动</Checkbox>
                          </Col>
                        </Row>
                        <Card>
                          <Tree
                            checkable
                            // checkStrictly={!this.state.checkStrictly}//父子联动与否
                            onExpand={this.onExpand}
                            autoExpandParent={true}
                            expandedKeys={this.state.expandedKeys}
                            onCheck={this.onCheck}
                            checkedKeys={this.state.checkedKeys}
                            onSelect={this.onSelect}
                            selectedKeys={this.state.selectedKeys}
                          >
                            {this.renderTreeNodes(this.state.dataSourceMenu)}
                            {/* {this.renderTreeNodes(treeData)} */}
                          </Tree>
                        </Card>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

              </Modal>
              {/* 角色创建 */}
              <Modal width={600} title="创建角色"
                visible={this.state.isCreateVisible}
                onOk={() => this.handleCreateOk()}
                onCancel={() => this.handleCancel()}
                okText="确定"
                cancelText="取消">
                <Form name="basic"
                  // onFinish={onFinish}
                  {...formItemLayout}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item name="name" label="名称" >
                        <Input placeholder="请输入名称" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="description" label="描述" >
                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </div>
          </div>
          {/* 创建 */}
          {/* <div style={{ display: (this.state.showRegistered === true) ? "block" : "none" }}>
            <CreateAccess onSubmit={this.printContent.bind(this)} />
          </div> */}
        </div>
      </>
    );
  }
}
// export default AccessPolicy;
export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(AccessPolicy);