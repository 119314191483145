import React from 'react';
import { Form, Button, Col, Select, DatePicker, message } from 'antd';
import BackPage from '../../../common/backPage';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { nodeService } from '../../../../services/node.service';

import './node-log.scss';
import { getNodeIdAction } from '../../../../../store/actionCreators';
import store from '../../../../../store';

const { RangePicker } = DatePicker;
let startTime = '';
let endTime = '';
let searchLogType = '';
const { Option } = Select;

function Search(props) {
    const [form] = Form.useForm();
    const onChange = (values, datastring) => {
        startTime = datastring[0];
        endTime = datastring[1];
        
        props.onClick();
    };
    const handleChange = (value) => {
        console.log(value);
        searchLogType = value;
        props.onClick();
    }
    return (
        <>
            <div className="node-log-search">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form">
                    <Col span={20} offset={2}>
                        <Form.Item name='type' label='日志类型' style={{ float: 'left', marginRight: '7.14px' }}>
                            <Select style={{ width: '150px' }} placeholder="请选择日志类型" onChange={handleChange} allowClear>
                                {props.logTypeList}
                            </Select>
                        </Form.Item>
                        <Form.Item name='time' label='时间段' style={{ float: 'left', marginRight: '7.14px' }}>
                            <RangePicker
                                style={{ width: 328, height: 28 }}
                                locale={locale} onChange={onChange}
                                showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Form>
            </div>
        </>
    );
}

class NodeLog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nodeId: '',
            nodeLogName: '查看日志',
            nodeLogs: [],
            logsItems: null,

            logTypeList: []
        };
    }

    componentWillUnmount() {
        startTime = '';
        endTime = '';
        searchLogType = ''
    }

    componentDidMount() {
        this.getLogType();
        if (this.props.location.state) {
            const nodeId = this.props.location.state.id;
            if (nodeId) {
                const action = getNodeIdAction(nodeId);
                store.dispatch(action);
                this.setState({
                    nodeId: nodeId
                });
                this.getNodeList(nodeId);
                this.getNodeDetail(nodeId);
            }
        } else {
            const nodeId = store.getState().nodeId;
            if (nodeId) {
                this.setState({
                    nodeId: nodeId
                });
                this.getNodeList(nodeId);
                this.getNodeDetail(nodeId);
            }
        }
    }
    getNodeDetail(nodeId) {
        nodeService.getNodeDetail(nodeId || this.state.nodeId).then(res => {
            if (res.code === 200) {
                const node = res.node;
                this.setState({
                  
                    nodeLogName: "查看日志 -- " + node.name,
                   
                });
            } 
            // else {
            //     message.error('获取节点详情失败');
            // }
        }, error => {
            error && message.error(error);
        });
    }
    getLogType() {
        nodeService.getLogType().then(res => {
            if (res.code === 200) {
                const logTypes = res.logTypes;
                this.setState({
                    logTypeList: this.getLogTypeItem(logTypes),
                });
            } 
            // else {
            //     message.error('获取日志类型失败，' + res.message);
            // }
        }, error => {
            error && message.error(error);
        });
    }
    getLogTypeItem(logTypes) {
        return logTypes.map((item, index) => {
            return (
                <Option key={item.logTypeValue} >{item.logTypeName}</Option>
            );
        });
    }

    getNodeList(nodeId) {
        nodeService.getLog(nodeId || this.state.nodeId, startTime, endTime, searchLogType).then(res => {
            if (res.code === 200) {
                const nodeLogs = res.nodeLogs
                const options = [];
                nodeLogs.map(item => {
                    options.push(
                        <p><span style={{ marginRight: 12 }}>{item.upTime}</span>{item.logContent}</p>
                    )
                })
                this.setState({
                    logsItems: options
                })

            } 
            // else {
            //     message.error('获取日志详情失败');
            // }
        }, error => {
            error && message.error(error);
        });
    }
    goback() {
        if (this.props.onSubmit) {
            var { showManage } = this.state;
            this.props.onSubmit({ showManage })
        }
        this.props.history.go(-1);
    }
    render() {
        return (
            <>
                <div className="node-log-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.nodeLogName} itemTitle="边缘节点" itemSubTitle="查看日志" />

                    <div className="node-log-content">
                        <Search logTypeList={this.state.logTypeList} onClick={() => this.getNodeList()} />
                        <div className="log-content">
                            {this.state.logsItems}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default NodeLog;