import React from "react";
import {
  Form,
  Button,
  Table,
  Badge,
  Modal,
  message,
  DatePicker,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./../../../../../index.scss";
import { nodeService } from "../../../../services/node.service";
import BackPage from "../../../common/backPage";

const { RangePicker } = DatePicker;
class LogCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddVisible: false,
      nodeName: this.props?.location.state?.name,
      nodeId: this.props?.location.state?.id,
      logTasksData: [],
      rangeDate: [],
      ifDisabled: true,
      confirmLoading: false,
    };
  }

  goback() {
    this.props.history.push("/home/node-manage");
  }

  componentDidMount() {
    const nodeId = this.props?.location.state?.id;
    const nodeName = this.props?.location.state?.name;
    if (nodeId) {
      sessionStorage.setItem("nodeName", nodeName || "");
      sessionStorage.setItem("nodeId", nodeId || "");
      this.getLogCollectionTasks(nodeId);
      this.backInterval = setInterval(() => {
        this.getLogCollectionTasks(nodeId);
      }, 5000);
      this.setState({
        nodeName: nodeName,
        nodeId: nodeId,
      });
    } else {
      if (sessionStorage.getItem("nodeId")) {
        const nodeId = sessionStorage.getItem("nodeId");
        const nodeName = sessionStorage.getItem("nodeName");
        this.getLogCollectionTasks(nodeId);
        this.backInterval = setInterval(() => {
          this.getLogCollectionTasks(nodeId);
        }, 5000);
        this.setState({
          nodeName: nodeName,
          nodeId: nodeId,
        });
      }
    }
  }

  //日志采集任务查询
  getLogCollectionTasks(nodeId) {
    nodeService
      .getLogCollectionTasks(nodeId)
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.setState({
            logTasksData: res.tasks,
          });
          const findResult = res.tasks.find(
            (ele) => ele.state === "WAITING" || ele.state === "STARTING"
          );
          if (findResult) {
            this.setState({
              ifDisabled: true,
            });
          } else {
            this.setState({
              ifDisabled: false,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          ifDisabled: false,
        });
      });
  }

  componentWillUnmount() {
    clearInterval(this.backInterval);
    sessionStorage.removeItem("nodeId");
    sessionStorage.removeItem("nodeName");
  }
  //   modal visible
  showAddConfirm() {
    this.setState({
      isAddVisible: true,
    });
  }
  //   modal取消
  handleCancel = () => {
    this.setState({
      isAddVisible: false,
    });
  };
  //   modal确认
  handleOk(event) {
    if (this.state.rangeDate[0] && this.state.rangeDate[1]) {
      this.setState({
        confirmLoading: true,
      });
      this.addLogCollectionTasks(
        this.state.nodeId,
        this.state.rangeDate[0] + ":00",
        this.state.rangeDate[1] + ":00"
      );
    } else {
      message.warning("请选择采集时间");
    }
  }

  // 新增任务
  addLogCollectionTasks(nodeId, startTime, endTime) {
    nodeService
      .addLogCollectionTasks(nodeId, startTime, endTime)
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          message.success("操作成功");
          this.setState({
            confirmLoading: false,
            isAddVisible: false,
          });
          this.getLogCollectionTasks(nodeId);
        }
      })
      .catch((error) => {
        this.setState({
          confirmLoading: false,
        });
        error && message.error(error);
      });
  }

  //table状态判断
  getState(record) {
    if (record.state === "WAITING") {
      return (
        <div>
          <Badge status="warning" />
          <span>等待</span>
        </div>
      );
    } else if (record.state === "STARTING") {
      return (
        <div>
          <Badge status="processing" />
          <span>采集中</span>
        </div>
      );
    }
    if (record.state === "SUCCESS") {
      return (
        <div>
          <Badge status="success" />
          <span>成功</span>
        </div>
      );
    } else if (record.state === "FAILED") {
      return (
        <Tooltip placement="topLeft" title={record.message} overlayInnerStyle={{fontSize:12}}>
          <Badge status="error" />
          <span>异常</span>
        </Tooltip>
      );
    } else {
      return "";
    }
  }
  render() {
    // RangePicker 改变
    const onChange = (value, dateString) => {
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
      this.setState({
        rangeDate: dateString,
      });
    };

    const Columns = [
      {
        title: "起始时间",
        dataIndex: "startTime",
        render: (text, record) => <>{text ? text : "-"}</>,
      },
      {
        title: "结束时间",
        dataIndex: "endTime",
        render: (text, record) => <>{text ? text : "-"}</>,
      },
      {
        title: "任务状态",
        dataIndex: "state",
        render: (text, record) => <>{this.getState(record)}</>,
      },
      {
        title: "日志文件大小",
        dataIndex: "fileSize",
        render: (text, record) => <>{text||text===0 ? `${text} M` : "-"}</>,
      },
      {
        title: "日志文件上传目录",
        dataIndex: "filePath",
        render: (text, record) => <>{text ? text : "-"}</>,
      },

      {
        title: "创建时间",
        dataIndex: "createTime",
        render: (text, record) => <>{text ? text : "-"}</>,
      },
      {
        title: "修改时间",
        dataIndex: "updateTime",
        render: (text, record) => <>{text ? text : "-"}</>,
      },
      //   {
      //     title: "失败原因",
      //     dataIndex: "message",
      //   },
    ];
    return (
      <>
        <div className="common-detail-container">
          <BackPage
            onClick={() => this.goback()}
            headeTitle={"日志采集任务---" + this.state.nodeName}
            itemTitle="日志采集任务"
          />
          <div style={{ background: "#fff", padding: "20px 20px" }}>
            <Button
              type="primary"
              title={
                this.state.ifDisabled ? "已有正在进行的任务，暂时不可操作" : ""
              }
              disabled={this.state.ifDisabled}
              onClick={() => this.showAddConfirm()}
            >
              新增任务
            </Button>
            <div className="common-center-container" style={{ padding: 0 }}>
              <div className="user-table-card">
                <Table
                  rowKey={(record) => record.id}
                  bordered={false}
                  columns={Columns}
                  // scroll={{ y: 300 }}
                  dataSource={this.state.logTasksData}
                  //   pagination={{ position: ["topLeft"] }}
                />
              </div>
            </div>
            <Modal
              bodyStyle={{ paddingBottom: 5, paddingLeft: 16 }}
              width={415}
              title="新增任务"
              visible={this.state.isAddVisible}
              confirmLoading={this.state.confirmLoading}
              onOk={() => this.handleOk()}
              onCancel={() => this.handleCancel()}
              okText="确定"
              cancelText="取消"
              //   footer={null}
            >
              <Form layout="vertical">
                <Form.Item
                  label="采集时间："
                  name="time"
                  required
                  //   tooltip={{
                  //     title: "默认时间范围为...",
                  //     icon: <InfoCircleOutlined style={{ fontSize: 12 }} />,
                  //   }}
                >
                  <RangePicker
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onChange}
                    // onOk={onOk}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

export default LogCollection;
