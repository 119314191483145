/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Input, Tabs, Select, message, Button, Col, Row, Table, Transfer, ConfigProvider, Modal } from 'antd';
import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { userService } from '../../../../../services/user.service';
import { roleService } from '../../../../../services/role.service';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { connect } from 'react-redux';
import { AUTCONFIG, getDisplayName } from '../../../../../../utils/auth.config';
import './edit-user.scss';
import store from '../../../../../../store';

const { Option } = Select;
const { TextArea } = Input;

const { confirm } = Modal;
const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};


class EditUser extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            showManage: true,
            canEdit: [false, false, false, false, false, false, false, false, false, false],

            userId: null,
            userObj: null,


            userCreateTime: '',
            userUpdateTime: '',
            userName: '编辑用户',
            companyList: [],

            roleLists: [], // 角色所有数据
            // organLists: [], // 企业组织所有数据
            targetRoleKeys: [], // 当前用户已包含的角色id
            // targetOrganKeys: [], // 当前用户已包含的角色id

            isRoleLVisible: false,
            // isOrganVisible: false,

            dataSourceRole: [], // 当前用户已包含的角色列表
            // dataSourceOrgan: [], // 当前用户已包含的角色列表
            type: '',
            userType: '',
            loginUserId: null
        };
    }

    goback() {
        // this.setState({
        //     canEdit: [false, false, false, false, false, false, false, false, false, false]
        // })
        // if (this.props.onSubmit) {
        //     var { showManage } = this.state;
        //     this.props.onSubmit({ showManage })
        // }
        this.props.history.push('/home/sys/user-manage')
    }
    clearPass() {
        this.formRef.current.setFieldsValue({
            password: ''
        });
    }
    getCompanyList() {
        userService.getCompanyList().then(res => {
            const getCompanyItem = (companys) => {
                return companys.map((item, index) => {
                    return (
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                    );
                });
            }
            if (res.code === 200) {
                const companys = res.companys;
                this.setState({
                    companyList: getCompanyItem(companys),
                });
            }
        }, error => {
            error && message.error(error);
        });
    }
    setPass(event) {
        const value = event.target.value;
        if (value === '') {
            this.formRef.current.setFieldsValue({
                password: '******',
            })
        }
    }
    cancel = () => {
        this.formRef.current.setFieldsValue({
            username: this.state.userObj.username,
            realName: this.state.userObj.realName,
            password: '******',
            description: this.state.userObj.description,
            mobile: this.state.userObj.mobile,
            email: this.state.userObj.email,
            company: this.state.userObj.company,
            address: this.state.userObj.address,
        });
    }

    componentDidMount() {
        this.setState({
            loginUserId: store.getState().currentUser.id
        });
        if (this.props.location.state) {
            const userId = this.props.location.state.id;
            this.setState({
                userId: userId
            });
            this.getUserDetail(userId);

            this.getCompanyList();
            sessionStorage.setItem('userId', userId);
            // this.getUserOrgan(userId);
        } else {
            if (sessionStorage.getItem('userId')) {
                const userId = sessionStorage.getItem('userId');
                this.setState({
                    userId: userId
                });
                this.getUserDetail(userId);
                //  this.getUserRole(userId);
                this.getCompanyList();
            } else {
                this.goback();
            }
        }
    }




    getUserDetail(userId) {
        userService.getUserDetail(userId).then(res => {
            if (res.code === 200) {
                const user = res.users;
                const stringName = getDisplayName(user.type);
                this.setState({
                    userObj: user,
                    userCreateTime: user.createTime,
                    userUpdateTime: user.updateTime,
                    userName: '编辑用户 -- ' + user.username,
                    type: user.type,
                    createBy: user.createBy,
                    userType: stringName
                });
                this.formRef.current.setFieldsValue({
                    username: user.username,
                    realName: user.realName,
                    description: user.description,
                    password: '******',
                    mobile: user.mobile,
                    email: user.email,
                    companyId: user.company?.id,
                    address: user.address,
                });
            } 
            // else {
            //     message.error('获取用户详情失败');
            // }
        }, error => {
            error && message.error(error);
        });
    }

    render() {
        const onFinish = (values) => {
            let password = '';
            if (values.password !== '******') {
                password = values.password;
            }
            userService.editUser(this.state.userId,
                values.username,
                values.realName,
                password,
                values.mobile,
                values.email,
                values.description,
                values.companyId,
                values.address).then(res => {
                    if (res.code === 200) {
                        message.success('修改成功');
                        this.goback();
                    } 
                    // else {
                    //     message.error('修改失败，' + res.message);
                    // }
                }, error => {
                    error && message.error(error);
                });
        };

        // const columnsOrgan = [
        //     {
        //         title: '企业组织',
        //         dataIndex: 'name',
        //         key: 'name'
        //     },
        //     {
        //         title: '描述',
        //         dataIndex: 'description',
        //         key: 'description'
        //     },
        //     {
        //         title: '关联时间',
        //         dataIndex: 'createTime',
        //         key: 'createTime'
        //     },
        //     {
        //         title: '操作',
        //         dataIndex: 'time',
        //         key: 'time',
        //         render: (text, record) => <a href onClick={() => this.removeOrganConnection(record.id)}>移除</a>
        //     }
        // ];
        return (
            <>
                <div className="common-pd20">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.userName} itemTitle="用户管理" itemSubTitle="查看用户" />
                    <div className="common-create-center-container">
                        <div className="common-create-page">
                            <Form name="basic"
                                ref={this.formRef}
                                name="validate_other"
                                labelAlign="left"
                                {...formItemLayout}
                                onFinish={onFinish} >

                                <Form.Item name="username" label="账号名" rules={[
                                    { required: true, message: '账号名不能为空' },
                                    { max: 64, message: '账号名不允许超过64个字符' },
                                    { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g"), message: '请输入64位以内中英文/数字/中划线/下划线' }]} >
                                    <Input placeholder="请输入账号名" />
                                </Form.Item>

                                <Form.Item name="realName" label="用户名称" rules={[
                                    { required: true, message: '用户名称不能为空' },
                                    { max: 64, message: '用户名称不允许超过64个字符' },
                                    { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g"), message: '请输入64位以内中英文/数字/中划线/下划线' }]} >
                                    <Input placeholder="请输入用户名称" />
                                </Form.Item>

                                <Form.Item name="password" label="密码" rules={[{ required: false, message: '密码不能为空' },
                                //   { pattern: /^(?![a-z]+$)(?![A-Z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]{6,23}$/, 
                                {
                                    pattern: /((?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{6,23})|(^[*]{6}$)$/,
                                    message: '6-23位，至少包含以下两种：大写字母、小写字母、数字、特殊字符，且不能输入中文与空格'
                                }]} >
                                    <Input disabled={this.state.userId == this.state.loginUserId} type="password" onFocus={() => this.clearPass()} onBlur={(value) => this.setPass(value)} placeholder="请输入密码" />
                                </Form.Item>

                                <Form.Item name="mobile" label="手机号码" rules={[{ required: true, message: '手机号码不能为空' }, { pattern: /^[1][3,4,5,7,8][0-9]{9}$/, message: '请输入正确的手机号' }]}>
                                    <Input placeholder="请输入手机号码" />
                                </Form.Item>

                                <Form.Item name="email" label="电子邮箱" rules={[{ type: 'email', message: '请输入正确的电子邮箱' }]}>
                                    <Input placeholder="请输入电子邮箱" />
                                </Form.Item>

                                {/* 只有主用户可以 编辑企业 并且登录用户为管理员和超级用户 */}
                                {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value
                                    || this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value)
                                    && this.state.userObj?.type === AUTCONFIG.MAIN.value ?

                                    <Form.Item name="companyId" label="企业" rules={[{ required: true, message: '请选择企业名称' }]}>
                                        <Select style={{ width: 317 }} placeholder="请选择企业" showSearch allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.companyList}
                                        </Select>
                                    </Form.Item>
                                    : ''}


                                <Form.Item name="address" label="联系地址" >
                                    <Input placeholder="请输入联系地址" />
                                </Form.Item>

                                <Form.Item name="description" label="描述" >
                                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                                </Form.Item>
                                {this.props.currentUser.type === AUTCONFIG.SUPERADMIN.value ?

                                    <Form.Item label="用户类型"  >
                                        {this.state.userType || '--'}
                                    </Form.Item>
                                    : ''}
                                {/* 登录用户类型为主用户时，只可以查看 */}
                                {/* 用户类型为子用户时，只可以查看 */}
                                {(this.props.currentUser?.type === AUTCONFIG.MAIN.value ||
                                    this.state.userObj?.type === AUTCONFIG.SUB.value) ?

                                    <Form.Item label="企业" >
                                        {this.state.userObj?.company?.name}
                                    </Form.Item>
                                    : ''}

                                <Form.Item label="创建时间" >
                                    {this.state.userCreateTime}
                                </Form.Item>


                                <Form.Item label="更新时间"  >
                                    {this.state.userUpdateTime || '暂无数据'}
                                </Form.Item>

                                <BottomBtnGroup onClick={() => this.goback()} />
                            </Form>
                        </div>

                        {/* {this.state.type === 'SUB' || (this.state.type === 'SYS' &&this.state.createBy)? 

                    <div className="common-trans-card">
                     
                        <Row style={{ marginBottom: 20 }}>
                            <Button type="primary" onClick={this.addTransRole}> 添加 </Button>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Table style={{ marginBottom: 20 }} pagination={false} columns={columnsRole} dataSource={this.state.dataSourceRole} />
                            </Col>
                        </Row>
                        
                    </div>  : '' } */}
                    </div>
                </div>


            </>
        );
    }
}

// export default ;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(EditUser);