import React from 'react';
import { Form, Input, Select, message, Row, Col, InputNumber, Card , Divider, Button, Table, Modal, Badge } from 'antd';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { algorithmService } from '../../../../../services/algorithm-manage';
import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};

class CreatAlgorithmMangement extends React.Component{
    formRef = React.createRef();
    constructor(props) {
    super(props);
    this.state = {
      title: '新增算法',
      algorithmLabels: [],
    };

    }
    componentDidMount() {
        this.getAlgorithmLabels()
    }
    
    //获取算法标签
    getAlgorithmLabels(){
        const getLableItem = (labels) => {
          return labels.map((item, index) => {
            return (
              <Option key={item.labelName} >{item.labelName}</Option>
            );
          });
        }

        algorithmService.getAlgorithmLabels('',100,'').then(res => {
          if (res.code === 200) {
            console.log(res);
            this.setState({
              algorithmLabels: getLableItem(res.labels),
            })
          }
      }).catch(error => {
        error && message.error(error);
      })
    }
    
    
    goback() {
        this.props.history.push('/home/algorithm-manage');
    }

    render() {
        const onFinish = (values) => {
            console.log(values);
            algorithmService.addAlgorithmNamespaces(values.algorithmName, values.label, values.description).then(res => {
              message.destroy();
            if (res.code === 200) {
              message.success('创建算法成功');
              this.goback();
            } 
            // else {
            //   message.success('创建算法失败，' + res.message);
            // }
          }, error => {
            message.destroy();
            error && message.error(error);
          });
        };

        return (
        <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle={this.state.title} itemTitle="算法" itemSubTitle="算法管理" />
        <div className="common-create-center-container">
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish}>
              <Form.Item name="algorithmName" label="算法名称"
               rules={[
                { required: true, message: '请输入算法名称' },
                { max: 64, message: '算法名称不允许超过64个字符'}, 
                { pattern: new RegExp(/^[0-9a-zA-Z-_\s\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线/空格'}]}  >
                <Input placeholder="请输入算法名称" />
              </Form.Item>
              
              <Form.Item name="label" label="算法标签" >
                <Select   allowClear style={{ width: '200px', }} placeholder="请选择算法标签" showArrow>
                  {this.state.algorithmLabels}
                </Select>
              </Form.Item>

              <Form.Item name="description" label="描述" >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255}/>
              </Form.Item>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
        </div>
      </div>
        )
    }
}

export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(CreatAlgorithmMangement);