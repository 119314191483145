import React from 'react';
import { Form, Input, message, Select } from 'antd';
import { otaService } from '../../../../services/ota.service';

import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { AUTCONFIG } from '../../../../../utils/auth.config';

import { userService } from '../../../../services/user.service';
import { connect } from "react-redux";
const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class CreateOta extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true,
      adminList: []
    };

  }
  componentDidMount() {
    setTimeout(() => {
      this.formRef.current?.setFieldsValue({ adminId: this.props.currentUser?.id });
    }, 1200)
    this.getUserList();
  }
  goback() {
    this.formRef.current.resetFields();
    if (this.props.onSubmit) {
      var { showManage } = this.state;
      this.props.onSubmit({ showManage })
    }
    this.props.history.push('/home/ota-factory');
  }
  getUserList() {
    userService.getAllUsers().then(item => {
      
        const adminList = item.users.filter(elx => {
            return elx.type === AUTCONFIG.SUPERADMIN.value 
                  || elx.type === AUTCONFIG.ADMIN.value;
        })
        this.setState({
            adminList
        })
       
    })
}
  render() {
    const onFinish = (values) => {
      otaService.addOta(values.name, values.model, values.description, values.adminId).then(res => {
        if (res.code === 200) {
          message.success('创建产品成功');
          this.goback();
        } 
        // else {
        //   message.success('创建产品失败，' + res.message);
        // }
      }, error => {
        error && message.error( error);
      });
    };
    return (
      <>
      <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="创建产品" itemTitle="产品" itemSubTitle="创建产品" />
        <div className="common-create-center-container">
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish}>
              <Form.Item name="name" label="产品名称" rules={[
                { required: true, message: '请输入产品名称' },
                { pattern: /^[\u0391-\uFFE5\w_-]{0,64}$/, message: '请输入64位以内中英文/数字/中划线/下划线' }]}  >
                <Input placeholder="请输入产品名称" />
              </Form.Item>
              <Form.Item name="model" label="产品型号" rules={[
                { required: true, message: '请输入产品型号' },
                { pattern: /^[[\u0391-\uFFE5\w_-]{0,64}$/, message: '请输入64位以内中英文/数字/中划线/下划线' }]}  >
                <Input placeholder="请输入产品型号" />
              </Form.Item>
              {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?
                            <Form.Item name='adminId' label='管理员' >
                                <Select
                                    showSearch  allowClear
                                    filterOption={(input, option) =>
                                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="请选择管理员" >
                                        {
                                            this.state.adminList.map((item, index) => {
                                                return (
                                                <Option key={item.id} value={item.id}>{item.realName}/{item.username}</Option>
                                                );
                                            })
                                        }
                                
                                </Select>
                            </Form.Item> : ''}
              <Form.Item name="description" label="描述" >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255}/>
              </Form.Item>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
        </div>
      </div>
      </>
    );
  }
}



// export default CreateOta;


export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(CreateOta);