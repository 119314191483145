/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Select, message, Row, Col, ConfigProvider, Divider, Pagination, Button, Table, Modal, Badge } from 'antd';
import '../../../../../index.scss';
import zhCN from 'antd/lib/locale/zh_CN';
import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { terminalService } from '../../../../services/terminal-equ';
import { nodeService } from '../../../../services/node.service'
import { connect } from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

let flag = false;

let page = 1;
let pageSize = 10;
let dataCount = null;
let searchNodeName = ''
let searchLabel = [];
let nodeList = [];
let nodeActions = [];

function NodeModal(props) {

  //const [form] = Form.useForm();
  const form = React.createRef();
  const nodeColumns = [
    {
      title: '节点名称',
      dataIndex: 'name',
      key: 'name',
      // render: (text, record) => (
      //   ddddd(record)
      // )
    },
    {
      title: 'epack版本',
      dataIndex: 'firmwareVersion',
      key: 'firmwareVersion',
    },
    {
      title: '产品型号',
      dataIndex: 'productModel',
      key: 'productModel',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const ddddd = (record) => {
    console.log(record);
  }

  const onFinish = (values) => {
    const str = values?.searchNodeName?.split("\\")
    //console.log(...str);
    let newStr = ''
    for (let i = 0; i < str?.length; i++) {
      newStr += str[i]
    }

    searchNodeName = newStr;
    searchLabel = values.label || [];
    console.log(searchNodeName);
    page = 1;
    props.onClick();
  };
  const resetForm = () => {
    //form.resetFields();
    form.current.resetFields();
    searchNodeName = '';
    searchLabel = [];
    nodeActions = [];
    flag = true;
    page = 1;
    pageSize = 10;
    props.reform();
  }

  //对象变量，用来保存对行数据的选中
  const rowSelection = {
    selectedRowKeys: props.selectedNodeIds,

    onChange: (selectedRowKeys, selectedRows) => {
      nodeActions = selectedRows;
      console.log(nodeActions);
      props.changeSelected();
    },
  };

  const onPageChange = (pageNumber) => {

    page = pageNumber;

    props.onClick();
  }
  const onShowSizeChange = (current, pageSizeNumber) => {
    pageSize = pageSizeNumber;
    page = 1;

    props.onClick();
  }
  // const tagRender = (props) => {
  //   const { label, value, closable, onClose } = props;
  //   console.log(props)
  //   const onPreventMouseDown = event => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   };
  //   return (
  //     <Tag
  //       color='blue'
  //       onMouseDown={onPreventMouseDown}
  //       closable={closable}
  //       onClose={onClose}
  //       closeIcon= {
  //       (
  //         <CloseCircleOutlined style={{background: '#0070CC', borderRadius:'50%', color: 'white'}} />
  //       )
  //       }
  //       style={{ marginRight: 3 }}
  //     >
  //       {label}
  //     </Tag>
  //   );
  // }
  return (
    <>
      <div className="common-search-form">
        <Form
          //form={form}
          ref={form}
          name="advanced_search"
          layout="inline"
          className="ant-advanced-search-form"
          style={{ justifyContent: 'center', marginBottom: 12 }}
          onFinish={onFinish} >

          <Form.Item name='searchNodeName' label='节点名称' style={{ marginTop: 0 }}>
            <Input placeholder="请输入关键字" />
          </Form.Item>

          {/* <Form.Item label='标签' name="label" style={{ marginTop: 0 }}>
                <Select   mode="multiple" allowClear style={{width: 200}}  placeholder="请选择标签"  showArrow>
                {props.labelList}
                </Select>
            </Form.Item> */}
          <Form.Item style={{ marginTop: 0 }}>
            <Button type="primary" htmlType="submit"> 查询 </Button>
            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
          </Form.Item>

        </Form>
      </div>
      <div>
        <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        rowKey={record => record.id}
        columns={nodeColumns}
        dataSource={nodeList}
        pagination={false}
        scroll={{ y: 300 }}
        />

            {
              nodeList.length > 0 &&
              <div className="common-bottom-pagination" style={{ marginTop: '10px' }}>
                <ConfigProvider locale={zhCN}>
                  <Pagination
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={1}
                    current={page}
                    pageSize={pageSize}
                    total={dataCount}
                    onChange={onPageChange} />
                </ConfigProvider>
              </div>
            }
        
      </div>
      
    </>
  );
}


class CreateTerminalEquManagement extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      nodeList: [],
      selectedNodeIds: [],//选择的节点
      chooseNodeList: [],//展示选择的节点信息
      uuidList: [],//节点 1
      nodeVisible: false,
      hasSubmit: false
    };
  }

  //弹窗添加节点
  addNode() {
    const { chooseNodeList } = this.state;
    const newArr1 = [...chooseNodeList, ...nodeActions]
    if (newArr1.length > 1) {
      message.error("只能部署一个节点！")
    } else {
      this.setState({
        nodeVisible: true,
        selectedNodeIds: []
      })
      // page = 1;
      // pageSize = 10;
      // searchNodeName = '';
      // this.getSearchNodeList()
    }
  }

  //弹窗的取消设置
  handleOk(e) {
    console.log(e);
    this.setState({
      nodeVisible: false,
    })
  }

  //节点部署成功
  handleNodeOk = () => {
    //const arr = [...this.state.chooseNodeList, ...nodeActions]
    //const newArr = Array.from(new Set(arr))

    const { chooseNodeList } = this.state;

    // const newArr1 = chooseNodeList.filter(item => {
    //   let arrlist = nodeActions.map(item2 => item2.id)
    //   return !arrlist.includes(item.id)
    // })
    // const newArr = [...newArr1, ...nodeActions]

    const newArr1 = [...chooseNodeList, ...nodeActions]
    if (newArr1.length > 1) {
      message.error("只能部署一个节点！")
    } else {
      // const newArr = this.deteleObject(newArr1)

      let arr1 = [...chooseNodeList]
      let arr2 = [...nodeActions]
      let filter = arr1.filter(item => !arr2.some(ele => ele.id === item.id))
      const newArr = [...filter, ...arr2]

      console.log("chooseNodeList的值为", newArr);

      const newUuidList = newArr.map(item => item.nodeUuid)
      console.log("添加后的节点集合", newUuidList);

      this.setState({
        chooseNodeList: newArr,
        uuidList: newUuidList,
      })
      this.handleOk();
    }

  }

  //保存删除后的节点数据
  deleteNode = (text, record) => {
    console.log("要删除的信息", record);
    const { chooseNodeList } = this.state
    const newChooseNodeList = chooseNodeList.filter(todoNode => {
      return !(todoNode.name === record.name)
    })
    console.log(newChooseNodeList);

    const newUuidList = newChooseNodeList.map(item => item.nodeUuid)
    console.log("删除后的节点集合", newUuidList);
    this.setState({
      chooseNodeList: newChooseNodeList,
      uuidList: newUuidList,
    })
  }

  goback() {

    this.props.history.push('/home/terminal-equ/list');
  }
  onSelectTypeChange(value) {
    console.log(value)
  }
  componentDidMount() {
    this.getSearchNodeList()
  }

  changeSelected() {
    const selectedNodeIds = [];
    nodeActions.forEach(ele => {
      selectedNodeIds.push(ele.id)
    });
    this.setState({
      selectedNodeIds: selectedNodeIds,
    })

  }

  //获取节点列表信息
  getSearchNodeList() {
    if (flag) {
      this.setState({
        selectedNodeIds: [],
      })
      flag = false
    }
    console.log(page,pageSize);
    nodeService.getNodeList(searchNodeName, '', '', '', '', '', '', '', '', '', pageSize, page).then(res => {
      if (res.code === 200) {
        console.log("查找的节点信息为:", res);
        nodeList = res.nodes;
        dataCount = res.dataCount;
        this.setState({
          nodes: res.nodes,
        })
      }
    })
  }

  //创建设备接口
  addDevice(deviceName, aliasName, nodeUuid, deviceType, rtspUrl, deviceInterface) {
    terminalService.addDevice(deviceName, aliasName, nodeUuid, deviceType, rtspUrl, deviceInterface).then(res => {
      if (res.code === 200) {
        message.success("创建成功！")
        this.goback();
      }
    }).catch(error => {
      error && message.error(error);
    })
  }


  //状态匹配
  getState(record) {
    if (record.state === 'UNCONNECTED') {
      return <span><Badge status="warning" />未纳管</span>
    }
    else if (record.state === 'RUNNING') {
      return <span><Badge status="success" />运行中</span>
    }
    else if (record.state === 'UPGRADEWAITING') {
      return <span><Badge status="warning" />待升级</span>
    }
    else if (record.state === 'UPGRADING') {
      return <span><Badge status="warning" />升级中</span>
    }
    else if (record.state === 'DISABLED') {
      return <span><Badge status="error" />禁用</span>
    }
    else if (record.state === 'OFFLINE') {
      return <span><Badge status="error" />不在线</span>
    }
    else if (record.state === 'DELETED') {
      return <span><Badge status="default" />已删除</span>
    }
    else {
      return '';
    }
  }

  render() {
    const onFinish = (values) => {
      console.log(values);
      if (this.state.uuidList.length === 0) {
        message.error("请选择节点")
      } else {
        this.addDevice(values.aliasName, ...this.state.uuidList, values.deviceType, values.rtspUrl)
        //this.goback();
      }

    };

    //部署节点的列
    const columsOne = [
      {
        title: '节点名称',
        dataIndex: 'name',
      },
      {
        title: '描述',
        dataIndex: 'description',
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (text, record) => (
          this.getState(record)
        )
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => {
          return (
            <span>

              <a onClick={() => this.deleteNode(text, record)}>删除</a>
            </span>
          )
        }
      }
    ]
    return (
      <>
        <div className="common-pd20">
          <BackPage onClick={() => this.goback()} headeTitle="注册设备" itemTitle="设备管理" itemSubTitle="注册设备" />
          <div className="common-create-center-container">

            <div className="common-create-page">


              <Form
                ref={this.formRef}
                name="validate_other"
                labelAlign="left"
                {...formItemLayout}
                onFinish={onFinish} >
                {/* <Form.Item name="deviceName" label="设备名称" rules={[{ required: true, message: '请输入设备名称' }]} >
                  <Input placeholder="请输入设备名称" />
                </Form.Item> */}
                <Form.Item name="aliasName" label="设备别名" rules={[{ required: true, message: '请输入设备别名' }]}>
                  <Input placeholder="请输入设备别名" />
                </Form.Item>
                <Form.Item name='deviceType' label='设备类型' rules={[{ required: true, message: '请选择设备类型' }]} style={{ marginTop: '8px' }}>
                  <Select allowClear
                    placeholder="请选择设备类型" >
                    <Option value="0">视频摄像头</Option>
                    {/* <Option value="1">音频</Option>
                    <Option value="2">屏幕</Option>
                    <Option value="3">麦克风</Option>
                    <Option value="4">其他</Option> */}
                  </Select>
                </Form.Item>
                <Form.Item name="rtspUrl" label="RTSP地址" rules={[{ required: true, message: '请输入RTSP地址' }]}>
                  <Input placeholder="请输入RTSP地址" />
                </Form.Item>

                <BottomBtnGroup onClick={() => this.goback()} />

              </Form>


              <div className="common-create-page">
                <span >* 所属节点 :</span>
                <Button onClick={() => this.addNode()} type="primary" style={{ fontSize: 13, marginBottom: 20, marginLeft: 20 }}>选择节点</Button>
                <Table columns={columsOne}  //列
                  rowKey={record => record.id}
                  dataSource={this.state.chooseNodeList}  //行数据
                  pagination={false}   //不展示分页
                  scroll={{ y: 200 }}  //滚动
                //bordered={true}
                />

              </div>

            </div>
          </div>

          {/* 弹窗 */}
          <Modal width={800} title="选择节点" visible={this.state.nodeVisible} onOk={() => this.handleNodeOk()} onCancel={() => this.handleOk()} okText="确定" cancelText="取消">
            <NodeModal changeSelected={() => this.changeSelected()}
              selectedNodeIds={this.state.selectedNodeIds}
              chooseNodeList={this.state.chooseNodeList} onClick={() => this.getSearchNodeList()} reform={() => this.getSearchNodeList()} />
          </Modal>
        </div>
      </>
    );
  }
}

//export default CreateUser;


export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(CreateTerminalEquManagement);