import React from 'react';

import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import { nodeService } from '../../../../../services/node.service';
import { labelService } from '../../../../../services/label.service';


import './../../../../../../index.scss';
import CreateApplication from '../../../application/create-application/create-application';

const { confirm } = Modal;

let searchName = '';
let searchUuid = '';


//select
const { Option } = Select;

function Search(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    searchName = values.name;
    searchUuid = values.uuid;
    page = 1;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchName = '';
    searchUuid = '';
    page = 1;
    props.onClick();
  };
  return (
    <>
      <div className="common-search-form">
      <Form
          form={form}
          name="advanced_search"
          layout="inline"
          style={{justifyContent:"center"}}
          className="ant-advanced-search-form"
          onFinish={onFinish} >
         
            <Form.Item name='name' label='应用名称' style={{ marginTop:'8px' }}>
              <Input placeholder="请输入应用名称" style={{ width: '240px' }} />
            </Form.Item>
            <Form.Item name='name' label='应用状态' style={{ marginTop:'8px' }}>
              <Select style={{ width: '150px' }} placeholder="请选择应用状态" >
                {/* <Option value="jack">Jack</Option> */}
              </Select>
            </Form.Item>
            <Form.Item  style={{ marginTop:'8px' }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </Form.Item>
        
        </Form>
      </div>
    </>
  );
}
let page = 1;
let pageSize = 10;
function onChange(value) {
  console.log('changed', value);
}
class Application extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      nodeList: [],
      nodeAmount: 0,
      deleteIds: [],
      showUpgrade: false,
      showQuota: false,
      history: require("history").createHashHistory,
    };
  }

  /* 升级 */
  showUpgrade = (id) => {
    this.setState({
      showUpgrade: true,
    });
  };

  hideModalUpgrade = () => {
    this.setState({
      showUpgrade: false,
    });
  };
  /* 配额 */
  showQuota = (id) => {
    this.setState({
      showQuota: true,
    });
  };

  hideModalQuota = () => {
    this.setState({
      showQuota: false,
    });
  };

  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        nodeService.removeNodes(event).then(res => {
          if (res.code === 200) {
            message.success('删除成功');
            this.searchList();
          } 
          // else {
          //   message.error('删除失败，' + res.message);
          // }
        }, error => {
          message.error('删除失败');
        })
      },
      onCancel() {

      },
    });
  }
  showMultiDeleteConfirm() {
    if (this.state.deleteIds.length === 0) {
      message.warning('请先选择要删除的节点');
      return;
    }
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        // nodeService.removeNodes(this.state.deleteIds).then(res => {
        //   if (res.code === 200) {
        message.success('删除成功');
        //     this.searchList();
        //   } else {
        //     message.error('删除失败，' + res.message);
        //   }
        // }, error => {
        //   message.error('删除失败');
        // })
      },
      onCancel() {

      },
    });
  }

  searchList() {
    // this.setState({
    //   nodeList: [{ id: 1, name: 'node1', description: '描述', type: 'type', createTime: '2021-04-30 09:36' }],
    //   nodeAmount: 1
    // });
    // nodeService.getNodeList(searchName, searchUuid, '', pageSize, page).then(res => {
    //   if (res.code === 200) {
    //     const nodes = res.nodes;
    //     nodes.forEach(element => {
    //       element.key = element.id;
    //     });
    //     this.setState({
    //       nodeList: nodes,
    //       nodeAmount: res.dataCount
    //     });
    //   } else {
    //     message.error('获取节点列表失败，' + res.message);
    //   }
    // }, error => {
    //   message.error('获取节点列表失败，' + error);
    // });
  }
  componentDidMount() {
    this.searchList();
  }
  getLableItem(labels) {
    return labels.map((item, index) => {
      return (
        <Option key={item.id} >{item.name}</Option>
      );
    });
  }

  showEnaConfirm(event) {
    confirm({
      title: '确定启用吗?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        nodeService.startNodes(event).then(res => {
          if (res.code === 200) {
            message.success('启用应用成功');
            this.searchList();
          }
          //  else {
          //   message.error('启用应用失败，' + res.message);
          // }
        }, error => {
          message.error('启用应用失败，' + error);
        });
      },
      onCancel() {
      },
    });
  }
  showDisConfirm(event) {
    confirm({
      title: '确定停止吗?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        nodeService.stopNodes(event).then(res => {
          if (res.code === 200) {
            this.searchList();
            message.success('停止应用成功');
          } 
          // else {
          //   message.error('停止应用失败，' + res.message);
          // }
        }, error => {
          message.error('停止应用失败，' + error);
        });
      },
      onCancel() {
      },
    });
  }



  getState(record) {
    if (record.state === 'UNCONNECTED') {
      return <div><div className="common-green"></div> <span>未纳管</span></div>
    } else if (record.state === 'RUNNING') {
      return <div><div className="common-green"></div> <span>运行中</span></div>
    } else if (record.state === 'UPGRADING') {
      return <div><div className="common-blue"></div> <span>升级中</span></div>
    } else if (record.state === 'OVERTIME') {
      return <div><div className="common-purple"></div> <span>超时</span></div>
    } else if (record.state === 'FAILED') {
      return <div><div className="common-yellow"></div> <span>异常</span></div>
    } else if (record.state === 'DISABLED') {
      return <div><div className="common-gray"></div> <span>禁用</span></div>
    } else if (record.state === 'WAITING') {
      return <div><div className="common-yellow"></div> <span>操作中</span></div>
    } else {
      return '';
    }
  }

  gotoDetail = (text, key) => {
    this.props.history.push({ pathname: '/home/node-manage/application/view-application', state: { id: text } });
  }
  render() {
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.searchList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    }
    const registered = () => {
      this.props.history.push({ pathname: '/home/node-manage/application/create-application' });
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          deleteIds: selectedRowKeys
        })
      }
    };
    const columns = [
      {
        title: '应用名称',
        dataIndex: 'name',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
      },
      {
        title: '描述',
        dataIndex: 'description',
        width: '15%'
      },
      {
        title: '资源配额',
        dataIndex: 'instanceNum',
      },
      {
        title: '实例',
        dataIndex: 'deviceNum',
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (text, record) => (
          <>
            {
              this.getState(record)
            }
          </>
        ),
      },
      {
        title: '运行时长',
        dataIndex: 'createBy',
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => (
          <>
            {/*  {
              record.type === '1' ?
                <a href onClick={() => this.showEnaConfirm(record.id)} style={{ marginRight: '5px' }}>启用</a>
                :
                <a href onClick={() => this.showDisConfirm(record.id)} style={{ marginRight: '5px' }}>禁用</a>
            } */}
            <a href onClick={() => this.showEnaConfirm(record.id)} style={{ marginRight: '5px' }}>启用</a>

            <a href onClick={() => this.showDisConfirm(record.id)} style={{ marginRight: '5px' }}>停止</a>

            <a href onClick={() => this.showDeleteConfirm(record.id)} style={{ marginRight: '5px' }}>删除</a>

            <a href onClick={() => this.showUpgrade(record.id)} style={{ marginRight: '5px' }}>升级</a>

            <a href onClick={() => this.showQuota(record.id)} style={{ marginRight: '5px' }}>调整资源</a>
          </>
        ),
      },
    ];

    return (
      <>
        <div>
          <div>
            <div className="common-center-container" style={{ padding: 0 }}>
              <Search onClick={() => this.searchList()} />
              <div className="oper-button">

                <Button type="primary" onClick={registered}>  创建  </Button>

                <Button style={{ marginLeft: 10 }} onClick={() => this.showMultiDeleteConfirm()}> 删除 </Button>
              </div>
              <div className="user-table-card">
                <Table
                  rowSelection={{
                    ...rowSelection,
                  }}
                  rowKey={record => record.id}
                  bordered={false}
                  pagination={false}
                  columns={columns}
                  dataSource={this.state.nodeList}
                />
              </div>
              <div>
                {
                  this.state.nodeList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination showSizeChanger onShowSizeChange={onShowSizeChange} defaultCurrent={1} current={page} pageSize={pageSize} total={this.state.nodeAmount} onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>


        </div>

        {/* 升级 */}
        <Modal
          title="应用升级"
          visible={this.state.showUpgrade}
          onOk={this.hideModalUpgrade}
          onCancel={this.hideModalUpgrade}
          okText="确定"
          cancelText="取消"
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            name="form_in_modal"
          >
            <Form.Item
              name="title"
              label="应用镜像"
            >
              <Select placeholder="">
                <Option value="china">China</Option>
                <Option value="usa">U.S.A</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="title"
              label="镜像版本"
            >
              <Select placeholder="">
                <Option value="china">China</Option>
                <Option value="usa">U.S.A</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        {/* 配额 */}
        <Modal
          title="调整应用配额"
          visible={this.state.showQuota}
          onOk={this.hideModalQuota}
          onCancel={this.hideModalQuota}
          okText="确定"
          cancelText="取消"
        >
          <Form
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            name="form_in_modal"
          >
            <Form.Item
              name="title"
              label="CPU"
            >
              <InputNumber min={0} max={10} onChange={onChange} />
            </Form.Item>
            <Form.Item
              name="title"
              label="GPU"
            >
              <InputNumber min={0} max={10} onChange={onChange} />
            </Form.Item>
            <Form.Item
              name="title"
              label="内存"
            >
              <InputNumber min={0} max={10} onChange={onChange} />
            </Form.Item>
          </Form>
        </Modal>

      </>
    );
  }
}
export default Application;