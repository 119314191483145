import { Form, Input, Col, Row, message, Select, DatePicker, Button, Table, Modal, Spin } from 'antd';

import locale from 'antd/es/date-picker/locale/zh_CN';

import React from 'react';
import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { jobService } from '../../../../services/job.service';
import { nodeService } from '../../../../services/node.service';
import { labelService } from '../../../../services/label.service';
import { otaService } from '../../../../services/ota.service';
import { firmwareService } from '../../../../services/firmware.service';
import { AUTCONFIG } from '../../../../../utils/auth.config';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { connect }  from 'react-redux'
import { getJobIdAction } from '../../../../../store/actionCreators';
import store from '../../../../../store';

const { TextArea } = Input;
const { Option } = Select;


const formItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4},
    wrapperCol: { xs: 24, sm: 12, md: 15},
};

class ViewJob extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      showManage: true,

      jobId: null,
      jobObj: {},

      loading: true,
      jobCreateTime: '',
      jobUpdateTime: '',
      jobTitle: '查看任务',
      jobState: '',
      jobExecuteTime: '',
      jobFirmwareId: '',
      jobFirmwareVersion: '',
      description: '',
      isNodeVisible: false,
      username: '',
      realName: '',
      tenantName: '',
      labelList: [],
      otaList: [],
      chooseNodeIds: [],
      otaDefaultSelectProductObj: null,

      selectedNodeIds: [],
      selectedFirId: [],
      name: '',
      code: '',
      description: '',
    };
  }

 



  goback() {
    // if (this.props.onSubmit) {
    //   var { showManage } = this.state;
    //   this.props.onSubmit({ showManage })
    // }
    this.props.history.push('/home/batch-job');
  }



  componentDidMount() {


    if (this.props.location.state) {
      const jobId = this.props.location.state.id;
      if (jobId) {
        const action = getJobIdAction(jobId);
        store.dispatch(action);
        this.setState({
          jobId: jobId
        });
        this.getJobDetail(jobId);
      }
    } else {
      const jobId = store.getState().jobId;
      if (jobId) {
        this.setState({
          jobId: jobId
        });
        this.getJobDetail(jobId);
      }
    }
  }


  getJobDetail(jobId) {
    jobService.getJobDetail(jobId).then(res => {
        
      if (res.code === 200) {
        const job = res.upgradeTask;
        const nodeIds = [];
        if (job.nodes) {
          job.nodes.forEach(ele => {
            nodeIds.push(ele.id)
          })
        }
        const selectedNodeIds = [];
        job.nodes.forEach(ele => {
          selectedNodeIds.push(ele.id)
        })
        const selectedFirId = [];
        selectedFirId[0] = job.nodeFirmwareId;
        const executeTime =  moment(job.executeTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        this.setState({
       
          jobCreateTime: job.createTime,
          jobUpdateTime: job.updateTime,
          jobTitle: '查看任务 -- ' + job.name,
          jobState: job.state,
          jobFirmwareId: job.nodeFirmwareId,
          jobFirmwareVersion: job.firmwareVersion,
          chooseNodeList: job.nodes,
          jobExecuteTime: executeTime,
          chooseFirmwareList: [{ id: job.nodeFirmwareId }],
          selectedNodeIds: selectedNodeIds,
          selectedFirId: selectedFirId,
          description: job.description || '',
          loading: false,
          username: job.username,
          realName: job.realName,
          tenantName: job.tenantName,
          name: job.name,
          code: job.code,
          description: job.description,
        });


        // this.formRef.current.setFieldsValue({
        //   name: job.name || '',
        //   description: job.description || '',
        // });
      } 
      // else {
      //   message.error('获取任务详情失败,' + res.message);
      // }
    }, error => {
      error && message.error(error);
    });
  }


 

  getState(state) {
    if (state === 'WAITING') {
      return <div><div className="common-yellow"></div> <span>待升级</span></div>
    } else if (state === 'UPGRADING') {
      return <div><div className="common-yellow"></div> <span>升级中</span></div>
    } else if (state === 'SUCCESS') {
      return <div><div className="common-green"></div> <span>升级成功</span></div>
    } else if (state === 'PARTIAL') {
      return <div><div className="common-purple"></div> <span>部分成功</span></div>
    } else if (state === 'FAILED') {
      return <div><div className="common-red"></div> <span>升级失败</span></div>
    } else if (state === 'EXCEPTION'){
      return <div><div className="common-yellow"></div> <span>异常</span></div>;
    } else {
        return '';
      }
  }
  getNodeState(record) {
    // f (record.state === 'WAITING') {
    //     return <div><div className="common-yellow"></div> <span>待升级</span></div>
    //   } 
   if (record.taskState === 'SUCCESS') {
      return <div><div className="common-green"></div> <span>成功</span></div>
    } if (record.taskState === 'WAITING') {
        return <div><div className="common-blue"></div> <span>待升级</span></div>
    } else if (record.taskState === 'UPGRADING') {
      return <div><div className="common-yellow"></div> <span>升级中</span></div>
    }  else if (record.taskState === 'FAILED') {
      return <div><div className="common-red"></div> <span>失败</span></div>
    }  else {
      return '';
    }
  }
  render() {
    const nodeListColumns = [
      {
        title: '节点名称',
        dataIndex: 'name',
        key: 'name',
        render: text => <>{text}</>,
      },
      {
        title: '产品型号',
        dataIndex: 'productModel',
        key: 'productModel',
      },
      {
        title: '目标版本',
        dataIndex: 'firmwareVersion',
        key: 'firmwareVersion',
        render: () => this.state.jobFirmwareVersion
      },
      {
        title: '升级前版本',
        dataIndex: 'firmwareVersion',
        key: 'firmwareVersion',
      },
      {
        title: '升级状态',
        dataIndex: 'taskState',
        key: 'taskState',
        render: (text, record) => (
          <>
              {
                this.getNodeState(record)
              }
           
          </>
          ),
      },
      {
        title: '失败原因',
        dataIndex: 'taskReason',
        key: 'taskReason',
      },
    
    ];


    return (
      <>
      <Spin spinning={this.state.loading}>
        <div className="common-detail-container">
          <BackPage onClick={() => this.goback()} headeTitle={this.state.jobTitle} itemTitle="批量升级" itemSubTitle="查看任务" />
          <div className="common-edit-card">
            <Form name="basic"
          
              labelAlign="left"
              ref={this.formRef}
              {...formItemLayout} >
              <Row>
                <Col span={12}>
                  <Form.Item label="状态" >
                    {/* {this.state.jobState} */}
                    {
                      this.getState(this.state.jobState)
                    }
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="name" label="任务名称"  >
                  {this.state.jobTitle}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="description" label="描述" >
                  {this.state.description || '--'}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item  label="升级epack" name="jobFirmwareVersion" >
                    <span style={{ marginRight: 10 }}>{this.state.jobFirmwareVersion}</span>
                   
                  </Form.Item>
                </Col>
              </Row>
              <Row>
              {this.props.currentUser?.type !== AUTCONFIG.SUB.value ? 
                <Col span={12}>
                  <Form.Item  label="所属用户" >
                  {this.state.realName || '--'}
                  </Form.Item>
                </Col> : ''}
              </Row>
              <Row>
              {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value  ||
                this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value )? 
                <Col span={12}>
                  <Form.Item  label="主用户" >
                  {this.state.tenantName || '--'}
                  </Form.Item>
                </Col> : ''}
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="创建时间" >
                    {this.state.jobCreateTime}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="更新时间"  >
                    {this.state.jobUpdateTime || '暂无数据'}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="执行时间"  >
                    {
                    this.state.jobExecuteTime
                    }
                  </Form.Item>
                </Col>
              </Row>
            
            </Form>
          </div>

          <div className="job-bottom-card">
            <Row className="job-bottom-card-title">升级对象  </Row>
            <div className="job-bottom-content">
              <div >
                {/* <Button style={{ border: '1px solid #0070CC', color: '#0070CC', float: 'left' }} onClick={() => this.showNodeModal()}>选择节点</Button> */}
                {/* <Form.Item label='标签'>
                  <Select mode="multiple" allowClear style={{ width: '300px' }} placeholder="请选择标签" onChange={(value) => this.handleChangeLabel(value)} >
                    {this.state.labelList}
                  </Select>
                </Form.Item> */}
              </div>
              <Table columns={nodeListColumns}
                rowKey={record => record.id}
                pagination={false}
                dataSource={this.state.chooseNodeList}
                scroll={{ y: 240 }}
              />
            </div>
          </div>
        </div>

        {/* <Modal width={800} title="选择升级节点" visible={this.state.isNodeVisible} onOk={() => this.handleNodeOk()} onCancel={() => this.handleCancel()} okText="确定" cancelText="取消">
          <NodeModal changeSelected={() => this.changeSelected()} selectedNodeIds={this.state.selectedNodeIds} chooseNodeIds={this.state.chooseNodeIds} onClick={() => this.getNodeList()} />
        </Modal>
        <Modal title="选择升级epack" visible={this.state.isFirmwareVisible} width={800} onOk={() => this.handleFirmwareOk()} onCancel={() => this.handleCancel()} okText="确定" cancelText="取消">
          <FirmwareModal changeSelectedFir={() => this.changeSelectedFir()} selectedFirId={this.state.selectedFirId} otaList={this.state.otaList} otaDefaultSelectProductObj={this.state.otaDefaultSelectProductObj} onClick={() => this.getFirmwareList()} />
        </Modal> */}
        </Spin>
      </>
    );
  }
}


// export default ViewJob;

export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(ViewJob);


let searchNodeName = ''

let nodeActions = [];
let firActions = [];



