import React from 'react';
import { Form, Input, Button, Col, Row, Table, Modal, message, ConfigProvider, Pagination , DatePicker, Select} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import locale from 'antd/es/date-picker/locale/zh_CN';
import BackPage from '../../../common/backPage';
import moment from 'moment';
import { otaService } from '../../../../services/ota.service';
import './../../../../../index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

let page = 1;
let pageSize = 10;
let searchName = '';
let searchState = '';
let startTime  = '';
let endTime = '';


function Search(props) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        searchName = values.name || '';
        searchState = values.type || '';
        if(values.testTime && values.testTime.length > 1) {
            startTime = values.testTime[0].format('YYYY-MM-DD HH:mm:ss');
            endTime = values.testTime[1].format('YYYY-MM-DD HH:mm:ss');
        } else {
            startTime = '';
            endTime = '';
        }
        page = 1;
        props.onClick();
    };
    const resetForm = () => {
        form.resetFields();
         searchName = '';
  
         searchState = '';
         startTime  = '';
         endTime = '';
         page = 1;
        props.onClick();
    }

    return (
        <>
            <div className="common-search-form">
                <Form
                    form={form}
                    name="advanced_search"
                    layout="inline"
                    style={{ justifyContent: 'center' }}
                    className="ant-advanced-search-form"
                    onFinish={onFinish} >
                   
                        <Form.Item name='name' label='产品SN' style={{ marginTop: '8px' }}>
                            <Input placeholder="请输入产品SN" />
                        </Form.Item>
                        <Form.Item name='type' label='状态' style={{ marginTop: '8px' }}>
                            <Select
                                initialvalue=""
                                placeholder="请选择状态" >
                                    <Option value="SUCCESS">成功</Option>
                                    <Option value="FAILED">失败</Option>
                                    <Option value="EXCEPTION">异常</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="testTime" label="测试时间"  style={{ marginTop: '8px' }} >
                            <RangePicker format="YYYY-MM-DD HH:mm:ss"   style={{ width: 328, height: 28 }}
                                locale={locale}  showTime/>
                        </Form.Item>
                        <Form.Item style={{ marginTop: '8px' }}>
                            <Button type="primary" htmlType="submit"> 查询 </Button>
                            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
                        </Form.Item>
                    
                </Form>
            </div>
        </>
    );
}

class TestRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recordList: [],
            recordAmount: 0,
            actionIds: [],
            loading: true,
            productId: '',
            page:1,
            pageSize: 10,
            productName: '测试记录',
            isShowNodeUpdateState: false,
            recordid: '',
            subloading: false,
            resultcolumns: [
                {
                    title: '脚本名称',
                    dataIndex: 'scriptName'
                },
                {
                    title: '状态',
                    dataIndex: 'state',
                    render: (text, record) => (
                        <>
                           
                            {
                                text === 'EXCEPTION' && <div><div className="common-yellow"></div> <span>异常</span></div>
                            }
                            {
                                text === 'SUCCESS' && <div><div className="common-green"></div> <span>成功</span></div>
                            }
                            {
                                text === 'FAILED' && <div><div className="common-red"></div> <span>失败</span></div>
                            }
                        </>
                    ),
                },
                {
                    title: '运行日志',
                    dataIndex: 'message'
                },
                {
                    title: '运行时间',
                    dataIndex: 'upTime'
                },
            ],
            nodeList: []
        };
    }
    form = React.createRef();
    componentDidMount() {
        searchName = '';
        searchName = '';
        searchState = '';
        startTime  = '';
        endTime = '';
        page = 1;
        pageSize = 10;
        if (this.props.location.state) {
            const productId = this.props.location.state.id;
            this.setState({
                productId
            });
            sessionStorage.setItem('productId', productId)
            this.searchList(productId);
            this.getProductDetail(productId);
        } else {
           if(sessionStorage.getItem('productId'))  {
              const productId = sessionStorage.getItem('productId');
                this.setState({
                    productId
                });
                this.searchList(productId);
                this.getProductDetail(productId);
           } else {
               this.goBack();
           }
        }
    }
    getProductDetail(productId) {
        otaService.getOtaDetail(productId).then(res => {
            if (res.code === 200) {
                const ota = res.product;
                this.setState({
                    productName: '测试记录 -- ' + ota.productName,
                });
            }  
        }, error => {
            error && message.error(error);
        })
    }

 
    goBack() {
        this.props.history.push('/home/ota-factory');
    }

    handleCancel() {
        this.setState({
            isShowNodeUpdateState: false
        });
    }
    searchList(productId) {
        productId = productId || this.state.productId;
        this.setState({
            loading: true,
            page,
            pageSize
        })
  
        otaService.getRecordList(productId, searchName.replace(/(^\s*)|(\s*$)/g,''),
         searchState, startTime, endTime, pageSize , page).then(res => {
            this.setState({
                loading: false
            });
            this.setState({
                recordList: res.scriptResults,
                recordAmount: res.dataCount
            })
        }).catch(error => {
            this.setState({
                loading: false
            })
            error && message.error( error);
        })
    }
    getResultList(obj) {
        this.setState({
            subloading: true
        })
        otaService.getStateRecordList(obj).then(res => {
            this.setState({
                subloading: false
            })
            if(res.code === 200) {
                this.setState({
                    nodeList: res.result
                })
            }
        }, error => {
            error && message.error( error);
        })
    }
    onFinish(values) {
        const params = {
            id: this.state.recordid,
            name: values.name || '',
            state: values.state || ''
        }
        this.getResultList(params);
    }
    resetForm() {
        this.form.current?.resetFields();
        const params = {
            id: this.state.recordid
        }
        this.getResultList(params);
    }
    showSuccess(record) {
        const params = {
            id: record.executionId,
            state: 'SUCCESS'
        };
        this.setState({
            recordid: record.executionId,
            isShowNodeUpdateState: true
        });
        this.form.current?.resetFields();
        this.form.current?.setFieldsValue({ state: 'SUCCESS' });
        if(!this.form.current) {
            setTimeout(() => {
                this.form.current?.setFieldsValue({ state: 'SUCCESS' });
            }, 520)
        }
        this.getResultList(params);
    }
    showFailed(record) {
        const params = {
            id: record.executionId,
            state: 'FAILED'
        }
        this.setState({
            recordid: record.executionId,
            isShowNodeUpdateState: true
        });
        this.form.current?.resetFields();
        this.form.current?.setFieldsValue({ state: 'FAILED' });
        if(!this.form.current) {
            setTimeout(() => {
                this.form.current?.setFieldsValue({ state: 'FAILED' });
            }, 520)
        }
        this.getResultList(params);
    }
    render() {
        const columns = [
            {
                title: '产品SN',
                dataIndex: 'seriesNumber'
            },
            {
                title: '脚本总数目',
                dataIndex: 'totalNum'
            },
            {
                title: '成功脚本数目',
                dataIndex: 'successNum',
                render: (text, record) => (
                    <>
                    
                       <a onClick={() => this.showSuccess(record)}>{text || 0 }</a> 
                    </>
                )
            },
            {
                title: '失败脚本数目',
                dataIndex: 'failedNum',
                render: (text, record) => (
                    <>
                   <a onClick={() => this.showFailed(record)}>{text || 0 }</a> 
                    </>
                )
            },
            {
                title: '状态',
                dataIndex: 'state',
                render: (text, record) => (
                    <>
                       
                        {
                            text === 'EXCEPTION' && <div><div className="common-yellow"></div> <span>异常</span></div>
                        }
                        {
                            text === 'SUCCESS' && <div><div className="common-green"></div> <span>成功</span></div>
                        }
                        {
                            text === 'FAILED' && <div><div className="common-red"></div> <span>失败</span></div>
                        }
                    </>
                ),
            },
            {
                title: '测试时间',
                dataIndex: 'upTime'
            }
        ];
        const onPageChange = (pageNumber,ps) => {
            page = pageNumber;
            if(ps!=pageSize) {
              page = 1
            }
            pageSize = ps
            this.searchList();
          };
       
        return (
            <>
                <div className="common-pd20">
                    <div>
                    <BackPage onClick={() => this.goBack()} headeTitle={this.state.productName} itemTitle="脚本" itemSubTitle="创建产品" />
                       <div className="common-center-container">
                           <Search  onClick={() => this.searchList()}/>
                          
                            <div className="user-table-card">
                                <Table
                                 
                                    rowKey={record => record.id}
                                    bordered={false}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={this.state.recordList}
                                    loading={this.state.loading}
                                />
                            </div>
                            <div>
                                {
                                    this.state.recordList.length > 0 &&
                                    <div className="common-bottom-pagination">
                                        <ConfigProvider locale={zhCN}>
                                            <Pagination showSizeChanger defaultCurrent={1} 
                                            current={this.state.page} 
                                            pageSize={this.state.pageSize} 
                                            total={this.state.recordAmount} onChange={onPageChange} />
                                        </ConfigProvider>
                                    </div>
                                }
                            </div>
                       </div>
                    </div>
                    <Modal width={800} title="脚本运行结果" 
                          visible={this.state.isShowNodeUpdateState} 
                          footer={null}
                          onOk={() => this.handleCancel()} 
                          onCancel={() => this.handleCancel()} okText="确定" cancelText="取消">
                        <div className="common-search-form">
                        <Form
                        ref={this.form}
                        layout="inline"
                        style={{ justifyContent: 'center' }}
                        className="ant-advanced-search-form"
                        onFinish={(event) => this.onFinish(event) } >
                        
                            <Form.Item name='name' label='脚本名称' >
                                <Input placeholder="请输入脚本名称" />
                            </Form.Item>
                            <Form.Item name='state' label='状态' >
                                <Select   style={{ width: '150px' }} placeholder="请选择状态"  >
                                    <Option value="SUCCESS">成功</Option>
                                    <Option value="FAILED">失败</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"> 查询 </Button>
                                <Button style={{ margin: '0 9.46px' }} onClick={() => this.resetForm()}>重置 </Button>
                            </Form.Item>
                      
                        </Form>
                        </div>
                        <div className="user-table-card">
                            <Table
                            rowKey={record => record.id}
                            bordered={false}
                            tableLayout="fixed"
                            loading={this.state.subloading}
                            columns={this.state.resultcolumns}
                            scroll={{ y: 320 }}
                            dataSource={this.state.nodeList}
                            />
                        </div>
                     </Modal>
                </div>
            </>
        );
    }
}


export default TestRecord;