import React from 'react';
import { Form, Input, message, Spin } from 'antd';
import { otaService } from '../../../../../services/ota.service';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class ViewShell extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true,
      productId: '',
      productName: '查看脚本',
      id: '',
      createTime: '',
      updateTime: '',
      scriptName: '',
      url:'',
      param: '',
      order: '',
      description: '',
      loading: true
    };

  }
  componentDidMount() {
    if (this.props.location.state) {
      const productId = this.props.location.state.productId;
      const id = this.props.location.state.id;
      this.setState({
          productId,
          id
      });
      sessionStorage.setItem('shellId', id)
      this.getShellDetail(id);
  } else {
    if(sessionStorage.getItem('productId') && sessionStorage.getItem('shellId')) {
      const productId = sessionStorage.getItem('productId');
      const id = sessionStorage.getItem('shellId');
          this.setState({
            productId,
            id
        });
        this.getShellDetail(id);
    } else {
      this.props.history.push('/home/ota-factory');
    }
  }
}
  goback() {
    this.props.history.push({ pathname: '/home/ota-factory/test-shell', state: { id: this.state.productId } });
  }
  getShellDetail(id) {
    otaService.getShellDetail(id).then(res => {
        if (res.code === 200) {
            const ota = res.script;
           
              this.setState({
                  createTime: ota.createTime,
                  updateTime: ota.updateTime,
                  productName: '查看脚本 -- ' +  ota.scriptName,
                  scriptName: ota.scriptName,
                  url: ota.url,
                  param: ota.param,
                  order: ota.order,
                  description: ota.description,
                  loading: false
              })
        }  
        // else {
        //   this.setState({
        //     loading: false
        //   })
        // }
    }).catch(error => {
      this.setState({
        loading: false
      })
      error && message.error(error);
    })
}
  render() {
   
    return (
      <>
      <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle={this.state.productName} itemTitle="脚本" itemSubTitle="创建产品" />
        <div className="common-create-center-container">
          <Spin spinning={this.state.loading}>
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
             >
              <Form.Item name="scriptName" label="脚本名称"   >
              {this.state.scriptName || '--'}
              </Form.Item>
              <Form.Item name="url" label="URL"  >
                 {this.state.url || '--'}
              </Form.Item>
              <Form.Item name="param" label="运行参数"   >
              {this.state.param || '--'}
              </Form.Item>
              <Form.Item name="order" label="运行顺序"  >
                {this.state.order || '--'}
              </Form.Item>
              <Form.Item name="description" label="描述" >
              {this.state.description || '--'}
              </Form.Item>
              <Form.Item name="description" label="创建时间" >
                {this.state.createTime || '--'}
              </Form.Item>
              <Form.Item name="description" label="更新时间" >
              {this.state.updateTime || '--'}
              </Form.Item>
            
            </Form>
          </div>
          </Spin>
        </div>
      </div>
      </>
    );
  }
}

export default ViewShell;