import React from 'react';
import BackPage from '../../../../common/backPage';
import { Form, Input, Select, message, Row, Col, InputNumber, Card, Divider, Button, Table, Modal, Space } from 'antd';
import { algorithmService } from '../../../../../services/algorithm-manage';

const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};

class ViewAlgorithmGroup extends React.Component{
    state = {
        algorithmGroupName: '',
        id: -1,
        algorithmNum: 0,
        description: '',
        createBy: '',
        createTime: '',
        updateTime: '',
        algorithms: [], //算法组列表
        number: '',
    }

    componentDidMount() {
        if (this.props.location.state) {
            const id = this.props.location.state.id;
            console.log("查看算法组详情的id为：", id);
            this.getAlgorithmGroupDetail(id)
            //this.getAlgorithm(id, name,'','','')
            sessionStorage.setItem('algorithmGroupId', id)
            this.setState({
              id,
            });
          } else {
            if(sessionStorage.getItem('algorithmGroupId')) {
              const id = sessionStorage.getItem('algorithmGroupId');
              this.setState({
                id,
              });
              this.getAlgorithmGroupDetail(id)
              //this.getAlgorithm(id, name,'','','')
            } else {
              this.goback();
            }
          }
    }

    //查询算法组详情接口
    getAlgorithmGroupDetail(id) {
        algorithmService.getAlgorithmGroupDetail(id).then(res => {
            if (res.code === 200) {
              console.log(res);
              this.setState({
                  algorithmNum: res.group.algorithmNum,
                  createBy: res.group.createBy,
                  createTime: res.group.createTime,
                  updateTime: res.group.updateTime,
                  algorithmGroupName: res.group.algorithmGroupName,
                  description: res.group.description,
                  algorithms: res.group.algorithms,
                  number: res.group.algorithms.length,
              })
            }
            }).catch(error => {
              error && message.error(error);
            })
    }

    goback() {
        this.props.history.push('/home/algorithm-manage');
    }

    render() {
        const confColumns = [
            {
              title: '算法名称',
              dataIndex: 'algorithmName',
            },
            {
              title: '算法版本',
              dataIndex: 'algorithmVersion',
            },
            {
              title: '描述',
              dataIndex: 'description',
            },
            {
              title: '创建用户',
              dataIndex: 'createBy',
            },
            {
              title: '创建时间',
              dataIndex: 'createTime',
            },
            {
              title: '更新时间',
              dataIndex: 'updateTime',
            },
            // {
            //   title: '操作',
            //   render: (text, record) => (
            //     <Space size="middle">
            //       <a onClick={() => this.gotoEdit(record)}>编辑</a>
            //       <a onClick={() => this.showDeleteConfirm(record)}>删除</a>
            //     </Space>
            //   ),
            // },
          ];
        return (
            <div className="common-pd20">
                <BackPage onClick={() => this.goback()} headeTitle={'查看算法组--' + this.state.algorithmGroupName} itemTitle="查看算法组" itemSubTitle="查看算法组" />
                <div className="common-create-center-container">
                    <div className="common-create-page">
                        <Form name="complex-form"
                        // onFinish={onFinish}
                        labelAlign="left"
                        ref={this.formRef}
                        {...formItemLayout}
                        >
                            <Form.Item name="algorithmNum" label="算法数量">
                                {this.state.number} 个
                            </Form.Item>
                            
                            <Form.Item name="description" label="算法组描述">
                                {this.state.description || '---'} 
                            </Form.Item>
                        
                            <Form.Item name="createTime" label="创建用户">
                                {this.state.createBy || '---'}
                            </Form.Item>
                  
                            <Form.Item name="createTime" label="创建时间">
                                {this.state.createTime || '---'}
                            </Form.Item>
                        
                            <Form.Item name="updateTime" label="更新时间">
                                {this.state.updateTime || '---'}
                            </Form.Item>
                        </Form>  

                        <Divider/>
                        <div className="job-bottom-content">
                            <Table columns={confColumns}
                                rowKey={record => record.id}
                                pagination={false}
                                dataSource={this.state.algorithms}

                            />
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
}

export default ViewAlgorithmGroup;