import React from 'react';
import ReactDOM from 'react-dom';
import { Row, Col, Form, Input, Button, Checkbox, Divider, message } from 'antd';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import store from '../../../../store/index';
import { getTokenChangeAction } from '../../../../store/actionCreators';
import { getCurrentAction } from '../../../../store/actionCreators';
import { loginService } from '../../../services/login';
import './login.scss'

class Login extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      type: 1,
      history: require("history").createHashHistory,
      loginFlag: false
    };
  }

  typeChange(i) {
    this.setState(prevState => ({
      type: i
    }))
  }
  componentDidMount() {
    const action = getTokenChangeAction('');
    store.dispatch(action);
    const action2 = getCurrentAction('');
    store.dispatch(action2);
  }

  componentWillUnmount() {
    clearInterval(timerC)
  }

  render() {
    if (this.state.loginFlag) {
      return <Router><Redirect to='/first-page' /></Router>;
    }

    const onFinish = (values) => {
      const body = {
        username: values.username,
        password: values.password
      };
      message.destroy();
      message.loading('系统登录中~~', 2500);
      fetch('/v1/console/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(body)
      })
        .then(res => res.json())
        .then(
          (result) => {
            message.destroy();
            if (result.code === 200) {
              // localStorage.setItem('auth_token', result.token);
              document.cookie = result.token;
            
              if(!document.cookie) {
                  message.warn('请开启浏览器cookie访问权限！');
                  return;
              }
              const action = getTokenChangeAction(result.token);
              store.dispatch(action);
              this.setState({
                loginFlag: true
              });
             
              fetch(`/v1/console/users/current`, {
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8',
                  Authorization: `Bearer ${result.token}`
                }
              }).then(response => {
                //  message.success('登录成功');

                response.json().then(res => {
                  if (res.code === 200) {
                     
                    const currentUser = res.users;
              
                    const action2 = getCurrentAction(currentUser);
                    store.dispatch(action2);
                  }
                })
              })
              //   loginService.getCurrentUser().then(res => {
              //     message.success('登录成功');
              //     if (res.code === 200) {
              //     //   const action = getTokenChangeAction(result.token);
              //     //   store.dispatch(action);
              //       const currentUser = res.users;
              //       const action2 = getCurrentAction(currentUser);
              //       store.dispatch(action2);
              //     }

              //   });
              // this.props.history.push('/home#/first-page');
            } else {
              message.error('登录失败，' + result.message);
            }
          },
          (error) => {
            message.destroy();
            message.error('登录失败，' + error);
          }
        )
    };

    return (
      <Router>
        <div className="login-container">
          <Row className="login-head-title"><Col span={24}><h2><img src="images/logo.png" width={157.5} height={75}/></h2></Col></Row>
          <Row className="login-content">
            <Col span={14}>
              <div className="login-left-container"><img className="login-left-img" src="images/login.png" alt='' /></div>
            </Col>
            <Col span={10}>
              <div className="login-right-container">
                <div className="login-box">
                  {/* <Row className="login-type">
                    <Col span={12} style={{ cursor: 'pointer' }} className={this.state.type === 1 ? "login-tab-active" : null} onClick={this.typeChange.bind(this, 1)}>密码登录 </Col>
                    <Col span={12} style={{ cursor: 'pointer' }} className={this.state.type === 2 ? "login-tab-active" : null} onClick={this.typeChange.bind(this, 2)}> 短信登录</Col>
                  </Row> */}
                  <div style={{ paddingLeft: '44px', paddingRight: '44px',paddingTop: '36px' }}>
                    {
                      this.state.type === 1 ?

                        <>
                          <h2 style={{ fontSize: '24px', fontWeight: 400, marginBottom: '30px' }}>账号登录</h2>
                          <Form
                            onFinish={onFinish}
                            name="basic"
                            className="login-form"
                            
                          >
                            <Form.Item
                              name="username"
                              rules={[{ required: true, message: '请输入用户名' }]}
                            >
                              <Input placeholder="请输入用户名" style={{ height: '36px' }} />
                            </Form.Item>
                            <Form.Item
                              name="password"
                              rules={[{ required: true, message: '请输入密码' }]}
                            >
                              <Input type="password" style={{ height: '36px' }} placeholder="请输入密码" />
                            </Form.Item>
                            {/* <Form.Item>
                              <div style={{ width: '50%', float: 'left', textAlign: 'left' }}>
                                <Form.Item name="remember" valuePropName="checked" noStyle >
                                  <Checkbox>自动登录</Checkbox>
                                </Form.Item>
                              </div>

                              <div className="login-registered" style={{ width: '50%' }}>
                                <a href='/registered'>注册账号</a>
                                <Divider type="vertical" />
                                <span> 忘记密码? </span>
                              </div>
                            </Form.Item> */}

                            <Form.Item>
                              <Button type="primary" htmlType="submit" block> 登录</Button>
                            </Form.Item>
                          </Form>
                        </>

                        :
                        <PhoneLogin />
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="login-footer"><Col span={24}></Col></Row>
        </div>
      </Router>
    )
  }
}


let timerC = null;
let codeTime = 60;

class PhoneLogin extends React.Component {
  timerC;
  codeTime = 60;
  constructor(props) {
    super(props);
    this.state = {
      codeTitle: '获取验证码',
      phoneNumberValue: ''
    };
  }
  getCode() {
    // if (this.state.codeTitle === "获取验证码") {
    // timerC = setInterval(() => this.timerChange(), 1000);
    // loginService.getPhoneCode().then(res => {

    // });
    // } else {
    //   return;
    // }
  }
  handleChange(e) {
    const inputValues = e.target.value;
    this.setState(() => ({ phoneNumberValue: inputValues })); // 异步
  }
  timerChange() {
    if (codeTime > 0) {
      this.setState({
        codeTitle: codeTime + "s后重新获取"
      });
      codeTime--;
    } else {
      this.setState({
        codeTitle: codeTime + "获取验证码"
      });
      clearInterval(timerC)
    }
  }
  render() {
    const onFinish = (values) => {
      console.log("============================")
      console.log(values)
    };
    return (
      <>
        <h2 style={{ fontSize: '24px', fontWeight: 400, marginBottom: '30px' }}>账号登录</h2>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish} >
          <Form.Item name="phoneNumber" rules={[{ required: true, message: '请输入手机号' }]} >
            <Input value="phoneNumber" onChange={(e) => this.handleChange(e)} placeholder="请输入手机号" style={{ height: '50px' }} />
          </Form.Item>
          <Form.Item name="code" rules={[{ required: true, message: '请输入验证码' }]}  >
            <Input style={{ height: '50px' }} placeholder="请输入验证码" />
            <div className="login-get-code">
              <Divider type="vertical" />
              <a href onClick={() => this.getCode()}>{this.state.codeTitle}</a>
            </div>
          </Form.Item>
          {/* <Form.Item>
            <div style={{ width: '50%', float: 'left', textAlign: 'left' }}>
              <Form.Item name="remember" valuePropName="checked" noStyle >
                <Checkbox>自动登录</Checkbox>
              </Form.Item>
            </div>

            <div className="login-registered" style={{ width: '50%' }}>
              <a href='/registered'>注册账号</a>
              <Divider type="vertical" />
              <span> 忘记密码? </span>
            </div>
          </Form.Item> */}
          <Form.Item>
            <Button type="primary" htmlType="submit" block> 登录</Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}



ReactDOM.render(<Login />, document.getElementById('root'));
export default withRouter(Login);





// function PasswordLogin(props) {
//   console.log(props)
//   const onFinish = (values) => {
//     if (!values.username || !values.password) {
//       message.warning('用户名或密码不能为空');
//       return;
//     }
//     const body = {
//       username: values.username,
//       password: values.password
//     };
//     fetch('/v1/console/login', {
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json;charset=UTF-8',
//       },
//       body: JSON.stringify(body)
//     })
//       .then(res => res.json())
//       .then(
//         (result) => {
//           if (result.code === 200) {
//             localStorage.setItem('auth_token', result.data);
//             message.success('登录成功');
//             props.onClick();
//           } else {
//             message.error('登录失败，' + result.message);
//           }
//         },
//         (error) => {
//           message.error('登录失败，' + error);
//         }
//       )
//   };
//   return (
//     <>
//       <h2 style={{ fontSize: '24px', fontWeight: 400, marginBottom: '30px' }}>账号登录</h2>
//       <Form
//         onFinish={onFinish}
//         name="basic"
//         className="login-form"
//         initialValues={{ remember: true }}
//       >
//         <Form.Item
//           name="username"
//           rules={[{ required: true, message: '请输入用户名' }]}
//         >
//           <Input placeholder="请输入用户名" style={{ height: '50px' }} />
//         </Form.Item>
//         <Form.Item
//           name="password"
//           rules={[{ required: true, message: '请输入密码' }]}
//         >
//           <Input type="password" style={{ height: '50px' }} placeholder="请输入密码" />
//         </Form.Item>
//         <Form.Item>
//           <div style={{ width: '50%', float: 'left', textAlign: 'left' }}>
//             <Form.Item name="remember" valuePropName="checked" noStyle >
//               <Checkbox>自动登录</Checkbox>
//             </Form.Item>
//           </div>

//           <div className="login-registered" style={{ width: '50%' }}>
//             <a href='/registered'>注册账号</a>
//             <Divider type="vertical" />
//             <span> 忘记密码? </span>
//           </div>
//         </Form.Item>

//         <Form.Item>
//           <Button type="primary" htmlType="submit" block> 登录</Button>
//         </Form.Item>
//       </Form>
//     </>
//   );
// }