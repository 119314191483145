import { handleResponse } from './handle-response';
import store from '../../store/index';

export const jobService = {
    getJobList,
    getJobDetail,
    addJob,
    removeJob,
    editJob,
    getUpdateState,
    removeNode
};


function getJobList(name, tenantId, state,upgradeType, offset, limit) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/batch/upgrades?name=${name || ''}&userId=${tenantId || ''}&state=${state || ''}&upgradeType=${upgradeType || ''}&offset=${offset || 1}&limit=${limit || 20}`, requestOptions).then(handleResponse).then(res => {
        return res;
    });
}
function getJobDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/batch/upgrades/${id}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}

function addJob(name, description, upgradeType, type, executeTime, nodeUuids, firmwareId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name || '',
            description: description || '',
            upgradeType: upgradeType || 0,
            type: type || 0,
            executeTime: executeTime || '',
            nodeUuids: nodeUuids || [],
            firmwareId: firmwareId
        })
    };
    return fetch(`/v1/console/batch/upgrades`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function removeJob(ids) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/batch/upgrades/${ids}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}

function editJob(id, name, description, executeTime, nodeUuids, firmwareId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name || '',
            description: description || '',
            executeTime: executeTime || '',
            nodeUuids: nodeUuids || [],
            firmwareId: firmwareId,
        })
    };
    return fetch(`/v1/console/batch/upgrades/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function getUpdateState(upgradeId, nodeName, nodeState, offset, limit ) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/batch/upgrades/${upgradeId}/nodes?nodeName=${nodeName || ''}&nodeState=${nodeState || ''}&limit=${limit || 10}&offset=${offset || 1}`, requestOptions).then(handleResponse).then(res => { return res });

}
function removeNode(taskId, nodeId) {
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/batch/upgrades/${taskId}/nodes/${nodeId}`, requestOptions).then(handleResponse).then(res => { return res });

}
//编辑移除节点

// function getAllJobs() {
//     const requestOptions = {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             Authorization: `Bearer ${store.getState().authToken}`
//         },
//     };
//     return fetch(`/v1/console/jobs`, requestOptions).then(handleResponse).then(res => { return res });
// }