import React from 'react';
import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, Tabs } from 'antd';
import { ExclamationCircleOutlined , PlusOutlined} from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import { algorithmService } from '../../../../services/algorithm-manage';
import './../../../../../index.scss';
import AlgorithmGroup from '../algorithm-manage/algorithm-group'

import { connect } from "react-redux";
import store from '../../../../../store';
import { AUTCONFIG } from '../../../../../utils/auth.config';
import { searchRule } from '../../../../../utils/search-util'

const { Option } = Select;

const { TabPane } = Tabs;
const { confirm } = Modal;

let page = 1;
let pageSize = 10;
let searchName = '';
let searchVersion = '';

class Search extends React.Component{
    form = React.createRef();

    onFinish = (values) => {
        console.log(values);
        let name = values.algorithmName || '';
        // let str = searchRule(name)
        name = name.replace(/[\^\+\|\%\\\{\}\#\&\|\+]/g, '5a0c511f-3cc8-428c-860b-f747c16b2341')//特殊字符
        // name = name.replace(/[\s]/g, '')//空格
        searchName = name || '';
    
        page = 1;
        this.props.onClick();
      };
       resetForm = () => {
        this.form.current.resetFields();
        searchName = '';
        page = 1;
        pageSize = 10;
        this.props.onClick();
      }
     
      componentDidMount() {
        this.resetForm();
      }
      render() { 
            return (
                <>
                <div className="common-search-form">
                    <Form
                     ref={this.form}
                    name="advanced_search_user"
                    className="ant-advanced-search-form"
                    onFinish={this.onFinish}
                    layout="inline"
                   
                    // style={{ justifyContent: 'center' }}
                     >
    
                    <Form.Item name='algorithmName' label='算法名称' style={{  marginTop: '8px' }}>
                        <Input placeholder="请输入算法名称" />
                    </Form.Item>
                    
                    <Form.Item style={{ marginTop: '8px' }}>
                        <Button type="primary" htmlType="submit"> 查询 </Button>
                        <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
                    </Form.Item>
    
                    </Form>
                </div>
                </>
            );
        }
}

class AlgorithmMange extends React.Component{
    state = {
        loading: true,
        algorithmsList: [],
        page: 1,
        pageSize: 10,
        dataCount: null,
    }

    showDeleteConfirm(event) {
        confirm({
          title: '确定删除吗?',
          icon: <ExclamationCircleOutlined />,
          content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
          closable: true,
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            this.deleteAlgorithm(event.id)
            
          },
          onCancel() {
    
          },
        });
    }

  componentDidMount() {
        this.changeType()
        //this.getAlgorithm()
    }

    //调用查找算法接口
    getAlgorithm() {
        algorithmService.getAlgorithmOverview(searchName, pageSize, page).then(res => {
            if (res.code === 200) {
              console.log(res);
              this.setState({
                algorithmsList: res.namespaces,
                loading: false,
                dataCount: res.dataCount,
              })
            }
        }).catch(error => {
          error && message.error(error);
          this.setState({
            loading: false
          });  
        })
    }

    //调用删除算法接口
    deleteAlgorithm(id) {
        algorithmService.deleteAlgorithmNamespaces(id).then(res => {
            if (res.code === 200) {
              message.success("删除成功！")
              this.changeType();
            }
            }).catch(error => {
              error && message.error(error);
            })
    }
    
    //匹配标签类型
    getLableType = (record) => {
      if (record.label === '1') {
        return <span>视频</span>
      } else {
        return <span></span>
      }
    }

    gotoEdit = (text) => {
        this.props.history.push({ pathname: '/home/algorithm-manage/edit', state: { id: text.id } });
      }

    gotoDetail = (text, record) => {
        console.log(text);
        this.props.history.push({ pathname: '/home/algorithm-manage/view', state: { name: text.name, id: text.id } });
      }

    changeType() {
        this.setState({
            page,
            pageSize
        })
    
        this.setState({
          loading: true
        });
        // setTimeout(() => {
        //   this.setState({
        //       loading: false
        //     }); 
        // }, 200)
    
        //查找
        this.getAlgorithm()
    }

    render() {
      const onPageChange = (pageNumber) => {
        page = pageNumber;
        this.changeType();
      }
      const onShowSizeChange = (current, pageSizeNumber) => {
        pageSize = pageSizeNumber;
        page = 1;
  
        this.changeType();
      }

        const addAlgorithm = () => {
            this.props.history.push('/home/algorithm-manage/add');
        };

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                actionIds: selectedRowKeys
              })
            },
          
        };

        const columns = [
            {
              title: '算法名称',
              dataIndex: 'name',
              render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record)}>{text}</a>,
            },
            {
              title: '算法标签',
              dataIndex: 'label',
              // render: (text, record) => (
              //   this.getLableType(record)
              // )
            },
            {
              title: '版本数目',
              dataIndex: 'algorithmNum',
            },
            {
              title: '创建时间',
              dataIndex: 'createTime',
            },
            {
              title: '操作',
              dataIndex: 'action',
              width: '20%',
              render: (text, record) => (
                <>
      
                  <a href onClick={() => this.gotoEdit(record)} style={{ marginRight: '5px' }}>编辑</a>
                  <a href onClick={() => this.showDeleteConfirm(record)} style={{ marginRight: '5px' }}>删除</a>
                
                </>
              )
            },
          ];

        return (
            <div className="common-pd20">
                <div >
                    <h1 className="common-top-title0">算法管理</h1>
              <div className="common-center-container">
                <Tabs>
                  <TabPane tab='算法' key='1'>
                      <Search onClick={() => this.changeType()} 
                            currentUser={this.props.currentUser} 
                            labelList={this.state.labelList} />
                        <div className="oper-button">
                            <Router>
                                <Button type="primary" onClick={addAlgorithm} style={{width:80}}>新增算法 </Button>
                            </Router>
                        </div>
                        <div className="user-table-card">
                            <Table
                            rowSelection={{
                                ...rowSelection,
                            }}
                            rowKey={record => record.id}
                            bordered={false}
                            tableLayout="fixed"
                            pagination={false}
                            loading={this.state.loading}
                            columns={columns}
                            dataSource={this.state.algorithmsList}
                            />
                    </div>
                    <div>
                      {
                        this.state.algorithmsList.length > 0 &&
                        <div className="common-bottom-pagination">
                          <ConfigProvider locale={zhCN}>
                            <Pagination
                              showSizeChanger
                              onShowSizeChange={onShowSizeChange}
                              defaultCurrent={1}
                              current={this.state.page}
                              pageSize={this.state.pageSize}
                              total={this.state.dataCount}
                              onChange={onPageChange} />
                          </ConfigProvider>
                        </div>
                      }
                    </div>          
                  </TabPane>

                  <TabPane tab="算法组" key="2">
                        <AlgorithmGroup history={this.props.history}/>
                  </TabPane>
                </Tabs>
                        
                    </div>
                </div>
          </div>
       
        )
    }
}

export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(AlgorithmMange);