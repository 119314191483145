import { handleResponse } from './handle-response';
import store from '../../store/index';

export const mirrorService = {
    getLogList,
    getPosition,
    getDetailPosition,
    logDownload,
    nodeDeleted,
    resetNode,
    recoverNode,
    getCloudStorageFiles,
    getFilesDownload,
    getCloudFiles,
    deleteCloudFile,
    getAiCheckRecord
};

//列表展示
function getLogList(startTime, endTime, nodeName, nodeSeriesNumber, logAddr, logLevel, logType, limit, offset) {
    return fetch(`/v1/console/pageLog?startTime=${startTime || ''}&endTime=${endTime || ''}&nodeName=${nodeName || ''}&nodeSeriesNumber=${nodeSeriesNumber || ''}&logAddr=${logAddr || ''}&logLevel=${logLevel || ''}&logType=${logType || -1}&limit=${limit || 10}&offset=${offset || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//地图数据接口
function getPosition() {
    return fetch(`/v1/console/nodes/distribution`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//详情数据的调用
function getDetailPosition(province, city, district) {
    return fetch(`/v1/console/nodes/distribution/address?city=${city || ''}&province=${province || ''}&district=${district || ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//日志下载接口
function logDownload(startTime, endTime, nodeName, nodeSeriesNumber, logAddr, logLevel, logType) {
    return fetch(`/v1/console/log/download?startTime=${startTime || ''}&endTime=${endTime || ''}&nodeName=${nodeName || ''}&nodeSeriesNumber=${nodeSeriesNumber || ''}&logAddr=${logAddr || ''}&logLevel=${logLevel || ''}&logType=${logType || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        responseType: 'blob',
    }).then(handleResponse).then(res => {
        return res;
    });
}
//查询节点已删除列表
function nodeDeleted(name, productModel, labelIds, tenantId, company, stationId, province, city, district, limit, offset) {
    return fetch(`/v1/console/nodes/deleted?name=${name || ''}&productModel=${productModel || ''}&userId=${tenantId || ''}&labelIds=${labelIds || ''}&companyId=${company || ''}&seriesNumber=${stationId || ''}&province=${province || ''}&city=${city || ''}&district=${district || ''}&limit=${limit || 10}&offset=${offset || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//重置节点
function resetNode(id) {
    return fetch(`/v1/console/nodes/${id}/reset`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    }).then(handleResponse).then(res => {
        return res;
    });
}
//恢复
function recoverNode(id) {
    return fetch(`/v1/console/nodes/${id}/recover`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    }).then(handleResponse).then(res => {
        return res;
    });
}

//获取网络存储文件列表
function getCloudStorageFiles(limit, offset) {
    return fetch(`/v1/console/cloud-storage/files?limit=${limit || 10}&offset=${offset || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
//获取文件下载地址
function getFilesDownload(key) {
    return fetch(`/v1/console/cloud-storage/files/download?key=${key || []}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
//获取节点下文件目录

function getCloudFiles(seriesNumber) {
    return fetch(`/v1/console/cloud-storage/files/${seriesNumber}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
//删除网络存储文件
function deleteCloudFile(fileName) {
    return fetch(`/v1/console/cloud-storage/files?fileName=${fileName}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
// 查询鉴权记录
function getAiCheckRecord(nodeName, seriesNumber, algorithmName, checkSource, result, startTime, endTime, limit, offset) {
    return fetch(`/v1/console/ai-check/record?nodeName=${nodeName || ''}&seriesNumber=${seriesNumber || ''}&algorithmName=${algorithmName || ''}&checkSource=${checkSource || ''}&result=${result || ''}&startTime=${startTime || ''}&endTime=${endTime || ''}&limit=${limit || 10}&offset=${offset || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}