import { handleResponse } from './handle-response';
import store from '../../store/index';

export const loginService = {
    login,
    regist,
    getPhoneCode,
    changePassword,
    getCurrentUser,
    changeCurrentUserMsg,
    loginOut
};
function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, password: password })
    };
    return fetch(`/v1/console/login`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}



function loginOut(phoneNum) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/loginout`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function regist(username, mobile, password, code) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, mobile: mobile, password: password, code: code })
    };
    return fetch(`/v1/console/registers`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function getPhoneCode(phoneNum) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`/v1/console/authcode?mobile=${phoneNum}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function changePassword(oldPassword, newPassword) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({ newPassword: newPassword, oldPassword: oldPassword })
    };
    return fetch(`/v1/console/users/password`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function changeCurrentUserMsg(name, mobile, email, companyId, address) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({ realName: name, email: email, mobile: mobile, companyId: companyId, address: address })
    };
    return fetch(`/v1/console/users`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function getCurrentUser() {
    return fetch(`/v1/console/users/current`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    })
}
