import React from 'react';
import { Form, Input, message, Spin } from 'antd';
import { companyService } from '../../../../../services/company.service';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class EditCompany extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true,
      productId: '',
      title: '编辑企业',
      id: '',
      createTime: '',
      updateTime: '',
      loading: true
    };

  }
  componentDidMount() {
    if (this.props.location.state) {
    
        const id = this.props.location.state.id;
        this.setState({
           
            id
        });
        this.getShellDetail(id);
        sessionStorage.setItem('companyId', id)
    } else {
      if(sessionStorage.getItem('companyId')) {
        const id = sessionStorage.getItem('companyId');
        this.setState({
            id
        });
        this.getShellDetail(id);
      } else {
        this.goback();
      }
    }
}
goback() {
  this.props.history.push('/home/sys/company-manage');
}
  getShellDetail(id) {
    companyService.getCompanyDetail(id).then(res => {
        if (res.code === 200) {
            const company = res.company;
            this.formRef.current?.setFieldsValue({
                name: company.name,
                contactPerson: company.contactPerson,
                mobile: company.mobile,
                email: company.email,
                address: company.address,
                description: company.description,
                loading: false
              });
              this.setState({
                  createTime: company.createTime,
                  updateTime: company.updateTime,
                  title: '编辑企业 -- ' +  company.name,
              })
        }   
        // else {
        //   this.setState({
        //     loading: false
        //   })
        // }
    }).catch(error => {
      this.setState({
        loading: false
      })
      error && message.error(error);
    })
}
  render() {
    const onFinish = (values) => {
        Object.keys(values).forEach(elx => {
            values[elx] =  values[elx] || '';
        });
        message.loading('信息保存中~~', 2500);
        companyService.updateCompany(this.state.id, values).then(res => {
          message.destroy();
        if (res.code === 200) {
          message.success('编辑企业成功');
          this.goback();
        } 
        // else {
        //   message.success('编辑企业失败，' + res.message);
        // }
      }, error => {
        message.destroy();
        error && message.error(error);
      });
    };
    return (
      <>
      <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle={this.state.title} itemTitle="脚本" itemSubTitle="创建产品" />
        <div className="common-create-center-container">
      
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish}>
              <Form.Item name="name" label="企业名称"  rules={[
                { required: true, message: '请输入企业名称' },
                {max: 64, message: '企业名称不允许超过64个字符'}, 
                { pattern: new RegExp(/^[0-9a-zA-Z-_.\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线/点'}]}>
                <Input placeholder="请输入企业名称" />
              </Form.Item>
              <Form.Item name="contactPerson" label="联系人" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || /^[\u0391-\uFFE5\w_-](.*){0,64}$/.test(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('请输入64位以内中英文/数字/中划线/下划线/点'));
                    },
                })]}  >
                <Input placeholder="请输入联系人" />
              </Form.Item>
              <Form.Item name="mobile" label="电话"  rules={[{ pattern: /^[1][3,4,5,7,8][0-9]{9}$/, message: '请输入正确的手机号' }]} >
                <Input placeholder="请输入电话" />
              </Form.Item>
              <Form.Item name="email" label="邮箱" rules={[{ type: 'email', message: '请输入正确的电子邮箱' }]} >
                <Input placeholder="请输入邮箱" />
              </Form.Item>
              <Form.Item name="address" label="地址"  >
                 <Input placeholder="请输入地址" />
              </Form.Item>
              <Form.Item name="description" label="描述" >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
              </Form.Item>
              <Form.Item name="description" label="创建时间" >
                {this.state.createTime || '--'}
              </Form.Item>
              <Form.Item name="description" label="更新时间" >
              {this.state.updateTime || '--'}
              </Form.Item>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
          
        </div>
      
      </div>
      </>
    );
  }
}

export default EditCompany;