import React from 'react';
import {
  Table,
  Button,
  Modal,
  message,
  ConfigProvider,
  Pagination,
  Form,
} from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import BackPage from "../../../common/backPage";
import { nodeService } from "../../../../services/node.service";

import "./node-update.scss";
import store from "../../../../../store";
import { getNodeIdAction } from "../../../../../store/actionCreators";

let page = 1;
let pageSize = 10;

class NodeUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 详情
      nodeLogName: "节点升级",
      nodeObj: {},
      nodeId: null,
      nodeState: "",
      nodeFirmwareId: "",
      nodeFirmwareName: "",
      nodeFirmwareVersion: "",

      // 历史列表
      updateHistory: [],
      nodeAmount: 0,

      // 弹窗
      isCanUpdateVisible: false,
      canUpdatesList: [],
      canUpdatesAmount: 0,
      newestFirmware: null,
      page: 1,
      pageSize: 10,
      firmwareVersion: "",
    };
  }

  componentDidMount() {
    page = 1;
    pageSize = 10;
    if (this.props.location.state) {
      const nodeId = this.props.location.state.id;
      if (nodeId) {
        const action = getNodeIdAction(nodeId);
        store.dispatch(action);
        this.setState({
          nodeId: nodeId,
        });
        this.getNodeDetail(nodeId);
        this.getNodeUpdate(nodeId);
        this.getNodeUpdateHistory(nodeId);
        this.interval = setInterval(
          () => {
            this.getNodeDetail(nodeId);
            this.getNodeUpdate(nodeId);
            this.getNodeUpdateHistory(nodeId);
          },
          30000
        );
        this.getNodeCanUpdateFirmwareList(nodeId);
      }
    } else {
      const nodeId = store.getState().nodeId;
      if (nodeId) {
        this.setState({
          nodeId: nodeId,
        });
        this.getNodeDetail(nodeId);
        this.getNodeUpdate(nodeId);
        this.getNodeUpdateHistory(nodeId);
        this.interval = setInterval(
          () => {
            this.getNodeDetail(nodeId);
            this.getNodeUpdate(nodeId);
            this.getNodeUpdateHistory(nodeId);
          },
          30000
        );
        this.getNodeCanUpdateFirmwareList(nodeId);
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  getNodeUpdate(nodeId) {
    nodeService.getUpgradeFirmwares(nodeId).then((res) => {
      const firmwares = res.firmwares[0]?.firmwareVersion;
      this.setState({
        firmwareVersion: firmwares,
      });
    });
  }
  getNodeDetail(nodeId) {
    nodeService.getNodeDetail(nodeId || this.state.nodeId).then(
      (res) => {
        if (res.code === 200) {
          const node = res.node;
          this.setState({
            nodeObj: node,
            nodeLogName: "节点升级 -- " + node.name,
            nodeState: node.state,
            nodeFirmwareId: node.nodeFirmwareId,
            nodeFirmwareName: node.firmwareName,
            nodeFirmwareVersion: node.firmwareVersion,
          });
        }
        //  else {
        //     message.error('获取节点详情失败');
        // }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }

  getNodeUpdateHistory(nodeId) {
    this.setState({
      page,
      pageSize,
    });

    nodeService
      .getNodeUpdateHistory(nodeId || this.state.nodeId, page, pageSize)
      .then(
        (res) => {
          if (res.code === 200) {
            const nodeUpdates = res.nodeUpgrades || [];
            nodeUpdates.forEach((element) => {
              element.key = element.id;
            });
            this.setState({
              updateHistory: nodeUpdates,
              nodeAmount: res.dataCount,
            });
          }
          // else {
          //     message.error('获取更新记录失败，' + res.message);
          // }
        },
        (error) => {
          error && message.error(error);
        }
      );
  }

  goback() {
    if (this.props.onSubmit) {
      var { showManage } = this.state;
      this.props.onSubmit({ showManage });
    }
    this.props.history.go(-1);
  }

  showUpdate() {
    this.getNodeCanUpdateFirmwareList();
    this.setState({
      isCanUpdateVisible: true,
    });
  }
  getNodeState(record) {
    if (record.state === "UNCONNECTED") {
      return (
        <div>
          <div className="common-deep-gray"></div> <span>未纳管</span>
        </div>
      );
    } else if (record.state === "RUNNING") {
      return (
        <div>
          <div className="common-green"></div> <span>运行中</span>
        </div>
      );
    }
    if (record.state === "UPGRADEWAITING") {
      return (
        <div>
          <div className="common-yellow"></div> <span>待升级</span>
        </div>
      );
    } else if (record.state === "UPGRADING") {
      return (
        <div>
          <div className="common-yellow"></div> <span>升级中</span>
        </div>
      );
    } else if (record.state === "DISABLED") {
      return (
        <div>
          <div className="common-gray"></div> <span>禁用</span>
        </div>
      );
    } else if (record.state === "OFFLINE") {
      return (
        <div>
          <div className="common-red"></div> <span>不在线</span>
        </div>
      );
    } else if (record.state === "DELETED") {
      return (
        <div>
          <div className="common-red"></div> <span>已删除</span>
        </div>
      );
    } else {
      return "";
    }
  }
  getNodeCanUpdateFirmwareList(nodeId) {
    nodeService.getNodeCanUpdateFirmware(nodeId || this.state.nodeId).then(
      (res) => {
        if (res.code === 200) {
          const firmwares = res.firmwares;
          firmwares.forEach((element) => {
            element.key = element.id;
          });
          this.setState(
            {
              canUpdatesList: firmwares,
              newestFirmware: firmwares[firmwares.length - 1],
            },
            function () {
              console.log(this.state.newestFirmware);
            }
          );
        }
        // else {
        //     message.error('获取升级列表失败');
        // }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  handleOk() {
    this.getNodeDetail();
    this.getNodeUpdateHistory();
    this.handleCancel();
  }
  handleCancel() {
    this.setState({ isCanUpdateVisible: false });
  }
  getState(record) {
    if (record.state === "1" || record.state === 1) {
      return (
        <div>
          <div className="common-green"></div> <span>可用</span>
        </div>
      );
    } else if (record.state === "0" || record.state === 0) {
      return (
        <div>
          <div className="common-red"></div> <span>禁用</span>
        </div>
      );
    } else {
      return "";
    }
  }
  render() {
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.getNodeUpdateHistory();
    };
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.getNodeUpdateHistory();
    };
    const columns = [
      {
        title: "epack版本",
        dataIndex: "firmwareVersion",
      },
      {
        title: "描述",
        dataIndex: "description",
        width: "15%",
      },
      {
        title: "状态",
        dataIndex: "state",
        render: (text, record) => (
          <>
            {text === "WAITING" && (
              <div>
                <div className="common-yellow"></div> <span>待升级</span>
              </div>
            )}
            {text === "UPGRADING" && (
              <div>
                <div className="common-yellow"></div> <span>升级中</span>
              </div>
            )}
            {text === "SUCCESS" && (
              <div>
                <div className="common-green"></div> <span>成功</span>
              </div>
            )}
            {text === "FAILED" && (
              <div>
                <div className="common-red"></div> <span>失败</span>
              </div>
            )}
          </>
        ),
      },
      {
        title: "更新时间",
        dataIndex: "updateTime",
      },
      {
        title: "原因",
        dataIndex: "reason",
      },
    ];
    return (
      <>
        <div className="node-log-container">
          <BackPage
            onClick={() => this.goback()}
            headeTitle={this.state.nodeLogName}
            itemTitle="边缘节点"
            itemSubTitle="节点升级"
          />

          <div
            style={{
              height: "240px",
              backgroundColor: "#fff",
              marginBottom: "12px",
              padding: "24px",
              paddingBottom: "0px",
            }}
          >
            <Form {...formItemLayout} labelAlign="left">
              <Form.Item name="state" label="状态">
                {this.getNodeState(this.state.nodeObj)}
              </Form.Item>
              <Form.Item name="version" label="epack版本">
                {this.state.nodeFirmwareVersion || "暂无数据"}
                {this.state.nodeObj?.upgradeable === "true" ? (
                  <ArrowUpOutlined
                    style={{
                      fontSize: 12,
                      color: "#389e0d",
                      marginLeft: "8px",
                    }}
                  />
                ) : (
                  ""
                )}
              </Form.Item>
              <Form.Item name="new" label="最新版本">
                <span style={{ fontSize: "12px" }}>
                  {this.state.firmwareVersion || "暂无数据"}
                </span>
                {this.state.nodeObj?.state === "RUNNING" ? (
                  <Button
                    className="node-update"
                    onClick={() => this.showUpdate()}
                  >
                    更新
                  </Button>
                ) : (
                  ""
                )}
              </Form.Item>
              <Form.Item name="messgge" label="">
                <div
                  style={{
                    fontWeight: 700,
                    color: "#73d13d",
                  }}
                >
                  <span>更新成功后，请重启小站</span>
                </div>
              </Form.Item>
            </Form>
          </div>
          <div className="update-content">
            <div className="update-list">更新记录</div>
            <div className="update-table">
              <Table
                rowKey={(record) => record.id}
                bordered={false}
                pagination={false}
                columns={columns}
                dataSource={this.state.updateHistory}
              />
            </div>
            <div>
              {this.state.updateHistory.length > 0 && (
                <div
                  className="common-bottom-pagination"
                  style={{ paddingBottom: 16 }}
                >
                  <Pagination
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={1}
                    current={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.nodeAmount}
                    onChange={onPageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <UpdateNode
          handleOk={() => this.handleOk()}
          nodeId={this.state.nodeId}
          canUpdatesList={this.state.canUpdatesList}
          isCanUpdateVisible={this.state.isCanUpdateVisible}
          handleCancel={() => this.handleCancel()}
        ></UpdateNode>
      </>
    );
  }
}
export default NodeUpdate;

function UpdateNode(props) {
    const update = (event) => {
        nodeService.nodeUpdateFirmware(props.nodeId, event.id).then(res => {
            if (res.code === 200) {
                message.success('开始更新');
                props.handleOk();
            } 
            // else {
            //     message.error('更新失败，' + res.message)
            // }
        }, error => {
            error && message.error(error);
        });
    }
    const columns = [
        {
            title: 'epack名称',
            dataIndex: 'firmwareName',
        },
        {
            title: 'epack版本',
            dataIndex: 'firmwareVersion',
        },
        {
          title: '类型',
          dataIndex: 'isPrivate',
          key: 'isPrivate',
          render: (text, record) => {
              return text===0?'公共版':'专属版'
          }
        },
        {
            title: '描述',
            dataIndex: 'description',
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (text, record) => (
                <>
                    <a href onClick={() => update(record)}>更新</a>
                </>
            ),
        }
    ];

    return (
        <Modal title="可更新版本" visible={props.isCanUpdateVisible} onCancel={props.handleCancel}
            footer={null}
        >
            <Table
                rowKey={record => record.id}
                bordered={false}
                pagination={false}
                columns={columns}
                dataSource={props.canUpdatesList}
            />

        </Modal>

    );
}