import React from "react";
import {
  Table,
  Pagination,
  ConfigProvider,
  Modal,
  message,
  Button,
  Form,
  Input,
  Breadcrumb 
} from "antd";
import {
  ExclamationCircleOutlined,
  DownloadOutlined,
  FolderOpenOutlined,
  FileOutlined,
  DeleteOutlined 
} from "@ant-design/icons";
import zhCN from "antd/lib/locale/zh_CN";

import { mirrorService } from "../../../../services/operation.service";
import { nodeService } from "../../../../services/node.service";
import { connect } from "react-redux";

import { AUTCONFIG } from "../../../../../utils/auth.config";
import "./../../../../../index.scss";
import store from "../../../../../store";
import { searchRule } from "../../../../../utils/search-util";
const { confirm } = Modal;

//查询
let seriesNumber = "";
let page = 1;
let pageSize = 10;
let dowmloadLists = [];
let count = 0;
let key = 0;
let once = true;
let beIn = false; //进入层级目录标志
function Search(props) {
  const form = React.createRef();
  const onFinish = (values) => {
    let name = "";
    if (values.seriesNumber != null) {
      name = searchRule(values.seriesNumber);
    }
    seriesNumber = name || "";
    if (seriesNumber) {
      page = 1;
      pageSize = 10;
      props.onClick_1();
    } else {
      page = 1;
      pageSize = 10;
      props.onClick_2();
    }
  };
  const resetForm = () => {
    form.current.resetFields();
    seriesNumber = "";
    page = 1;
    pageSize = 10;
    props.onClick_2();
  };

  return (
    <div>
      <div className="common-search-form">
        <Form
          ref={form}
          name="advanced_search_user"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          layout="inline"
          // style={{ justifyContent: "center" }}
        >
          <Form.Item
            name="seriesNumber"
            label="节点SN"
            style={{ marginTop: "8px" }}
          >
            <Input placeholder="请输入节点SN号" />
          </Form.Item>
          <Form.Item style={{ marginTop: "8px" }}>
            <Button type="primary" htmlType="submit">
              查询
            </Button>
            <Button style={{ margin: "0 9.46px" }} onClick={resetForm}>
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
let upLevelData = "";
let upLevelDataKey = 0;
class CloudStorage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPositon: "",
      currentFilesData: {},
      getDownloadLoading: false,
      filesList: [], //表格数据
      filesFullList: [], //进入层级后保存的完整数据，为分页做准备
      filesAmount: 0,
      fileDownloadUrl: "",
      // deleteIds: [],
      showManage: true,
      loading: true,
      isModalVisible: false,
      page: 1,
      pageSize: 10,
      page2: 1,
      pageSize2: 10,
      filesAmount2: 0,
      nodeList: [],
      nodeAmount: 0,
      labelList: [],
      actionIds: [],
      actionRows: [],
      currentkeys: [],
      // selectedLable: [],
      history: require("history").createHashHistory,
    };
  }
  componentDidMount() {
    page = 1;
    pageSize = 10;
    seriesNumber = "";
    this.getNodeListInfo();
    // this.getCloudStorageFiles();
  }
  componentWillUnmount() {
    once = true;
    beIn = false;
  }
  //获取节点下文件目录
  searchList() {
    upLevelData = "";
    once = true;
    beIn = false;
    this.setState({
      loading: true,
      page,
      pageSize,
      page2: 1,
      pageSize2: 10,
      currentPositon: "",
      currentFilesData: {},
    });
    mirrorService
      .getCloudFiles(seriesNumber)
      .then((res) => {
        if (res.code === 200) {
          let data_mid = [];
          key = 0;
          if (Object.keys(res.fileTree).length !== 0) {
            data_mid.push(res.fileTree);
            this.readNodes(data_mid);
            this.setState({
              filesList: data_mid,
              filesAmount: 1,
              loading: false,
            });
          } else {
            nodeService
              .getNodeList("","","","","","",seriesNumber,"","","",pageSize,1,)
              .then((res) => {
                console.log(res);
                if (res.code === 200) {
                  if (res.nodes[0]?.seriesNumber === seriesNumber) {
                    const fakeData = {
                      qcPath: res.nodes[0].name,
                      qcFile: [],
                      childPath: [],
                    };
                    data_mid.push(fakeData);
                    this.readNodes(data_mid);
                  }
                  this.setState({
                      filesList: data_mid,
                      filesAmount: 1,
                      loading: false,
                    });
                }
              }).catch((error) => {
                this.setState({
                  loading: false,
                  filesList: [],
                });
                message.error(error);
              });;
          }
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          filesList: [],
        });
        message.error(error);
      });
  }
  //文件目录数据处理
  readNodes(nodes = []) {
    for (let item of nodes) {
      item.key = key;
      key++;
      if (item?.qcFile) {
        for (let info of item.qcFile) {
          const newInfo = this.handleData(info);
          item.childPath.push(newInfo);
        }
      }
      if (item?.childPath && item?.childPath?.length) {
        this.readNodes(item.childPath);
      }
    }
  }
  handleData(data) {
    data.alias = data?.name;
    const nameArr = data?.name.split("/");
    data.name = nameArr[nameArr.length - 1];
    return data;
  }

  // 获取文件列表
  // getCloudStorageFiles() {
  //   upLevelData = "";
  //   once = true;
  //   beIn = false;
  //   this.setState({
  //     loading: true,
  //     page,
  //     pageSize,
  //     page2: 1,
  //     pageSize2: 10,
  //     currentPositon: "",
  //     currentFilesData: {},
  //   });
  //   mirrorService.getCloudStorageFiles(pageSize, page).then(
  //     (res) => {
  //       console.log(res);
  //       if (res.code === 200) {
  //         key = 0;
  //         const data_mid = res.fileTree;
  //         this.readNodes(data_mid);
  //         this.setState({
  //           filesList: data_mid,
  //           filesAmount: res.dataCount,
  //           loading: false,
  //         });
  //       }
  //     },
  //     (error) => {
  //       this.setState({
  //         loading: false,
  //       });
  //       error && message.error(error);
  //     }
  //   );
  // }
  // 获取节点
  getNodeListInfo() {
    upLevelData = "";
    once = true;
    beIn = false;
    this.setState({
      loading: true,
      page,
      pageSize,
      page2: 1,
      pageSize2: 10,
      currentPositon: "",
      currentFilesData: {},
    });

    nodeService
      .getNodeList("", "", "", "", "", "", "", "", "", "", pageSize, page)
      .then(
        (res) => {
          this.setState({
            loading: false,
          });
          if (res.code === 200) {
            const nodes = res.nodes;
            nodes.forEach((element) => {
              element.key = element.id;
            });
            this.setState({
              filesList: nodes,
              filesAmount: res.dataCount,
            });
          }
        },
        (error) => {
          this.setState({
            loading: false,
          });
          error && message.error(error);
        }
      );
  }
  // 文件删除
  showDeleteConfirm(record) {
    console.log(record);
    confirm({
      okText: "确认",
      cancelText: "取消",
      title: `确认删除吗？`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        if (record.alias) {
          this.deleteAction(record.alias);
        } else {
          this.deleteAction(record.name);
        }
      },
      onCancel() {},
    });
  }
  deleteAction(name) {
    mirrorService.deleteCloudFile(name).then(
      (res) => {
        if (res.code === 200) {
          message.success("操作成功");
          if (seriesNumber) {
            this.searchList();
          } else {
            if (this.state.filesList.length === 1) {
              page = page - 1;
              page = page ? page : 1;
            }
            this.getNodeListInfo();
          }
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  // 文件下载
  showDownloadConfirm(record) {
    if (record.alias) {
      this.downloadAction(record.alias);
    } else {
      this.downloadAction(record.name);
      // this.downloadAction(record.name);
    }
  }
  downloadAction(name) {
    mirrorService.getFilesDownload(name).then(
      (res) => {
        console.log(res.url);
        if (res.code === 200) {
          this.showConfirm(res.url);
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  //批量下载
  downloadBath() {
    count = 0;
    dowmloadLists = [];
    const selectIds = this.state.actionIds;
    if (selectIds.length === 0) {
      message.warning("请先选择文件！");
    }
    // else if (selectIds.length > 8) {
    //   message.warning('最多选择9个文件！')
    //   selectIds.forEach(ele => this.getDownloadUrl(ele));
    // }
    else {
      this.setState({
        getDownloadLoading: true,
      });
      selectIds.forEach((ele) => this.getDownloadUrl(ele));
    }
  }
  // 批量文件下载
  getDownloadUrl(key) {
    mirrorService.getFilesDownload(key).then(
      (res) => {
        if (res.code === 200) {
          dowmloadLists.push(res.url);
          count++;
          if (count === this.state.actionIds.length) {
            this.setState({
              getDownloadLoading: false,
            });
            this.showBathConfirm(dowmloadLists);
          }
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  download(url, triggerDelay, removeDelay) {
    //动态添加iframe，设置src，然后删除
    let iframe = document.createElement("iframe");
    //src设置下载地址
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);
  }

  showBathConfirm(urlList) {
    confirm({
      okText: "确认",
      cancelText: "取消",
      title: `确认下载吗？`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        urlList.forEach((ele, index) => {
          this.download(ele, 100, 2000);
        });
      },
      onCancel() {},
    });
  }
  showConfirm(url) {
    confirm({
      okText: "确认",
      title: `确认下载吗？`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        window.location.href = url;
      },
      onCancel() {},
    });
  }

  handleAddCancel = () => {
    this.setState({
      isAddVisible: false,
      initCities: [],
      initSecondCity: "",
      citiesInfo: {},
      secondCityInfo: {},
      selectLicences: {},
    });
  };

  handleFile(record) {
    if(seriesNumber){
      console.log(record);
      this.handleDirectory(record)
    }else{
      if (beIn) {
      this.handleDirectory(record);
      console.log(record);
    } else {
      this.searchList_2(record);
    }
    }
  }

  //获取节点下文件目录
  searchList_2(record) {
    upLevelData = "";
    once = true;
    beIn = false;
    this.setState({
      loading: true,
      page,
      pageSize,
      page2: 1,
      pageSize2: 10,
      currentPositon: "",
      currentFilesData: {},
    });
    mirrorService
      .getCloudFiles(record.seriesNumber)
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          key = 0;
          let data_mid=[]
          if (Object.keys(res.fileTree).length !== 0) {
            data_mid.push(res.fileTree);
            this.readNodes(data_mid);
            this.handleDirectory(data_mid[0]);
            this.setState({
              loading: false,
            });
          }else{
            this.setState({
              filesList: data_mid,
              loading: false,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          filesList: [],
        });
        message.error(error);
      });
  }

  // 文件层级查找
  handleDirectory(record) {
    // console.log(record);
    beIn = true;
    let filesPageData = [];
    const midData = this.state.currentFilesData;
    if (once) {
      //完整数据，防止被覆盖
      midData.fullData = record;
      once = !once;
      this.handleFullPath([record], "", "", record.key);
    } else {
      this.handleFullPath([midData.fullData], "", "", record.key);
    }
    midData[record.qcPath] = record.childPath;
    const info = record?.childPath;
    filesPageData = info.slice(0, 10);
    this.setState({
      page2: 1,
      pageSize2: 10,
      filesAmount2: info.length,
      filesFullList: info,
      filesList: filesPageData,
      currentPositon: record.qcPath,
    });
  }
  // 文件完整路径处理
  handleFullPath(data = [], upData = "", key1 = "", match = "") {
    //待查数组，上一层数据，上一层数据唯一标志，待查值
    for (let item of data) {
      let macth1 = match;
      let qc = item?.qcPath;
      let key = item?.key;
      if (item.key === macth1) {
        upLevelData = upData;
        upLevelDataKey = key1; //FIXME:可以用一个整体的对象优化
      }
      if (item?.childPath && item?.childPath?.length) {
        this.handleFullPath(item.childPath, qc, key, macth1);
      }
    }
  }

  //上一级处理
  handleUpPath(val) {
    if (!val) {
      once = true;
      beIn = false;
      this.setState({
        currentFilesData: {},
        currentPositon: val,
      });
      if (seriesNumber) {
        this.searchList();
      } else {
        this.getNodeListInfo();
      }
    } else {
      let filesPageData = [];
      const midData = this.state.currentFilesData;
      filesPageData = midData[val].slice(0, 10);
      this.setState({
        currentPositon: val,
        filesList: filesPageData,
        filesFullList: midData[val],
        page2: 1,
        pageSize2: 10,
        filesAmount2: midData[val].length,
      });
      this.handleFullPath([midData.fullData], "", "", upLevelDataKey);
    }
  }

  render() {
    const onPageChange = (pageNumber, ps) => {
      page = pageNumber;
      if (ps !== pageSize) {
        page = 1;
      }
      pageSize = ps;
      if (seriesNumber) {
        this.searchList();
      } else {
        this.getNodeListInfo();
      }
    };

    const onPageChange2 = (pageNumer, ps) => {
      const filesPageFull = this.state.filesFullList;
      const pageSize_2 = this.state.pageSize2;
      let info = [];
      let dataCount = 0;
      info = filesPageFull.slice((pageNumer - 1) * ps, pageNumer * ps);
      dataCount = filesPageFull.length;
      this.setState({
        page2: pageNumer,
        pageSize2: ps,
        filesAmount2: dataCount,
        filesList: info,
      });
      if (ps !== pageSize_2) {
        info = filesPageFull.slice(0, ps);
        this.setState({
          page2: 1,
          filesList: info,
        });
      }
    };
    // 判断文件类型
    const Ttype = (fileName) => {
      let suffix = "";
      // 获取类型结果
      let result = "";
      const fileArr = fileName.split(".");
      suffix = fileArr[fileArr.length - 1];
      if (suffix !== "") {
        suffix = suffix.toLowerCase();
        const filesList = [
          "cd",
          "wave",
          "aiff",
          "mpeg",
          "midi",
          "wma",
          "vgf",
          "ogg",
          "ape",
          "flac",
          "aac",
          "mp3",
          "mp4",
          "flv",
          "f4v",
          "avi",
          "mov",
          "wmv",
          "rmvb",
          "wav",
          "mpg",
          "ogv",
          "webm",
          "hevc",
        ];
        result = filesList.find((item) => item === suffix);
      }
      return result;
    };
    const sysColumns = [
      {
        title: "文件名称",
        dataIndex: "name",
        render: (text, record) => (
          <>
            {record.qcPath || record.seriesNumber ? (
              <a
                href
                onClick={() => {
                  this.handleFile(record);
                }}
              >
                <FolderOpenOutlined style={{ fontSize: 20, marginRight: 8 }} />
                {record.qcPath || record.seriesNumber || record.name}
              </a>
            ) : (
              <span>
                <FileOutlined style={{ fontSize: 20, marginRight: 8 }} />
                {record.seriesNumber || record.name || record.qcPath}
              </span>
            )}
          </>
        ),
      },
      {
        title: "文件大小",
        dataIndex: "size",
        render: (text, record) => (
          <>
            {!isNaN(record.size)
              ? `${(text / 1024 / 1024).toFixed(2)} MB`
              : "---"}
          </>
        ),
      },
      {
        title: "操作",
        dataIndex: "action",
        width: "16%",
        render: (text, record) => (
          <>
            {record?.eTag ? (
              <Button
                style={{ marginRight: 10 }}
                icon={<DownloadOutlined />}
                size="small"
                onClick={() => this.showDownloadConfirm(record)}
              >
                下载
              </Button>
            ) : (
              " "
            )}
            {record?.eTag ? (
              <Button
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => this.showDeleteConfirm(record)}
              >
                删除
              </Button>
            ) : (
              "---"
            )}
            {/* <a onClick={() => this.showDownloadConfirm(record)}>下载<ArrowDownOutlined style={{ fontSize: 12, color: '#389e0d', marginLeft: '4px' }} /></a> */}
          </>
        ),
      },
    ];
    const columns = [
      {
        title: "文件名称",
        dataIndex: "name",
        render: (text, record) => (
          <>
            {Ttype(record.name || record.qcPath) ? (
              <span>
                <FileOutlined style={{ fontSize: 20, marginRight: 8 }} />
                {record.name || record.qcPath}
              </span>
            ) : (
              <span>
                <FolderOpenOutlined style={{ fontSize: 20, marginRight: 8 }} />
                {record.name || record.qcPath}
              </span>
            )}
          </>
        ),
      },
      {
        title: "文件大小",
        dataIndex: "size",
        render: (text, record) => (
          <>
            {!isNaN(record.size)
              ? `${(text / 1024 / 1024 / 1024).toFixed(3)} GB`
              : "---"}
          </>
        ),
      },
      {
        title: "操作",
        dataIndex: "action",
        width: "16%",
        render: (text, record) => (
          <>
            {record?.eTag ? (
              <Button
                style={{ marginRight: 10 }}
                icon={<DownloadOutlined />}
                size="small"
                onClick={() => this.showDownloadConfirm(record)}
              >
                下载
              </Button>
            ) : (
              "---"
            )}
            {record?.eTag ? (
              <Button
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => this.showDeleteConfirm(record)}
              >
                删除
              </Button>
            ) : (
              "---"
            )}

            {/* <a onClick={() => this.showDownloadConfirm(record)}>下载<ArrowDownOutlined style={{ fontSize: 12, color: '#389e0d', marginLeft: '4px' }} /></a> */}
          </>
        ),
      },
    ];

    return (
      <>
        <div className="common-pd20">
          <div
            style={{
              display: this.state.showManage === true ? "block" : "none",
            }}
          >
            <h1 className="common-top-title0">网络存储</h1>
            <div className="common-center-container ">
              <Search
                onClick_1={() => this.searchList()}
                onClick_2={() => this.getNodeListInfo()}
              />
              <div className="oper-button">
                <a
                  href
                  style={{ marginRight: 15 }}
                  onClick={() => {
                    this.handleUpPath(upLevelData);
                  }}
                >
                  返回上一级:
                </a>
                <>
                  <span>{upLevelData}</span> {upLevelData ? ">" : ""}{" "}
                  <span style={{ fontWeight: 700 }}>
                    {this.state.currentPositon}
                  </span>
                </>
              </div>
              <div className="user-table-card">
                {/* 
                    rowSelection={{
                    ...rowSelection,
                  }} */}
                {store.getState().currentUser.type !== AUTCONFIG.SUB.value ? (
                  <Table
                    // rowSelection={{
                    //   ...rowSelection,
                    // }}
                    // rowKey={(record) => record.key}
                    bordered={false}
                    pagination={false}
                    tableLayout="fixed"
                    columns={sysColumns}
                    loading={this.state.loading}
                    dataSource={this.state.filesList}
                    // dataSource={this.state.filesList}
                  />
                ) : (
                  <Table
                    // rowKey={(record) => record.name}
                    bordered={false}
                    tableLayout="fixed"
                    loading={this.state.loading}
                    pagination={false}
                    columns={sysColumns}
                    dataSource={this.state.filesList}
                  />
                )}
              </div>
              <div>
                {this.state.filesList.length > 0 && !beIn && (
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination
                        showSizeChanger
                        defaultCurrent={1}
                        current={this.state.page}
                        pageSize={this.state.pageSize}
                        total={this.state.filesAmount}
                        onChange={onPageChange}
                      />
                    </ConfigProvider>
                  </div>
                )}
                {this.state.filesList.length > 0 && beIn && (
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination
                        showSizeChanger
                        defaultCurrent={1}
                        current={this.state.page2}
                        pageSize={this.state.pageSize2}
                        total={this.state.filesAmount2}
                        onChange={onPageChange2}
                      />
                    </ConfigProvider>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// export default NodeManage;

export default connect((state) => ({
  currentUser: state.currentUser,
}))(CloudStorage);
