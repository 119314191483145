import React from 'react';
import { Form, Input, Select, message, Row, Col, InputNumber, Card , Divider, Button, Table, Modal, Space } from 'antd';
import { ExclamationCircleOutlined , PlusOutlined} from '@ant-design/icons';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { algorithmService } from '../../../../../services/algorithm-manage';
import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const { confirm } = Modal;

const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};

class ViewAlgorithmMangement extends React.Component{
  state = {
        loading: false,
        id: -1,
        name: '',
        versionNum: 0,
        createTime: '',
        updateTime: '',
        algorithmName: '',
        label: '',
        description: '',
        
        algorithmVersionList: '',
    }

    showDeleteConfirm(event) {
        confirm({
          title: '确定删除吗?',
          icon: <ExclamationCircleOutlined />,
          content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
          closable: true,
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            this.deleteAlgorithmVersion(event.id)
            
          },
          onCancel() {
    
          },
        });
    }
    
    changeType() {
      this.setState({
        loading: true
      });
      setTimeout(() => {
        this.setState({
            loading: false
          }); 
      }, 200)
  
      //刷新页面
      if (this.props.location.state) {
        const name = this.props.location.state.name;
        const id = this.props.location.state.id;
        console.log("查看算法详情的name为：", name);
        console.log("查看算法详情的id为：", id);
        this.getgetAlgorithmDetail(id, name)
        this.getAlgorithm(id, name,'','','')
        sessionStorage.setItem('algorithmId', id)
        sessionStorage.setItem('algorithmName', name)
        this.setState({
          id,
          name,
        });
      } else {
        if(sessionStorage.getItem('algorithmId')) {
          const name = sessionStorage.getItem('algorithmName');
          const id = sessionStorage.getItem('algorithmId');
          this.setState({
            id,
            name,
          });
          this.getgetAlgorithmDetail(id, name)
          this.getAlgorithm(id, name,'','','')
        } else {
          this.goback();
        }
      }
    }

    componentDidMount() {
        if (this.props.location.state) {
            const name = this.props.location.state.name;
            const id = this.props.location.state.id;
            console.log("查看算法详情的name为：", name);
            console.log("查看算法详情的id为：", id);
            this.getgetAlgorithmDetail(id, name)
            this.getAlgorithm(id, name,'','','')
            sessionStorage.setItem('algorithmId', id)
            sessionStorage.setItem('algorithmName', name)
            this.setState({
              id,
              name,
            });
          } else {
            if(sessionStorage.getItem('algorithmId')) {
              const name = sessionStorage.getItem('algorithmName');
              const id = sessionStorage.getItem('algorithmId');
              this.setState({
                id,
                name,
              });
              this.getgetAlgorithmDetail(id, name)
              this.getAlgorithm(id, name,'','','')
            } else {
              this.goback();
            }
          }
    }

    //调用算法详情接口
    getgetAlgorithmDetail(id, name) {
        algorithmService.getAlgorithmDetail(id, name,'','').then(res => {
            if (res.code === 200) {
              console.log(res);
              this.setState({
                  versionNum: res.namespace.algorithmNum,
                  createTime: res.namespace.createTime,
                  updateTime: res.namespace.updateTime,
                  algorithmName: res.namespace.name,
                  label: res.namespace.label,
                  description: res.namespace.description,
              })
            }
            }).catch(error => {
              error && message.error(error);
            })
    }

    //调用删除算法版本接口
    deleteAlgorithmVersion(id) {
      algorithmService.deleteAlgorithm(id).then(res => {
        if (res.code === 200) {
          message.success("删除成功！")
          this.changeType();
        }
        }).catch(error => {
          error && message.error(error);
        })
    }
    
    //调用显示算法版本列表
    getAlgorithm(id, algorithmName, algorithmVersion, limit, offset) {
      algorithmService.getAlgorithm(id, algorithmName, algorithmVersion, limit, offset).then(res => {
        if (res.code === 200) {
          console.log(res);
          this.setState({
            algorithmVersionList: res.algorithms,
          })
        }
        }).catch(error => {
          error && message.error(error);
        })
    }

    goback() {
        this.props.history.push('/home/algorithm-manage');
    }

    //添加算法版本
    addAlgorithmVersion(id, algorithmName) {
      this.props.history.push({ pathname: '/home/algorithm-version/add', state: { id: id, algorithmName: algorithmName } })
    }

    //编辑算法版本
    gotoEdit(text) {
        this.props.history.push({ pathname: '/home/algorithm-version/edit', state: { id: text.id ,algorithmName: text.algorithmName} });
    }
    
    //匹配标签类型
    getLableType = (record) => {
      if (record === '1') {
        return <span>视频</span>
      } else {
        return <span></span>
      }
    }


    render() {
        const confColumns = [
            {
              title: '版本',
              dataIndex: 'algorithmVersion',
            },
            {
              title: '描述',
              dataIndex: 'description',
            },
            {
              title: '创建时间',
              dataIndex: 'createTime',
            },
            {
              title: '更新时间',
              dataIndex: 'updateTime',
            },
            {
              title: '操作',
              render: (text, record) => (
                <Space size="middle">
                  <a onClick={() => this.gotoEdit(record)}>编辑</a>
                  <a onClick={() => this.showDeleteConfirm(record)}>删除</a>
                </Space>
              ),
            },
          ];
        return (
          <div className="common-pd20">
          <BackPage onClick={() => this.goback()} headeTitle={'查看算法--' + this.state.algorithmName} itemTitle="查看算法" itemSubTitle="查看任务" />
                <div className="common-create-center-container">
                <div className="common-create-page">
                <Form name="complex-form"
                  // onFinish={onFinish}
                  labelAlign="left"
                  ref={this.formRef}
                  {...formItemLayout}
                >
                      <Form.Item name="versionNum" label="版本数目">
                        {this.state.versionNum || 0} 个
                      </Form.Item>
                      
                      <Form.Item name="label" label="算法标签" >
                          {this.state.label}
                      </Form.Item>

                      <Form.Item name="description" label="描述">
                        {this.state.description || '---'} 
                      </Form.Item>
                  
                      <Form.Item name="createTime" label="创建时间">
                        {this.state.createTime || '---'}
                      </Form.Item>
                  
                  
                      <Form.Item name="updateTime" label="更新时间">
                        {this.state.updateTime || '---'}
                      </Form.Item>
                </Form>  
       
                    </div>
          <Divider/>
          <div className="common-create-page">
            <span>算法版本</span>
               <Button onClick={() => this.addAlgorithmVersion(this.state.id, this.state.algorithmName)} type="primary" style={{ fontSize: 13, marginBottom: 20, marginLeft: 20 }}>新增版本</Button>
             
            <div className="job-bottom-content">
              <Table columns={confColumns}
                rowKey={record => record.id}
                pagination={false}
                dataSource={this.state.algorithmVersionList}

              />
            </div>
          </div>
        </div>
        </div>
        )
    }
}

export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(ViewAlgorithmMangement);