import React from 'react';
import {  message, Steps, Result, Button, Card } from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, CloudServerOutlined } from '@ant-design/icons';
import './../../../../../index.scss';
import { nodeService } from '../../../../services/node.service';
import BackPage from '../../../common/backPage';

const { Step } = Steps;

class RemoteSSH extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            flag: false,
            status: '',
            name: this.props?.location.state?.name,
            id: this.props?.location.state?.id,
            stepCurrent: 0,
            stepStatus: "wait",
            stepTitle_1: "开始启动",
            stepTitle_2: "启动中",
            stepTitle_3: "成功",
            runTime: 0,
            sshAddress: '---',
            // sshPassword: '---',
            resultStatus: 'info',
            resultTitle: '远程SSH未启动',
            start: '启动服务',
            close: '停止服务',
            startDisabled: false,
            stopDisabled: true
        };
    }

    goback() {
        this.props.history.push('/home/node-manage');
    }
    componentDidMount() {
        const nodeId = this.props?.location.state?.id;
        const nodeName = this.props?.location.state?.name;
        if (nodeId) {
            sessionStorage.setItem('nodeName', nodeName || '');
            sessionStorage.setItem('nodeId', nodeId || '');
            this.setState({
                id: nodeId,
                name: nodeName
            })
        } else {
            if (sessionStorage.getItem('nodeName')) {
                this.setState({
                    id: sessionStorage.getItem('nodeId'),
                    name: sessionStorage.getItem('nodeName')
                })
            } else {
                this.goback();
            }
        }
        this.getSSHInfo();
        this.interval = setInterval(() => this.getSSHInfo(), 5000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
        sessionStorage.removeItem('nodeId');
        sessionStorage.removeItem('nodeName');
    }
    getStatus(info) {
        if (info?.state === 'STARTING') {
            this.setState({
                flag: true,
                status: info.state,
                stepCurrent: 1,
                stepTitle_1: "开始启动",
                stepTitle_2: "启动中",
                stepTitle_3: "成功",
                stepStatus: "process",
                runTime: info.runTime,
                sshAddress: '---',
                // sshPassword: '---',
                resultStatus: 'info',
                resultTitle: '远程SSH启动中',
                startDisabled: true,
                stopDisabled: true
            })
        }
        if (info?.state === 'STARTED') {
            this.setState({
                status: info.state,
                runTime: info.runTime,
                sshAddress: info.relayServerIp + ':' + info.relayServerPort,
                // sshPassword: info.sshPassword,
                resultStatus: 'success',
                resultTitle: '远程SSH已启动',
                startDisabled: true,
                stopDisabled: false,
                stepCurrent: 2,
                stepTitle_1: "开始启动",
                stepTitle_2: "启动中",
                stepTitle_3: "成功",
                stepStatus: "finish",
            })
        }
        if (info?.state === 'STOPPING') {
            this.setState({
                flag: true,
                status: info.state,
                stepCurrent: 1,
                stepTitle_1: "开始停止",
                stepTitle_2: "停止中",
                stepTitle_3: "停止",
                stepStatus: "process",
                runTime: info.runTime,
                sshAddress: info.relayServerIp + ':' + info.relayServerPort,
                // sshPassword: info.sshPassword,
                resultStatus: 'warning',
                resultTitle: '远程SSH停止中',
                startDisabled: true,
                stopDisabled: true
            })
        }
        if (info?.state === 'STOPPED') {
            this.setState({
                status: info.state,
            })
            if (this.state.flag) {
                this.setState({
                    runTime: info.runTime,
                    sshAddress: '---',
                    // sshPassword: '---',
                    resultStatus: 'error',
                    resultTitle: '远程SSH已停止',
                    startDisabled: false,
                    stopDisabled: true,
                    stepCurrent: 2,
                    stepTitle_1: "开始停止",
                    stepTitle_2: "停止中",
                    stepTitle_3: "停止",
                    stepStatus: "finish",
                })
            }
        }
        if (info?.state === 'FAILED') {
            this.setState({
                status: info.state,
            })
            if (this.state.flag) {
                this.setState({
                    stepCurrent: 2,
                    stepTitle_1: "开始启动",
                    stepTitle_2: "启动中",
                    stepTitle_3: "失败",
                    stepStatus: "error",
                    status: info.state,
                    runTime: info.runTime,
                    sshAddress: '---',
                    // sshPassword: '---',
                    resultStatus: 'error',
                    resultTitle: '远程SSH异常',
                    startDisabled: false,
                    stopDisabled: true
                })
            }

        }
    }
    getSSHInfo() {
        nodeService.getSSHInfo(this.state?.id || sessionStorage.getItem('nodeId')).then((res) => {
            console.log(res);
            if (res.code === 200) {
                this.getStatus(res.ssh[0])
            } 
            // else {
            //     message.error('获取SSH信息失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        }
        )
    }
    handleStart() {
        this.setState({
            flag: true
        });
        nodeService.startSSH(this.state?.id || sessionStorage.getItem('nodeId')).then((res) => {
            if (res.code === 200) {
                this.getSSHInfo(this.state?.id);
            } 
            // else {
            //     message.error('启动SSH失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
        // }else{
        //     nodeService.reStartSSH(this.state?.id).then((res) => {
        //         if (res.code === 200) {
        //             this.getSSHInfo(this.state?.id);
        //         }else {
        //             message.error('重启SSH失败' + res.message);
        //           }
        //     }, error => {
        //         error && message.error(error);
        //       }) 
        // }
    }
    handleClose() {
       
        nodeService.stopSSH(this.state?.id || sessionStorage.getItem('nodeId')).then((res) => {
            if (res.code === 200) {
                this.setState({
                    stepCurrent: 0,
                    stepTitle_1: "开始停止",
                    stepTitle_2: "停止中",
                    stepTitle_3: "停止",
                    flag: true
                })
                this.getSSHInfo(this.state?.id);
            } 
            // else {
            //     message.error('停止SSH失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
    }

    render() {
        return (
            <>
                <div className="common-pd20">
                    <BackPage onClick={() => this.goback()} headeTitle={"远程SSH---" + this.state.name} itemTitle="远程SSH" itemSubTitle="远程SSH" />
                    <div className="common-create-center-container">
                        <div className="common-create-page">
                            {(this.state.status === '' || this.state.status === 'FAILED' || this.state.status === 'STOPPED' || this.state.status === 'STARTED') && !this.state.flag ? '' :
                                <Steps current={this.state.stepCurrent} status={this.state.stepStatus}>
                                    <Step
                                        title={this.state.stepTitle_1}
                                    />
                                    <Step
                                        title={this.state.stepTitle_2}
                                        icon={this.state.stepCurrent === 1 ? <LoadingOutlined /> : ''}
                                    />
                                    <Step
                                        title={this.state.stepTitle_3}
                                    />
                                </Steps>}

                            <Result
                                status={this.state.resultStatus}
                                title={this.state.resultTitle}
                                subTitle={'运行时间:  ' + this.state.runTime + '分钟'}
                                icon={
                                    this.state.status === 'STARTING' ? <InfoCircleOutlined /> :
                                        (this.state.status === 'STARTED' ? <CheckCircleOutlined /> :
                                            (this.state.status === 'STOPPING' ? <InfoCircleOutlined /> :
                                                (this.state.status === 'STOPPED' && this.state.flag ? <CheckCircleOutlined /> :
                                                    (this.state.status === 'FAILED' && this.state.flag ? <CloseCircleOutlined /> : <CloudServerOutlined />))))
                                }
                                extra={
                                    [this.state.status === '' || this.state.status === 'STARTING' || this.state.status === 'STOPPED' || this.state.status === 'FAILED' ? ''
                                        : <Card style={{ marginBottom: 30 }}>
                                            <h4>SSH连接地址:  {this.state.sshAddress}</h4>
                                            {/* <h4>VNC密码:  {this.state.sshPassword}</h4> */}
                                        </Card>,
                                    <Button disabled={this.state.startDisabled} type="primary" key="console" onClick={() => { this.handleStart() }}>
                                        {this.state.start}
                                    </Button>,
                                    <Button key="buy" disabled={this.state.stopDisabled} onClick={() => { this.handleClose() }}>
                                        {this.state.close}
                                    </Button>,
                                    ]}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default RemoteSSH;