import { Form, Input, Col, Row, message, Descriptions, Button, Table, Modal, ConfigProvider, Pagination, Upload, Select } from 'antd';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';

import React from 'react';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { firmwareService } from '../../../../../services/firmware.service';

import '../../../../../../css/modal.scss';
import { getOtaFirmwareIdAction } from '../../../../../../store/actionCreators';
import store from '../../../../../../store';
import { AUTCONFIG } from '../../../../../../utils/auth.config';
import { connect } from "react-redux";

const { TextArea } = Input;
const { confirm } = Modal;
const {Option} = Select;

const formItemLayout = {
  labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4 },
  wrapperCol: { xs: 24, sm: 12, md: 15 },
};

let searchSoftPage = 1;
let searchSoftPageSize = 20;


class ViewFirmware extends React.Component {
  formRef = React.createRef();
  editSoftFormRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true,

      firmwareId: null,
      firmwareObj: null,


      firmwareCreateTime: '',
      firmwareUpdateTime: '',
      firmwareTitle: '查看epack',




      softList: [],
      softAmount: 0,
      isAddSoftModalVisible: false,

      // soft detail
      softDetail: null,
      isSoftDetailModalVisible: false,
      showSoftDetailModalVisible: true,
      mode: null,
      history: require("history").createHashHistory,

    };
  }
  handleCancel = () => {
    this.setState({ isAddSoftModalVisible: false, isSoftDetailModalVisible: false, showSoftDetailChankanModal: false });
  };
  showAddSoft() {
    this.setState({
      isAddSoftModalVisible: true
    })
  }
  handleAddSoftOk() {
    this.setState({ isAddSoftModalVisible: false });
    this.getSoftList(this.state.firmwareId);
  }
  handleEditSoftOk() {
    this.setState({ isSoftDetailModalVisible: false });
    this.getSoftList(this.state.firmwareId);
  }

  showSoftDetailModal(record, key) {
    const soft = record;
    this.setState({ isSoftDetailModalVisible: true, softDetail: soft }, function () {
      this.editSoftFormRef.current.setFieldsValue({
        name: soft.softwareName || '',
        version: soft.softwareVersion || '',
        description: soft.description || '',
      });
    });
  }
  showSoftDetailChankanModal(record, key) {
    const soft = record;
    this.setState({ showSoftDetailChankanModal: true, softDetail: soft }, function () { });
  }
  getSoftList(firmwareId) {
    firmwareService.getFirmwareSoftwareList(firmwareId || this.state.firmwareId, searchSoftPage, searchSoftPageSize).then(res => { // softPage
      if (res.code === 200) {
        const soft = res.debs;
        soft.forEach(ele => {
          ele.key = ele.id
        });
        this.setState({
          softList: soft,
          softAmount: res.dataCount
        });
      }
    }, error => {
      error && message.error(error);
    })
  } // soft end


  goback() {
    // if (this.props.onSubmit) {
    //   var { showManage } = this.state;
    //   this.props.onSubmit({ showManage })
    // }
    const otaId = store.getState().otaId;
    this.props.history.push({ pathname: '/home/ota-factory/view/detail', state: { id: otaId } });
  }

  cancel = () => {
    this.formRef.current.setFieldsValue({
      name: this.state.firmwareObj.name,
      version: this.state.firmwareObj.version,
      description: this.state.firmwareObj.description,
    });
  }
  showDeleteSoftConfirm(id) {
    confirm({
      title: '确定删除软件吗?',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        firmwareService.removeFirmwareSoftware(id).then(res => {
          if (res.code === 200) {
            message.success('删除软件成功');
            searchSoftPage = 1;
            this.getSoftList(this.state.firmwareId);
          } 
          // else {
          //   message.error('删除软件失败，' + res.message);
          // }
        }, error => {
          error && message.error(error);
        });
      },
      onCancel() {
      },
    });
  }

  componentDidMount() {
    searchSoftName = '';
    searchSoftVersion = '';
    searchSoftPage = 1;
    searchSoftPageSize = 10;
    if (this.props.location.state) {
      const firmwareId = this.props.location.state.id;
      if (firmwareId) {
        const action = getOtaFirmwareIdAction(firmwareId);
        store.dispatch(action);
        this.setState({
          firmwareId: firmwareId
        });
        this.getFirmwareDetail(firmwareId);
      }
      const mode = this.props.location.state.mode;
      this.setState({
        mode: mode
      })
      sessionStorage.setItem('mode', mode)
      this.getSoftList(firmwareId);

    } else {
      const firmwareId = store.getState().otaFirId;
      if (firmwareId) {
        this.setState({
          firmwareId: firmwareId,
        });
        this.getFirmwareDetail(firmwareId);
      }
      this.setState({
        mode: sessionStorage.getItem('mode')
      })

      this.getSoftList(firmwareId);
    }
  }

  getFirmwareDetail(firmwareId) {
    firmwareService.getFirmwareDetail(firmwareId).then(res => {
      if (res.code === 200) {
        console.log('epack详情', res);
        const firmware = res.firmware;
        this.setState({
          firmwareObj: firmware,
          firmwareCreateTime: firmware.createTime,
          firmwareUpdateTime: firmware.updateTime,
          firmwareTitle: this.state.mode ? '查看epack -- ' + firmware.firmwareName : '编辑epack -- ' + firmware.firmwareName,
        });
        this.formRef.current?.setFieldsValue({
          name: firmware.firmwareName || '',
          version: firmware.firmwareVersion || '',
          description: firmware.description || '',
          url: firmware.url || '',
          isPrivate: firmware.isPrivate
        });
      } 
      // else {
      //   message.error('获取epack详情失败,' + res.message);
      // }
    }, error => {
      error && message.error(error);
    });
  }


  render() {
    const onFinish = (values) => {
      console.log(values);
      firmwareService.editFirmware(this.state.firmwareId, values.name, values.version, values.description, values.url, values.isPrivate).then(res => {
        if (res.code === 200) {
          message.success('修改成功');
          this.goback();
        } 
        // else {
        //   message.error('修改失败，' + res.message);
        // }
      }, error => {
        error && message.error(error);
      });
    };
    const columnsSoft = [
      {
        title: '软件名称',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => <a href onClick={() => this.showSoftDetailChankanModal(record, record.key)}>{text.split(/.deb$/g)[0]}</a>
      },
      {
        title: '大小',
        dataIndex: 'size',
        key: 'size',
        render: (text, record) => <span>{record.size} KB</span>
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime'
      }, {
        title: '更新时间',
        dataIndex: 'updateTime',
        key: 'updateTime'
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
          <>
            {/* <a href onClick={() => this.showSoftDetailModal(record, record.key)} style={{ marginRight: 10 }}>编辑</a> */}
            <a href onClick={() => this.showDeleteSoftConfirm(record.id)}>删除</a>
          </>
        ),
      }

    ];
    const columnsSoftAdmin = columnsSoft.slice(0, columnsSoft.length - 1)
    const onPageChange = (pageNumber) => {
      searchSoftPage = pageNumber;
      this.getSoftList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      searchSoftPageSize = pageSizeNumber;
      searchSoftPage = 1;
      this.getSoftList();
    }

    return (
      <>
        <div className="ota-detail-container">
          <BackPage onClick={() => this.goback()} headeTitle={this.state.firmwareTitle} itemTitle="OTA管理" itemSubTitle="查看产品epack" />
          <div className="common-edit-card">
            <Form name="basic"
              onFinish={onFinish}
              labelAlign="left"
              ref={this.formRef}
              {...formItemLayout} >
              <Row>
                <Col span={12}>
                  {
                    this.state.mode ?
                      <Form.Item name="name" label="epack名称" >
                        {this.state.firmwareObj?.firmwareName || '暂无数据'}
                      </Form.Item> :
                      <Form.Item name="name" label="epack名称" rules={[
                        { required: true, message: 'epack名称不能为空' },
                        { max: 64, message: 'epack名称不允许超过64个字符' },
                        { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g"), message: '请输入64位以内中英文/数字/中划线/下划线' }]} >
                        <Input placeholder="请输入epack名称" />
                      </Form.Item>
                  }

                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {
                    this.state.mode ?
                      <Form.Item name="description" label="描述" >
                        {this.state.firmwareObj?.description || '暂无数据'}
                      </Form.Item> :
                      <Form.Item name="description" label="描述" >
                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                      </Form.Item>}
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {
                    this.state.mode ?
                      <Form.Item name="version" label="版本" >
                        {this.state.firmwareObj?.firmwareVersion || '暂无数据'}
                      </Form.Item> :
                      <Form.Item name="version" label="版本" rules={[
                        { required: true, message: '版本不能为空' },
                        { pattern: /^((\d+)\.){1,}(\d+)$/, message: 'epack版本格式不对,例如123.123.123' }]} >
                        <Input placeholder="请输入版本" />
                      </Form.Item>
                  }
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                    <Form.Item name="isPrivate" label="epack类型" >
                      {this.state.firmwareObj?.isPrivate===0?'公共版':'专属版' || '暂无数据'}
                    </Form.Item> 
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {
                    this.state.mode ?
                      <Form.Item name="url" label="URL"  >
                        {this.state.firmwareObj?.url || '暂无数据'}
                      {/* </Form.Item> : <Form.Item name="url" label="URL" rules={[{ required: true, message: 'URL不能为空' }, { max: 99, message: 'URL不允许超过99个字符' },]} > */}
                      </Form.Item> : <Form.Item name="url" label="URL" >
                        <Input placeholder="请输入URL" />
                      </Form.Item>}
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="创建时间" >
                    {this.state.firmwareCreateTime || '暂无数据'}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="更新时间"  >
                    {this.state.firmwareUpdateTime || '暂无数据'}
                  </Form.Item>
                </Col>
              </Row>
              {
                this.state.mode ? '' :
                  <BottomBtnGroup onClick={() => this.goback()} />}
            </Form>
          </div>
          {
            this.state.mode ?
              <div className="ota-bottom-card">
                <Row className="ota-bottom-card-title">
                  软件列表
                </Row>
                <div className="ota-bottom-content">

                  {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?
                    <Row style={{ marginBottom: 16 }}>
                      <Button type="primary" onClick={() => this.showAddSoft()}> 新增软件 </Button>
                    </Row> : ''}
                  <Row>
                    <Col span={24}>
                      <div className="user-table-card">
                        {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?

                          <Table
                            style={{ marginBottom: 20 }}
                            rowKey={record => record.id}
                            bordered={false}
                            pagination={false}
                            columns={columnsSoft}
                            dataSource={this.state.softList}
                          /> : <Table
                            style={{ marginBottom: 20 }}
                            rowKey={record => record.id}
                            bordered={false}
                            pagination={false}
                            columns={columnsSoftAdmin}
                            dataSource={this.state.softList}
                          />}
                      </div>
                      <div>
                        {
                          this.state.softList.length > 0 &&
                          <div className="common-bottom-pagination">
                            <ConfigProvider locale={zhCN}>
                              <Pagination showSizeChanger onShowSizeChange={onShowSizeChange} defaultCurrent={1} current={searchSoftPage} pageSize={searchSoftPageSize} total={this.state.softAmount} onChange={onPageChange} />
                            </ConfigProvider>
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </div> : ''}
        </div>

        <AddSoft
          isAddSoftModalVisible={this.state.isAddSoftModalVisible}
          handleAddSoftOk={() => this.handleAddSoftOk()}
          handleCancel={() => this.handleCancel()}
          firId={this.state.firmwareId} />
        <EditSoft isSoftDetailModalVisible={this.state.isSoftDetailModalVisible}
          editSoftFormRef={this.editSoftFormRef}
          handleEditSoftOk={() => this.handleEditSoftOk()}
          handleCancel={() => this.handleCancel()}
          softDetail={this.state.softDetail} />

        {/* onOk={() => this.editSoft()} onCancel={() => this.handleCancel()} */}
        <Modal width={850} title="查看软件" visible={this.state.showSoftDetailChankanModal} footer={null}
          onCancel={() => this.handleCancel()}>
          <Descriptions bordered column={2}>
            <Descriptions.Item label="软件名称" //span={2}包含列的数量
            > {this.state.softDetail?.name.split(/.deb$/g)[0] || '暂无数据'}</Descriptions.Item>
            <Descriptions.Item label="版本"> {this.state.softDetail?.version || '暂无数据'}</Descriptions.Item>
            <Descriptions.Item label="创建时间">{this.state.softDetail?.createTime || '暂无数据'}</Descriptions.Item>
            <Descriptions.Item label="更新时间">{this.state.softDetail?.updateTime || '暂无数据'}</Descriptions.Item>
            <Descriptions.Item label="描述">  {this.state.softDetail?.description || '暂无数据'}</Descriptions.Item>
          </Descriptions>
        </Modal>
      </>
    );
  }
}

export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(ViewFirmware);
// export default ViewFirmware;

// edit soft
function EditSoft(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {

    firmwareService.editFirmwareSoftware(props.softDetail.id, values.name, values.version, values.description).then(res => {
      if (res.code === 200) {
        message.success("编辑成功");
        props.handleEditSoftOk();
      } 
      // else {
      //   message.success("编辑失败，" + res.message);
      // }
    }, error => {
      error && message.error(error);
    });
  };
  const resetForm = () => {

    props.handleCancel();
  }
  return (
    <Modal width={600} title="编辑软件" visible={props.isSoftDetailModalVisible} footer={null}
      onCancel={resetForm}>
      <Form
        form={form}
        style={{ marginLeft: 120 }}
        name="edit_soft"
        labelAlign="left"
        ref={props.editSoftFormRef}
        {...formAddSoftItemLayout}
        onFinish={onFinish}
      >
        <Form.Item name='name' label='软件名称' rules={[{ required: true, message: '请输入软件名称' }]}>
          <Input placeholder="请输入软件名称" />
        </Form.Item>
        <Form.Item name='version' label='版本' rules={[{ required: true, message: '请输入版本' }]}>
          <Input placeholder="请输入软件版本" />
        </Form.Item>
        <Form.Item name="description" label="描述" >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
        </Form.Item>
        <Form.Item label="创建时间"  >
          {props.softDetail?.createTime || '暂无数据'}
        </Form.Item>
        <Form.Item label="更新时间"  >
          {props.softDetail?.updateTime || '暂无数据'}
        </Form.Item>
        <Form.Item >
          <div style={{ textAlign: 'center', width: '100%' }}>
            <Button type="primary" htmlType="submit"> 保存 </Button>
            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>取消 </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}
//// edit soft end===


const formAddSoftItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 13 },
};

// add AddSoft组件
class AddSoft extends React.Component {
  form = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
    };
  }
  handleUpload = () => {
    console.log('选中的文件', this.state.fileList)
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files', file);
    });
    // console.log(fileList);
    this.setState({
      uploading: true,
    });
    firmwareService.uploadDeb(this.props.firId, formData).then(res => {
      if (res.code === 200) {
        message.success("上传成功");
        // this.form.current?.resetFields();
        this.setState({
          fileList: [],
          uploading: false,
        });
        this.props.handleAddSoftOk();
      } 
      // else {
      //   message.error("创建失败，" + res.message);
      //   this.setState({
      //     uploading: false,
      //   });
      // }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        uploading: false,
      });
    });
  }

  onFinish = (values) => {
    const upList = this.state.fileList;
    if (upList.length === 0) {
      message.warning("请上传文件");
    } else {
      this.handleUpload();
    }
  };
  resetForm = () => {
    this.form.current.resetFields();
    this.props.handleCancel();
    if (this.state.uploading) { return }
    this.setState({
      fileList: [],
    });
  }
  render() {
    const { uploading, fileList } = this.state;
    const props = {
      multiple: true,
      onRemove: file => {
        if (uploading) {
          return false
        }
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        // console.log(file);
        let name = file.name;
        let reg_1 = /[\u4e00-\u9fa5]/g;
        let reg_2 = /.deb$/g;
        let res_1 = reg_1.test(name);
        let res_2 = reg_2.test(name);
        if (res_1 || !res_2) {
          message.warning('文件名不能为中文且必须为.deb结尾');
        } else {
          let res = this.state.fileList?.find(ele => {
            return ele.name === name
          })
          if (!res) {
            this.setState(state => ({
              fileList: [...state.fileList, file],
            }));
          } else {
            message.warning('文件已重复!')
          }
        }
        return false;
      },
      fileList,
    };
    return (
      <>
        <Modal
          title="新增软件"
          width={600}
          visible={this.props.isAddSoftModalVisible}
          onCancel={this.resetForm}
          footer={null}>
          <Form
            style={{ marginBottom: 10, marginLeft: 40 }}
            ref={this.form}
            name="add_soft"
            labelAlign="left"
            {...formAddSoftItemLayout}
            onFinish={this.onFinish} >
            <Form.Item
              name="upload"
              label="文件上传"
              rules={[
                { required: true, message: '请上传文件' },
              ]}
            >
              {/* {uploading ?  <Button type="primary" loading={uploading} icon={<UploadOutlined />}> {uploading ? '上传中' : '选择文件'}</Button> : 
              <Upload null {...props} >
          
                <Button type="primary" loading={uploading} icon={<UploadOutlined />}> {uploading ? '上传中' : '选择文件'}</Button>
              </Upload>} */}

              <Upload disabled={uploading} {...props} >
                <Button type="primary" loading={uploading} icon={<UploadOutlined />}> {uploading ? '上传中' : '选择文件'}</Button>
              </Upload>


            </Form.Item>
            {/* <Form.Item name='name' label='软件名称' rules={[
              { required: true, message: '请输入软件名称' },
              { max: 64, message: '软件名称不允许超过64个字符' },
              { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g"), message: '请输入64位以内中英文/数字/中划线/下划线' }]}>
              <Input placeholder="请输入软件名称" />
            </Form.Item>
            <Form.Item name='version' label='软件版本' rules={[{
              required: true, message: '请输入软件版本'
            },
            { pattern: /^((\d+)\.){1,}(\d+)$/, message: '软件版本格式不对,例如123.123.123' }]}>
              <Input placeholder="请输入软件版本" />
            </Form.Item>
            <Form.Item name="description" label="描述" >
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255} />
            </Form.Item> */}
            <div style={{ textAlign: 'center' }}>
              <Button type="primary" disabled={this.state.uploading ? true : false} htmlType="submit"> 确定 </Button>
              <Button style={{ margin: '0 9.46px' }} disabled={this.state.uploading ? true : false} onClick={this.resetForm}>取消 </Button>
            </div>
          </Form>
        </Modal>

      </>
    );
  }
}





let searchSoftName = '';
let searchSoftVersion = '';
// let versionList = [{

// }];

// const { Option } = Select;

function Search(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    searchSoftName = values.name || '';
    searchSoftVersion = values.version || '';
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchSoftName = '';
    searchSoftVersion = '';
    props.onClick();
  }
  // const onGenderChange = (value) => {
  //   searchFirwareVersion = value;
  // }
  // const getVersions = () => {
  //   return versionList.map((item, index) => {
  //     return (
  //       <Option value={item.id}>{item.name}</Option>
  //     );
  //   });
  // }
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish} >
          <Col span={24}>
            <Form.Item name='name' label='软件名称' style={{ float: 'left', marginRight: '7.14px' }}>
              <Input placeholder="请输入软件名称" />
            </Form.Item>
            <Form.Item name='version' label='版本' style={{ float: 'left', marginRight: '7.14px' }}>
              <Input placeholder="请输入版本" />
              {/* <Select
                placeholder="请选择版本"
                onChange={onGenderChange} >
                {getVersions()}
              </Select> */}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </>
  );
}