import React from 'react';
import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message,notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
// import CreateUser from './create-user/create-user';
import { userService } from '../../../../services/user.service';

import './../../../../../index.scss';
import './user-manage.scss';
// import { loginService } from '../../../../services/login';
import { connect } from "react-redux";
import store from '../../../../../store';
import { AUTCONFIG } from '../../../../../utils/auth.config';
import { searchRule } from '../../../../../utils/search-util'

const { Option } = Select;


const { confirm } = Modal;

let searchUsername = '';
let searchRealName = '';
let searchType = '';
let searchTenantUser = '';
let page = 1;
let pageSize = 10;
// let pagesizeLoading = false;
// let currentUser = null;

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {type:''};
    }
   form = React.createRef();
  
   onFinish = (values) => {
    let username = values.username || '';
    let str = searchRule(username)
    searchUsername = str || '';

    let realName = values.realName || '';
    let str1 = searchRule(realName)
    searchRealName = str1 || '';
    
    searchType = values.type || '';
    searchTenantUser = values.tenantUser || '';
    page = 1;
    this.props.onClick();
  };
   resetForm = () => {
       
    this.form.current.resetFields();
    searchUsername = '';
    searchRealName = '';
    searchType  = '';
    this.setState({
        type:  ''
    })
    this.form.current.setFieldsValue({ type: '' });
    searchTenantUser = '';
    page = 1;
    this.props.onClick();
  }
 
  componentDidMount() {
    this.resetForm();
  }

   onGenderChange = (value) => {
    
  }
 
  render() { 
        return (
            <>
            <div className="common-search-form">
                <Form
                 ref={this.form}
                name="advanced_search_user"
                className="ant-advanced-search-form"
                onFinish={this.onFinish}
                layout="inline"
                onValuesChange={this.onGenderChange}
                // style={{ justifyContent: 'center' }}
                 >

                <Form.Item name='username' label='账号名称' style={{  marginTop: '8px' }}>
                    <Input placeholder="请输入账号名称" />
                </Form.Item>
                <Form.Item name='realName' label='用户名称' style={{ marginTop: '8px' }}>
                    <Input placeholder="请输入用户名称" />
                </Form.Item>
                <Form.Item name='type' label='用户类型' style={{ marginTop: '8px' }}>
                    <Select
                        initialvalue=""
                        placeholder="请选择用户类型" >
                            <Option value="">全部</Option>
                             {/* 登录用户类型超级管理员 */}
                            {store.getState().currentUser?.type === AUTCONFIG.SUPERADMIN.value ? 
                               <Option value="0">超级管理员</Option> : ''
                             }
                             {/* 登录用户类型 超级管理员或者是管理员 */}
                             { (store.getState().currentUser?.type === AUTCONFIG.ADMIN.value  || 
                               store.getState().currentUser?.type === AUTCONFIG.SUPERADMIN.value) ? 
                               <Option value="3">管理员</Option> : ''
                             }
                            <Option value="1">主用户</Option>
                            <Option value="2">子用户</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={{ marginTop: '8px' }}>
                    <Button type="primary" htmlType="submit"> 查询 </Button>
                    <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
                </Form.Item>

                </Form>
            </div>
            </>
        );
    }
}

class UserManage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      userList: [],
      userAmount: 0,
      actionIds: [],
      loading: false,
      page: 1,
      pageSize: 10,
      showManage: true,
      showRegistered: false,
      showView: false,
      tenantList: [],
    //  history: require("history").createHashHistory,
    };
  }

  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        userService.removeUser(event).then(res => {
          if (res.code === 200) {
            message.success('删除成功');
            page  = 1;
            this.changeType();
          } 
          // else {
          //   message.error('删除失败，' + res.message);
          // }
        }, error => {
          error && message.error(error);
        })
      },
      onCancel() {

      },
    });
  }
  showEnaConfirm(event) {
    confirm({
      title: '确定启用吗?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        userService.changeUserState(event, 1).then(res => {
          if (res.code === 200) {
            message.success('启用用户成功');
            this.changeType();
          } 
          // else {
          //   message.error('启用用户失败，' + res.message);
          // }
        }, error => {
          error && message.error(error);
        });
      },
      onCancel() {
      },
    });
  }
  showDisConfirm(event) {
    confirm({
      title: '确定禁用吗?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        userService.changeUserState(event, 0).then(res => {
          if (res.code === 200) {
            message.success('禁用用户成功');
            
            this.changeType();
          } 
          // else {
          //   message.error('禁用用户失败，' + res.message);
          // }
        }, error => {
          error && message.error(error);
        });
      },
      onCancel() {
      },
    });
  }
  showMultiDeleteConfirm() {
    if (this.state.actionIds.length === 0) {
      message.warning('请先选择要删除的用户');
      return;
    }
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      
      onOk: () => {
        userService.removeUsers(this.state.actionIds).then(res => {
          if (res.code === 200) {
            message.success('删除成功');
            page  = 1;
            this.changeType();
          } 
          // else {
          //   message.error('删除失败，' + res.message);
          // }
        }, error => {
          error && message.error(error);
        })
      },
      onCancel() {

      },
    });
  }

  searchTenant() {
    this.setState({
      loading: true
    });

    userService.getTenantUserList(searchRealName, searchUsername, searchTenantUser, page, pageSize).then(res => {
      if (res.code === 200) {
        const users = res.users;
        users.forEach(element => {
          element.key = element.id;
        });
        this.setState({
          userList: users,
          loading: false,
          userAmount: res.dataCount
        });
      } 
      // else {
      //   this.setState({
      //     loading: false
      //   });
      //   message.error('获取用户列表失败');
      // }
    }, error => {
      this.setState({
        loading: false
      });
      error && message.error(error);
    });
  }
  changeType() {
      this.setState({
          page,
          pageSize
      })
  
      this.searchList();
  
  }
  searchList() {
    this.setState({
      loading: true
    });
    userService.getUserList(
      searchRealName.replace(/(^\s*)|(\s*$)/g,''),
      searchUsername.replace(/(^\s*)|(\s*$)/g,''), 
      searchType, 
      page, 
      pageSize).then(res => {
      if (res.code === 200) {
        const users = res.users;
        users.forEach(element => {
          element.key = element.id;
        });
        this.setState({
          userList: users,
          loading: false,
          userAmount: res.dataCount
        });
      } 
      // else {
      //   this.setState({
      //     loading: false
      //   });
      //   message.error('获取用户列表失败');
      // }
    }, error => {
      this.setState({
        loading: false
      });
      error && message.error(error);
    });
  }
  componentWillMount() {
   // this.getCurrentUser();
  }
  componentDidMount() {
  //  this.changeType();
    pageSize = 10;
    page = 1;
   
  }
  
  
  printContent(comment) {
    this.setState({
      showManage: true,
      showRegistered: false,
    });
    if (comment.showManage) {
      this.changeType();
    }
  }
  showUserToken=(text,record)=>{
    // console.log(record);
    userService.getUsersToken(record?.id).then(res=>{
        console.log(res);
        if(res.code===200){
          notification['success']({
          message: `${record.realName} 用户令牌：`,
          description:res.token,
          duration:0
       })}
       },error => {
        error && message.error(error);
      }
    )
  }
  gotoEdit = (text, key) => {
    this.props.history.push({ pathname: '/home/sys/user-manage/edit', state: { id: text } });
  }
  gotoDetail = (text, key) => {
    this.props.history.push({ pathname: '/home/sys/user-manage/detail', state: { id: text } });
  }
  
  gotoAddlabel = (text, key) => {
    this.props.history.push({ pathname: '/home/sys/user-manage/addlabel', state: { id: text } });
  }
  getUserType = (record) => {
    // if (record.type === 'SYS') {
    //   return <span>管理员</span>
    // } else if (record.type === 'SUB') {
    //   return <span>子用户</span>
    // } else if (record.type === 'MAIN') {
    //   return <span>主用户</span>
    // }
    let stringName  = '';
    Object.keys(AUTCONFIG).forEach(elx => {
      if(AUTCONFIG[elx].value === record.type) {
         stringName = AUTCONFIG[elx].displayName
      }
    });
    return <>
     <span>{stringName}</span>
    </>;
  }
  getState(record) {
    if (record.state === 'ENABLED') {
      return <div><div className="common-green"></div> <span>正常</span></div>
    } else if (record.state === 'DISABLED') {
      return <div><div className="common-red"></div> <span>禁用</span></div>
    } else {
      return '';
    }
  }
  getDisOrEnaBtn(record) {
    if (this.props.currentUser && this.props.currentUser.type ===  AUTCONFIG.ADMIN.value) {  //  当前登录用户是管理员 
      if (record.state === 'ENABLED' && record.type !== AUTCONFIG.ADMIN.value ) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <a onClick={() => this.showDisConfirm(record.key)} 
                  style={{ marginRight: '5px' }}>禁用</a>
      } else if (record.state === 'DISABLED'  && record.type !== AUTCONFIG.ADMIN.value ) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <a  onClick={() => this.showEnaConfirm(record.key)} 
                   style={{ marginRight: '5px' }}>启用</a>
      } 
    } else if (this.props.currentUser && this.props.currentUser.type === AUTCONFIG.MAIN.value) {   // 当前登录用户是主用户
        if (record.state === 'ENABLED' && record.type === AUTCONFIG.SUB.value) {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return <a onClick={() => this.showDisConfirm(record.key)} 
                      style={{ marginRight: '5px' }}>禁用</a>
          } else if (record.state === 'DISABLED' && record.type === AUTCONFIG.SUB.value) {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return <a  onClick={() => this.showEnaConfirm(record.key)} 
                        style={{ marginRight: '5px' }}>启用</a>
          } 
    } else if (this.props.currentUser && this.props.currentUser.type === AUTCONFIG.SUPERADMIN.value) { // 当前登录用户是超级管理员
      if (record.state === 'ENABLED' && record.type !== AUTCONFIG.SUPERADMIN.value) {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          return <a onClick={() => this.showDisConfirm(record.key)} 
                    style={{ marginRight: '5px' }}>禁用</a>
        } else if (record.state === 'DISABLED' && record.type !== AUTCONFIG.SUPERADMIN.value) {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          return <a  onClick={() => this.showEnaConfirm(record.key)} 
                      style={{ marginRight: '5px' }}>启用</a>
        }
    } else {
      return '';
    }

  }
  /**
   * 当前用户不能删除自己
   * @param {*} record 
   * @returns 
   */
  getCheckboxProps (record)  {

    // 超级管理员
    if(store.getState().currentUser?.type === AUTCONFIG.SUPERADMIN.value &&  record.type === AUTCONFIG.SUPERADMIN.value)  {
        return ''
    }
    // 管理员
    if(store.getState().currentUser?.type === AUTCONFIG.ADMIN.value &&  record.type === AUTCONFIG.ADMIN.value ) {
         return ''
   }
   // 主用户
   if(store.getState().currentUser?.type === AUTCONFIG.MAIN.value &&  record.type === AUTCONFIG.MAIN.value) {
    return ''
  }
  if( this.props.currentUser?.id == record.id) {
      return ''
  }
  return (<a href onClick={() => this.showDeleteConfirm(record.key)}>删除</a>);
}
  render() {
   const onPageChange = (pageNumber,ps) => {
      page = pageNumber;
      if(ps!=pageSize) {
        page = 1
      }
      pageSize = ps
      this.changeType();
    }
    const registered = () => {
    //   this.setState({
    //     showManage: false,
    //     showRegistered: true,
    //   });
      this.props.history.push('/home/sys/user-manage/add');
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          actionIds: selectedRowKeys
        })
      },
      getCheckboxProps:(record) => {
         // 管理员不能删除自己
          if(store.getState().currentUser?.type === AUTCONFIG.ADMIN.value &&  record.type === AUTCONFIG.ADMIN.value)  {
              return {disabled: true}
          }
          // 超级管理员不能删除自己
          if(store.getState().currentUser?.type === AUTCONFIG.SUPERADMIN.value &&  record.type === AUTCONFIG.SUPERADMIN.value)  {
            return {disabled: true}
         }
         // 主用户不能删除自己
          if(store.getState().currentUser?.type === AUTCONFIG.MAIN.value &&  record.type === AUTCONFIG.MAIN.value ) {
               return {disabled: true}
         }
        if( this.props.currentUser?.id == record.id) {
            return {disabled: true}
        }
      }
    };

    const columns = [
      {
        title: '账号名',
        dataIndex: 'username',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
      },
      {
        title: '用户名称',
        dataIndex: 'realName',
      },
      {
        title: '手机号码',
        dataIndex: 'mobile',
      },
      {
        title: '绑定标签',
     
        dataIndex: 'bindCount',
        render: (text, record) => <a href onClick={this.gotoAddlabel.bind(text, record.key)}>{text || 0}</a>,
      },
      {
        title: '企业',
        width:'15%',
        dataIndex: 'company',
        render: (text, record) =>  <span>{text?.name}</span> 
      },
      {
        title: '类型',
        dataIndex: 'type',
        render: (text, record) => (
          <>
            {
              this.getUserType(record)
            }
          </>
        ),

      },
      {
        title: '所属用户',
        dataIndex: 'createBy',
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (text, record) => (
          <>
            {
              this.getState(record)
            }
          </>
        ),
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        width: '15%',
        render: (text, record) => (
          <>
            {this.getDisOrEnaBtn(record)}
            <a href onClick={this.gotoEdit.bind(text, record.key)} style={{ marginRight: '5px' }}>编辑</a>
            <a href onClick={()=>{this.showUserToken(text,record)}} style={{ marginRight: '5px' }}>令牌</a>
            {this.getCheckboxProps(record)}
          </>
        )
      },
    ];
    const columnsUser = [
        {
          title: '账号名',
          dataIndex: 'username',
          render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
        },
        {
          title: '用户名称',
          dataIndex: 'realName',
        },
        {
          title: '手机号码',
          dataIndex: 'mobile',
        },
        {
            title: '绑定标签',
         
            dataIndex: 'bindCount',
            render: (text, record) => <a href onClick={this.gotoAddlabel.bind(text, record.key)}>{text || 0}</a>,
          },
        {
          title: '企业',
          dataIndex: 'company',
          width:'15%',
          render: (text, record) =>  <span>{text?.name}</span> 
        },
        {
          title: '类型',
          dataIndex: 'type',
          render: (text, record) => (
            <>
              {
                this.getUserType(record)
              }
            </>
          ),
  
        },
     
        {
          title: '状态',
          dataIndex: 'state',
          render: (text, record) => (
            <>
              {
                this.getState(record)
              }
            </>
          ),
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
        },
        {
          title: '操作',
          dataIndex: 'action',
          render: (text, record) => (
            <>
              {this.getDisOrEnaBtn(record)}
              <a href onClick={this.gotoEdit.bind(text, record.key)} style={{ marginRight: '5px' }}>编辑</a>
              <a href onClick={()=>{this.showUserToken(text,record)}} style={{ marginRight: '5px' }}>令牌</a>
              {this.getCheckboxProps(record)}
            </>
          )
        },
      ];
    return (
      <>
        <div className="common-pd20">
          <div style={{ display: (this.state.showManage === true) ? "block" : "none" }}>
            <h1 className="common-top-title0">用户管理</h1>
            <div className="common-center-container">
              <Search onClick={() => this.changeType()} currentUser={this.props.currentUser} tenantList={this.state.tenantList} />
              <div className="oper-button">
                <Router>
                  <Button type="primary" onClick={registered}>创建</Button>
                </Router>
                <Button style={{ marginLeft: 10 }} onClick={() => this.showMultiDeleteConfirm()}> 删除 </Button>
              </div>
              <div className="user-table-card">
                <Table
                  rowSelection={{
                    ...rowSelection,
                  }}
                  rowKey={record => record.id}
                  bordered={false}
                  tableLayout="fixed"
                  pagination={false}
                  loading={this.state.loading}
                  columns={this.props.currentUser?.type === AUTCONFIG.MAIN.value ? columnsUser : columns  }
                  dataSource={this.state.userList}
                />
              </div>
              <div>
                {
                  this.state.userList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination 
                        showSizeChanger 
                        defaultCurrent={1} 
                        current={this.state.page} 
                        pageSize={this.state.pageSize} 
                        total={this.state.userAmount} 
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}


export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(UserManage);
