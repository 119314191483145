import React from 'react';
import { Form, Row, Col,Transfer,  Input, Button, Table, Pagination, ConfigProvider, Modal, message, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import BackPage from '../../../../common/backPage';
import { labelService }  from '../../../../../services/label.service';
import { userService } from '../../../../../services/user.service';
import { connect } from 'react-redux';
const { confirm } = Modal;
const { Option } = Select;
let  pageSize = 10;
let  page = 1;
let labelName  = '';

class Search extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    form = React.createRef();
    onFinish = (values) => {
        labelName  = values.name || '';
        
        this.props.onClick();
    }
    resetForm = () => {
        labelName  = '';
      
        this.form.current.resetFields();
        this.props.onClick();
    }
    render () {

        return (
            <div className="common-search-form">
            <Form
              ref={this.form}
              name="advanced_search_label"
              className="ant-advanced-search-form"
              layout="inline"
              style={{ justifyContent: 'center' }}
              onFinish={this.onFinish} >
                <Form.Item name='name' label='标签名' style={{ marginTop: '8px' }}>
                    <Input placeholder="请输入账号名" />
                </Form.Item>
           
                <Form.Item style={{ marginTop: '8px' }}>
                    <Button type="primary" htmlType="submit"> 查询 </Button>
                    <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
                </Form.Item>
            </Form>
            </div>
        );
    }
}

class BindLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            labelList: [],
            userId: '',
            userCount: 0,
            page: 1,
            labelName: '标签绑定--',
            currentType: '',
            targetLabelKeys: [],
        
            bindLabelLists: [],
            isModalVisible: false,
            pageSize: 10,
            showLabelList: [],
         
        };
    }
    searchList(userId) {
        this.setState({
            loading: true,
            page,
            pageSize
          });
          userId = userId || this.state.userId;
        userService.getLabelList(userId, labelName).then(res => {
          
            if(res.code === 200) {
               
                this.setState({
                    loading: false,
                    labelList: res.users,
                    showLabelList: res.users.filter(elx => {
                        if(labelName && elx.name.indexOf(labelName) !== -1) {
                            return true;
                        }
                      
                        if( !labelName) {
                            return true;
                        }
                    }),
                         
                    targetLabelKeys: res.users.map(exl => exl.id)
                })
                
            } 
            // else {

            // }
        }, error => {
          error && message.error( error);
        })
    }
    goback() {
        this.props.history.push('/home/sys/user-manage');
    }
    registered() {
        this.setState({
            isModalVisible: true
        })
    }
    handleOk() {
        this.setState({
            isModalVisible: false
        })
        message.loading('保存中~~')
        userService.addLabelToUsers(this.state.userId, this.state.targetLabelKeys).then(res => {
            message.destroy();
            if(res.code == 200) {
                message.success('保存成功！');
                this.searchList();
            } 
            // else {
            //     message.error('绑定失败！');
            // }
        }).catch(error => {
            this.searchList();
            message.destroy();
            error && message.error( error);
        })
    }
    handleCancel() {
        this.setState({
            isModalVisible: false
        });
        this.searchList();
    }
    getUserDetail(userId) {
        userService.getUserDetail(userId).then(res => {
          if (res.code === 200) {
            const label = res.users;
           
            this.setState({
          
              labelName:  '标签绑定--' + label.realName,
              currentType: label.type
            });
           
          } 
          // else {
          //   message.error('获取标签详情失败');
          // }
        }, error => {
          error && message.error( error);
        });
      }
    componentDidMount() {
       
        if (this.props.location.state) {
            const userId = this.props.location.state.id;
            this.setState({
              userId: userId
            });
            this.searchList(userId);
            this.getLabelList(userId);
            this.getUserDetail(userId);
            sessionStorage.setItem('userId', userId);
        } else {
          if(sessionStorage.getItem('userId')) {
              const userId = sessionStorage.getItem('userId');
              this.setState({
                userId: userId
              });
              this.searchList(userId);
              this.getLabelList(userId);
              this.getUserDetail(userId);
          } else {
              this.goback();
          }
        }
      }
      getLabelList(userId) {
        labelService.getAllBindLabels(userId).then(res => {
          if (res.code === 200) {
            const labels = res.labels;
            labels.forEach(elx => elx.key = elx.id);
            this.setState({
                bindLabelLists: labels
            });
          }
        }, error => {
          error && message.error( error);
        });
      }

    onPageChange = (pageNumber) => {
        page = pageNumber;
        
        this.searchList();
      }
      handleChangeUser= targetKeys => {
        this.setState({ targetLabelKeys: targetKeys });
      };
     onShowSizeChange = (current, pageSizeNumber) => {
        pageSize = pageSizeNumber;
        page = 1;
        this.searchList();
      }
    showDeleteConfirm(event) {
       
        confirm({
          title: '确定移除吗?',
          okText: "确认",
          cancelText: "取消",
          icon: <ExclamationCircleOutlined />,
          content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
          closable: true,
          onOk: () => {
            userService.removeLabelFromuser(this.state.userId, event.id).then(res => {
              if (res.code === 200) {
                message.success('删除成功');
                // if( this.state.userList.length === 1) {
                //     page = page - 1 ;
                //     page = page ? page : 1;
                // }
                this.searchList();
              } 
              // else {
              //   message.error('删除失败，' + res.message);
              // }
            }, error => {
              error && message.error( error);
            })
          },
          onCancel() {
          },
        });
      }
    render () {
     let     columns  = [
        {
            title: '标签名称',
            dataIndex: 'name',
           // render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
          },
        //   {
        //     title: '标签编码',
        //     dataIndex: 'code',
        //   },
          {
            title: '描述',
            dataIndex: 'description',
            width: '10%'
        },
          {
            title: '绑定时间',
            dataIndex: 'createTime',
          },
          {
            title: '操作',
            dataIndex: 'action',
            render: (text, record) => <a href onClick={() => this.showDeleteConfirm(record)}>删除</a>
          },
    ]
    // 如果当前登录用户 是 主用户  绑定的用户是 系统用户
    // if(this.props.currentUser?.type === 'MAIN' && this.state.currentType === 'SYSTEM' )  {
    //     columns = columns.slice(0, 3);
    //  }
        return (
            <div className="common-pd20">
                <BackPage onClick={() => this.goback()} headeTitle={this.state.labelName} itemTitle="标签管理" itemSubTitle="创建标签" />
                <div className="common-center-container">
               <Search onClick={() => this.searchList()} />
              <div className="oper-button">
                {/* {this.props.currentUser?.type === 'MAIN' && this.state.currentType === 'SYS' ? 
                '':  */}
                    <Router>
                    <Button type="primary" onClick={this.registered.bind(this)} >  添加  </Button>
                </Router>

               
               </div>
                <div className="user-table-card">
                    <Table
                        //   rowSelection={{
                        //     ...rowSelection,
                        //   }}
                        rowKey={record => record.id}
                        bordered={false}
                      
                        loading={this.state.loading}
                        columns={columns}
                        dataSource={this.state.showLabelList}
                        />
            
                </div>
                <div>
                {/* {
                  this.state.showUserList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination 
                        showSizeChanger 
                        onShowSizeChange={this.onShowSizeChange.bind(this)} 
                        defaultCurrent={1} 
                        current={this.state.page} 
                        pageSize={this.state.pageSize} 
                        total={this.state.userCount} 
                        onChange={this.onPageChange.bind(this)} />
                    </ConfigProvider>
                  </div>
                } */}
              </div>
              </div>
              <Modal width={1000} title="选择标签" visible={this.state.isModalVisible}
               onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                    <Transfer
                        dataSource={this.state.bindLabelLists}
                        listStyle={{ width: 500, height: 300 }}
                        titles={['选择标签', '已选择']}
                        operations={['添加', '移除']}
                        targetKeys={this.state.targetLabelKeys}
                        onChange={this.handleChangeUser}
                        render={item => `${item.name}`} 
                    />
                 </Modal>
            </div>
        );
    }
}




export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(BindLabel);