import React from 'react';
import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message } from 'antd';
import { ExclamationCircleOutlined , PlusOutlined} from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import { companyService } from '../../../../services/company.service';
import './../../../../../index.scss';
import './company-manage.scss';
import { connect } from "react-redux";
import store from '../../../../../store';
import { AUTCONFIG } from '../../../../../utils/auth.config';
import { searchRule } from '../../../../../utils/search-util'

const { Option } = Select;


const { confirm } = Modal;

let searchName = '';
let page = 1;
let pageSize = 10;

function Search(props) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        let name = values.name || '';
        let str = searchRule(name)
        searchName = str || '';
        page = 1;
        props.onClick();
    };
    const resetForm = () => {
        form.resetFields();
        searchName = '';
        page = 1;
        props.onClick();
    }
    return (
        <>
            <div className="common-search-form">
                <Form
                    form={form}
                    name="advanced_search"
                    layout="inline"
                //    style={{ justifyContent: 'center' }}
                    className="ant-advanced-search-form"
                    onFinish={onFinish} >
                   
                        <Form.Item name='name' label='企业名称' style={{ marginTop: '8px' }}>
                            <Input placeholder="请输入企业名称" />
                        </Form.Item>
                        <Form.Item style={{ marginTop: '8px' }}>
                            <Button type="primary" htmlType="submit"> 查询 </Button>
                            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
                        </Form.Item>
                    
                </Form>
            </div>
        </>
    );
}


class CompanyManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyList: [],
            companyAmount: 0,
            page: 1,
            pageSize: 10,
            loading: true,
            actionIds: []
           // history: require("history").createHashHistory,
        };
    }
    componentDidMount() {
        searchName = '';
        page  =1;
        pageSize = 10;
        this.searchList();
    }
    searchList() {
        this.setState({
            page,
            pageSize,
            loading: true,
            actionIds: []
        })
 
        companyService.getCompanyList(searchName.replace(/(^\s*)|(\s*$)/g,''), pageSize, page).then(res => {
            this.setState({
                loading: false
            })
            if(res.code === 200) {
                this.setState({
                    companyList: res.companys,
                    companyAmount: res.dataCount,
                    loading: false
                })
            }
            //  else {
            //     message.error('列表加载失败, ' + res.message)
            // }
        }).catch(error => {
            this.setState({
                loading: false
            });
            message.error(error);
        })
    }
    showMultiDeleteConfirm() {
        if (this.state.actionIds.length === 0) {
          message.warning('请先选择要删除的企业');
          return;
        }
        confirm({
            title: '确定删除吗?',
            icon: <ExclamationCircleOutlined />,
            content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
            closable: true,
            onOk: () => {
                companyService.removeBatchCompany(this.state.actionIds.toString()).then(res => {
                    if (res.code === 200) {
                        message.success('删除成功');
                        page = 1;
                        this.searchList();
                    } 
                    // else {
                    //     message.error('删除失败，' + res.message);
                    // }
                }, error => {
                    message.error(error);
                })
            },
            onCancel() {

            },
        });
        
    }
    showDeleteConfirm(event) {
        confirm({
            title: '确定删除吗?',
            icon: <ExclamationCircleOutlined />,
            content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
            closable: true,
            onOk: () => {
                companyService.removeCompany(event).then(res => {
                    if (res.code === 200) {
                        message.success('删除成功');
                        page = 1;
                        this.searchList();
                    } 
                    // else {
                    //     message.error('删除失败，' + res.message);
                    // }
                }, error => {
                    message.error(error);
                })
            },
            onCancel() {

            },
        });
    }
    goToAdd() {
        this.props.history.push('/home/sys/company-manage/add');
       
    }
    gotoDetail(text, event) {
      
        this.props.history.push({ pathname: '/home/sys/company-manage/view', state: {  id: text} });
    }
    goToEdit(event) {
      
        this.props.history.push({ pathname: '/home/sys/company-manage/edit', state: {  id: event} });
    }
    render() {
        const columns = [
            {
                title: '企业名称',
                dataIndex: 'name',
                render: (text, record) => <a  onClick={() => this.gotoDetail(record.id)}>{text}</a>,
            },
            {
                title: '联系人',
                dataIndex: 'contactPerson'
            },
            {
                title: '电话',
                dataIndex: 'mobile'
            },
            {
                title: '邮箱',
                dataIndex: 'email'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => (
                    <>
                    
                     <a href onClick={() => this.goToEdit(record.id)} style={{marginRight: 8}}>编辑</a>
                     <a href onClick={() => this.showDeleteConfirm(record.id)}>删除</a>
                    </>
                )
            },
        ];
        const columnsss = columns.slice(0, columns.length - 1);
     //  const columnsss = columns.slice(0, columns.length);
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                actionIds: selectedRowKeys
              })
            }
        }
        const onPageChange = (pageNumber,ps) => {
            page = pageNumber;
            if(ps!=pageSize) {
              page = 1
            }
            pageSize = ps
            this.searchList();
          };
        return (
        <>
          <div className="common-pd20">
                    <div>
                    <h1 className="common-top-title0">企业管理</h1>
    
                       <div className="common-center-container">
                           <Search  onClick={() => this.searchList()}/>
                           <div className="oper-button">
                          
                                <Router>
                                    <Button type="primary"  onClick={() => this.goToAdd()}> 创建 </Button>
                                </Router> 
                                {/* <Button style={{ marginLeft: 10 }} onClick={() => this.showMultiDeleteConfirm()}> 删除 </Button>  */}
                                {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ? 
                                <Button style={{ marginLeft: 10 }} onClick={() => this.showMultiDeleteConfirm()}> 删除 </Button> : ''}
                            </div>
                            <div className="user-table-card">
                            {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ? 
                                <Table
                                 rowSelection={{
                                    ...rowSelection,
                                  }}
                                    rowKey={record => record.id}
                                    bordered={false}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={this.state.companyList}
                                    loading={this.state.loading}
                                /> : <Table
                                   
                                   rowKey={record => record.id}
                                   bordered={false}
                                   pagination={false}
                                   columns={columnsss}
                                   dataSource={this.state.companyList}
                                   loading={this.state.loading}
                               />
                             } 
                            </div>
                            <div>
                                {
                                    this.state.companyList.length > 0 &&
                                    <div className="common-bottom-pagination">
                                        <ConfigProvider locale={zhCN}>
                                            <Pagination showSizeChanger 
                                            defaultCurrent={1} 
                                            current={this.state.page} 
                                            pageSize={this.state.pageSize} 
                                            total={this.state.companyAmount} 
                                            onChange={onPageChange} />
                                        </ConfigProvider>
                                    </div>
                                }
                            </div>
                       </div>
                    </div>

              
                </div>
        
        </>
        );
    }
}


// export default CompanyManage;

export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(CompanyManage);
  