import React from 'react';
import { Form, Row, Col,Transfer,  Input, Button, Table, Pagination, ConfigProvider, Modal, message, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import BackPage from '../../../../common/backPage';
import { labelService }  from '../../../../../services/label.service';
import { userService } from '../../../../../services/user.service';
import { connect } from 'react-redux';
const { confirm } = Modal;
const { Option } = Select;
let  pageSize = 10;
let  page = 1;
let username  = '';
let realName = ''
class Search extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    form = React.createRef();
    onFinish = (values) => {
        username  = values.username || '';
        realName = values.realName || '';
        this.props.onClick();
    }
    resetForm = () => {
         username  = '';
         realName = ''
        this.form.current.resetFields();
        this.props.onClick();
    }
    render () {

        return (
            <div className="common-search-form">
            <Form
              ref={this.form}
              name="advanced_search_label"
              className="ant-advanced-search-form"
              layout="inline"
              style={{ justifyContent: 'center' }}
              onFinish={this.onFinish} >
                <Form.Item name='username' label='账号名' style={{ marginTop: '8px' }}>
                    <Input placeholder="请输入账号名" />
                </Form.Item>
                <Form.Item name='realName' label='用户名称' style={{ marginTop: '8px' }}>
                    <Input placeholder="请输入用户名称" />
                </Form.Item>
                <Form.Item style={{ marginTop: '8px' }}>
                    <Button type="primary" htmlType="submit"> 查询 </Button>
                    <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
                </Form.Item>
            </Form>
            </div>
        );
    }
}

class BindUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            userList: [],
            labelId: '',
            userCount: 0,
            page: 1,
            labelName: '用户绑定 -- ',
            currentType: '',
            targetUserKeys: [],
            bindUserLists: [],
            bindUserLists: [],
            isModalVisible: false,
            pageSize: 10,
            showUserList: [],
            columns: [
                {
                    title: '账号名',
                    dataIndex: 'username',
                   // render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
                  },
                  {
                    title: '用户名称',
                    dataIndex: 'realName',
                  },
                  {
                    title: '手机号码',
                    dataIndex: 'mobile',
                  },
                  {
                    title: '企业',
                    width:'15%',
                    dataIndex: 'company',
                  },
               
                  {
                    title: '绑定时间',
                    dataIndex: 'createTime',
                  },
                  {
                    title: '操作',
                    dataIndex: 'action',
                    render: (text, record) => <a href onClick={() => this.showDeleteConfirm(record.key)}>删除</a>
                  },
            ]
        };
    }
    searchList(labelId) {
        this.setState({
            loading: true,
            page,
            pageSize
          });
          labelId = labelId ||  this.state.labelId;
        labelService.getUserList(labelId, username, realName).then(res => {
          
            if(res.code === 200) {
               
                this.setState({
                    loading: false,
                    userList: res.users,
                    showUserList: res.users.filter(elx => {
                        if(username && elx.username.indexOf(username) !== -1) {
                            return true;
                        }
                        if(realName && elx.realName.indexOf(realName) !== -1) {
                            return true;
                        }
                        if(!realName && !username) {
                            return true;
                        }
                    }),
                         
                    targetUserKeys: res.users.map(exl => exl.id)
                })
                
            } 
            // else {

            // }
        }, error => {
          error && message.error(error);
        })
    }
    goback() {
        this.props.history.push('/home/sys/label-manage');
    }
    registered() {
        this.setState({
            isModalVisible: true
        })
    }
    handleOk() {
        this.setState({
            isModalVisible: false
        })
        message.loading('保存中~~')
        labelService.addUsersToLabel(this.state.labelId, this.state.targetUserKeys).then(res => {
            message.destroy();
            if(res.code == 200) {
                message.success('保存成功！');
                this.searchList();
            } 
            // else {
            //     message.error('绑定失败！');
            // }
        }).catch(error => {
            this.searchList();
            message.destroy();
            error && message.error(error);
        })
    }
    handleCancel() {
        this.setState({
            isModalVisible: false
        });
        this.searchList();
    }
    getLabelDetail(labelId) {
        labelService.getLabelDetail(labelId).then(res => {
          if (res.code === 200) {
            const label = res.label;
            this.setState({
          
              labelName:  '用户绑定 -- ' + label.name,
              currentType: label.type
            });
           
          } 
          // else {
          //   message.error('获取标签详情失败');
          // }
        }, error => {
          error && message.error(error);
        });
      }
    componentDidMount() {
      
        if (this.props.location.state) {
          const labelId = this.props.location.state.id;
          this.setState({
            labelId: labelId
          });
         
          this.searchList(labelId);
          this.getTenantList(labelId);
          this.getLabelDetail(labelId);
          sessionStorage.setItem('labelId', labelId);
        } else {
          if(sessionStorage.getItem('labelId')) {
            const labelId = sessionStorage.getItem('labelId');
            this.setState({
              labelId: labelId
            });
            this.searchList(labelId);
            this.getTenantList(labelId);
            this.getLabelDetail(labelId);
          }
        }
         
        
      }
      getTenantList(labelId) {
        userService.getBindLabelUserList(labelId).then(res => {
          if (res.code === 200) {
            const tenants = res.users;
            tenants.forEach(elx => elx.key = elx.id);
            this.setState({
              bindUserLists: tenants,
            });
          }
        }, error => {
          error && message.error(error);
        });
      }
    onPageChange = (pageNumber) => {
        page = pageNumber;
        
        this.searchList();
      }
      handleChangeUser= targetKeys => {
        this.setState({ targetUserKeys: targetKeys });
      };
     onShowSizeChange = (current, pageSizeNumber) => {
        pageSize = pageSizeNumber;
        page = 1;
        this.searchList();
      }
    showDeleteConfirm(event) {
       
        confirm({
          title: '确定移除吗?',
          okText: "确认",
          cancelText: "取消",
          icon: <ExclamationCircleOutlined />,
          content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
          closable: true,
          onOk: () => {
            labelService.removeUserFromLabel(this.state.labelId, event).then(res => {
              if (res.code === 200) {
                message.success('删除成功');
                // if( this.state.userList.length === 1) {
                //     page = page - 1 ;
                //     page = page ? page : 1;
                // }
                this.searchList();
              }
              // else {
              //   message.error('删除失败，' + res.message);
              // }
            }, error => {
              error && message.error(error);
            })
          },
          onCancel() {
          },
        });
      }
    render () {
     let  columns = [
        {
            title: '账号名',
            dataIndex: 'username',
           // render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
          },
          {
            title: '用户名称',
            dataIndex: 'realName',
          },
          {
            title: '手机号码',
            dataIndex: 'mobile',
          },
          {
            title: '企业',
            width:'15%',
            dataIndex: 'company',
            render: (text, record) =>  <span>{text?.name}</span> 
          },
       
          {
            title: '绑定时间',
            dataIndex: 'createTime',
          },
          {
            title: '操作',
            dataIndex: 'action',
            render: (text, record) => {
               
                return (
                    <a href onClick={() => this.showDeleteConfirm(record.id)}>删除</a>
                );
            }
          },
    ]
   // if(this.props.currentUser?.type === 'MAIN' && this.state.currentType === 'SYSTEM' )  {
        // columns = columns.slice(0, 5);
  //   }
        return (
            <div className="common-pd20">
                <BackPage onClick={() => this.goback()} headeTitle={this.state.labelName} itemTitle="标签管理" itemSubTitle="创建标签" />
                <div className="common-center-container">
               <Search onClick={() => this.searchList()} />
              <div className="oper-button">
                 
                    <Router>
                    <Button type="primary" onClick={this.registered.bind(this)} >  添加  </Button>
                </Router>

               
               </div>
                <div className="user-table-card">
                    <Table
                        //   rowSelection={{
                        //     ...rowSelection,
                        //   }}
                        rowKey={record => record.id}
                        bordered={false}
                      
                        loading={this.state.loading}
                        columns={columns}
                        dataSource={this.state.showUserList}
                        />
            
                </div>
                <div>
                {/* {
                  this.state.showUserList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination 
                        showSizeChanger 
                        onShowSizeChange={this.onShowSizeChange.bind(this)} 
                        defaultCurrent={1} 
                        current={this.state.page} 
                        pageSize={this.state.pageSize} 
                        total={this.state.userCount} 
                        onChange={this.onPageChange.bind(this)} />
                    </ConfigProvider>
                  </div>
                } */}
              </div>
              </div>
              <Modal width={1000} title="选择用户" visible={this.state.isModalVisible}
               onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                    <Transfer
                        dataSource={this.state.bindUserLists}
                        listStyle={{ width: 500, height: 300 }}
                        titles={['选择用户', '已选择']}
                        operations={['添加', '移除']}
                        targetKeys={this.state.targetUserKeys}
                        onChange={this.handleChangeUser}
                        render={item => `${item.realName}/${item.username}`} 
                    />
                 </Modal>
            </div>
        );
    }
}




export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(BindUser);