
import FirstPage from './first-page/first-page';
import UserCenter from './user-center/user-center';
import OtaFactory from './ota-factory/ota-factory';
import CreateOta from './ota-factory/create-ota/create-ota'
// import UserManage from './system-manage/user-manage/user-manage';
// import OrganManage from './system-manage/organ-manage/organ-manage';
// import PermissionManage from './system-manage/permission-manage/permission-manage';
// import TagManage from './system-manage/tag-manage/tag-manage';

import UserManage from './system-management/user-manage/user-manage';
import CreateUser from './system-management/user-manage/create-user/create-user';
import AccessPolicy from './system-management/access-policy/access-policy';
import CreateAccess from './system-management/access-policy/create-access/create-access';

import LabelManagement from './system-management/label-manage/label-manage';
import CreateLabel from './system-management/label-manage/create-label/create-label';
import EditUser from './system-management/user-manage/edit-user/edit-user';
import ViewUser from './system-management/user-manage/view-user/view-user';
import BindLabel from './system-management/user-manage/bind-label/bing-label';

import CompanyManage from './system-management/company-manage/company-manage';
import CreateCompany from './system-management/company-manage/create/create-company';
import ViewCompany from './system-management/company-manage/view/view-company';
import EditCompany from './system-management/company-manage/eidt/edit-company';

import MenuManage from './system-management/menu-manage/menu-manage';

// import ViewNode from './node-management/view-node/view-node';
import ViewAccess from './system-management/access-policy/view-access/view-access';
import ViewLabel from './system-management/label-manage/view-label/view-label';
import EditLabel from './system-management/label-manage/edit-label/edit-label';
import BindUser from './system-management/label-manage/bind-user/bind-user';
import ViewOta from './ota-factory/view/view-ota/view-ota';
import EditOta from './ota-factory/edit/edit-ota/edit-ota';
import TestShell from './ota-factory/test-shell/test-shell';
import TestRecord from './ota-factory/test-record/test-record';
import CreateShell from './ota-factory/test-shell/create/create-shell';
import EditShell from './ota-factory/test-shell/eidt/edit-shell';
import ViewShell from './ota-factory/test-shell/view/view-shell'

import NodeManage from './node-manage/node-manage';
import NodeLog from './node-manage/node-log/node-log';
import CreateNode from './node-manage/create-node/create-node';
import NodeUpdate from './node-manage/node-update/node-update';
import NodeMonitor from './node-manage/node-monitor/node-monitor';
import ViewNode from './node-manage/view-node/view-node';
import EditNode from './node-manage/edit-node/edit-node';
import RemoteOperation from './node-manage/remote-operation/remote-operation';
import RemoteSSH from './node-manage/remote-ssh/remote-ssh';
import AlgorithmAuthorization from './node-manage/algorithm-authorization/algorithm-authorization';
import LogConfig from './node-manage/log-config/log-config';
import LogCollection from './node-manage/log-collection/log-collection';
import AlgorithmInstall from './node-manage/algorithm-install/algorithm-install';
import UserSoftwareUpdate from './node-manage/user-software-update/user-software-update';
// import NodeCertificate from './node-manage/node-certificate/node-certificate';


import BatchJob from './batch-job/batch-job';
import ViewJob from './batch-job/view-job/view-job';
import EditJob from './batch-job/edit-job/edit-job';
import CreateJob from './batch-job/create-job/create-job';
import ViewFirmware from './ota-factory/view/view-firmware/view-firmware';
// import EditFirmware from './ota-factory/edit/edit-firmware/edit-firmware';

import CreateApplication from './application/create-application/create-application';
import ViewApplication from './application/view-application/view-application';
import CreateEquipment from './equipment/create-equipment/create-equipment';
import ViewEquipment from './equipment/view-equipment/view-equipment';


import EdgeEffectListManagement from './edge-effect/edge-effect-management/list';
import CreateEdgeEffectManagement from './edge-effect/edge-effect-management/create/create';
import ViewEdgeEffectManagement from './edge-effect/edge-effect-management/view/view-node';
import UpgradeM from './edge-effect/edge-effect-management/upgrade/upgrade';
import EdgeEffectLog from './edge-effect/edge-effect-management/view/node-log/node-log'
import EdgeEffectMonitor from './edge-effect/edge-effect-management/view/node-monitor/node-monitor';

//////zrb
import EdgeEffectListManagementDemo from './edge-app/edge-effect-management/list';
import ViewEdgeEffectManagementDemo from './edge-app/edge-effect-management/view/view-node'
import EdgeEffectLogDemo from './edge-app/edge-effect-management/view/node-log/node-log'
import EdgeEffectMonitorDemo from './edge-app/edge-effect-management/view/node-monitor/node-monitor';
import CreateEdgeEffectManagementDemo from './edge-app/edge-effect-management/create/create';
import EditEdgeEffectManagementDemo from './edge-app/edge-effect-management/edit/edit-node'
// import EditApplicationDemo from './edge-app/edge-effect-management/edit/application/application'
// import EditEquipmentDemo from './edge-app/edge-effect-management/edit/equipment/equipment';

import EdgeEffectListTemplate from './edge-effect/edge-effect-template/list';
import ViewEdgeEffectTemplate from './edge-effect/edge-effect-template/view/view';
import CreateEdgeEffectTemplate from './edge-effect/edge-effect-template/create/create';

import DeployEdgeEffectTemplate from './edge-effect/edge-effect-template/deploy/deploy';

import EdgeEffectListConfig from './edge-effect/edge-effect-config/list';
import CreateEdgeEffectConfig from './edge-effect/edge-effect-config/create/create';
import ViewEdgeEffectConfig from './edge-effect/edge-effect-config/view/view';
import TerminalEquManagement from './terminal-equ/list';
import EditTerminalEquManagement from './terminal-equ/edit/edit';
import ViewTerminalEquManagement from './terminal-equ/view/view';

import CreateTerminalEquManagement from './terminal-equ/create/create'
import FringeMarket from './fringe-market/fringe-market';
import CreateModel from './fringe-market/create-model/create-model';
import ModelDetail from './fringe-market/model-detail/model-detail';
import EditModel from './fringe-market/edit-model/edit-model';

import MirrorWarehouse from './mirror-warehouse/mirror-warehouse';
import ViewMirror from './mirror-warehouse/view-mirror/view-mirror';
import DescriptionDetail from './mirror-warehouse/view-mirror/description-detail/description-detail';
import EditMirror from './mirror-warehouse/edit-mirror/edit-mirror';

import ServiceLog from './operation-manage/service-log/service-log';
import TaskLog from './operation-manage/task-log/task-log';
import ActionLog from './operation-manage/action-log/action-log';
// import GeographicalDistribution from './operation-manage/geographical-distribution/geographical-distribution';
import NodeRecall from './operation-manage/node-recall/node-recall';
import NodeView from './operation-manage/node-recall/view-node/view-node';
// import GeographicalDistribution2 from './operation-manage/geographical-distribution2/geographical-distribution2';
import CloudStorage from './operation-manage/cloud-storage/cloud-storage';
import AiCheckRecord from './operation-manage/ai-check-record/ai-check-record';

import AlgorithmMange from './system-management/algorithm-manage/algorithm-manage'
import CreatAlgorithmMangement from './system-management/algorithm-manage/create/create'
import EditAlgorithmMangement from './system-management/algorithm-manage/edit/edit'
import ViewAlgorithmMangement from './system-management/algorithm-manage/view/view'
import CreatAlgorithmVersionMangement from './system-management/algorithm-manage/algorithm-version/create-version'
import EditAlgorithmVersionMangement from './system-management/algorithm-manage/algorithm-version/edit-version'

import CreatAlgorithmGroup from './system-management/algorithm-manage/create/create-group'
import EditAlgorithmGroup from './system-management/algorithm-manage/edit/edit-group'
import ViewAlgorithmGroup from './system-management/algorithm-manage/view/view-group'

import ConfigManage from './system-management/config-manage/config-manage'
import ViewConfigManage from './system-management/config-manage/view/view'
import EditConfigManagement from './system-management/config-manage/edit/edit'


import UserDeb from './user-deb/user-deb';
import ViewUserDeb from './user-deb/view-user-deb/view-user-deb';
import ViewUserFirmware from './user-deb/view-user-deb/view-firmware/view-firmware';
import EditFirmware from './user-deb/view-user-deb/edit-firmware/edit-firmware';

import EpackExclusiveManage from './epack-exclusive-manage/epack-exclusive-manage';
import AddEpackExclusiveManageUser from './epack-exclusive-manage/create/create';




// eslint-disable-next-line import/no-anonymous-default-export
export default [

  { path: "/home/first-page", name: "", component: FirstPage, auth: true },
  // { path: "/home/node-management", name: "边缘节点", component: NodeManagement, auth: true },
  // { path: "/home/node-management/detail", name: "节点详情", component: ViewNode, auth: true },


  { path: "/home/node-manage", name: "边缘节点", component: NodeManage, auth: true },
  { path: "/home/node-manage/detail", name: "节点详情", component: ViewNode, auth: true },
  { path: "/home/node-manage/edit", name: "节点编辑", component: EditNode, auth: true },
  { path: "/home/node-manage/add", name: "节点创建", component: CreateNode, auth: true },
  { path: "/home/node-manage/log", name: "节点日志", component: NodeLog, auth: true },
  { path: "/home/node-manage/monitor", name: "节点监控", component: NodeMonitor, auth: true },
  { path: "/home/node-manage/update", name: "节点升级", component: NodeUpdate, auth: true },

  { path: "/home/node-manage/remote-operation", name: "远程运维", component: RemoteOperation, auth: true },
  { path: "/home/node-manage/remote-ssh", name: "远程SSH", component: RemoteSSH, auth: true },
  { path: "/home/node-manage/algorithm-authorization", name: "算法授权", component: AlgorithmAuthorization, auth: true },
  { path: "/home/node-manage/log-config", name: "日志配置", component: LogConfig, auth: true },
  { path: "/home/node-manage/log-collection", name: "日志采集", component: LogCollection, auth: true },

  { path: "/home/node-manage/algorithm-install", name: "算法安装", component: AlgorithmInstall, auth: true },
  { path: "/home/node-manage/user-software-update", name: "用户软件包升级", component: UserSoftwareUpdate, auth: true },
  // { path: "/home/node-manage/certificate", name: "节点证书", component: NodeCertificate, auth: true },

  { path: "/home/node-manage/application/create-application", name: "创建应用", component: CreateApplication, auth: true },
  { path: "/home/node-manage/application/view-application", name: "查看应用", component: ViewApplication, auth: true },

  { path: "/home/node-manage/equipment/create-equipment", name: "创建设备", component: CreateEquipment, auth: true },
  { path: "/home/node-manage/equipment/view-equipment", name: "查看设备", component: ViewEquipment, auth: true },

  { path: "/home/user-center", name: "用户中心", component: UserCenter, auth: true },




  { path: "/home/ota-factory", name: "OTA管理", component: OtaFactory, auth: true },
  { path: "/home/ota-factory/view/detail", name: "OTA详情", component: ViewOta, auth: true },
  { path: "/home/ota-factory/view/firmware", name: "epack详情", component: ViewFirmware, auth: true },
  { path: "/home/ota-factory/edit/detail", name: "OTA详情", component: EditOta, auth: true },
 // { path: "/home/ota-factory/edit/firmware", name: "epack详情", component: EditFirmware, auth: true },
  {path: "/home/ota-factory/create", name: "OTA管理", component: CreateOta, auth: true },
  {path: "/home/ota-factory/test-shell", name: "测试脚本", component: TestShell, auth: true },
  {path: "/home/ota-factory/test-shell/add", name: "创建脚本", component: CreateShell, auth: true },
  {path: "/home/ota-factory/test-shell/edit", name: "编辑脚本", component: EditShell, auth: true },
  {path: "/home/ota-factory/test-shell/view", name: "查看脚本", component: ViewShell, auth: true },
  {path: "/home/ota-factory/test-record", name: "测试记录", component: TestRecord, auth: true },
  

  




  { path: "/home/batch-job", name: "批量作业", component: BatchJob, auth: true },
  { path: "/home/batch-job/detail", name: "任务详情", component: ViewJob, auth: true },
  { path: "/home/batch-job/create", name: "创建任务", component: CreateJob, auth: true },
  { path: "/home/batch-job/edit", name: "编辑任务", component: EditJob, auth: true },

  { path: "/home/sys/user-manage", name: "用户管理", component: UserManage, auth: true },
  { path: "/home/sys/user-manage/add", name: "新建用户", component: CreateUser, auth: true },
  { path: "/home/sys/user-manage/edit", name: "编辑用户", component: EditUser, auth: true },
  { path: "/home/sys/user-manage/detail", name: "用户详情", component: ViewUser, auth: true },

  { path: "/home/sys/company-manage", name: "企业管理", component: CompanyManage, auth: true },
  { path: "/home/sys/company-manage/add", name: "新建企业", component: CreateCompany, auth: true },
  { path: "/home/sys/company-manage/view", name: "企业详情", component:  ViewCompany, auth: true },
  { path: "/home/sys/company-manage/edit", name: "编辑企业", component: EditCompany, auth: true },

  { path: "/home/sys/user-manage/addlabel", name: "绑定标签", component: BindLabel, auth: true },
  { path: "/home/sys/access-policy", name: "角色管理", component: AccessPolicy, auth: true },
  { path: "/home/sys/access-policy/add", name: "新建角色", component: CreateAccess, auth: true },
  { path: "/home/sys/access-policy/detail", name: "角色详情", component: ViewAccess, auth: true },

  { path: "/home/sys/label-manage", name: "标签管理", component: LabelManagement, auth: true },
  { path: "/home/sys/label-manage/add", name: "新建标签", component: CreateLabel, auth: true },
  { path: "/home/sys/label-manage/bind", name: "用户绑定", component: BindUser, auth: true },
  { path: "/home/sys/label-manage/detail", name: "标签详情", component: ViewLabel, auth: true },
  { path: "/home/sys/label-manage/edit", name: "编辑标签", component: EditLabel, auth: true },

  { path: "/home/sys/menu-manage", name: "菜单管理", component: MenuManage, auth: true },

  //  { path: "/", redirect:'/home/first-page', auth: true },


  { path: "/home/effect-edge-m/list", name: "应用管理", component: EdgeEffectListManagementDemo, auth: true },
  { path: "/home/effect-edge-m/add", name: "应用创建", component: CreateEdgeEffectManagementDemo, auth: true },
  { path: "/home/effect-edge-m/edit", name: "应用修改", component:  EditEdgeEffectManagementDemo, auth: true },
  { path: "/home/effect-edge-m/detail", name: "应用查看", component: ViewEdgeEffectManagementDemo, auth: true },
  { path: "/home/effect-edge-m/log", name: "应用日志", component: EdgeEffectLogDemo, auth: true },
  { path: "/home/effect-edge-m/monitor", name: "应用监控", component: EdgeEffectMonitorDemo, auth: true },

  // { path: "/home/effect-edge-m/list", name: "应用管理", component: EdgeEffectListManagement, auth: true },
  // { path: "/home/effect-edge-m/add", name: "应用创建", component: CreateEdgeEffectManagement, auth: true },
  // { path: "/home/effect-edge-m/upgrade", name: "应用升级", component:  UpgradeM, auth: true },
  // { path: "/home/effect-edge-m/detail", name: "应用查看", component: ViewEdgeEffectManagement, auth: true },
  // { path: "/home/effect-edge-m/log", name: "应用日志", component: EdgeEffectLog, auth: true },
  // { path: "/home/effect-edge-m/monitor", name: "应用监控", component: EdgeEffectMonitor, auth: true },


  { path: "/home/effect-edge-t/list", name: "应用模板", component: EdgeEffectListTemplate, auth: true },
  { path: "/home/effect-edge-t/view", name: "应用查看", component: ViewEdgeEffectTemplate, auth: true },
  { path: "/home/effect-edge-t/add", name: "创建模板", component: CreateEdgeEffectTemplate, auth: true },
 
  { path: "/home/effect-edge-t/deploy", name: "创建模板", component: DeployEdgeEffectTemplate, auth: true },


  { path: "/home/effect-edge-c/list", name: "应用配置", component: EdgeEffectListConfig, auth: true },
  { path: "/home/effect-edge-c/add", name: "创建配置", component: CreateEdgeEffectConfig, auth: true },
  { path: "/home/effect-edge-c/view", name: "查看配置", component: ViewEdgeEffectConfig, auth: true },

  { path: "/home/terminal-equ/list", name: "终端设备", component: TerminalEquManagement, auth: true },
  { path: "/home/terminal-equ/add", name: "终端设备", component: CreateTerminalEquManagement, auth: true },
  { path: "/home/terminal-equ/view", name: "设备查看", component: ViewTerminalEquManagement, auth: true },
  { path: "/home/terminal-equ/edit", name: "设备编辑", component: EditTerminalEquManagement, auth: true },


  { path: "/home/mirror-warehouse/list", name: "镜像仓库", component: MirrorWarehouse, auth: true },
  { path: "/home/mirror-warehouse/view", name: "查看镜像", component: ViewMirror, auth: true },
  { path: "/home/mirror-warehouse/deps", name: "镜像描述", component: DescriptionDetail, auth: true },
  { path: "/home/mirror-warehouse/edit", name: "编辑镜像", component: EditMirror, auth: true },


  

  { path: "/home/fringe-market", name: "边缘市场", component: FringeMarket, auth: true },
  { path: "/home/fringe-market/create", name: "发布模型", component: CreateModel, auth: true },
  { path: "/home/fringe-market/detail", name: "模型详情", component: ModelDetail, auth: true },
  { path: "/home/fringe-market/edit", name: "编辑模型", component: EditModel, auth: true },

  { path: "/home/operation/service", name: "日志服务", component: ServiceLog, auth: true },
  { path: "/home/operation/task", name: "任务日志", component: TaskLog, auth: true },
  { path: "/home/operation/action", name: "操作日志", component: ActionLog, auth: true },
  // { path: "/home/operation/geography", name: "地理分布", component:  GeographicalDistribution, auth: true },
  { path: "/home/operation/nodeRecall", name: "节点找回", component: NodeRecall, auth: true },
  { path: "/home/operation/nodeRecall/detail", name: "节点详情", component:NodeView, auth: true },
  // { path: "/home/operation/geography2", name: "地理分布", component:  GeographicalDistribution2, auth: true },
  { path: "/home/operation/cloud-storage-manage", name: "网络存储", component:  CloudStorage, auth: true },
  { path: "/home/operation/ai-check-record", name: "鉴权记录", component:  AiCheckRecord, auth: true },
  
  { path: "/home/algorithm-manage", name: "算法管理", component: AlgorithmMange, auth: true },
  { path: "/home/algorithm-manage/add", name: "增加算法", component: CreatAlgorithmMangement, auth: true },
  { path: "/home/algorithm-manage/edit", name: "编辑算法", component: EditAlgorithmMangement, auth: true },
  { path: "/home/algorithm-manage/view", name: "查看算法", component: ViewAlgorithmMangement, auth: true },
  { path: "/home/algorithm-version/add", name: "添加算法版本", component: CreatAlgorithmVersionMangement, auth: true },
  { path: "/home/algorithm-version/edit", name: "修改算法版本", component: EditAlgorithmVersionMangement, auth: true },

  { path: "/home/algorithm-group/add", name: "增加算法组", component: CreatAlgorithmGroup, auth: true },
  { path: "/home/algorithm-group/edit", name: "编辑算法组", component: EditAlgorithmGroup, auth: true },
  { path: "/home/algorithm-group/view", name: "查看算法组", component: ViewAlgorithmGroup, auth: true },

  { path: "/home/sys/config-manage", name: "配置管理", component: ConfigManage, auth: true },
  { path: "/home/sys/config-manage/view", name: "查看配置", component: ViewConfigManage, auth: true },
  { path: "/home/sys/config-manage/edit", name: "编辑配置", component: EditConfigManagement, auth: true },

  { path: "/home/userDeb", name: "用户软件包概览", component: UserDeb, auth: true },
  { path: "/home/userDeb/view", name: "用户软件包", component: ViewUserDeb, auth: true },
  { path: "/home/userDeb/view/firmware", name: "用户软件包固件", component: ViewUserFirmware, auth: true },
  { path: "/home/userDeb/view/edit", name: "用户软件包编辑", component: EditFirmware, auth: true },



  { path: "/home/epack-exclusive-manage", name: "epack专属管理", component: EpackExclusiveManage, auth: true },
  { path: "/home/epack-exclusive-manage/add", name: "epack专属管理添加用户", component: AddEpackExclusiveManageUser, auth: true },
];