import React from 'react';
import { Form, Input, Button, Col, Row, Table, Transfer, ConfigProvider, Tabs, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { roleService } from '../../../../../services/role.service';
import { menuService } from '../../../../../services/menu.service';
import { labelService } from '../../../../../services/label.service';
import zhCN from 'antd/lib/locale/zh_CN';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import FunctionItem from './functionItem';
import './view-access.scss';


const { TextArea } = Input;

const { TabPane } = Tabs;

const { confirm } = Modal;
const formItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4},
    wrapperCol: { xs: 24, sm: 12, md: 15},
  };


class ViewAccess extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            showManage: true,
            canEdit: [false, false],

            roleId: null,
            roleObj: null,


            roleCreateTime: '',
            roleUpdateTime: '',
            roleName: '查看角色',

            labelLists: [], // 标签所有数据
            menuLists: [], // 菜单所有数据
            targetLabelKeys: [], // 当前角色已包含的标签id
            targetMenuKeys: [],

            isLabelVisible: false,
            isMenuVisible: false,

            dataSourceLabel: [], // 当前用户已包含的标签列表
            dataSourceMenu: [], // 当前角色已包含的权限列表
        };
    }
    goback() {
        this.setState({
            canEdit: [false, false, false, false, false, false, false, false, false, false]
        })
        if (this.props.onSubmit) {
            var { showManage } = this.state;
            this.props.onSubmit({ showManage })
        }
        this.props.history.go(-1);
    }
    cancel = () => {
        this.formRef.current.setFieldsValue({
            name: this.state.roleObj.name || '',
            description: this.state.roleObj.description || '',
        });
    }
    componentDidMount() {
        const roleId = this.props.location.state.id;
        if (roleId) {
            this.setState({
                roleId: roleId
            });
            this.getRoleDetail(roleId);
            this.getRoleLabel(roleId);
            this.getRoleMenu(roleId);
        }
    }
    getRoleDetail(roleId) {
        roleService.getRoleDetail(roleId).then(res => {
            if (res.code === 200) {
                const role = res.role;
                this.setState({
                    roleObj: role,
                    roleName: '查看角色--' + role.name,
                    roleCreateTime: role.createTime,
                    roleUpdateTime: role.updateTime
                });
                this.formRef.current.setFieldsValue({
                    name: role.name || '',
                    description: role.description || ''
                });
            } 
            // else {
            //     message.error('获取角色详情失败,' + res.message);
            // }
        }, error => {
            message.error('获取角色详情失败,' + error);
        });
    }
    getRoleLabel(roleId) {
        roleService.getRoleLabel(roleId || this.state.roleId).then(res => {
            if (res.code === 200) {
                console.log(res);
                const arr = res.labelInfos;
                arr.forEach(ele => {
                    ele.key = ele.id
                })
                this.setState({
                    dataSourceLabel: arr
                });
            } 
            // else {
            //     message.error('获取角色包含的标签失败，' + res.message);
            // }
        }, error => {
            message.error('获取角色包含的标签失败,' + error.message);
        })
    }
    getRoleMenu(roleId) {
        roleService.getRoleMenu(roleId || this.state.roleId).then(res => {
            if (res.code === 200) {
                const arr = res.resources;
                arr.forEach(ele => {
                    ele.key = ele.id
                })
                this.setState({
                    dataSourceMenu: arr
                });
            } 
            // else {
            //     message.error('获取角色包含的权限失败' + res.message);
            // }
        }, error => {
            message.error('获取角色包含的权限失败,' + error.message);
        })
    }
    // trans label
    addTransLabel = () => {
        labelService.getAllLabels().then(res => {
            if (res.code === 200) {
                const labels = res.labels;
                labels.forEach(ele => {
                    ele.key = ele.id;
                })
                const targetData = [];
                this.state.dataSourceLabel.forEach(ele => {
                    if (ele.type === 'USER') {
                        targetData.push(ele.key);
                    }
                })
                this.setState({
                    labelLists: labels,
                    targetLabelKeys: targetData,
                    isLabelVisible: true
                });
            }
        })
    }
    handleOkLabel = () => {
        const arr = [];
        this.state.targetLabelKeys.forEach(ele => {
            arr.push({ id: ele });
        })
        roleService.addLabelForRole(this.state.roleId, arr).then(res => {
            if (res.code === 200) {
                message.success('修改绑定标签成功');
                this.getRoleLabel(this.state.roleId);
            } 
            // else {
            //     message.error('修改绑标签失败，' + res.message);
            // }
        }, error => {
            message.error('修改绑定标签失败，' + error);
        });
        this.setState({
            isLabelVisible: false
        })
    }
    handleCancelLabel = () => {
        this.setState({
            isLabelVisible: false
        })
    }
    removeLabelConnection(labelId) {
        const that = this;
        confirm({
            title: '确定移除吗?',
            icon: <ExclamationCircleOutlined />,
            content: <p style={{ color: 'red' }}>此为解除关联，而非删除标签</p>,
            onOk() {
                roleService.removeLableFromRole(that.state.roleId, labelId).then(res => {
                    if (res.code === 200) {
                        message.success('移除关联成功');
                        that.getRoleLabel(that.state.roleId);
                    }
                    //  else {
                    //     message.error('移除关联失败，' + res.message);
                    // }
                }, error => {
                    message.error('移除关联失败，' + error.message);
                })
            },
            onCancel() {
                console.log('Cancel');
            }
        });
    }

    handleChangeLabel = targetLabelKeys => {
        this.setState({ targetLabelKeys: targetLabelKeys });
    };

    // trans menu
    addTransMenu = () => {
        menuService.getAllMenus().then(res => {
            if (res.code === 200) {
                const menus = res.resources;
                menus.forEach(ele => {
                    ele.key = ele.id;
                })
                const targetData = [];
                this.state.dataSourceMenu.forEach(ele => {
                    // if (ele.type === 'USER') {
                    targetData.push(ele.key);
                    // }
                })
                this.setState({
                    menuLists: menus,
                    targetMenuKeys: targetData,
                    isMenuVisible: true
                });
            }
        })
    }
    handleOkMenu = () => {
        const arr = [];
        this.state.targetMenuKeys.forEach(ele => {
            arr.push({ id: ele });
        });
        roleService.addMenuForRole(this.state.roleId, arr).then(res => {
            if (res.code === 200) {
                message.success('修改绑定权限模块成功');
                this.getRoleMenu(this.state.roleId);
            } 
            // else {
            //     message.error('修改权限模块失败，' + res.message);
            // }
        }, error => {
            message.error('修改权限模块失败，' + error);
        });
        this.setState({
            isMenuVisible: false
        })
    }
    handleCancelMenu = () => {
        this.setState({
            isMenuVisible: false
        })
    }
    removeMenuConnection(menuId) {
        const that = this;
        confirm({
            title: '确定移除吗?',
            icon: <ExclamationCircleOutlined />,
            content: <p style={{ color: 'red' }}>此为解除关联，而非删除权限模块</p>,
            onOk() {
                roleService.removeMenuFromRole(that.state.roleId, menuId).then(res => {
                    if (res.code === 200) {
                        message.success('移除关联成功');
                        that.getRoleMenu(that.state.roleId);
                    } 
                    // else {
                    //     message.error('移除关联失败，' + res.message);
                    // }
                }, error => {
                    message.error('移除关联失败，' + error.message);
                })
            },
            onCancel() {
                console.log('Cancel');
            }
        });
    }

    handleChangeMenu = targetMenuKeys => {
        this.setState({ targetMenuKeys: targetMenuKeys });
    };
    handleClose(item) {
        roleService.removeMenuFormRole(this.state.roleId, item).then(res => {
            if (res.code === 200) {
                this.getRoleMenu(this.state.roleId);
            } 
            // else {
            //     message.error('关闭关联失败，' + res.message);
            //     this.getRoleMenu(this.state.roleId);
            // }
        }, error => {
            message.error('关闭关联失败，' + error.message);
        })
    }
    handleOpen(item) {
        roleService.openMenuItem(this.state.roleId, item).then(res => {
            if (res.code === 200) {
                // message.success('移除关联成功');
                this.getRoleMenu(this.state.roleId);
            } 
            // else {
            //     message.error('建立关联失败，' + res.message);
            //     this.getRoleMenu(this.state.roleId);
            // }
        }, error => {
            message.error('建立关联失败，' + error.message);
        })
    }
    getFunctionList(childResource) {
        // console.log('childResource',childResource);
        return childResource.map(item => {
            return (
                <FunctionItem 
                disabled
                closeItem={(item) => this.handleClose(item)}
                 openItem={(item) => this.handleOpen(item)} 
                 value={item} itemId={item.id} key={item.id} />
            );
        });
    }

    render() {
        const onFinish = (values) => {
            roleService.editRole(this.state.roleId, values.name, values.description).then(res => {
                if (res.code === 200) {
                    message.success('修改成功');
                    this.getRoleDetail(this.state.roleId);
                } 
                // else {
                //     message.error('修改失败，' + res.message);
                // }
            }, error => {
                message.error('失败');
            });
        };
        const columnsLable = [
            {
                title: '标签名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '标签',
                dataIndex: 'type',
                key: 'type',
                render: (text, record) => (
                    <>
                        {
                            record.type === 'SYSTEM' ?
                                <span>预置</span>
                                :
                                <span>自定义</span>
                        }
                    </>
                ),
            },
            {
                title: '关联时间',
                dataIndex: 'createTime',
                key: 'createTime'
            },
            {
                title: '操作',
                dataIndex: 'time',
                key: 'time',
                render: (text, record) => (
                    <>
                        {
                            record.type === 'SYSTEM' ?
                                ''
                                :
                                <a href onClick={() => this.removeLabelConnection(record.id)}>移除</a>
                        }
                    </>
                ),
            }
        ];
        const columnsMenu = [
            {
                title: '系统模块',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '功能操作',
                dataIndex: 'function',
                key: 'function',
                render: (text, record) => (
                    <>
                        {this.getFunctionList(record.childResource)}
                    </>
                ),
            },
            // {
            //     title: '操作',
            //     dataIndex: 'time',
            //     key: 'time',
            //     render: (text, record) => (
            //         <>
            //             {
            //                 <a href onClick={() => this.removeMenuConnection(record.id)}>移除</a>
            //             }
            //         </>
            //     ),
            // }
        ];
        return (
            <>
                <div className="common-detail-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.roleName} itemTitle="角色管理" itemSubTitle="查看角色" />
                    <div className="common-edit-card">
                        <Form name="basic"
                            onFinish={onFinish}
                            labelAlign="left"
                            ref={this.formRef}
                            {...formItemLayout} >
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="name" label="名称" rules={[{ required: true, message: '名称不能为空' }]} >
                                        <Input placeholder="请输入名称" readonly="readonly"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="description" label="描述" >
                                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" readonly="readonly"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <Form.Item label="创建时间" >
                                        {this.state.roleCreateTime}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="更新时间"  >
                                        {this.state.roleUpdateTime || '暂无数据'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <BottomBtnGroup onClick={() => this.goback()} /> */}
                        </Form>
                    </div>

                    {/* <div className="common-trans-card">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="标签" key="1">
                                <Row style={{ marginBottom: 20 }}>
                                    <Button type="primary" onClick={this.addTransLabel}> 添加 </Button>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Table style={{ marginBottom: 20 }} pagination={false} columns={columnsLable} dataSource={this.state.dataSourceLabel} />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="权限" key="2">
                                <Row style={{ marginBottom: 20 }}>
                                    <Button type="primary" onClick={this.addTransMenu}> 添加  </Button>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Table style={{ marginBottom: 20 }} pagination={false} columns={columnsMenu} dataSource={this.state.dataSourceMenu} />
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div> */}
                </div>

                <Modal title="关联标签" width={720}
                    visible={this.state.isLabelVisible}
                    onOk={this.handleOkLabel}
                    onCancel={this.handleCancelLabel}
                    okText="确定"
                    cancelText="取消">
                    <ConfigProvider locale={zhCN}>
                        <Transfer
                            dataSource={this.state.labelLists}
                            listStyle={{ width: 250, height: 300 }}
                            titles={['选择标签', '已选择']}
                            operations={['添加', '移除']}
                            targetKeys={this.state.targetLabelKeys}
                            onChange={this.handleChangeLabel}
                            render={item => `${item.name}`} />
                    </ConfigProvider>
                </Modal>

                <Modal title="关联权限" width={720}
                    visible={this.state.isMenuVisible}
                    onOk={this.handleOkMenu}
                    onCancel={this.handleCancelMenu}
                    okText="确定"
                    cancelText="取消">
                    <ConfigProvider locale={zhCN}>
                        <Transfer
                            dataSource={this.state.menuLists}
                            listStyle={{ width: 250, height: 300 }}
                            titles={['选择模块', '已选择']}
                            operations={['添加', '移除']}
                            targetKeys={this.state.targetMenuKeys}
                            onChange={this.handleChangeMenu}
                            render={item => `${item.name}`} />
                    </ConfigProvider>
                </Modal>


            </>
        );
    }
}

export default ViewAccess;
