import React from 'react';
import { Switch } from 'antd';
import './item.scss'

class FunctionItem extends React.Component {
    onChange(checked, value) {
        const { closeItem } = this.props;
        const { openItem } = this.props;
        const { itemId } = this.props;
        if (checked) {
            openItem(itemId);
        } else {
            closeItem(itemId);
        }
    }
    render() {
        const { value } = this.props;
        let switchStatus = false;
        if (value.states === '1') {
            switchStatus = true;
        } else {
            switchStatus = false;
        }
        return (
            <>
                <div style={{ width: '100%', marginBottom: '10px' }}>
                    <li className="role-menu-item">{value.name}</li>
                    <Switch disabled defaultChecked={switchStatus} 
                    onChange={(event) => this.onChange(event, value)} 
                    />
                </div>
            </>
        );
    }
}
export default FunctionItem;


// authorize: "system:label:delete"
// childResource: []
// id: 20
// odr: null
// parentId: 6
// states: "1"
// type: "ACTION"
// url: null