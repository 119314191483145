import { Upload, message, Card, Breadcrumb, Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './upload-mirror.scss';

const { Dragger } = Upload;
const props = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
class UploadMirror extends React.Component {
    constructor(){
        super();
        this.state = {
          showManage: true
        };
    }
    submit(){
        if(this.props.onSubmit){
            var {showManage} = this.state;
            this.props.onSubmit({showManage})
        }
       // this.setState({showManage});
    }
  render() {
     
    return (
      <>
      {/* <h1 className="top-title">
            <div>
                <img onClick={this.submit.bind(this)} src="/images/left-arrow.png" alt=""/>
                <span>上传镜像</span>
            </div>
            <Breadcrumb>
            <Breadcrumb.Item>
                <a onClick={this.submit.bind(this)}>镜像仓库</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>上传镜像</Breadcrumb.Item>
            </Breadcrumb> 
        </h1> */}
      <Card>
        <Dragger {...props} >
            <p className="ant-upload-drag-icon" style={{paddingTop:36}}>
            <InboxOutlined />
            </p>
            <p className="ant-upload-text" style={{paddingBottom:36,paddingTop:16}}>点击或将镜像文件拖拽到这里上传</p>
        </Dragger>
        <div style={{marginTop: 20, textAlign: 'center'}}>
          <Button type="primary" onClick={() => {
            this.props.onClick();
          }}>
              确认
          </Button>
          <Button style={{marginLeft: 24}} onClick={() => {
            this.props.onClick();
          }}>取消</Button>
        </div>
       
      </Card>
        
      </>
       
    ); 
  }
}

export default UploadMirror;